<div class="header">
  <div class="head-main row">
    <div class="col-6 social">
      <a target="_blank" class="icon" appSocialLink="instagram"><i class="icon-instagram"></i></a>
      <a target="_blank" class="icon" appSocialLink="facebook"><i class="icon-facebook"></i></a>
      <a target="_blank" class="icon" appSocialLink="youtube"><i class="icon-youtube"></i></a>
      <a target="_blank" class="icon" appSocialLink="twiter"><i class="icon-twitter"></i></a>
      <a target="_blank" class="icon" appSocialLink="pinterest"><i class="icon-pinterest2"></i></a>
      <a target="_blank" class="icon" appSocialLink="linkedin"><i class="icon-linkedin2"></i></a>
    </div>
    <div class="col-6 user">
      <div [hidden]="!isUserCredentialProviderAcquiring || loginUser?.UserName" class="message">loading...</div>
      <a href="{{domainName}}account/order-shoppingcart" class="cart"
        [appUserRoleAccessibility]="{userRole:'Dealer,Designer', display:'flex'}"><i class="icon-Cart"></i><span
          class="number">{{shoppingCartCount}}</span><span>cart</span></a>
      <div [hidden]="isUserCredentialProviderAcquiring" class="login">
        <div *ngIf="!loginUser?.UserName" style="position: relative;top: -5px;"><i
            class="icon-user"></i><span>my<i>TA</i></span></div>
        <div style="display: flex">
          <div *ngIf="!loginUser?.UserName" class="" appLoginPopup><i class="icon-circle"></i><a>login<i>/</i></a></div>
          <div *ngIf="!loginUser?.UserName" class=""><a appRegisterPopup>sign up</a></div>
        </div>
      </div>
      <div *ngIf="loginUser?.UserName" class="welcome">
        <i class="icon-user"></i> Welcome, <span class="name"><b>{{loginUser.FirstName}}</b></span>
        <div class="menu">
          <a href="{{domainName}}account/account-profile"><i class="icon-user"></i><span>Account Settings</span></a>
          <a [appUserRoleAccessibility]="{userRole:'Consumer,Sales Associate,Designer,Dealer', display:'block'}"
            href="{{domainName}}account/account-setting"><i class="icon-setting"></i>Your Settings</a>
          <a href="{{domainName}}account/account-wishlist"><i class="icon-heart_outline"></i>Wish Lists</a>
          <a [appUserRoleAccessibility]="{userRole: 'Dealer,Designer', display:'block'}"
            href="{{domainName}}account/account-order"><i class="icon-Oder_History"></i>Order History</a>
          <a (click)="logout($event)"><i class="icon-log_out"></i>Log Out</a>
        </div>
      </div>
      <div class="mode" [appUserRoleAccessibility]="{userRole: 'Dealer,Designer', display:'flex'}">
        <app-ta-mat-slide-toggle-module></app-ta-mat-slide-toggle-module>
      </div>
    </div>
  </div>
</div>