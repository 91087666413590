import { Component, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { TALocalStogareKeys, TALocalStogareService } from 'src/app/service/core/taLocalStorage';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-system-message',
  templateUrl: './system-message.component.html',
  styleUrls: ['./system-message.component.scss']
})
export class SystemMessageComponent implements OnInit {

  isShown: boolean = false;

  constructor(private taLocalStorage: TALocalStogareService, @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      this.isShown = !this.taLocalStorage.get(TALocalStogareKeys.TALocalStogareIsismissedMessage);
    }
  }

  ngOnInit() {
  }

  dismiss(){
    this.isShown = false;
    if (isPlatformBrowser(this.platformId)) {
      this.taLocalStorage.set(TALocalStogareKeys.TALocalStogareIsismissedMessage, 1);
    }
  }
}
