<div class=breadcrumb_ta *ngIf="breadcrumbs?.length">
  <a routerLink="/"><i class="icon-home"></i></a>
  <a class="breadcrumb_link" routerLink="/"> Home</a>
  <span class="breadcrumb_separator">{{" / "}}</span>
  <ng-container *ngFor="let crumb of breadcrumbs;">
    <a class="breadcrumb_link" [routerLink]="[crumb.url]" [hidden]="crumb?.params">{{crumb.label}}</a>
    <a class="breadcrumb_link" [routerLink]="[crumb.url, crumb.params]" [skipLocationChange]="true" *ngIf="crumb?.params">{{crumb.label}}</a>
    <span class="breadcrumb_separator">{{" / "}}</span>
  </ng-container>
</div>
