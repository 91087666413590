import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TaAPIResponse } from './TaApiServiceCommon';
import { map, tap } from 'rxjs/operators';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { ModelApiBrand, ModelApiCity, ModelApiCollection, ModelApiCountry, ModelApiDesigner, ModelApiLifeStyle, ModelApiOptionGroup, ModelApiRegion, ModelApiRoom, ModelApiStyle, ModelApiType } from 'src/app/service/api/model/model';

class TaApiServiceMetaCityRegionCountry {
  constructor(private http: HttpClient) { }
  get(cityName: string): Observable<TaAPIResponse<ModelApiCity[]>> {
    const command: string = `api/Store/GetCityRegionCountry?cityName=${cityName}`;
    return this.http.get<TaAPIResponse<ModelApiCity[]>>(`${environment.appApiUrl}/${command}`).pipe(map(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        res.Data.map(i => {
          return i.value = i.ID;
        })
      }
      return res;
    }));
  }
}

class TaApiServiceMetaCountry {
  private countriesBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiCountry[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiCountry[]>> {
    if (this.countriesBehaviorSubject?.value) {
      return this.countriesBehaviorSubject;
    } else {
      const command: string = 'api/Store/GetCountries';
      return this.http.get<TaAPIResponse<ModelApiCountry[]>>(`${environment.appApiUrl}/${command}`).pipe(
        map(res => {
          if (TAUtility.isAPIRespondOk(res)) {
            res.Data.map(i => {
              return i.value = i.ID;
            })
          }
          return res;
        })
        ,tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.countriesBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaRegion {
  private regionBehaviorSubjectDict: { [countryId: string]: BehaviorSubject<TaAPIResponse<ModelApiRegion[]>> } = {};
  constructor(private http: HttpClient) { }
  get(countryId: string): Observable<TaAPIResponse<ModelApiRegion[]>> {
    if (this.regionBehaviorSubjectDict[countryId]?.value) {
      return this.regionBehaviorSubjectDict[countryId];
    } else {
      const command: string = `api/Store/GetRegionsByCountryId/${countryId}`;
      return this.http.get<TaAPIResponse<ModelApiRegion[]>>(`${environment.appApiUrl}/${command}`).pipe(
        map(res => {
          if (TAUtility.isAPIRespondOk(res)) {
            res.Data.map(i => {
              return i.value = i.ID;
            })
          }
          return res;
        }),
        tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.regionBehaviorSubjectDict[countryId] = new BehaviorSubject(res);
        }
      }));
    }
  }
}


class TaApiServiceMetaCity {
  private cityBehaviorSubjectDict: { [regionId: string]: BehaviorSubject<TaAPIResponse<ModelApiCity[]>> } = {};
  constructor(private http: HttpClient) { }
  get(regionId: string): Observable<TaAPIResponse<ModelApiCity[]>> {
    if (this.cityBehaviorSubjectDict[regionId]?.value) {
      return this.cityBehaviorSubjectDict[regionId];
    } else {
      const command: string = `api/Store/GetCitiesByRegionId/${regionId}`;
      return this.http.get<TaAPIResponse<ModelApiCity[]>>(`${environment.appApiUrl}/${command}`).pipe(
        map(res => {
          if (TAUtility.isAPIRespondOk(res)) {
            res.Data.map(i => {
              return i.value = i.ID;
            })
          }
          return res;
        })
        ,tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.cityBehaviorSubjectDict[regionId] = new BehaviorSubject(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaStyle {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiStyle[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiStyle[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetStyles';
      return this.http.get<TaAPIResponse<ModelApiStyle[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiStyle[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaLifeStyle {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiLifeStyle[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiLifeStyle[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetLifeStyles';
      return this.http.get<TaAPIResponse<ModelApiLifeStyle[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiLifeStyle[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaRoom {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiRoom[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiRoom[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetRooms';
      return this.http.get<TaAPIResponse<ModelApiRoom[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiRoom[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaType {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiType[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiType[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetTypes';
      return this.http.get<TaAPIResponse<ModelApiType[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiType[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}


class TaApiServiceMetaCollection {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiCollection[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiCollection[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetCollections';
      return this.http.get<TaAPIResponse<ModelApiCollection[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiCollection[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaBrand {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiBrand[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiBrand[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetBrands';
      return this.http.get<TaAPIResponse<ModelApiBrand[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiBrand[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaDesigner {
  private dataBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiDesigner[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiDesigner[]>> {
    if (this.dataBehaviorSubject.value) {
      return this.dataBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetDesigners';
      return this.http.get<TaAPIResponse<ModelApiDesigner[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiDesigner[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dataBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaOptionGroup {
  private optionGroupBehaviorSubject: BehaviorSubject<TaAPIResponse<ModelApiOptionGroup[]>> = new BehaviorSubject(null);
  constructor(private http: HttpClient) { }
  get(): Observable<TaAPIResponse<ModelApiOptionGroup[]>> {
    if (this.optionGroupBehaviorSubject.value) {
      return this.optionGroupBehaviorSubject;
    } else {
      const command = '/api/MetaData/GetOptionGroups';
      return this.http.get<TaAPIResponse<ModelApiOptionGroup[]>>(`${environment.appApiUrl}/${command}`).pipe(tap((res: TaAPIResponse<ModelApiOptionGroup[]>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.optionGroupBehaviorSubject.next(res);
        }
      }));
    }
  }
}

class TaApiServiceMetaResourceFileRule {
  constructor(private http: HttpClient) { }

  getAllItemResourceFiles(): Observable<TaAPIResponse<TaApiItemResourceFile[]>> {
    const command = '/api/MetaData/GetAllItemResourceFiles';
    return this.http.get<TaAPIResponse<any[]>>(`${environment.appApiUrl}/${command}`);
  }

  getItemResourceFileByID(id: string): Observable<TaAPIResponse<TaApiItemResourceFile>> {
    const command = `/api/MetaData/GetItemResourceFileByID?id=${id}`;
    return this.http.get<TaAPIResponse<any>>(`${environment.appApiUrl}/${command}`);
  }

  updateItemResourceFile(item: TaApiItemResourceFile) {
    const command = `${environment.appApiUrl}/api/MetaData/UpdateItemResourceFile`;
    return this.http.post<TaAPIResponse<any>>(command, item);
  }

  addItemResourceFile(item: TaApiItemResourceFile) {
    const command = `${environment.appApiUrl}/api/MetaData/AddItemResourceFile`;
    return this.http.post<TaAPIResponse<any>>(command, item);
  }

  deleteItemResourceFile(id) {
    const command = `/api/MetaData/DeleteItemResourceFile?id=${id}`;
    return this.http.get<TaAPIResponse<any>>(`${environment.appApiUrl}/${command}`);
  }

  getRulesByItemResourceFileID(id): Observable<TaAPIResponse<TaApiItemResourceFileRule[]>> {
    const command = `${environment.appApiUrl}/api/MetaData/GetRulesByItemResourceFileID?ItemResourceFileID=${id}`;
    return this.http.get<TaAPIResponse<TaApiItemResourceFileRule[]>>(`${command}`).pipe(map(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        res.Data.forEach(element => {
          element.Rule = JSON.parse(element.Rule);
        });
      }
      return res;
    }));
  }

  updateItemResourceFileRule(rule: TaApiItemResourceFile) {
    const command = `${environment.appApiUrl}/api/MetaData/UpdateItemResourceFileRule`;
    return this.http.post<TaAPIResponse<any>>(command, rule);
  }

  addItemResourceFileRule(rule: TaApiItemResourceFile) {
    const command = `${environment.appApiUrl}/api/MetaData/AddItemResourceFileRule`;
    return this.http.post<TaAPIResponse<any>>(command, rule);
  }

  deleteItemResourceFileRule(id) {
    const command = `/api/MetaData/DeleteItemResourceFileRule?id=${id}`;
    return this.http.get<TaAPIResponse<any>>(`${environment.appApiUrl}/${command}`);
  }

  filterItemResourceFileRuleId(id) {
    const command = `/api/MetaData/FilterItemsByRule?id=${id}`;
    return this.http.get<TaAPIResponse<TaApiItemResourceFile>>(`${environment.appApiUrl}/${command}`);
  }
}


@Injectable({
  providedIn: 'root'
})

export class TaApiServiceMeta {
  apiServiceMetaOptionGroup: TaApiServiceMetaOptionGroup;
  apiServiceMetaResourceFileRule: TaApiServiceMetaResourceFileRule;
  apiServiceMetaDesigner: TaApiServiceMetaDesigner;
  apiServiceMetaBrand: TaApiServiceMetaBrand;
  apiServiceMetaCollection: TaApiServiceMetaCollection;
  apiServiceMetaType: TaApiServiceMetaType;
  apiServiceMetaRoom: TaApiServiceMetaRoom;
  apiServiceMetaStyle: TaApiServiceMetaStyle;
  apiServiceMetaLifeStyle: TaApiServiceMetaLifeStyle;
  apiServiceMetaCountry: TaApiServiceMetaCountry;
  apiServiceMetaRegion: TaApiServiceMetaRegion;
  apiServiceMetaCity: TaApiServiceMetaCity;
  apiServiceMetaCityRegionCountry: TaApiServiceMetaCityRegionCountry

  constructor(private http: HttpClient) {
    this.apiServiceMetaOptionGroup = new TaApiServiceMetaOptionGroup(http);
    this.apiServiceMetaResourceFileRule = new TaApiServiceMetaResourceFileRule(http);
    this.apiServiceMetaDesigner = new TaApiServiceMetaDesigner(http);
    this.apiServiceMetaBrand = new TaApiServiceMetaBrand(http);
    this.apiServiceMetaCollection = new TaApiServiceMetaCollection(http);
    this.apiServiceMetaType = new TaApiServiceMetaType(http);
    this.apiServiceMetaRoom = new TaApiServiceMetaRoom(http);
    this.apiServiceMetaStyle = new TaApiServiceMetaStyle(http);
    this.apiServiceMetaLifeStyle = new TaApiServiceMetaLifeStyle(http);
    this.apiServiceMetaCountry = new TaApiServiceMetaCountry(http);
    this.apiServiceMetaRegion = new TaApiServiceMetaRegion(http);
    this.apiServiceMetaCity = new TaApiServiceMetaCity(http);
    this.apiServiceMetaCityRegionCountry = new TaApiServiceMetaCityRegionCountry(http);
  }
}

export class TaApiItemResourceFile {
  ID?: string;
  Name?: string;
  IsEnabled?: boolean;
  Title?: string;
  Category?: string;
  URL?: string;
  ModifiedBy?: string;
  ModifiedDate?: string;
  Property?: string;
}

export class TaApiItemResourceFileRule {
  ID?: string;
  ItemResourceFile_ID?: string;
  Rule?: any;
  Title?: string;
  Description?: string;
  IsEnabled?: boolean;
  ModifiedBy?: string;
  ModifiedDate?: string;
  Region?: string;
}
