import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TaConstantsService {
  DatabaseData = {
    OptionGroups: {
      US_UPD_Group: { ID: "29CB4321-A304-45C6-9D48-BB649CF3CBB0" },
      Group_UPD: { ID: "4223A7BB-EB4B-4AD2-9271-D5BBFDE284E2" },
      AXH_Finish_Group: { ID: "6D3916AD-04F4-4D9B-97E9-5A1FCDE2DCA2" },
      US_Finish_Group: { ID: "111295C4-B75A-4435-B789-3CA594436937" },
    },
    Brands: {
      TA_Accents: { ID: "026FB9F7-3D9F-4A1E-BE73-1C5A3A35B463" },
      Alexa_Hampton: { ID: "37CDA835-EEB5-45A4-B132-4D505B1534F7" },
      Keno_Bros: { ID: "6D19B18B-BE99-403D-9BFC-5377E7130EBA" },
      Althorp_Living_History: { ID: "D9D7C23D-82B6-4470-B9AE-AF51AFBD7F4D" },
      TA_Studio: { ID: "87614FBC-9801-4312-A2D4-BC2F67C345AA" },
      Salone: { ID: "76CAF86D-B8A8-4416-9DA8-DB2A031F7D67" },
      Michael_Berman: { ID: "AE36B491-8722-4F65-BF00-EA910BF78745" },
      Upholstery: { ID: "52D2444F-4BC6-4540-8159-F0B8BD4423AC" },
      Theodore_Alexander: { ID: "D1483451-48E9-4FB4-BA11-FA8552084DFF" },
    },
    Collections: {
      The_Alexa_Hampton_Collection: { ID: "9E007F80-83DD-467A-92ED-21AF909E9F76" }
    },
    DATA_FOR_TESTING: [
      { id: '10546', categoryName: "Aiden Chair", sku: "5253", brandLogo: "assets/fd_logo.png", disablePrice: false, urlImage: '' },
      // { id: '10271', categoryName: "Aiden Sectional", sku: "609-53/609-68", brandLogo: "assets/fd_logo.png", disablePrice: false, urlImage: '' },
      { id: '10547', categoryName: "Aiden Sofa", sku: "608-10", brandLogo: "assets/fd_logo.png", disablePrice: false, urlImage: '' },
      { id: '14196', categoryName: "Sasha Swivel Chair", sku: "5282", disablePrice: false, urlImage: '' },
      { id: '10549', categoryName: "Aiden II Sofa", sku: "608-10", disablePrice: false, urlImage: '' },
      { id: '10577', categoryName: "Aiden Dressmaker Swivel Chair", sku: "5251", disablePrice: false, urlImage: '' },
      { id: '14191', categoryName: "Nob Hill Stool", sku: "8053", disablePrice: false, urlImage: '' },
      { id: '14190', categoryName: "Lia Side Chair", sku: "3001", disablePrice: false, urlImage: '' },
      { id: '14199', categoryName: "Joan Slipper Chair", sku: "5310", disablePrice: false, urlImage: '' },
      { id: '14172', categoryName: "Catalina Channel Arm 95' Sofa", sku: "TASU50149-95", disablePrice: false, urlImage: '' },
      { id: '14173', categoryName: "Catalina Channel Arm Club Chair", sku: "TASU50149-32", disablePrice: false, urlImage: '' },
      { id: '14194', categoryName: "Willoughby Chair", sku: "MB104", disablePrice: false, urlImage: '' },
      { id: '14183', categoryName: "Riley Upholstered Chair (Cushion Seat and Back)", sku: "5189", disablePrice: false, urlImage: '' },
      { id: '14174', categoryName: "Catalina Swivel Chair", sku: "TASU52149", disablePrice: false, urlImage: '' },
      { id: '10784', categoryName: "Aiden LAF Chaise", sku: "609-41", disablePrice: false, urlImage: '' },
      { id: '10785', categoryName: "Aiden Right Arm Chaise", sku: "609-42", disablePrice: false, urlImage: '' },
      { id: '14167', categoryName: "Ravenswood Sofa", sku: "TASU50101-85", disablePrice: false, urlImage: '' },
      { id: '14197', categoryName: "Brett Upholstered Chair", sku: "5260", disablePrice: false, urlImage: '' },
      { id: '14171', categoryName: "Catalina Channel Arm 85' Sofa", sku: "TASU50149-85", disablePrice: false, urlImage: '' },
    ],
  }

  constructor() { }
}
