import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Banner } from 'src/app/shared/Banner.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class BannerService {
    list: Banner[] | undefined;    
    formData!: Banner;
    formData001!: Banner;
    formData002!: Banner;
    formData003!: Banner;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "Banner";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];      
        this.formData = {
        };
    }    
    getByID(ID: number): Observable<TaAPIResponse<Banner>> {
        let url = this.aPIURL + this.controller + '/GetByID';
        const params = new HttpParams()            
            .set('ID', JSON.stringify(ID))
        return this.httpClient.get<TaAPIResponse<Banner>>(url, { params }).pipe(tap((res: any) => {
        }));
    }    
}

