import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Type } from 'src/app/shared/Type.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class TypeService {
    list: Type[];
    listData: Type[];
    listMenuTop: Type[];    
    formData: Type;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "Type";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
        this.listData = [];
        this.listMenuTop = [];        
        this.formData = {           
        };
    }    
    getByID(ID: string): Observable<TaAPIResponse<Type>> {
        let url = this.aPIURL + this.controller + '/GetByID';
        const params = new HttpParams()
            .set('ID', ID)
        return this.httpClient.get<TaAPIResponse<Type>>(url, { params }).pipe(tap((res: any) => {
        }));
    }        
    getByURLCode(URLCode: string): Observable<TaAPIResponse<Type>> {
        let url = this.aPIURL + this.controller + '/GetByURLCode';
        const params = new HttpParams()
            .set('URLCode', URLCode)
        return this.httpClient.get<TaAPIResponse<Type>>(url, { params }).pipe(tap((res: any) => {
        }));
    }    
    getByIsActiveAndRegionToList(isActive: boolean, region: string): Observable<TaAPIResponse<Type>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveAndRegionToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
            .set('region', region)
        return this.httpClient.get<TaAPIResponse<Type>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByIsActiveToList(isActive: boolean): Observable<TaAPIResponse<Type>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))            
        return this.httpClient.get<TaAPIResponse<Type>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

