import { Injectable, TemplateRef } from '@angular/core';
import { NzMessageData, NzMessageService } from 'ng-zorro-antd/message';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TawMessageService {

  constructor(private messageService: NzMessageService) { }

  remove(id?: string) {
    this.messageService.remove(id);
  }

  success(content: string | TemplateRef<void>): Pick<Required<NzMessageData>, "onClose" | "messageId"> {
    return this.messageService.success(content, { nzDuration: 3000 });
  }

  error(content: string | TemplateRef<void>): Pick<Required<NzMessageData>, "onClose" | "messageId"> {
    return this.messageService.error(content, { nzDuration: 7000 });
  }

  info(content: string | TemplateRef<void>): Pick<Required<NzMessageData>, "onClose" | "messageId"> {
    return this.messageService.info(content, { nzDuration: 5000 });
  }

  warning(content: string | TemplateRef<void>): Pick<Required<NzMessageData>, "onClose" | "messageId"> {
    return this.messageService.warning(content, { nzDuration: 7000 });
  }

  loading(content: string | TemplateRef<void>): Pick<Required<NzMessageData>, "onClose" | "messageId"> {
    return this.messageService.loading(content);
  }
}

