import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MarketingResourceCategory } from 'src/app/shared/MarketingResourceCategory.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class MarketingResourceCategoryService {
    list: MarketingResourceCategory[];
    formData!: MarketingResourceCategory;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "MarketingResourceCategory";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.formData = {            
        }
    }    
    getByActiveToList(active: boolean): Observable<TaAPIResponse<MarketingResourceCategory>> {
        let url = this.aPIURL + this.controller + '/GetByActiveToList';
        const params = new HttpParams()        
        .set('active', JSON.stringify(active))
        return this.httpClient.get<TaAPIResponse<MarketingResourceCategory>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
    getByActiveAndRegionToList(active: boolean, region: string): Observable<TaAPIResponse<MarketingResourceCategory>> {
        let url = this.aPIURL + this.controller + '/GetByActiveAndRegionToList';
        const params = new HttpParams()        
        .set('active', JSON.stringify(active))
        .set('region', region)
        return this.httpClient.get<TaAPIResponse<MarketingResourceCategory>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
}

