import { Directive, ElementRef, AfterViewInit, OnDestroy, HostBinding, OnInit, HostListener, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appHrefExternal]'
})
export class HrefExternalDirective implements OnInit, OnChanges {

  @Input('appHrefExternal') link: string;
  @HostBinding('attr.href') href: string;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.injectURL();
  }

  ngOnInit(): void {

  }

  injectURL(){
    if (this.link) {
      if (this.link.toUpperCase().startsWith('HTTP')) {
        this.href = this.link;
      } else {
        this.href = `//${this.link}`;
      }
    }
  }
}

