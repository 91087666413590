import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, Input, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { TAAlertConfiguration, TAAlertConfigurationType, TaAlertPopup } from '../ta-alert-popup/ta-alert-popup.component';
import { MatDialogConfig, MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TaApiServiceCommon, TAAPIShareProductByEmailViewModel } from 'src/app/service/api/TaApiServiceCommon';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { iTaObserverComponent, TaFormHandler } from 'src/app/theoalex/taObserverComponent';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TAFormFieldComponentConfig, TAFormFieldComponentValidator } from '../address-form/address-form.component';
import { TawMessageService } from 'src/app/service/web/taw-message.service';
import { NzModalRef } from 'ng-zorro-antd/modal';

@Component({
  selector: 'app-share-product-email',
  templateUrl: './share-product-email.component.html',
  styleUrls: ['./share-product-email.component.scss']
})
export class ShareProductEmailComponent implements OnInit, OnDestroy, AfterViewInit {
  formHandler: TaFormHandler;
  AddressFormComponentValidator = TAFormFieldComponentValidator;
  formGroup: FormGroup;
  formConfig: TAFormFieldComponentConfig = {
    fromEmail: {value:"", errorMessage: "Please provide your valid email",  placeHolder:"Your email (*)", validators: {
      [TAFormFieldComponentValidator.required]: TAFormFieldComponentValidator.required,
      [TAFormFieldComponentValidator.email]: TAFormFieldComponentValidator.email,
    }},
    fromName: {value:"", errorMessage:"Please provide your name", placeHolder:"Your name (*)", validators: {
      [TAFormFieldComponentValidator.required]: TAFormFieldComponentValidator.required,
    }},
    toEmail: {value:"", errorMessage: "Please provide recipient's valid email", placeHolder:"Recipient's email", validators: {
      [TAFormFieldComponentValidator.required]: TAFormFieldComponentValidator.required,
      [TAFormFieldComponentValidator.email]: TAFormFieldComponentValidator.email,
    }},
    toName: {value:"", errorMessage:"Please provide recipient's name",  placeHolder:"Recipient's name", validators: {
      [TAFormFieldComponentValidator.required]: TAFormFieldComponentValidator.required,
    }},
    isSendCopy: {value: true, placeHolder:"Send yourself a copy"},
    message: {value:"", placeHolder:"Your message"},
  }
  @ViewChild("firstInputFocus") firstInputFocus: ElementRef;

  maximumRecipterError = false;
  check: boolean;
  shareProductSubcriber: Subscription;
  product: any;
  modalRef: NzModalRef;
  itemId: string;

  constructor(private matDialog: MatDialog,
    private dialog: NgbModal,
    private serviceCommon: TaApiServiceCommon,
    private fb: FormBuilder,
    private messageService: TawMessageService) {
      this.formHandler = new TaFormHandler(this.fb);
    }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.firstInputFocus.nativeElement.focus();
    }, 500)
  }

  ngOnInit() {
    this.formGroup = this.formHandler.initForm(this.formConfig);
  }


  ngOnDestroy(): void {
    TAUtility.unsubscribe(this.shareProductSubcriber);
  }

  validateForm(): boolean {
    let isValid: boolean = true;
    for (const i in this.formGroup.controls) {
      this.formGroup.controls[i].markAsDirty();
      this.formGroup.controls[i].updateValueAndValidity();
      isValid = isValid && this.formGroup.controls[i].valid;
    }
    return isValid;
  }

  sharedProduct() {
    let isValidForm = this.validateForm();
    if (isValidForm) {
      let viewModel : TAAPIShareProductByEmailViewModel = {
        ItemId: this.itemId,
        FromEmail: this.formConfig.fromEmail.value,
        FromName: this.formConfig.fromName.value,
        ToEmail: this.formConfig.toEmail.value,
        ToName: this.formConfig.toName.value,
        IsSendCopy: this.formConfig.isSendCopy.value,
        Message: this.formConfig.message.value
      }

      let messageRef = this.messageService.loading("Sending...");

      this.shareProductSubcriber = this.serviceCommon.shareProductByEmail(viewModel).subscribe((res: any) => {
        this.messageService.remove(messageRef.messageId);
        if (TAUtility.isAPIRespondOk(res)) {
          this.messageService.success("Your request has been sent");
        } else {
          this.messageService.error("Send request failed: " + res.Data)
        }
      });
    }
  }
}
