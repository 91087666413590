<mat-slide-toggle color="primary" (change)="onChanged($event)" [(ngModel)]="isPurchaseMode">
   <div class="toogle-top-header">
      <span class="d-none d-md-inline">
        <span [hidden]="isPurchaseMode">Retail</span>
        <span [hidden]="!isPurchaseMode">Purchase</span>
      </span>
      <span class="d-md-none">
        <span [hidden]="isPurchaseMode">R</span>
        <span [hidden]="!isPurchaseMode">P</span>
      </span>
   </div>
</mat-slide-toggle>
