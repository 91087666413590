import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IntiaroWishlist } from 'src/app/shared/IntiaroWishlist.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TAUtility } from 'src/app/theoalex/theoalex';

@Injectable({
    providedIn: 'root'
})
export class IntiaroWishlistService {
    list: IntiaroWishlist[] | undefined;
    formData: IntiaroWishlist = {};    
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "IntiaroWishlist";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
    }   
    getByUserID(userID: string): Observable<TaAPIResponse<IntiaroWishlist>> {
        let url = this.aPIURL + this.controller + '/GetByUserID';
        const params = new HttpParams()
            .set('userID', userID)
        return this.httpClient.get<TaAPIResponse<IntiaroWishlist>>(url, { params }).pipe(tap((res: any) => {
        }));
    }    
}

