import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'Trim'})
export class Trim implements PipeTransform {
    transform(str: string, ...args: any[]):string {
        return str ? str.trim() : "";
    }
}

// @Pipe({name: 'fabricName'})
// export class TransformFabricName implements PipeTransform {
//     transform(fabricCode: string,):string {
//       let fabricName: string;
//         if (fabricCode) {
//           fabricName = fabricCode.split('*')[0];
//         }
//         return fabricName;
//     }
// }
