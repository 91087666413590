<div id="theodoreIntiaro">
    <div style="text-align:center;">
        <a title="TAilor Fit" href="dummy/3D-tailorfit.html"
            style="text-decoration: none; border-bottom: 0px solid #636363; text-align: center;">
            <img src="assets/TAilorFit.png" alt="TAilor Fit" title="TAilor Fit" style="width: 20%; margin: 3% 0 0 0;">
        </a>
    </div>
</div>
<div class="row" style="margin: 3% 0;"> 
    <div class="col-xl-4">
    </div>   
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12411" title="Loveseat"><img
                src="https://theodorealexander.com/images/Intiaro/Loveseat.jpg" width=270 height=270 title="Loveseat"
                alt="Loveseats"></a>
        <p style="font-size: 18px;">Loveseat</p>
    </div>
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12457" title="Long Loveseat"><img
                src="https://theodorealexander.com/images/Intiaro/LongLoveseat.jpg" width=270 height=270 title="Long Loveseat"
                alt="Long Loveseat"></a>
        <p style="font-size: 18px;">Long Loveseat</p>
    </div>   
    <div class="col-xl-4">
    </div>
</div>