import { Directive, HostListener, ElementRef, Renderer2, HostBinding } from '@angular/core';
import { environment } from 'src/environments/environment';

@Directive({
  selector: '[appTBDClick]'
})
export class TBDClickDirective {

  constructor(private elementRef: ElementRef, private render: Renderer2) { }

  @HostBinding('style.cursor') cursor : string = 'not-allowed';
  

  @HostListener("click", ['$event']) function(evt) {
    if (environment.production) {
      console.log("This feature is in development.");
    } else {
      alert("This feature is in development.");
    }
    return false;
  }
}
