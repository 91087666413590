<div class="slider d-flex flex-column">
  <div class="slider_content d-flex flex-grow-1 overflow-hidden">
      <div *ngIf="listGhostBlog001.length" class="swiper-container position-relative w-100" [swiper]="swipeConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide slider_item d-flex" style="display: flex; justify-content: center;" *ngFor="let post of listGhostBlog001">
            <div class="img-fullsize-top-home-blog">
              <a style="color: #000 !important;" href="{{domainName}}blog/{{post.URLCode}}.html">
                <img class="pic-blog-top" style="object-fit: cover; object-position: center;" src="{{post.URLImage}}" width="100%" height="100%"></a>
            </div>
            <div class="post-title content-main-post-top">
              <a style="color: #000 !important;" href="{{domainName}}blog/{{post.URLCode}}.html" title="{{ post.Title }}">
                <h1 class="title-detail-post">{{ post.Title }}</h1>
                <div class="short-description-top">{{ post.Description }}</div>
              </a>
              <div class=" info-post">
                <span class="by-post"><img style="width: 20px; height: 20px; border-radius: 10px" src="https://theodorealexander.com/images/TA_logo.png"><span style="margin-left: 5px">By {{post.Author}}</span></span>
                <span class="date-post-detail"><i class="icon-timer"></i>{{post.DatePostString}}</span>
                <div class="share-detail">
                  <span>Share:</span>
                  <a target="_blank" href="{{post.ShareFacebook}}" title="{{ post.ShareFacebook }}"><i class="icon-facebook"></i></a>
                  <a target="_blank" href="{{post.ShareTwitter}}" title="{{ post.ShareTwitter }}"><i class="icon-twitter"></i></a>
                  <a target="_blank" href="{{post.SharePinterest}}" title="{{ post.SharePinterest }}"><i class="icon-pinterest2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="_TaGlobalCssSwiper_slider_navigation">
          <div class="_TaGlobalCssSwiper_slider_navigation_left">
            <div class="_TaGlobalCssSwiper_slider_navigation_left-button"></div>
          </div>
          <div class="_TaGlobalCssSwiper_slider_navigation_right">
            <div
              class="_TaGlobalCssSwiper_slider_navigation_right-button"
            ></div>
          </div>
        </div>
      </div>
  </div>
</div>
