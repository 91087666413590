import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ItemService } from 'src/app/shared/Item.service';
import { Item } from 'src/app/shared/Item.model';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { Router, NavigationEnd } from '@angular/router';
@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {
  queryString: string = "";
  ID: string;
  SKU: string;
  URL: string;
  URLAlternate: string = environment.appWebUrl;
  constructor(
    public itemService: ItemService,
    public typeService: TypeService,
    public collectionService: CollectionService,
    public router: Router,
  ) { 
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        if (this.queryString.includes("dealer-locator")) {
          let url = environment.appWebUrl + "dealer-locator.html";
          window.location.href = url;
        }
      }
    });
  }

  ngOnInit(): void {
    //this.setURL();
  }
  setURL() {
    this.URL = localStorage.getItem('URL');
    this.ID = localStorage.getItem('ID');
    this.SKU = localStorage.getItem('SKU');
    if (this.URL === environment.ProductDetail) {
      if(this.ID){
        this.itemService.getByID(this.ID).subscribe(res => {
          if (res) {            
            this.itemService.formData = (res.Data as Item);
            this.URLAlternate = environment.appWebUrl + environment.ProductDetail + "/" + this.itemService.formData.URLCode + ".html";
          }
        });
      }
      if(this.SKU){
        this.itemService.getBySKU(this.SKU).subscribe(res => {
          if (res) {
            this.itemService.formData = (res.Data as Item);
            this.URLAlternate = environment.appWebUrl + environment.ProductDetail + "/" + this.itemService.formData.URLCode + ".html";
          }
        });
      }
    }
    if (this.URL === environment.Type) {
      if(this.ID){
        this.typeService.getByID(this.ID).subscribe(res => {
          if (res) {
            this.typeService.formData = (res.Data as Type);
            this.URLAlternate = environment.appWebUrl + environment.Type + "/" + this.typeService.formData.URLCode + ".html";
          }
        });
      }
    }
    if (this.URL === environment.Collection) {
      if(this.ID){
        this.collectionService.getByID(this.ID).subscribe(res => {
          if (res) {
            this.collectionService.formData = (res.Data as Collection);
            this.URLAlternate = environment.appWebUrl + environment.Collection + "/" + this.collectionService.formData.URLCode + ".html";
          }
        });
      }
    }
  }
}
