<!-- <div class="block_menu_desktop_custom block_menu_desktop_item d-flex justify-content-between">
    <div class="block_menu_desktop_item_left">
        <h1>
            Upholstery
        </h1>
        <ul>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/about-upholstery" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_1.jpg">
                        <span>About Our Upholstery</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a href="{{collectionService ? tailorfitSubdomain  : collectionService.formData001.URL}}">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_2.jpg"
                            alt="{{collectionService ? 'TAilor Fit' : collectionService.formData001.DisplayName }}"
                            title="{{collectionService ? 'TAilor Fit' : collectionService.formData001.DisplayName}}">
                        <span>{{collectionService ? 'TAilor Fit' : collectionService.formData001.DisplayName }}</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryOurFabric, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/our-fabric" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_3.jpg">
                        <span>Our Fabrics</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryResource, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/upholstery-resource" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_4.jpg">
                        <span>Resources</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
    <div class="block_menu_desktop_item_right">
        <h1>Custom Palette Program</h1>
        <ul>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/about-custom-palette" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_1.jpg">
                        <span>About Custom Palette</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a href="{{domainName}}special/custom-palette.html">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_2.jpg"
                            alt="Custom Palette">
                        <span>Custom Palette Program</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteOurPalette, category: GoogleAnalyticEventCategory.taUserAction}'
                        href='{{"https://theodorealexander.sirv.com/website/Frontend/Live/assests/pdf/Custom-Palette.pdf" | tawSkipCachedFile}}'
                        target="_blank">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_3.jpg">
                        <span>Our Palette</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteResource, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/palette-download" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_4.jpg">
                        <span>Resources</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
</div> -->


<div class="block_menu_desktop_products block_menu_desktop_item d-flex justify-content-between">
    <ul>
        <li>
            <h1>Upholstery</h1>
        </li>
        <ng-container>
            <li>
                <a routerLink="/web/about-upholstery" (click)="onRouterClick()">About Our Upholstery</a>
            </li>
            <li>
                <a routerLink="/web/our-fabric" (click)="onRouterClick()">Our Fabrics</a>
            </li>
            <li>
                <a routerLink="/web/upholstery-resource" (click)="onRouterClick()">Resources</a>
            </li>
        </ng-container>
    </ul>

    <ul>
        <li>
            <h1>Custom Palette Program</h1>
        </li>
        <ng-container>
            <li>
                <a routerLink="/web/about-custom-palette" (click)="onRouterClick()">About Custom Palette</a>
            </li>
            <li>
                <a href="{{domainName}}special/custom-palette.html" (click)="onRouterClick()">Custom Palette Program</a>
            </li>
            <li>
                <a (click)="onRouterClick()"
                    href='{{"https://theodorealexander.sirv.com/website/Frontend/Live/assests/pdf/Custom-Palette.pdf" | tawSkipCachedFile}}'>Our
                    Palette</a>
            </li>
            <li>
                <a routerLink="/web/palette-download" (click)="onRouterClick()">Resources</a>
            </li>
        </ng-container>
    </ul>
    <ul>
        <li>
            <h1>3D VISUALIZATION</h1>
        </li>
        <ng-container>
            <li>
                <a href="{{tailorfitSubdomain}}" (click)="onRouterClick()">TAilor Fit Custom Upholstery</a>
            </li>
            <li>
                <a routerLink="/draper" (click)="onRouterClick()">Sofas and Chairs</a>
            </li>
        </ng-container>
    </ul>
    <ul>
        <li>
            <h1>VIRTUAL TOUR</h1>
        </li>
        <ng-container>
            <li>
                <a routerLink="/virtual-showroom" (click)="onRouterClick()">Virtual showroom tour</a>
            </li>
        </ng-container>
    </ul>
</div>