<!-- <div class="block_menu_desktop_custom block_menu_desktop_item d-flex justify-content-between">
    <div class="block_menu_desktop_item_right">
        <h1>
            3D UPHOLSTERY VISUALIZATION
        </h1>
        <ul>
            <li>
                <div class="image">
                    <a href="{{tailorfitSubdomain}}">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_2.jpg"
                            alt="TAilor Fit" title="TAilor Fit">
                        <span>TAilor Fit</span>
                    </a>
                </div>
            </li>
            <li></li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteResource, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/drapper" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/3_1.jpg">
                        <span>Sofas and Chairs</span>
                    </a>
                </div>
            </li>
            <li></li>
        </ul>
    </div>
</div> -->

<!-- <div class="block_menu_desktop_custom block_menu_desktop_item d-flex justify-content-between"> -->
<!-- <div class="block_menu_desktop_item_left">
        <h1>
            Upholstery
        </h1>
        <ul>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/about-upholstery" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_1.jpg">
                        <span>About Our Upholstery</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a href="{{tailorfitSubdomain}}">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_2.jpg"
                            alt="TAilor Fit" title="TAilor Fit">
                        <span>TAilor Fit</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryOurFabric, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/our-fabric" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_3.jpg">
                        <span>Our Fabrics</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryResource, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/upholstery-resource" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_4.jpg">
                        <span>Resources</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
    <div class="block_menu_desktop_item_right">
        <h1>Custom Palette Program</h1>
        <ul>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/about-custom-palette" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_1.jpg">
                        <span>About Custom Palette</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a href="{{domainName}}special/custom-palette.html">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_2.jpg"
                            alt="Custom Palette">
                        <span>Custom Palette Program</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteOurPalette, category: GoogleAnalyticEventCategory.taUserAction}'
                        href='{{"https://theodorealexander.sirv.com/website/Frontend/Live/assests/pdf/Custom-Palette.pdf" | tawSkipCachedFile}}'
                        target="_blank">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_3.jpg">
                        <span>Our Palette</span>
                    </a>
                </div>
            </li>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteResource, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/web/palette-download" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_4.jpg">
                        <span>Resources</span>
                    </a>
                </div>
            </li>
        </ul>
    </div>
    <div class="block_menu_desktop_item_right">
        <h1>VIRTUAL TOUR</h1>
        <ul>
            <li>
                <div class="image">
                    <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                        routerLink="/virtual-showroom" (click)="onRouterClick()">
                        <img class="Sirv" style="max-width: 100%;"
                            data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/3_2.jpg">
                        <span>Virtual Showroom Tour</span>
                    </a>
                </div>
            </li>

        </ul>
    </div> -->
<!-- </div> -->

<!-- 
<div class="block_menu_desktop_brands">
    <div class="block_menu_desktop_item_left">
        <ul>
            <li>
                <a class="block_menu_desktop_item_left_item" (click)="onBrandClick(0)"
                    [ngClass]="{'block_menu_desktop_item_left_item-selected': 0==selectedIndexBrand}">
                    <h3>Upholstery</h3>
                </a>
            </li>
            <li>
                <a class="block_menu_desktop_item_left_item" (click)="onBrandClick(1)"
                    [ngClass]="{'block_menu_desktop_item_left_item-selected': 1==selectedIndexBrand}">
                    <h3>Custom Palette</h3>
                </a>
            </li>
            <li>
                <a class="block_menu_desktop_item_left_item" (click)="onBrandClick(2)"
                    [ngClass]="{'block_menu_desktop_item_left_item-selected': 2==selectedIndexBrand}">
                    <h3>Virtual Tour</h3>
                </a>
            </li>
        </ul>
    </div>

    <div class="block_menu_desktop_item_right">
        <div class="block_menu_desktop_item_right_item"
            [ngClass]="{'block_menu_desktop_item_right_item-selected': 0==selectedIndexBrand}">
            <h3 class="">
                <div class="collection" class="design_item" [ngClass]="{'active': !iShowUpholStery}"
                    (click)="iShowUpholStery = false;">Upholstery</div>
            </h3>
            <ul class="d-flex block_menu_desktop_item_right_item_ul">
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                            routerLink="/web/about-upholstery" (click)="onRouterClick()">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_1.jpg">
                            <span>About Our Upholstery</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="image">
                        <a href="{{tailorfitSubdomain}}">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_2.jpg"
                                alt="TAilor Fit" title="TAilor Fit">
                            <span>TAilor Fit</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryOurFabric, category: GoogleAnalyticEventCategory.taUserAction}'
                            routerLink="/web/our-fabric" (click)="onRouterClick()">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_3.jpg">
                            <span>Our Fabrics</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryResource, category: GoogleAnalyticEventCategory.taUserAction}'
                            routerLink="/web/upholstery-resource" (click)="onRouterClick()">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/1_4.jpg">
                            <span>Resources</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>

        <div class="block_menu_desktop_item_right_item"
            [ngClass]="{'block_menu_desktop_item_right_item-selected': 1==selectedIndexBrand}">
            <h3 class="">
                <div class="collection" class="design_item" [ngClass]="{'active': !iShowCustomPalette}"
                    (click)="iShowCustomPalette = false;">Custom Palette Program</div>
            </h3>
            <ul class="d-flex block_menu_desktop_item_right_item_ul">
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                            routerLink="/web/about-custom-palette" (click)="onRouterClick()">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_1.jpg">
                            <span>About Custom Palette</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="image">
                        <a href="{{domainName}}special/custom-palette.html">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_2.jpg"
                                alt="Custom Palette">
                            <span>Custom Palette Program</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteOurPalette, category: GoogleAnalyticEventCategory.taUserAction}'
                            href='{{"https://theodorealexander.sirv.com/website/Frontend/Live/assests/pdf/Custom-Palette.pdf" | tawSkipCachedFile}}'
                            target="_blank">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_3.jpg">
                            <span>Our Palette</span>
                        </a>
                    </div>
                </li>
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteResource, category: GoogleAnalyticEventCategory.taUserAction}'
                            routerLink="/web/palette-download" (click)="onRouterClick()">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/2_4.jpg">
                            <span>Resources</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>

        <div class="block_menu_desktop_item_right_item"
            [ngClass]="{'block_menu_desktop_item_right_item-selected': 2==selectedIndexBrand}">
            <h3 class="">
                <div class="collection" class="design_item" [ngClass]="{'active': !iShowVirtualTour}"
                    (click)="iShowVirtualTour = false;">Virtual Tour</div>
            </h3>
            <ul class="d-flex block_menu_desktop_item_right_item_ul">
                <li>
                    <div class="image">
                        <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteAbout, category: GoogleAnalyticEventCategory.taUserAction}'
                            routerLink="/virtual-showroom" (click)="onRouterClick()">
                            <img class="Sirv" style="max-width: 100%;"
                                data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Custom/3_2.jpg">
                            <span>Virtual Showroom Tour</span>
                        </a>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</div> -->


<div class="block_menu_desktop_products block_menu_desktop_item d-flex justify-content-between">
    <ul>
        <li>
            <h1>3D VISUALIZATION</h1>
        </li>
        <ng-container>
            <li>
                <a href="{{tailorfitSubdomain}}">TAilor Fit Custom Upholstery</a>
            </li>
            <li>
                <a routerLink="/draper" (click)="onRouterClick()">Sofas and Chairs</a>
            </li>
            <li>
                <a routerLink="/barstool" (click)="onRouterClick()">High Fashion Barstools</a>
            </li>
        </ng-container>
    </ul>
</div>