import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable, ReplaySubject, BehaviorSubject, of } from 'rxjs';
import { TAUtility, UserRegions } from 'src/app/theoalex/theoalex';
import { map } from 'rxjs/operators';
import * as _ from "lodash";
import { TaApiServiceLogin } from './TaApiServiceLogin';

@Injectable({
  providedIn: 'root'
})
export class TaApiServiceXML {

  constructor(private http: HttpClient, private loginService: TaApiServiceLogin) { }

  private glossarySubject = new ReplaySubject<any>(1);
  private glossaryObservable: Observable<any> = this.glossarySubject.asObservable();
  private cachedGlossaryData;

  getGlossary(): Observable<any> {

    if (this.cachedGlossaryData) {
      return this.glossaryObservable;
    } else {
      let request: TAHttpModelMenuXMLRequest = {
        XmlPath: environment.xmlResource.glossary
      }
      return this.http.post(`${environment.appApiUrl}/api/xml/getxml/`, request)
        .pipe(map((res: any) => {
          if (TAUtility.isAPIRespondOk(res)) {
            this.cachedGlossaryData = res.Data.root.letter;
            this.glossarySubject.next(this.cachedGlossaryData);
            return this.cachedGlossaryData;
          }
        }))
    }
  }

  getMenuBrand() {
    let request: TAHttpModelMenuXMLRequest = {
      XmlPath: environment.xmlResource.menu.brand
    }
    return this.http.post(`${environment.appApiUrl}/api/xml/getxml/`, request);
  }

  private designersSubject = new ReplaySubject<any>(1);
  private designersObservable: Observable<any> = this.designersSubject.asObservable();
  private designerCachedData: any;
  public getMenuDesigner(): Observable<any> {
    if (this.designerCachedData) {
      return this.designersObservable;
    } else {
      const request: TAHttpModelMenuXMLRequest = { XmlPath: environment.xmlResource.menu.designer };
      return this.http.post(`${environment.appApiUrl}/api/xml/getxml/`, request)
        .pipe(map((res: any) => {
          if (TAUtility.isAPIRespondOk(res)) {
            this.designerCachedData = res.Data.root.Designer;
            this.designersSubject.next(this.designerCachedData);
            return this.designerCachedData;
          }
        }))
    }
  }

  getMenuCustom() {
    const request: TAHttpModelMenuXMLRequest = {
      XmlPath: environment.xmlResource.menu.custom
    };
    return this.http.post(`${environment.appApiUrl}/api/xml/getxml/`, request);
  }

  resolveSideBarFilter(): Observable<any> {    
    return this.http.get(environment.resource.file.sidebarFilter).pipe(map(res => {       
      return this.buildSideBarFilter(res);
    }))
  }

  private buildSideBarFilter(res) {
    let sideBarFilter = [];
    if (res) {
      for (let item in res) {
        switch (item.toUpperCase()) {
          case "ROOMS":
            let room = {
              name: "Rooms",
              title: "Rooms",
              countBy: TAUtility.getCountByText("ROOMS"),
              items: res.Rooms.Room.map(i => {
                let subRoom = {
                  name: i.Name,
                  title: i.Title,
                  id: i.ID,
                  countBy: TAUtility.getCountByText("ROOMS"),
                  items: i.Type.map(type => {
                    return { name: type.Name, title: type.Title, id: type.ID, countBy: 'TypeIds' }
                  })
                };
                return subRoom;
              })
            };
            sideBarFilter.push(room);
            break;
          case "COLLECTIONS":
            let collection = {
              name: "Collections",
              title: "Collections",
              countBy: TAUtility.getCountByText("COLLECTIONS"),
              items: _.compact(res.Collections.Collection.map(i => {
                if (!TAUtility.isExcludedByRegion(this.loginService.taUserCredential.Region, "COLLECTIONS", i.ID)) {
                  return { name: i.Name, title: i.Title, id: i.ID, countBy: TAUtility.getCountByText("COLLECTIONS") };
                }
              }))
            };
            sideBarFilter.push(collection);
            break;
          case "LIFESTYLES":
            let lifeStyle = {
              name: "Lifestyles",
              title: "Lifestyles",
              countBy: TAUtility.getCountByText("LIFESTYLES"),
              items: res.LifeStyles.LifeStyle.map(i => {
                return { name: i.Name, title: i.Title, id: i.ID, countBy: TAUtility.getCountByText("LIFESTYLES") };
              })
            };
            sideBarFilter.push(lifeStyle);
            break;
          case "STYLES":          
          let style = {
              name: "Styles",
              title: "Styles",
              countBy: TAUtility.getCountByText("STYLES"),
              items: res.Styles.Style.map(i => {
                return { name: i.Name, title: i.Title, id: i.ID, countBy: TAUtility.getCountByText("STYLES") };
              })
            };
            sideBarFilter.push(style); 
            break;
          case "SHAPES":
            let shape = {
              name: "Shapes",
              title: "Shapes",
              countBy: TAUtility.getCountByText("SHAPES"),
              items: res.Shapes.Shape.map(i => {
                return { name: i.Name, title: i.Title, id: i.ID, countBy: TAUtility.getCountByText("SHAPES"), };
              })
            };
            sideBarFilter.push(shape);
            break;

          case "EXTENDING":
            //True/false
            sideBarFilter.push({ name: "Extending", title: "Extending", id: 'TaExtending', isSelected: TAUtility.booleanFromString(res.Extending.Value), countBy: TAUtility.getCountByText("EXTENDING"), });
            break;
        }
      };
    }
    return sideBarFilter;
  }
}

export interface TAHttpModelMenuXMLRequest {
  XmlPath: string
}

export interface TAHttpModelMenuXMLRespond {
  Success?: boolean,
  StatusCode?: number,
  Data?: {
    root: {
      Brand: [],
      Column: []
    }
  }
}

export interface TAHttpModelMenuXMLRespondDataItem {
  Column: any[],
  ID: string,
  Name: string,
  Title: any,
  TitleIsImage: number,
}
