<div class="search" [ngClass]="{'search-open':isExpanded}">
  <span [hidden]="isExpanded" (click)="toggle()">Search</span>
  <i class="d-none d-xl-block search-icon icon-Search" (click)="onClickIconSearch()"></i>
  <div class="search_body" [hidden]="!isExpanded">
    <div class="search_body_input">
      <input #searchInput class="search_body_input" [(ngModel)]="textSearch" type="text" placeholder="Search..." [hidden]="!isExpanded"
      (keydown.enter)="onPressKeyEnter()" (keydown.esc)="onPressKeyESC()"/> <i class=" d-block d-xl-none icon-Search search-mobile " (click)="onClickIconSearch()"></i>
    </div>
    <div class="search_body_advance">
      <span class="search_body_advance_text" [hidden]="!isExpanded" skipLocationChange [routerLink]="['product-listing', {isAdvancedMode: true}]" (click)="closeSearchBar()">Advanced Product Search</span>
    </div>
  </div>
  <span class="d-none d-xl-block">
    <i class="search-icon icon-close" [hidden]="!isExpanded" (click)="toggle()"></i>
  </span>
</div>
