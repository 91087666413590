import { Component, OnInit, Inject, AfterViewInit, OnDestroy, PLATFORM_ID, Optional } from '@angular/core';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { TALocalStogareKeys, TALocalStogareService } from 'src/app/service/core/taLocalStorage';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { Subscription } from 'rxjs';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { isPlatformBrowser } from '@angular/common';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { TawMessageService } from 'src/app/service/web/taw-message.service'
import { TaApiServiceShoppingCart } from 'src/app/service/api/TaApiServiceShoppingCart';

@Component({
  selector: 'app-login-page',
  templateUrl: './login-page.component.html',
  styleUrls: ['./login-page.component.scss']
})
export class LoginPageComponent implements OnInit, AfterViewInit, OnDestroy, iTaObserverComponent {

  userAuthenticCheckedEventSubscription: Subscription;

  model = {
    isRemember: true,
    username: "",
    password: ""
  }
  isRecoveryPasswordMode: boolean = false;
  recoveryPasswordEmail: string;
  dialogRef: NzModalRef;
  messageString: string = "Please login";
  shoppingCartCount: string = "0";
  constructor(public shoppingCartService: TaApiServiceShoppingCart, private serviceLogin: TaApiServiceLogin, private dialog: NgbModal, private taLocalStorage: TALocalStogareService,
    @Inject(PLATFORM_ID) private platformId, @Optional() private message: NzMessageService
    , private taMessageService: TawMessageService
  ) {

  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  subscriberEvents(): void {
    this.userAuthenticCheckedEventSubscription = TaUniversalHubSubject.userCredentialSubjectData.subscribe(credential => {
      if (credential?.UserName) {
        this.dialogRef?.close();
      }
    })
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userAuthenticCheckedEventSubscription);
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.subscriberEvents();
      if (isPlatformBrowser(this.platformId)) {
        let remember = this.taLocalStorage.get(TALocalStogareKeys.TALocalStogareUserRemember);
        if (remember && remember.length > 5) {
          this.model.username = this.taLocalStorage.get(TALocalStogareKeys.TALocalStogareUserName);
          this.model.password = this.taLocalStorage.get(TALocalStogareKeys.TALocalStogareUserPassword);
        }
      }
    });
  }

  onClickClose() {
    if (this.isRecoveryPasswordMode) {
      this.isRecoveryPasswordMode = false;
    } else {
      this.dialogRef.close();
    }
  }

  onClickLogin() {
    if (this.model.username && this.model.password) {
      this.message.loading("Login...").messageId;
      this.messageString = "Login...";
      this.serviceLogin.login(this.model.username, this.model.password).subscribe(res => {
        this.handleLoginResult(res);
      });
    }
  }

  handleLoginResult(res: any) {
    this.message.remove();
    if (TAUtility.isAPIRespondOk(res)) {
      TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
        if (credential) {
          console.log(credential);
          localStorage.setItem('UserName', credential?.UserName);
          localStorage.setItem('UserID', credential?.UserId);
          localStorage.setItem('FirstName', credential?.FirstName);
          localStorage.setItem('UserRole', credential?.UserType);
          localStorage.setItem('Region', credential?.Region);
        }
      });
      TaUniversalHubSubject.userSettingSubject.subscribe(userSetting => {
        if (userSetting.ShowWholesalePrice) {
          localStorage.setItem('UserRetail', '1');
        }
        else {
          localStorage.setItem('UserRetail', '0');
        }
      });
      this.shoppingCartService.getClientShoppingCart(() => {
        this.shoppingCartCount = this.shoppingCartService.shoppingCart ? this.shoppingCartService.shoppingCart.Count : "0";
        localStorage.setItem('Cart', this.shoppingCartCount);
      });
      this.dialogRef?.close();
      if (this.model.isRemember) {
        if (isPlatformBrowser(this.platformId)) {
          this.taLocalStorage.set(TALocalStogareKeys.TALocalStogareUserRemember, "taremember");
          this.taLocalStorage.set(TALocalStogareKeys.TALocalStogareUserName, this.model.username);
          this.taLocalStorage.set(TALocalStogareKeys.TALocalStogareUserPassword, this.model.password);
        }
      } else {
        if (isPlatformBrowser(this.platformId)) {
          this.taLocalStorage.remove(TALocalStogareKeys.TALocalStogareUserRemember);
        }
      }
    } else {
      //login fail
      this.message.error(`Login failed: ${res.Data}`);
      this.messageString = "Login failed:" + res.Data;
    }
  }

  onClickLoginFacebook() {
  }

  onClickForgotPassword() {
    this.isRecoveryPasswordMode = true;
  }

  onClickSendRecoveryPassword() {
    if (this.recoveryPasswordEmail) {
      this.taMessageService.loading("Sending your password...");
      this.serviceLogin.recoveryPassword(this.recoveryPasswordEmail).subscribe(res => {
        this.taMessageService.remove();
        if (TAUtility.isAPIRespondOk(res)) {
          this.taMessageService.info('Done. Please check registered email for new password').onClose.subscribe(() => {
            this.isRecoveryPasswordMode = false;
          })
        } else {
          this.taMessageService.error(`Recover password failed: ${res.Data}`);
        }
      })
    }
  }
}
