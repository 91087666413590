import { Component, AfterViewInit, OnDestroy, ElementRef, OnInit, HostListener, ViewChild, PLATFORM_ID, Inject } from '@angular/core';
import { TaUniversalHubSubject, screenBreakpointEventSize } from '../service/web/universal-hub.service';
import { Subscription } from 'rxjs';
import { iTaObserverComponent } from '../theoalex/taObserverComponent';
import { TAUtility, GoogleAnalyticEventAction, GoogleAnalyticEventCategory, TawAccountProvider } from '../theoalex/theoalex';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';
import { filter } from 'rxjs/operators';
import { BreakpointObserver } from '@angular/cdk/layout';
import { isPlatformBrowser } from '@angular/common';
import { TaApiServiceLogin, TaApiServiceRegisterUser } from '../service/api/TaApiServiceLogin';
import { AngularFireAuth } from '@angular/fire/auth';
import { result } from 'lodash';
import { RegisterPopupDirective } from '../directive/register-popup.directive';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { Store } from '@ngrx/store';
import { AppRootState } from '../state-management';
import { TawAppStateAction } from '../state-management/app-state/app.action';
import { TawAppStateSelector } from '../state-management/app-state/app.selector';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnDestroy, iTaObserverComponent, OnInit {

  public static LAST_REQUESTED_URL: string = "";

  message: string = "";

  @HostListener('window:scroll', ['$event'])

  onScroll(event) {
    TaUniversalHubSubject.outletScrollEvent.next(event);
  }

  @ViewChild("header") header: ElementRef;

  goToTopSubscriber: Subscription;
  routerEventSubscriber: Subscription;
  httpLoadingChangedSubscriber: Subscription;
  screenSizeBreakpointSubscriber: Subscription;

  isFullHeaderLogo: boolean = true;
  isHttpLoading: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private serviceLogin: TaApiServiceLogin,
    private breakpointObserver: BreakpointObserver, @Inject(PLATFORM_ID) private platformId, private auth: AngularFireAuth,
    private modalService: NzModalService, private messageService: NzMessageService
    , private storeState: Store<AppRootState>) {
    this.route.queryParams.subscribe(params => {
      if (params['uid']) {
        this.serviceLogin.loginByUserId(params['uid']).subscribe(res => { });
      }
    });
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
    this.acquiringGoogleSignin();

    TaUniversalHubSubject.userCredentialProviderAcquiringSubject.next(true);
    this.storeState.select(TawAppStateSelector.selectMessage).subscribe(m => {
      TAUtility.consoleLog(m);
    })
  }

  private acquiringGoogleSignin() {
    this.auth.getRedirectResult().then((data) => {
      if (data?.user?.email) {
        this.serviceLogin.getUserIdByEmail(data?.user?.email).subscribe(res => {
          if (TAUtility.isAPIRespondOk(res)) {
            if (res.Data != null) {//found
              this.serviceLogin.loginByUserId(res.Data).subscribe(res => {
                if (TAUtility.isAPIRespondOk(res)) {
                } else {
                  this.messageService.error(`Login failed: ${res.Data}`);
                }
              });
            } else {//not found ->need to register
              let firstName = data?.user?.displayName.split(" ")[0];
              let lastName = data?.user?.displayName.split(" ")[data?.user?.displayName.split(" ").length - 1];
              let password = Date.now().toString();//dummy password
              let registerUser: TaApiServiceRegisterUser = {
                Email: data?.user?.email,
                Firstname: firstName,
                Lastname: lastName,
                Password: password,
                confirmedPassword: password,
                Provider: TawAccountProvider.google,
                IsEmailSubscribed: true
              }
              RegisterPopupDirective.show(this.modalService, registerUser, "Information to register");
            }
          }
        })
      }
    }).catch((error) => {
      this.message = error.message.toString();
      console.log(this.message);
      if (this.message.includes("Cannot read properties of undefined (reading 'call')")) {
      }
      else {
        if (this.message.includes("e[t] is undefined")) {
        }
        else {
          if (this.message.includes("e[t].call")) {
          }
          else {
            this.messageService.error(`Login/register failed: ${error.message}`, { nzDuration: 5000 });
          }
        }
      }
    }).finally(() => {
      TaUniversalHubSubject.userCredentialProviderAcquiringSubject.next(false);
    });
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  ngOnInit() {
    TAUtility.sendGoogleAnalyticEvent(GoogleAnalyticEventAction.taLoad);
    this.serviceLogin.loginByToken().subscribe();
  }

  onClick() {
    this.storeState.dispatch(TawAppStateAction.update());
  }

  subscriberEvents(): void {
    //listening for GoToTop button click
    if (isPlatformBrowser(this.platformId)) {
      this.goToTopSubscriber = TaUniversalHubSubject.scrollRequest.subscribe((offsetTop) => {
        window.scrollTo(0, (offsetTop || 0) - this.header.nativeElement.clientHeight);
      });
    }

    this.routerEventSubscriber = this.router.events.pipe(filter(e => e instanceof NavigationStart || e instanceof NavigationEnd))
      .subscribe((e: any) => {
        if (e instanceof NavigationStart) {
          TaUniversalHubSubject.taRouterNavigationStart.next(e);
          AppComponent.LAST_REQUESTED_URL = (e as NavigationEnd).url;
        }
        if (e instanceof NavigationEnd) {
          TaUniversalHubSubject.taRouterNavigationEnd.next(e);
          TaUniversalHubSubject.scrollRequest.next();
        }
      });

    //listening for finishing http request
    this.httpLoadingChangedSubscriber = TaUniversalHubSubject.httpLoadingChangedEvent.subscribe(isLoading => {
      setTimeout(() => { this.isHttpLoading = isLoading; }, 0);
    })

    //listening for screen size change
    this.screenSizeBreakpointSubscriber = this.breakpointObserver.observe(['(min-width: 992px)']).subscribe(result => {
      if (result && result.matches == false) {
        TaUniversalHubSubject.screenBreakpointEvent.next(screenBreakpointEventSize.mobile);
      } else {
        TaUniversalHubSubject.screenBreakpointEvent.next(screenBreakpointEventSize.tablet);
      }
    })
    //listening for screen size change
    this.screenSizeBreakpointSubscriber = this.breakpointObserver.observe(['(min-width: 1200px)']).subscribe(result => {
      if (result && result.matches == false) {
        TaUniversalHubSubject.screenBreakpointEvent.next(screenBreakpointEventSize.tablet);
      } else {
        TaUniversalHubSubject.screenBreakpointEvent.next(screenBreakpointEventSize.desktop);
      }
    })
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.goToTopSubscriber, this.routerEventSubscriber, this.httpLoadingChangedSubscriber, this.screenSizeBreakpointSubscriber);
  }
}
