import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Injectable({
    providedIn: 'root'
})
export class DownloadService {
   
    aPIURL: string = environment.APINewURL;
    controller: string = "Download";
    constructor(private httpClient: HttpClient) {   
    }
    getIsActiveTAUS() {
        let url = this.aPIURL + this.controller + '/GetIsActiveTAUS';
        const params = new HttpParams()            
        return this.httpClient.get(url, { params }).toPromise();
    }      
}

