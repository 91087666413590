//CORE
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule, Meta } from '@angular/platform-browser';

//MODULE
import { AppRoutingModule } from './app-routing.module';
import { TaInterceptor } from './service/api/TaInterceptor';
import { TaCommonModule } from './ta-common.module';
import { AngularFireModule } from '@angular/fire';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { RuntimeChecks, StoreModule } from '@ngrx/store';
//COMPONENT
import { AppComponent } from './AppComponent/app.component';


//DIRECTIVE

//PIPE

//3RD

//STATE MANAGEMENT
import { appReducers } from 'src/app/state-management';
import { EffectsModule } from '@ngrx/effects'

/** config angular i18n **/
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
registerLocaleData(en);
/** config ng-zorro-antd i18n **/
import { NZ_I18N, en_US } from 'ng-zorro-antd/i18n';
import { TaNgZorroAntdModule } from './ng-zorro-antd.module';
import { NgxMasonryModule } from 'ngx-masonry';
import { environment } from 'src/environments/environment';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { HR_Recruitment_CareerService } from 'src/app/shared/HR_Recruitment_Career.service';
import { HR_Recruitment_IntroduceService } from 'src/app/shared/HR_Recruitment_Introduce.service';
import { StoreService } from 'src/app/shared/Store.service';
import { ItemService } from 'src/app/shared/Item.service';
import { CollectionService } from 'src/app/shared/Collection.service';
import { LifeStyleService } from 'src/app/shared/LifeStyle.service';
import { StyleService } from 'src/app/shared/Style.service';
import { ShapeService } from 'src/app/shared/Shape.service';
import { TypeService } from 'src/app/shared/Type.service';
import { RoomAndUsageService } from 'src/app/shared/RoomAndUsage.service';
import { BrandService } from 'src/app/shared/Brand.service';
import { DesignerService } from 'src/app/shared/Designer.service';
import { MarketingResourceCategoryService } from 'src/app/shared/MarketingResourceCategory.service';
import { MarketingResourceService } from 'src/app/shared/MarketingResource.service';
import { MarketingResourceDetailService } from 'src/app/shared/MarketingResourceDetail.service';
import { Dealer_TausService } from 'src/app/shared/Dealer_Taus.service';
import { BannerService } from 'src/app/shared/Banner.service';
import { BannerDetailService } from 'src/app/shared/BannerDetail.service';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
import { PricingService } from 'src/app/shared/pricing.service';
import { InstagramService } from 'src/app/shared/Instagram.service';
import { GhostBlogService } from 'src/app/shared/GhostBlog.service';
import { FurnitureCareService } from 'src/app/shared/FurnitureCare.service';
import { IntiaroWishlistService } from 'src/app/shared/IntiaroWishlist.service';
import { IntiaroConfigurationService } from 'src/app/shared/IntiaroConfiguration.service';
import { IntiaroConfigurationImageService } from 'src/app/shared/IntiaroConfigurationImage.service';
import { IntiaroConfigurationAttributesService } from 'src/app/shared/IntiaroConfigurationAttributes.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { AppGlobal } from 'src/app/shared/AppGlobal';
import { HeadService } from 'src/app/shared/Head.service';
import { DesignersComponent } from './page/designers/designers.component';
import { NewAlexaHamptonComponent } from './page/Designers/new-alexa-hampton/new-alexa-hampton.component';
import { BrandComponent } from './page/brand/brand.component';
import { CollectionComponent } from './page/collection/collection.component';
import { TypeComponent } from './page/type/type.component';
import { ExtendingComponent } from './page/extending/extending.component';
import { LifeStyleComponent } from './page/life-style/life-style.component';
import { ShapeComponent } from './page/shape/shape.component';
import { SearchComponent } from './page/search/search.component';
import { RoomComponent } from './page/room/room.component';
import { IsStockedComponent } from './page/is-stocked/is-stocked.component';
import { IsActiveComponent } from './page/is-active/is-active.component';
import { CasualLivingComponent } from './page/casual-living/casual-living.component';
import { StyleComponent } from './page/style/style.component';
import { IsCFPItemComponent } from './page/is-cfpitem/is-cfpitem.component';
import { FiltersComponent } from './page/filters/filters.component';
import { CheckLoginComponent } from './page/check-login/check-login.component';
import { CheckSignUpComponent } from './page/check-sign-up/check-sign-up.component';
import { CheckRalphLaurenComponent } from './page/check-ralph-lauren/check-ralph-lauren.component';
import { RalphLaurenComponent } from './page/ralph-lauren/ralph-lauren.component';
import { CheckLogoutComponent } from './page/check-logout/check-logout.component';
import { LoginComponent } from './page/login/login.component';
import { CheckWishListComponent } from './page/check-wish-list/check-wish-list.component';
import { WishListComponent } from './page/wish-list/wish-list.component';
import { NotFoundComponent } from './page/not-found/not-found.component';
import { TaWebBlogMasterPageComponent } from 'src/app/blog/ta-web-blog-master-page/ta-web-blog-master-page.component';

import { GalleryComponent } from './intiaro/gallery/gallery.component';
import { IntiaroConfiguratorComponent } from './intiaro/intiaro-configurator/intiaro-configurator.component';
import { NavbarComponent } from './intiaro/navbar/navbar.component';
import { SendEmailComponent } from './intiaro/send-email/send-email.component';
import { SummaryComponent } from './intiaro/summary/summary.component';
import { TypeNoteModalComponent } from './intiaro/type-note-modal/type-note-modal.component';
import { StartComponent } from './intiaro/start/start.component';
import { ConfiguratorComponent } from './intiaro/configurator/configurator.component';
import { MainViewConfiguratorComponent } from './intiaro/main-view-configurator/main-view-configurator.component';
import { IntiaroComponent } from './intiaro/intiaro.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SofaComponent } from './intiaro/sofa/sofa.component';
import { ChairComponent } from './intiaro/chair/chair.component';
import { SectionalComponent } from './intiaro/sectional/sectional.component';
import { LoveseatComponent } from './intiaro/loveseat/loveseat.component';
import { OttomanComponent } from './intiaro/ottoman/ottoman.component';
import { TAilorFitComponent } from './intiaro/tailor-fit/tailor-fit.component';

import { RouteReuseStrategy } from '@angular/router/';
import { CacheRouteReuseStrategy } from './scripts/cache-route-reuse-strategy';
import { WrapperForMainViewComponent } from 'src/app/intiaro/wrapper-for-main-view/wrapper-for-main-view.component';

import { VirtualShowroomTourComponent } from './page/virtual-showroom-tour/virtual-showroom-tour.component';
import { DrapperComponent } from './page/drapper/drapper.component';
import { BarstoolComponent } from './page/barstool/barstool.component';

@NgModule({
  declarations: [
    AppComponent,
    DesignersComponent,
    NewAlexaHamptonComponent,
    BrandComponent,
    CollectionComponent,
    TypeComponent,
    ExtendingComponent,
    LifeStyleComponent,
    ShapeComponent,
    SearchComponent,
    RoomComponent,
    IsStockedComponent,
    IsActiveComponent,
    CasualLivingComponent,
    StyleComponent,
    IsCFPItemComponent,
    FiltersComponent,
    CheckLoginComponent,
    CheckSignUpComponent,
    CheckRalphLaurenComponent,
    RalphLaurenComponent,
    CheckLogoutComponent,
    LoginComponent,
    CheckWishListComponent,
    WishListComponent,
    NotFoundComponent,
    GalleryComponent,
    IntiaroConfiguratorComponent,
    NavbarComponent,
    SendEmailComponent,
    SummaryComponent,
    TypeNoteModalComponent,
    StartComponent,
    ConfiguratorComponent,
    MainViewConfiguratorComponent,
    IntiaroComponent,
    TaWebBlogMasterPageComponent,
    SofaComponent,
    ChairComponent,
    SectionalComponent,
    LoveseatComponent,
    OttomanComponent,
    TAilorFitComponent,
    WrapperForMainViewComponent,
    VirtualShowroomTourComponent,

    DrapperComponent,
    BarstoolComponent
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AngularFireModule.initializeApp(environment.firebaseConfig),
    StoreModule.forRoot(appReducers, { runtimeChecks: { strictStateImmutability: false } }),
    EffectsModule.forRoot([]),
    AngularFireAuthModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    TaCommonModule,
    TaNgZorroAntdModule,
    FormsModule,
    HttpClientModule,
    FontAwesomeModule,
    NgxMasonryModule,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    {
      provide: RouteReuseStrategy,
      useClass: CacheRouteReuseStrategy
    },
    Meta,
    HR_Recruitment_CareerService,
    HR_Recruitment_IntroduceService,
    StoreService,
    ItemService,
    CollectionService,
    LifeStyleService,
    StyleService,
    ShapeService,
    TypeService,
    RoomAndUsageService,
    BrandService,
    DesignerService,
    MarketingResourceService,
    MarketingResourceCategoryService,
    MarketingResourceDetailService,
    Dealer_TausService,
    BannerService,
    BannerDetailService,
    StepByStepService,
    PricingService,
    InstagramService,
    GhostBlogService,
    FurnitureCareService,
    IntiaroWishlistService,
    IntiaroConfigurationService,
    IntiaroConfigurationImageService,
    IntiaroConfigurationAttributesService,
    DownloadService,
    AppGlobal,
    HeadService,
  ]
})
export class AppModule { }
