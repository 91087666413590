import { Component, OnDestroy, OnInit, Optional } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { TAIntiaroData, TaApiServiceProduct } from 'src/app/service/api/TaApiServiceProduct';
import { TaConstantsService } from 'src/app/service/web/ta-constants.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-barstool',
  templateUrl: './barstool.component.html',
  styleUrls: ['./barstool.component.scss']
})
export class BarstoolComponent implements OnInit, OnDestroy {

  tailorfitURL: string = environment.TailorFitURL + "draper/theodore-configurator/";

  public searchKey = '';
  public sorter = {
    name: "Sort By",
    items: [
      {
        name: "Product A-Z",
        value: "product_name",
        isAcsending: true,
      },
      {
        name: "Product Z-A",
        value: "product_name",
        isAcsending: false,
      },
    ]
  }
  public listBarstool: TAIntiaroData[] = [];
  public isLoading: boolean = false;
  private barstoolData: Subscription;
  private listBarstoolClone: TAIntiaroData[] = [];

  constructor(@Optional() public taConstants: TaConstantsService,
    @Optional() private router: Router,
    @Optional() private productService: TaApiServiceProduct
  ) {
    localStorage.setItem("CurrUrl", this.router.url)
  }
  ngOnDestroy(): void {
    if (this.barstoolData) {
      this.barstoolData.unsubscribe();
    }
  }

  ngOnInit() {
    this.isLoading = false;
    this.barstoolData = this.productService.getIntiaroData().subscribe(res => {
      if (res && res.length > 0) {

        this.listBarstool = res;
        this.listBarstool = this.listBarstool.filter(x => x.client_identifier.includes('QSL') || x.client_identifier.includes('QSF')).sort((a, b) => {
          return a.product_name.localeCompare(b.product_name);
        })
        this.listBarstoolClone = this.listBarstool.sort((a, b) => {
          return a.product_name.localeCompare(b.product_name);
        })

        this.isLoading = true;
      }
    });
  }

  public onConfirmSearchKey() {
    if (this.searchKey) {
      if (this.listBarstool) {
        this.listBarstool = this.listBarstool.filter(x => x.client_identifier.includes('QSL') || x.client_identifier.includes('QSF')).filter(x => x.product_name.includes(this.searchKey) || x.client_identifier.includes(this.searchKey))
      }
    } else {
      this.barstoolData = this.productService.getIntiaroData().subscribe(res => {
        if (res && res.length > 0) {
          this.listBarstool = res;
          this.listBarstool = this.listBarstool.filter(x => x.client_identifier.includes('QSL') || x.client_identifier.includes('QSF')).sort((a, b) => {
            return a.product_name.localeCompare(b.product_name);
          })
          this.listBarstoolClone = this.listBarstool.sort((a, b) => {
            return a.product_name.localeCompare(b.product_name);
          })
          this.isLoading = true;
        }
      });
    }
  }

  public onSortByChangedByDropdown(selected) {
    if (selected) {
      if (selected.isAcsending) {
        this.listBarstool = this.listBarstool.filter(x => x.client_identifier.includes('QSL') || x.client_identifier.includes('QSF')).sort((a, b) => {
          return a.product_name.localeCompare(b.product_name);
        })
      } else {
        this.listBarstool = this.listBarstool.filter(x => x.client_identifier.includes('QSL') || x.client_identifier.includes('QSF')).sort((a, b) => {
          return b.product_name.localeCompare(a.product_name);
        })
      }
    }
  }

  public clearFilterTags() {
    if (this.searchKey) {
      this.searchKey = '';
      this.listBarstool = this.listBarstoolClone;
    }
  }

}
