<div class="productFilter d-flex flex-column">
    <div>
        <div class="products" *ngIf="list.length > 0" [ngClass]="{'isGridMode': true}">
            <div *ngFor="let product of list;" class="product">
                <div class="tags">
                    <span class="tag3d">3D</span>
                </div>
                <div class="info">
                    <div class="image">
                        <a routerLink="/draper/theodore-configurator/{{product.id}}">
                            <img alt="{{product?.categoryName}}" title="{{product?.categoryName}}"
                                [src]="product?.urlImage + '?w=400&h=400'">
                        </a>
                    </div>
                    <div class="name">
                        <a routerLink="/draper/theodore-configurator/{{product.id}}">
                            <h4>{{product?.categoryName}}</h4>
                        </a>
                        <div class="sku">{{product?.SKU}}</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>