import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-menu-about-us',
  templateUrl: './menu-about-us.component.html',
  styleUrls: ['./menu-about-us.component.scss']
})
export class MenuAboutUsComponent implements OnInit {
  domainName: string = environment.appWebUrl;
  constructor(private menu: MenuComponent) { }

  onRouterClick(){
    //close the menu when navigation
    this.menu.closeAllMenu();
  }

  ngOnInit() {
  }

}
