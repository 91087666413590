import { Injectable, Optional } from '@angular/core';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { TaAPIResponse } from './TaApiServiceCommon';
import { tap } from 'rxjs/operators';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TAProductDetailViewModel } from './TaApiServiceProduct';
import { TawUserAddressViewModel } from './model/model';

@Injectable({
  providedIn: 'root'
})
export class TaApiServiceShoppingCart {

  constructor(@Optional() private http: HttpClient) {
    TaUniversalHubSubject.userCredentialSubjectData.subscribe(() => {
      this._shoppingCart = null;
    })
  }

  private _shoppingCart: TaAPIGetShoppingCartData;

  public get shoppingCart(): TaAPIGetShoppingCartData {
    return this._shoppingCart;
  }

  getClientShoppingCart(whenGotItCallback: Function) {
    if (this._shoppingCart) {
      whenGotItCallback();
    } else {
      this.getShoppingCart().subscribe(() => {
        whenGotItCallback();
      })
    }
  }

  getShoppingCart(): Observable<TaAPIResponse<TaAPIGetShoppingCartData>> {
    const command = "api/ShoppingCart/GetShoppingCartItem";
    return this.http.get<TaAPIResponse<TaAPIGetShoppingCartData>>(`${environment.appApiUrl}/${command}`).pipe(tap((res) => {
      if (TAUtility.isAPIRespondOk(res)) {
        this._shoppingCart = res.Data;
      }
    }));
  }

  checkoutShoppingCart(): Observable<TaAPIResponse<TaAPICheckOutShoppingCartData>> {
    const command = "api/ShoppingCart/CheckOutShoppingCart";
    return this.http.get<TaAPIResponse<TaAPICheckOutShoppingCartData>>(`${environment.appApiUrl}/${command}`);
  }

  updateShoppingCartItem(updatedItem: TaAPIUpdateShoppingCartItemRequest): Observable<TaAPIResponse<TaAPIGetShoppingCartData>> {
    const command = 'api/ShoppingCart/UpdateShoppingCart';
    return this.http.post<TaAPIResponse<TaAPIGetShoppingCartData>>(`${environment.appApiUrl}/${command}`, updatedItem).pipe(tap((res) => {
      if (TAUtility.isAPIRespondOk(res)) {
        this._shoppingCart = res.Data;
        TaUniversalHubSubject.shoppingCartChanged.next(res.Data);
      }
    }));
  }

  removeShoppingCartItem(productId: string): Observable<TaAPIResponse<TaAPIGetShoppingCartData>> {
    const command = "api/ShoppingCart/RemoveItemInShoppingCart";
    return this.http.get<TaAPIResponse<TaAPIGetShoppingCartData>>(`${environment.appApiUrl}/${command}/${productId}`).pipe(tap((res) => {
      if (TAUtility.isAPIRespondOk(res)) {
        this._shoppingCart = res.Data;
        TaUniversalHubSubject.shoppingCartChanged.next(res.Data);
      }
    }));
  }

  getInfosToCreateNewOrder() : Observable<TaAPIResponse<TaAPIShoppingCartOrderInfo>>{
    const command = "api/ShoppingCart/GetInfosToCreateNewOrder";
    return this.http.get<TaAPIResponse<TaAPIShoppingCartOrderInfo>>(`${environment.appApiUrl}/${command}`);
  }

  submitOrder(orderInfo: TaAPIShoppingCartOrderInfo) : Observable<TaAPIResponse<TaAPIShoppingCartOrderInfo>>{         
    const command = "api/ShoppingCart/SubmitOrder";
    return this.http.post<TaAPIResponse<TaAPIShoppingCartOrderInfo>>(`${environment.appApiUrl}/${command}`, orderInfo).pipe(tap(res=> {      
      console.log(res);      
      if (TAUtility.isAPIRespondOk(res)){        
        this._shoppingCart = null;
        TaUniversalHubSubject.shoppingCartChanged.next();
      }
    }));
  }

  reserveItemsInShoppingCart(){
    const command = "api/ShoppingCart/ReserveItemsInShoppingCart";
    return this.http.get<TaAPIResponse<any>>(`${environment.appApiUrl}/${command}`);
  }
}

export interface TaAPIShoppingCartOrderInfo {
  OrderBy?: string,
  ShippingDate?: string[],
  ShoppingCartId?: string,
  ContainerReference?: string,
  SpecialInstruction?: string,
  OrderDate?: string,
  billingAddress?: TawUserAddressViewModel;
  BillingAddressString?: string;
  shippingAddress?: TawUserAddressViewModel;
  StoreID?: string;
  TausID?: string;
  ShippingAddressString?: string;
  // BillingAndShippingInfos?: {
  //   BillingAddress?: string,
  //   ShippingInfos?: {ID?: string, Name?: string, Selected?: boolean, FullAddress?: string}[],
  // }
  ShoppingCartInfos?: TaAPIGetShoppingCartData
}

export interface TaAPIUpdateShoppingCartItemRequest {
  ItemId?: string,
  Quantity?: number,
  QuantityMultiplier?: number,
  IsAdd?: boolean
}

export interface TaAPIGetShoppingCartData {
  Count?: string,
  Volume?: string,
  Total?: string,
  LstItem?: ShoppingCartItemViewModel[],
  IsAvailabiliyChanged?: boolean
}

export interface ShoppingCartItemViewModel extends TAProductDetailViewModel {
  IsAvailabiliyChanged?: boolean,
  NewAvailability?: string,
  Quantity?: number,
  ItemTotal?: number,
  Volume?: number,
  clientData?: any
}

export interface ClientShoppingCartItem {
  id: string,
  quantity: number
}

export interface TaAPICheckOutShoppingCartData {
  IsAvailabiliyChanged?: boolean,
  LstItem?: ShoppingCartItemViewModel[]
}
