<div class="block_open_menu">
  <ngb-accordion closeOthers="true">
    <!--Brands-->
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item"><i class="icon-arrow_menu_mobile"></i>Collections</a>
      </ng-template>

      <ng-template ngbPanelContent>
        <ul>
          <li *ngFor="let item of collectionService.listMenuTop">
            <a href="{{item.URL}}" class="item_left_branch" title="{{item.DisplayName}}">{{item.DisplayName}}</a>
          </li>
        </ul>
      </ng-template>

    </ngb-panel>

    <!--Products-->
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item"><i class="icon-arrow_menu_mobile"></i>Products</a>
      </ng-template>

      <ng-template ngbPanelContent *ngIf="roomAndUsageService.list">
        <ngb-accordion class="ngb-accordion-level1" closeOthers="true">
          <ngb-panel *ngFor="let item of roomAndUsageService.list">
            <ng-template ngbPanelTitle>
              <a class="block_menu_item sub_item"><i class="icon-arrow_menu_mobile"></i>{{item.DisplayName}}</a>
            </ng-template>
            <ng-template ngbPanelContent>
              <ul class="sub-item-menu-product">
                <ng-container *ngFor="let itemSub of typeService.listMenuTop">
                  <li *ngIf="item.ID===itemSub.RoomAndUsage_ID">
                    <a href="{{domainName}}{{type}}/{{itemSub.URLCode}}.html"
                      title="{{itemSub.DisplayName}}">{{itemSub.DisplayName}}</a>
                  </li>
                </ng-container>
              </ul>
            </ng-template>
          </ngb-panel>
        </ngb-accordion>
      </ng-template>
    </ngb-panel>

    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item"><i class="icon-arrow_menu_mobile"></i>Design Resources</a>
      </ng-template>
      <ng-template ngbPanelContent>
        <h1 class="title-custom-menu">
          <a>Upholstery</a>
        </h1>
        <ul class="block_menu_mobile_custom">
          <li>
            <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryAbout, category: GoogleAnalyticEventCategory.taUserAction}'
              routerLink="/web/about-upholstery" (click)="onRouterClick()">About Our Upholstery</a>
          </li>
          <li>
            <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryOurFabric, category: GoogleAnalyticEventCategory.taUserAction}'
              routerLink="/web/our-fabric" (click)="onRouterClick()">Our Fabrics</a>
          </li>
          <li>
            <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomUpholsteryResource, category: GoogleAnalyticEventCategory.taUserAction}'
              routerLink="/web/upholstery-resource" (click)="onRouterClick()">Resources</a>
          </li>
        </ul>
        <h1 class="title-custom-menu"> <a>Custom Palette Program</a></h1>
        <ul>
          <li>
            <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteAbout, category: GoogleAnalyticEventCategory.taUserAction}'
              routerLink="/web/about-custom-palette" (click)="onRouterClick()">About Custom Palette</a>
          </li>
          <li>
            <a href="{{domainName}}special/custom-palette.html">Custom Palette Program</a>
          </li>
          <li>
            <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteOurPalette, category: GoogleAnalyticEventCategory.taUserAction}'
              href='{{"https://theodorealexander.sirv.com/website/Frontend/Live/assests/pdf/Custom-Palette.pdf" | tawSkipCachedFile}}'
              target="_blank" target="_blank" (click)="onRouterClick()">Our Palette</a>
          </li>
          <li>
            <a [tawEventTracker]='{action: GoogleAnalyticEventAction.taMenuCustomPaletteResource, category: GoogleAnalyticEventCategory.taUserAction}'
              routerLink="/web/palette-download" (click)="onRouterClick()">Resources</a>
          </li>

        </ul>
        <h1 class="title-custom-menu"> <a>3D Visualization</a></h1>
        <ul>
          <li>
            <a href={{tailorfitSubDomain}} target="_blank">TAilor Fit</a>
          </li>
          <li>
            <a routerLink="/drapper">Sofas and Chairs</a>
          </li>
        </ul>
        <h1 class="title-custom-menu"> <a>Virtual Tour</a></h1>
        <ul>
          <li>
            <a routerLink="/virtual-showroom">{{"Virtual Tour"}}</a>
          </li>
        </ul>
      </ng-template>
    </ngb-panel>

    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item"><i class="icon-arrow_menu_mobile"></i>3D VISUALIZER</a>
      </ng-template>
      <ng-template ngbPanelContent>
        <h1 class="title-custom-menu"> <a>3D UPHOLSTERY VISUALIZATION</a></h1>
        <ul>
          <li>
            <a href={{tailorfitSubDomain}} target="_blank">TAilor Fit</a>
          </li>
          <li>
            <a routerLink="/drapper" (click)="onRouterClick()">Sofas and Chairs</a>
          </li>
          <li>
            <a routerLink="/barstool" (click)="onRouterClick()">High Fashion Barstools</a>
          </li>
        </ul>
      </ng-template>
    </ngb-panel>

    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item" routerLink="/virtual-showroom" (click)="onRouterClick()"><i
            class="icon-arrow_menu_mobile"></i>Virtual Showroom Tour</a>
      </ng-template>
    </ngb-panel>

    <!--Location-->
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item" href="{{domainName}}dealer-locator.html" (click)="onRouterClick()"><i
            class="icon-arrow_menu_mobile"></i>Locations</a>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="block_menu_mobile_location">
          <a href="{{domainName}}dealer-locator.html" (click)="onRouterClick()">
            <h3>Locations</h3>
          </a>
        </div>
      </ng-template>
    </ngb-panel>

    <!-- <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item" routerLink="/virtual-showroom" (click)="onRouterClick()"><i
            class="icon-arrow_menu_mobile"></i>Virtual Tour</a>
      </ng-template>
    </ngb-panel> -->

    <!--About Us-->
    <ngb-panel>
      <ng-template ngbPanelTitle>
        <a class="block_menu_item item_about"><i class="icon-arrow_menu_mobile"></i>About Us</a>
      </ng-template>
      <ng-template ngbPanelContent>
        <ul>
          <li><a href="{{domainName}}about.html" title="About Us">About Us</a></li>
          <li><a href="{{domainName}}contact.html" title="Contact Us">Contact Us</a></li>
          <li><a routerLink="/web/term-of-use" (click)="onRouterClick()">Terms of Use</a></li>
          <li><a routerLink="/web/privacy" (click)="onRouterClick()">Privacy Policy</a></li>
          <!-- <li><a appTBDClick="/web/career" (appTBDClick)="onRouterClick()">Careers</a></li> -->
        </ul>
      </ng-template>
    </ngb-panel>

  </ngb-accordion>
</div>