import { Subject, BehaviorSubject } from 'rxjs';
import { TaUserCredential, TaApiUserSetting } from '../api/TaApiServiceLogin';
import { TaAPIGetShoppingCartData } from '../api/TaApiServiceShoppingCart';
import { NavigationStart, NavigationEnd } from '@angular/router';
import { DimensionUnits } from '../core/taLocalStorage';
import { iTaBreadcrumb } from 'src/app/theoalex/taObserverComponent';
import { TaGhostBlogPost } from '../api/api-ghost-blog-content.service';

// @Injectable({
//   providedIn: 'root'
// })
export class TaUniversalHubSubject {

  constructor() { }

  static scrollRequest = new Subject<number>();

  static outletScrollEvent = new Subject<any>();

  static homeSwiperSlideChangedEvent = new Subject<any>();

  static httpLoadingChangedEvent = new Subject<boolean>(); // true = is loading

  static screenBreakpointEvent = new Subject<screenBreakpointEventSize>();

  static userSettingSubject = new BehaviorSubject<TaApiUserSetting>({ ShowSKUs: true });

  static userAuthenticCheckedEvent = new Subject<{ isLogged: boolean, userCredential: TaUserCredential }>();

  static userCredentialSubjectData = new BehaviorSubject<TaUserCredential>(null);

  static userCredentialSubjectChanged = new Subject<TaUserCredential>();

  static userCredentialProviderAcquiringSubject = new Subject<boolean>();

  static shoppingCartChanged = new Subject<TaAPIGetShoppingCartData>();

  static userPreferenceUnitDimensionChanged = new Subject<DimensionUnits>();

  static taRouterNavigationStart = new Subject<NavigationStart>();

  static taRouterNavigationEnd = new Subject<NavigationEnd>();

  static taBreadCrumbChanged = new Subject<iTaBreadcrumb[]>();

  //static taBlogPostForCarouselSubjectData = new BehaviorSubject<TaGhostBlogPost[]>([]);
}

export enum screenBreakpointEventSize {
  mobile, tablet, desktop
}
