import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaApiServiceCommon } from 'src/app/service/api/TaApiServiceCommon';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TaAlertPopup, TAAlertConfigurationType } from 'src/app/comp/ta-alert-popup/ta-alert-popup.component';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-ralph-lauren',
  templateUrl: './ralph-lauren.component.html',
  styleUrls: ['./ralph-lauren.component.scss']
})
export class RalphLaurenComponent implements OnInit {

  formData = {
    name: "",
    email: "",
    businessName: "",
    address: "",
    daytimePhone: "",
    state: "",
    country: "",
    zipCode: "",
    comment: ""
  }
  private sendRalphLaurenInquiriesSubscription: Subscription;
  modalRef: NgbModalRef;
  messageString: string = "DESIGN TRADE INQUIRIES";
  queryString: string = "";
  uRLReturn: string = "";
  constructor(private dialog: NgbModal, public router: Router, private serviceCommon: TaApiServiceCommon) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
      }
    });
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  subscriberEvents(): void {
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.sendRalphLaurenInquiriesSubscription);
  }


  onClickCloseButton() {
    if (this.queryString.includes("index")) {
      this.uRLReturn = environment.appWebUrl + "index.html";
    }
    else {
    }
    window.location.href = this.uRLReturn;
    //this.router.navigateByUrl(this.uRLReturn);
  }

  sendForm() {
    let notifyModalRef = TaAlertPopup.show({
      title: "Sending...",
      message: "Sending your request, please wait.",
      type: TAAlertConfigurationType.info
    }, this.dialog)
    this.messageString = "Sending your request, please wait.";
    this.sendRalphLaurenInquiriesSubscription = this.serviceCommon.SendRalphLaurenInquiries(this.formData).subscribe(res => {
      notifyModalRef.close();
      if (TAUtility.isAPIRespondOk(res)) {
        notifyModalRef = TaAlertPopup.show({
          title: "Sent successfully!",
          message: "Your request has been sent successfully.",
          onConfirm: () => { this.modalRef.close(); },
          type: TAAlertConfigurationType.success
        }, this.dialog);
        this.messageString = "Your request has been sent successfully.";
      } else {
        notifyModalRef = TaAlertPopup.show({
          title: "Sent fail!",
          message: "Sorry, the request failed.<br/> Please check your input and try again. <br/>" + res.Data,
          onConfirm: () => { },
          type: TAAlertConfigurationType.error
        }, this.dialog);
        this.messageString = "Sorry, the request failed. Please check your input and try again: " + res.Data;
      }
    })
  }

  onSubmit(form) {
    if (!form.invalid) {
      this.sendForm();
    }
  }
}