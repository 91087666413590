import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { FurnitureCare } from 'src/app/shared/FurnitureCare.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FurnitureCareService {
    list: FurnitureCare[] | undefined;
    list001: FurnitureCare[] | undefined;
    formData!: FurnitureCare;
    formData001!: FurnitureCare;
    formData002!: FurnitureCare;
    formData003!: FurnitureCare;
    formData004!: FurnitureCare;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "FurnitureCare";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.formData = {
        }
    }    
   
    getByActiveAndIsItemToList(active: boolean, isItem: boolean): Observable<TaAPIResponse<FurnitureCare>> {
        let url = this.aPIURL + this.controller + '/GetByActiveAndIsItemToList';
        const params = new HttpParams()            
            .set('active', JSON.stringify(active))
            .set('isItem', JSON.stringify(isItem))
        return this.httpClient.get<TaAPIResponse<FurnitureCare>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

