import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { environment } from 'src/environments/environment';
import { WishListComponent } from '../wish-list/wish-list.component';


@Component({
  selector: 'app-check-wish-list',
  templateUrl: './check-wish-list.component.html',
  styleUrls: ['./check-wish-list.component.scss']
})
export class CheckWishListComponent implements OnInit {

  queryString: string = "";
  uRLReturn: string = "";
  constructor(
    private loginService: TaApiServiceLogin,
    private dialog: NgbModal,
    public router: Router,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.checkWishList();
      }
    });
  }

  ngOnInit(): void {
  }

  checkWishList() {    
    if (this.queryString.includes("index")) {
      this.uRLReturn = environment.appWebUrl + "index.html";
    }
    else {
      if (this.queryString.includes("about")) {
        this.uRLReturn = environment.appWebUrl + "about.html";
      }
      if (this.queryString.includes("contact")) {
        this.uRLReturn = environment.appWebUrl + "contact.html";
      }
      if (this.queryString.includes("site-map")) {
        this.uRLReturn = environment.appWebUrl + "site-map.html";
      }
      if (this.queryString.includes("dealer-locator")) {
        this.uRLReturn = environment.appWebUrl + "dealer-locator.html";
      }
      if(this.uRLReturn.length==0){
        this.queryString = this.queryString.replace("/checkwishlist/", "");
        if (this.queryString.split("_")[1]) {
          let parameter01 = this.queryString.split("_")[0];
          let parameter02 = this.queryString.split("_")[1];
          this.uRLReturn = environment.appWebUrl + parameter01 + "/" + parameter02 + ".html";
        }
      }
    }
    const dialogRef = this.dialog.open(WishListComponent, {
      windowClass: 'ngb-modal-window-ta-full-screen'
    });
    dialogRef.result.then(() => {
      window.location.href = this.uRLReturn;
    }, () => {
      window.location.href = this.uRLReturn;
    });
    dialogRef.componentInstance.dialogRef = dialogRef;
  }
}