import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { LoginPageComponent } from '../login-page/login-page.component';
import { environment } from 'src/environments/environment';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { TaApiServiceShoppingCart } from 'src/app/service/api/TaApiServiceShoppingCart';
import { LoginComponent } from '../login/login.component';
import { isBuffer } from 'lodash';
@Component({
  selector: 'app-check-login',
  templateUrl: './check-login.component.html',
  styleUrls: ['./check-login.component.scss']
})
export class CheckLoginComponent implements OnInit {

  queryString: string = "";
  uRLReturn: string = "";
  shoppingCartCount: string = "0";
  constructor(
    private loginService: TaApiServiceLogin,
    private dialog: NgbModal,
    public router: Router,
    public shoppingCartService: TaApiServiceShoppingCart,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.checkLogin();
      }
    });
  }

  ngOnInit(): void {
  }

  checkLogin() {
    if (this.queryString.includes("index")) {
      this.uRLReturn = environment.appWebUrl + "index.html";
    }
    else {
      if (this.queryString.includes("about")) {
        this.uRLReturn = environment.appWebUrl + "about.html";
      }
      if (this.queryString.includes("contact")) {
        this.uRLReturn = environment.appWebUrl + "contact.html";
      }
      if (this.queryString.includes("site-map")) {
        this.uRLReturn = environment.appWebUrl + "site-map.html";
      }
      if (this.queryString.includes("dealer-locator")) {
        this.uRLReturn = environment.appWebUrl + "dealer-locator.html";
      }      
      if (this.uRLReturn.length == 0) {
        this.queryString = this.queryString.replace("/checklogin/", "");
        if (this.queryString.split("_")[1]) {
          let parameter01 = this.queryString.split("_")[0];
          let parameter02 = this.queryString.split("_")[1];
          this.uRLReturn = environment.appWebUrl + parameter01 + "/" + parameter02 + ".html";
        }
        if (this.queryString.split("_")[2]) {
          let parameter01 = this.queryString.split("_")[0];
          let parameter02 = this.queryString.split("_")[1];
          let parameter03 = this.queryString.split("_")[2];
          this.uRLReturn = environment.appWebUrl + parameter01 + "/" + parameter02 + ".html?ItemIndex=" + parameter03;
        }
      }

    }
    if (this.loginService.isLogin) {
    }
    else {
      if (this.loginService.isLogin == false) {
        const dialogRef = this.dialog.open(LoginComponent, {
          windowClass: 'ngb-modal-window-ta-full-screen'
        });
        dialogRef.result.then(() => {
          TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
            if (credential) {
              localStorage.setItem('UserName', credential?.UserName);
              localStorage.setItem('UserID', credential?.UserId);
              localStorage.setItem('FirstName', credential?.FirstName);
              localStorage.setItem('UserRole', credential?.UserType);
              localStorage.setItem('Region', credential?.Region);
              TaUniversalHubSubject.userSettingSubject.subscribe(userSetting => {
                if (userSetting.ShowWholesalePrice) {
                  localStorage.setItem('UserRetail', '1');
                }
                else {
                  localStorage.setItem('UserRetail', '0');
                }
                this.shoppingCartService.getClientShoppingCart(() => {
                  this.shoppingCartCount = this.shoppingCartService.shoppingCart ? this.shoppingCartService.shoppingCart.Count : "0";
                  localStorage.setItem('Cart', this.shoppingCartCount);
                  window.location.href = this.uRLReturn;
                });
              });
            }
          });
        }, () => {
          window.location.href = this.uRLReturn;
        });
        dialogRef.componentInstance.dialogRef = dialogRef;
      }
    }
  }
}
