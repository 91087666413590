<div class="productListing">
  <div class="breadcrumb_ta">
    <ul data-campaign="Header">
      <li><a routerLink="/" title="Home"><i class="icon-home"></i>Home</a></li>
      <li>&nbsp;&nbsp;/&nbsp;&nbsp;</li>
      <li><a routerLink="{{queryString}}" title="{{shapeService.formData.Name}}"><b>{{shapeService.formData.Name}}</b></a>
      </li>
    </ul>
  </div>
  <div class="productListing_content">
    <div class="productListing_content_filter">
      <div originroute="product-listing">
        <div class="productFilter d-flex flex-column">         
          <div class="d-flex flex-row main_product_seach_advand">
            <div class="productFilter_sidebar ta_no_scrollbar d-none d-xl-flex flex-column" [ngClass]="{'productFilter_sidebar_mobile-open' : isOpenFilterAtMobile}">           
              <div class="productFilter_sidebar_mobile" [hidden]="!isOpenFilterAtMobile">
                <div class="productFilter_sidebar_mobile_search">
                  <input type="text" placeholder="Search..." [(ngModel)]="searchString">
                  <i class="icon-Search" style="cursor: pointer;" (click)="onSearch(); onClickMobileFilterIcon(false)"></i>
                </div>
                <div class="productFilter_sidebar_mobile_stockProgram">    
                  <div displaytext="In Stock" *ngIf="isActiveTAUS">
                    <input type="checkbox" name="isStocked" [(ngModel)]="isStocked" (change)="onInStockChange()"
                      style="cursor: pointer;">
                    <span class="taCheckBox-text">&nbsp;In Stock</span>
                  </div>
                </div>
                <br />
                <div class="productFilter_sidebar_mobile_sort"> 
                  Sort by:
                  <select name="SortBy" [(ngModel)]="viewModel" (change)="onSortByChange()"
                    style="border-width: 0px;">
                    <option value="1">Newest</option>
                    <option value="2">Best Selling</option>
                    <option value="3">Product (A - Z)</option>
                    <option value="4">Product (Z - A)</option>
                  </select>                
                </div>
              </div>    
              <div>
                <div>
                  <div class="productListingFilter">                    
                    <ul>
                      <li class="ngb_accordion_header-level-0"><span (click)="onRoomChange()">ROOMS ({{roomAndUsageCount}})</span><span (click)="onRoomChange()" style="position: absolute; right: 0; cursor: pointer;"><i *ngIf="!isRoomShow" class="icon-plus" style="color: #000000; font-weight: bolder;"></i><i *ngIf="isRoomShow" class="icon-minus" style="color: #000000; font-weight: bolder;"></i></span>
                        <ul *ngIf="isRoomShow">
                          <li class="ngb_accordion_header" *ngFor="let item of roomAndUsageService.list;">
                             <div (click)="onRoomSubChange(item)"><i class="ngb_accordion_header_expand-collapse-indicatior-arrow"></i> {{item.DisplayName}} ({{item.ItemCount}})</div>
                             <ul style="margin-top: 10px; margin-left: 15px;" *ngIf="item.IsMenuShow">
                              <ng-container *ngFor="let itemSub of typeService.list">
                                <li *ngIf="item.ID===itemSub.RoomAndUsage_ID">                                  
                                  <a class="ngb-accordion_content-ul-li-div" routerLink="/{{type}}/{{itemSub.URLCode}}.html"
                                  title="{{itemSub.DisplayName}} ({{itemSub.ItemCount}})"><i class="icon-circle"></i> {{itemSub.DisplayName}} ({{itemSub.ItemCount}})</a>
                                </li>
                              </ng-container>                             
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li class="ngb_accordion_header-level-0" (click)="onCollectionChange()"><span>COLLECTIONS ({{collectionCount}})</span> <span style="position: absolute; right: 0; cursor: pointer;"><i *ngIf="!isCollectionShow" class="icon-plus" style="color: #000000; font-weight: bolder;"></i><i *ngIf="isCollectionShow" class="icon-minus" style="color: #000000; font-weight: bolder;"></i></span>
                        <ul style="margin-top: 10px;" *ngIf="isCollectionShow">
                          <li *ngFor="let item of collectionService.list;">
                            <a class="ngb-accordion_content-ul-li-div" routerLink="/{{collection}}/{{item.URLCode}}.html"
                            title="{{item.DisplayName}} ({{item.ItemCount}})"><i class="icon-circle"></i> {{item.DisplayName}} ({{item.ItemCount}})</a>
                          </li>
                        </ul>
                      </li>
                      <li class="ngb_accordion_header-level-0" (click)="onLifeStyleChange()"><span>LIFESTYLES ({{lifeStyleCount}})</span> <span style="position: absolute; right: 0; cursor: pointer;"><i *ngIf="!isLifeStyleShow" class="icon-plus" style="color: #000000; font-weight: bolder;"></i><i *ngIf="isLifeStyleShow" class="icon-minus" style="color: #000000; font-weight: bolder;"></i></span>
                        <ul style="margin-top: 10px;" *ngIf="isLifeStyleShow">
                          <li *ngFor="let item of lifeStyleService.list;">
                            <a class="ngb-accordion_content-ul-li-div" routerLink="/{{lifeStyle}}/{{item.URLCode}}.html"
                                title="{{item.DisplayName}} ({{item.ItemCount}})"><i class="icon-circle"></i> {{item.DisplayName}} ({{item.ItemCount}})</a>
                        </li>
                        </ul>
                      </li>
                      <li class="ngb_accordion_header-level-0" (click)="onStyleChange()"><span>STYLES ({{styleCount}})</span> <span style="position: absolute; right: 0; cursor: pointer;"><i *ngIf="!isStyleShow" class="icon-plus" style="color: #000000; font-weight: bolder;"></i><i *ngIf="isStyleShow" class="icon-minus" style="color: #000000; font-weight: bolder;"></i></span>
                        <ul style="margin-top: 10px;" *ngIf="isStyleShow">
                          <li *ngFor="let item of styleService.list;">
                            <a class="ngb-accordion_content-ul-li-div" routerLink="/{{style}}/{{item.URLCode}}.html"
                                title="{{item.DisplayName}} ({{item.ItemCount}})"><i class="icon-circle"></i> {{item.DisplayName}} ({{item.ItemCount}})</a>
                        </li>
                        </ul>
                      </li>
                      <li class="ngb_accordion_header-level-0" (click)="onShapeChange()"><span>SHAPES ({{shapeCount}})</span> <span style="position: absolute; right: 0; cursor: pointer;"><i *ngIf="!isShapeShow" class="icon-plus" style="color: #000000; font-weight: bolder;"></i><i *ngIf="isShapeShow" class="icon-minus" style="color: #000000; font-weight: bolder;"></i></span>
                        <ul style="margin-top: 10px;" *ngIf="isShapeShow">
                          <li *ngFor="let item of shapeService.list;">
                            <a *ngIf="item.ID===shapeService.formData.ID" class="ngb-accordion_content-ul-li-div" routerLink="/{{shape}}/{{item.URLCode}}.html"
                                title="{{item.DisplayName}} ({{item.ItemCount}})"><i class="icon-circle"></i> {{item.DisplayName}} ({{item.ItemCount}}) <span style="position: absolute; right: 0;"><i class="icon-checked"></i></span></a>
                            <a *ngIf="item.ID!=shapeService.formData.ID" class="ngb-accordion_content-ul-li-div" routerLink="/{{shape}}/{{item.URLCode}}.html"
                                title="{{item.DisplayName}} ({{item.ItemCount}})"><i class="icon-circle"></i>{{item.DisplayName}} ({{item.ItemCount}})</a>
                        </li>
                        </ul>
                      </li>
                      <li class="ngb_accordion_header-level-0">
                        <a routerLink="/extending.html" title="EXTENDING">EXTENDING ({{extendingCount}})</a>
                      </li>
                      <!-- <li class="ngb_accordion_header-level-0">
                        <a routerLink="/filters/;/;/;/{{shapeService.formData.URLCode}}/;/;/;" title="Advanced filters"> advanced filters</a>
                      </li> -->
                    </ul>                  
                  </div>
                </div>
              </div>     
              <div [hidden]="!isOpenFilterAtMobile" class="productFilter_sidebar_mobile_action fixed-bottom">                
                <div class="productFilter_sidebar_mobile_action-close" (click)="onClickMobileFilterIcon(false)">Close</div>                
              </div>      
            </div>
            <div class="productFilter_content w-100 d-flex flex-column flex-grow-1">
              <div class="productFilter_content_toolbar sticky_filter_horizontal">
                
                <div class="list-product-filter">
                  <h2 class="productFilter_content_toolbar_count"> {{itemService.listItemDataTransfer.length}} Results
                  </h2>
                  <div
                    class="productFilter_content_toolbar_action flex-grow-1 d-none d-xl-flex align-items-center justify-content-end">
                    <div class="productFilter_content_toolbar_action_search">
                      <input autofocus type="text" name="searchString" [(ngModel)]="searchString" placeholder="Search..."
                        class="ng-untouched ng-pristine ng-valid" (keyup.enter)="onSearch()">
                      <i (click)="onSearch()" class="icon-Search" style="cursor: pointer;"></i>

                    </div>
                    <div class="productFilter_content_toolbar_action_sort">
                      Sort by:
                      <select name="SortBy" [(ngModel)]="viewModel" (change)="onSortByChange()"
                        style="border-width: 0px;">
                        <option value="1">Newest</option>
                        <option value="2">Best Selling</option>
                        <option value="3">Product (A - Z)</option>
                        <option value="4">Product (Z - A)</option>
                      </select>
                    </div>
                    <div class="productFilter_content_toolbar_action_stockProgram">
                      <!-- <div displaytext="In Stocking Program" [hidden]="!isActiveTAUS">
                        <input type="checkbox" name="inStockProgram" [(ngModel)]="inStockProgram"
                          (change)="onInStockProgramChange()" style="cursor: pointer;">
                        <span class="taCheckBox-text">&nbsp;In Stocking Program</span>
                      </div> -->
                      <div displaytext="In Stock" *ngIf="isActiveTAUS">
                        <!-- <input type="checkbox" name="isStocked" [(ngModel)]="isStocked" (change)="onInStockChange()"
                          style="cursor: pointer;">
                        <span class="taCheckBox-text">&nbsp;In Stock</span> -->

                        <label class="stock_programe">In Stock
                          <input type="checkbox" checked="checked" name="isStocked" [(ngModel)]="isStocked" (change)="onInStockChange()"
                          style="cursor: pointer;">
                          <span class="checkmark"></span>
                        </label>

                      </div>
                    </div>
                    <div class="productFilter_content_toolbar_action_gridView">
                      <i (click)="onSwitchViewMode()" class="icon-grid_horizontal" [ngClass]="{active: isGridViewMode}"></i>
                    </div>
                    <div class="productFilter_content_toolbar_action_listView">
                      <i (click)="onSwitchViewMode()" class="icon-grid_vertical" [ngClass]="{active: !isGridViewMode}"></i>
                    </div>
                  </div>
                  <div class="d-xl-none" (click)="onClickMobileFilterIcon(true)">
                    <i class="icon-filter" style="font-size: 2em; cursor: pointer;"></i>
                  </div>
                </div>
              </div>
              <div class="result_search" [hidden]="!isResultEmpty">
                <div
                  style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Homepage/picture/bg_not_result.jpg') no-repeat; background-position: center center; height: 380px; background-size: cover;">
                  <div class="result_not_found">
                    <div class="note_not_result">
                      <span style="font-size: 50px; color: #cbcbcb; font-family: HurmeGeometricSans1-Thin;">Searching...</span>
                        <br />
                        We couldn't find a match for <b>"{{shapeService.formData.Name}}"</b>. Please try another search.
                    </div>                    
                  </div>
                </div>
              </div>
              <div class="ta_circle_loader_container h-100" *ngIf="isShowLoading">
                <div class="ta_circle_loader"></div>
              </div>
              <div class="products" [ngClass]="{'isGridMode': isGridViewMode}" style="margin-top: 10px;">
                <div class="product" *ngFor="let item of itemService.listItemDataTransfer;" id="{{item.ID}}">
                  <div class="tags">
                    <img *ngIf="item.IsCFPItem" src="/assets/img/CP-icon.png" class="pic-icon-cp">
                    <img *ngIf="item.IsAXHCFPItem" src="/assets/img/icon-AXH.png" class="pic-icon-axh">
                    <span *ngIf="item.IsNew" class="tag">New</span>
                  </div>
                  <div class="info">
                    <div class="image">
                      <a routerLink="/{{productDetail}}/{{item.URLCode}}.html" title="{{item.ProductName}}">
                        <img src="{{item.ImageSirv}}?w=400&amp;h=400" alt="{{item.ProductName}}" title="{{item.ProductName}}" />
                        <!-- <img src="{{imageURLSub}}/{{item.Image}}" alt="{{item.ProductName}}" title="{{item.ProductName}}" /> -->
                      </a>
                    </div>
                    <div class="name"><a routerLink="/{{productDetail}}/{{item.URLCode}}.html"
                        title="{{item.ProductName}}">
                        <h4>{{item.ProductName}}</h4>
                      </a>
                      <div class="sku">{{item.SKU}}</div>
                      <div class="addtowish" [appAddProductWishListPopup]="{productId: item.ID, productName: item.ProductName, productSku: item.SKU}"
                        (appAddProductWishListPopupOnClose)="onCloseWishlistPopup(item)">                          
                          <i [ngClass]="{'icon-heart_fill' : item?.IsInWishList, 'icon-heart_outline' : !item?.IsInWishList}"></i>
                      </div>
                      <div [hidden]="isGridViewMode" class="productFilter_content_productList_row_product_caption_description">
                        {{item.ExtendedDescription}}</div>
                    </div>
                    <div [hidden]="isGridViewMode" class="productFilter_content_productList_row_product_themestyle">
                      <b>Brand:</b> {{item.BrandName}}<br />
                      <b>Collection:</b> {{item.CollectionName}}<br />
                      <b>Room:</b> {{item.RoomAndUsageName}}<br />
                      <b>Type:</b> {{item.TypeName}}
                    </div>
                  </div>
                </div>
              </div>             
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>