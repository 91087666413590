<div id="theodoreIntiaro">
    <div style="text-align:center;">
        <a title="TAilor Fit" href="dummy/3D-tailorfit.html"
            style="text-decoration: none; border-bottom: 0px solid #636363; text-align: center;">
            <img src="assets/TAilorFit.png" alt="TAilor Fit" title="TAilor Fit" style="width: 20%; margin: 3% 0 0 0;">
        </a>
    </div>
</div>
<div class="row" style="margin: 3% 0;"> 
    <div class="col-xl-2">
    </div>   
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/10190" title="Sofa"><img
                src="https://theodorealexander.com/images/Intiaro/Sofa.jpg" width=270 height=270 title="Sofa"
                alt="Sofa"></a>
        <p style="font-size: 18px;">Sofa</p>
    </div>
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12471" title="Grand Sofa"><img
                src="https://theodorealexander.com/images/Intiaro/GrandSofa.jpg" width=270 height=270 title="Grand Sofa"
                alt="Grand Sofa"></a>
        <p style="font-size: 18px;">Grand Sofa</p>
    </div>
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12470" title="Long Sofa"><img
                src="https://theodorealexander.com/images/Intiaro/LongSofa.jpg" width=270 height=270 title="Long Sofa"
                alt="Long Sofa"></a>
        <p style="font-size: 18px;">Long Sofa</p>
    </div>
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12460" title="Mid Size Sofa"><img
                src="https://theodorealexander.com/images/Intiaro/MidSizeSofa.jpg" width=270 height=270
                title="Mid Size Sofa" alt="Mid Size Sofa"></a>
        <p style="font-size: 18px;">Mid Size Sofa</p>
    </div>
    <div class="col-xl-2">
    </div>
</div>