import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GhostBlog } from 'src/app/shared/GhostBlog.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class GhostBlogService {
    list: GhostBlog[] | undefined;    
    listShow: GhostBlog[] | undefined;    
    listCarousel: GhostBlog[] | undefined;    
    listHome: GhostBlog[] | undefined;    
    listSideBar: GhostBlog[] | undefined;    
    formData!: GhostBlog;
    formData001!: GhostBlog;
    formData002!: GhostBlog;
    formData003!: GhostBlog;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "GhostBlog";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];      
        this.listShow = [];      
        this.formData = {
        };
    }       
    getByActiveToList(active: boolean): Observable<TaAPIResponse<GhostBlog>> {
        let url = this.aPIURL + this.controller + '/GetByActiveToList';
        const params = new HttpParams()            
            .set('active', JSON.stringify(active))            
        return this.httpClient.get<TaAPIResponse<GhostBlog>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

