import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpEvent, HttpHandler, HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { TaUniversalHubSubject } from '../web/universal-hub.service';
import { environment } from 'src/environments/environment';
import { tap, catchError, finalize } from 'rxjs/operators';
import { TaApiServiceLogin } from './TaApiServiceLogin';
import { TALocalStogareKeys, TALocalStogareService } from '../core/taLocalStorage';
import { Router } from '@angular/router';

@Injectable(
  //   {
  //   providedIn: 'root'
  // }
)
export class TaInterceptor implements HttpInterceptor {
  public static _fakeIP: string;
  private count : number = 0;
  private maxRetryCount = 1;

  constructor(private serviceLogin: TaApiServiceLogin, private localStorage: TALocalStogareService, private router: Router) {
    let triedCount = +this.localStorage.get(TALocalStogareKeys.TAReloadTryCount);
    if (!triedCount) {
      this.localStorage.set(TALocalStogareKeys.TAReloadTryCount, this.maxRetryCount);
    } else {
      //Do nothing
    }
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //process the request
    let newHeaders = req.headers;
    if (req.url.startsWith(environment.appApiUrl) || req.url.startsWith("https://phattran.ghost.io")) {
      ++this.count;
      setTimeout(()=>{
          TaUniversalHubSubject.httpLoadingChangedEvent.next(this.count>0);
      }, 1000);
      if (TaInterceptor._fakeIP) {
        newHeaders = newHeaders.set('Ip_Address', TaInterceptor._fakeIP);        
      }
      newHeaders = newHeaders.set('API_KEY', 'X-some-key');
      const token = this.serviceLogin.taUserToken;
      if (token) {
        newHeaders = newHeaders.set('Authorization', `Bearer ${token}`);        
      }
    }

    req = req.clone({ headers: newHeaders });
    return next.handle(req).pipe(tap(event => {    
    }), catchError((error: HttpErrorResponse) => {
      if (req.url.startsWith(environment.appApiUrl)) {
        TaUniversalHubSubject.httpLoadingChangedEvent.next(false);        
        if (environment.production) {
          let triedCount = +this.localStorage.get(TALocalStogareKeys.TAReloadTryCount);
          this.localStorage.set(TALocalStogareKeys.TAReloadTryCount, triedCount - 1);
          if (triedCount > 0) {
            // try to reload
            this.serviceLogin.logout();
            setTimeout(()=>{
              window.location.reload();
            }, 1000);
          } else {
            // tried 3 times, stop retry
            setTimeout(()=>{
              //clear local storage
              this.localStorage.clearAnonymousWishlist();
              this.localStorage.clearShoppingCart();
              //then reset the count
              this.localStorage.set(TALocalStogareKeys.TAReloadTryCount, this.maxRetryCount);
              //reload back to home
              this.router.navigateByUrl("");
            }, 10000);//wait 10 sec to make sure all reloading has been finished, if not, the reload might run again
          }
        }
      }
      return throwError(error);
    }), finalize(()=>{
      this.count = Math.max(--this.count,0);
      TaUniversalHubSubject.httpLoadingChangedEvent.next(this.count>0);
    }));
  }
}

