import { HttpClient } from '@angular/common/http';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from '../../../environments/environment'
import { TAUtility, TawAccountProvider, UserRegions } from 'src/app/theoalex/theoalex';
import { TALocalStogareKeys, TALocalStogareService } from 'src/app/service/core/taLocalStorage';
import { tap, delay, map } from 'rxjs/operators';
import { from, Observable, of, Subscription } from 'rxjs';
import { TaUniversalHubSubject } from '../web/universal-hub.service';
import { TaAPIResponse } from './TaApiServiceCommon';
import { Router, Resolve } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { TawUserAddressViewModel, TawAddressBillingAndShipping } from './model/model';

@Injectable({
  providedIn: 'root'
})

export class TaApiServiceLogin {
  loginByTokenSubsciption: Subscription;
  private _isLogin: boolean;
  public get isLogin(): boolean {
    return !!(this._taUserCredential && this.taUserCredential.UserName);
  }

  private _isReady: boolean;
  public get isReady(): boolean {
    return !!this._taUserCredential;
  }

  private _taUserToken: string;
  public get taUserToken(): string {
    if (!this._taUserToken) {
      if (isPlatformBrowser(this.platformId)) {
        this._taUserToken = this.taLocalStorage.get(TALocalStogareKeys.TALocalStogareUserToken);
      }
    }
    return this._taUserToken;
  }
  public set taUserToken(newToken: string) {
    //if (newToken && this._taUserToken != newToken) {
    this._taUserToken = newToken;
    if (isPlatformBrowser(this.platformId)) {
      this.taLocalStorage.set(TALocalStogareKeys.TALocalStogareUserToken, newToken);
    }
    // if (this.loginByTokenSubsciption) {
    //   this.loginByTokenSubsciption.unsubscribe();
    // }
    //this.loginByTokenSubsciption = this.loginByToken().subscribe();
    //}
  }

  private _taUserCredential: TaUserCredential;
  public get taUserCredential(): TaUserCredential {
    return this._taUserCredential;
  }
  public set taUserCredential(value: TaUserCredential) {
    let isDirty: boolean = value && !this._taUserCredential;
    if (this._taUserCredential && value) {
      Object.keys(this._taUserCredential).forEach(key => {
        isDirty = isDirty || (this._taUserCredential[key] != value[key]);
      });
    }
    this._taUserCredential = value;
    TaUniversalHubSubject.userCredentialSubjectData.next(this._taUserCredential);
    if (isDirty) {
      TaUniversalHubSubject.userCredentialSubjectChanged.next(this._taUserCredential);
    }
    TaUniversalHubSubject.userAuthenticCheckedEvent.next({ isLogged: !!(this._taUserCredential && this._taUserCredential.UserName), userCredential: this._taUserCredential });
    this.getUserSetting();
  }

  private _dealerInfo: TaApiServiceUserUserInfo;
  public get dealerInfo(): TaApiServiceUserUserInfo {
    return this._dealerInfo;
  }
  public set dealerInfo(value: TaApiServiceUserUserInfo) {
    this._dealerInfo = value;
  }

  constructor(private http: HttpClient, private router: Router, private taLocalStorage: TALocalStogareService, @Inject(PLATFORM_ID) private platformId) {
    if (isPlatformBrowser(this.platformId)) {
      let clientToken: string = this.taLocalStorage.get(TALocalStogareKeys.TALocalStogareUserToken);
      this._taUserToken = clientToken;
    }
  }

  verifyEmail(Id?: string): Observable<any> {
    if (this.isLogin) {
      this.logout();
    }
    const command = "public/api/verifyemail";
    return this.http.get(`${environment.appApiUrl}/${command}/${Id}`)
      .pipe(tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.taUserToken = res.Data.Token;
          this.taUserCredential = res.Data.UserCredential
        }
      }));
  }

  loginByUserId(userId: string): Observable<TaAPIResponse<any>> {
    const command = "api/LoginByUserId";
    return this.http.get<any>(`${environment.appApiUrl}/api/LoginByUserId?userId=${userId}`)
      .pipe(tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.taUserToken = res.Data.Token;
          this.taUserCredential = res.Data.UserCredential;
        }
      }));
  }

  login(username?: string, password?: string, tempWishlist: [] = []): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      const command = "public/api/login";
      const user = {
        UserName: username,
        Password: password,
        WishListItems: this.taLocalStorage.getAnonymousWishlist().map((productId) => {
          return {
            ItemId: productId
            //,DateAdded: ""
          }
        })
      }
      //clear current token before call api to login because intercepter will attach the token
      this.ClearToken();
      return this.http.post<any>(`${environment.appApiUrl}/${command}`, user)
        .pipe(tap(res => {
          if (TAUtility.isAPIRespondOk(res)) {
            this.taUserToken = res.Data.Token;
            this.taUserCredential = res.Data.UserCredential;
          } else {
            this.logout();
          }
        }));
    }
  }

  ImpersonateUser(userId: string): Observable<any> {
    const command = "api/impersonate";
    return this.http.get<any>(`${environment.appApiUrl}/api/impersonate/${userId}`)
      .pipe(tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.taUserToken = res.Data.Token;
          this.taUserCredential = res.Data.UserCredential;
        }
      }));
  }

  public loginByToken(): Observable<TaAPIResponse<TaUserCredential>> {
    return this.http.get<TaAPIResponse<TaUserCredential>>(`${environment.appApiUrl}/api/loginByToken`).pipe(tap((res: TaAPIResponse<TaUserCredential>) => {
      //it always success with out care token
      //if invalid token, anonymous credential is returned
      this.taUserCredential = res.Data;
    }));
  }

  logout(isNeedGoHome?: boolean) {
    if (isNeedGoHome) {
      //go to home if in account page (pages require login)
      setTimeout(() => {
        if (this.router.url.startsWith("/account")) {
          this.router.navigateByUrl("/");
        }
      }, 0)
    }
    this.ClearToken();
    this.loginByToken().subscribe();
  }

  private ClearToken() {
    this._taUserToken = null;
    if (isPlatformBrowser(this.platformId)) {
      this.taLocalStorage.set(TALocalStogareKeys.TALocalStogareUserToken, null);
      localStorage.setItem("Cart", "0");
    }
  }

  registerWithProvider(registerObj: TaApiServiceRegisterUser): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      const command = "public/api/RegisterUserAndLoginByProvider";
      registerObj.WishListItems = this.taLocalStorage.getAnonymousWishlist().map((productId) => {
        return {
          ItemId: productId
          //,DateAdded: ""
        };
      })
      return this.http.post(`${environment.appApiUrl}/${command}`, registerObj).pipe(tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.taUserToken = res.Data.Token;
          this.taUserCredential = res.Data.UserCredential;
        }
      }));
    }
  }

  register(registerObj: TaApiServiceRegisterUser): Observable<any> {
    if (isPlatformBrowser(this.platformId)) {
      switch (registerObj.Provider) {
        case TawAccountProvider.google:
          return this.registerWithProvider(registerObj);
          break;
        default:
          const command = "public/api/RegisterUser";
          registerObj.WishListItems = this.taLocalStorage.getAnonymousWishlist().map((productId) => {
            return {
              ItemId: productId
              //,DateAdded: ""
            };
          })
          //http://api-test.theodorealexander.com/public/api/RegisterUser
          return this.http.post(`${environment.appApiUrl}/${command}`, registerObj);
          break;
      }
    }
  }

  getUserInfo(): Observable<any> {
    const command = "api/User/GetUserInfo";
    return this.http.get(`${environment.appApiUrl}/${command}`);
  }

  updateUserInfo(userInfo: TaApiServiceLoginUserModified): Observable<any> {
    const command = "api/User/UpdateUser";
    return this.http.post(`${environment.appApiUrl}/${command}`, userInfo);
  }

  changePassword(password: TaApiServiceUserChangePassword) {
    const command = "api/User/ChangePassword";
    return this.http.post(`${environment.appApiUrl}/${command}`, password);
  }

  getDealerInfo(): Observable<TaAPIResponse<TaApiServiceUserUserInfo>> {
    const command = "api/User/GetDealerInfo";
    return this.http.get<TaAPIResponse<TaApiServiceUserUserInfo>>(`${environment.appApiUrl}/${command}`).pipe(tap(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        this.dealerInfo = res.Data;
      }
    }));;
  }

  updateDealerInfo(dealerInfo: TaApiServiceUserDealerInfoUpdate): Observable<TaAPIResponse<TaApiServiceUserUserInfo>> {
    {
      const command = 'api/User/UpdateDealer';
      return this.http.post<TaAPIResponse<TaApiServiceUserUserInfo>>(`${environment.appApiUrl}/${command}`, dealerInfo).pipe(tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.dealerInfo = res.Data;
        }
      }));;
    }
  }

  getUserIdByEmail(email: string): Observable<TaAPIResponse<string>> {
    const command = "public/api/GetUserIdByEmail";
    return this.http.get<TaAPIResponse<string>>(`${environment.appApiUrl}/${command}?email=${email}`);
  }

  //++ADDRESS
  getUserAddress(): Observable<TaAPIResponse<TawUserAddressViewModel[]>> {
    const command = "api/User/GetUserAddress";
    return this.http.get<TaAPIResponse<TawUserAddressViewModel[]>>(`${environment.appApiUrl}/${command}`);
  }

  getActiveUserAddress(): Observable<TaAPIResponse<TawUserAddressViewModel[]>> {
    const command = "api/User/GetActiveUserAddress";
    return this.http.get<TaAPIResponse<TawUserAddressViewModel[]>>(`${environment.appApiUrl}/${command}`);
  }

  getUserAddressById(addressId: string): Observable<TaAPIResponse<TawUserAddressViewModel>> {
    const command = `api/User/GetUserAddressById?addressId=${addressId}`;
    return this.http.get<TaAPIResponse<TawUserAddressViewModel>>(`${environment.appApiUrl}/${command}`);
  }

  addUserAddress(userAddress: TawUserAddressViewModel): Observable<TaAPIResponse<TawUserAddressViewModel>> {
    const command = "api/User/AddUserAddress";
    return this.http.post<TaAPIResponse<TawUserAddressViewModel>>(`${environment.appApiUrl}/${command}`, userAddress);
  }

  updateUserAddress(userAddress: TawUserAddressViewModel): Observable<TaAPIResponse<TawUserAddressViewModel>> {
    const command = "api/User/UpdateUserAddress";
    return this.http.post<TaAPIResponse<TawUserAddressViewModel>>(`${environment.appApiUrl}/${command}`, userAddress);
  }

  deleteUserAddress(userAddressId: string): Observable<TaAPIResponse<string>> {
    const command = "api/User/DeleteUserAddress";
    return this.http.get<TaAPIResponse<string>>(`${environment.appApiUrl}/${command}?userAddressId=${userAddressId}`).pipe(map(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        res.Data = userAddressId;
      }
      return res;
    }));
  }
  //--ADDRESS

  //++ USER SETTING
  getUserSetting() {
    if (this.isLogin) {
      const command = "api/User/GetUserSetting";
      this.http.get<TaAPIResponse<TaApiUserSetting>>(`${environment.appApiUrl}/${command}`).pipe(
        //delay(5000),
        tap(res => {
          if (TAUtility.isAPIRespondOk(res)) {
            TaUniversalHubSubject.userSettingSubject.next(res.Data);
          } else {
            TaUniversalHubSubject.userSettingSubject.next({ ShowSKUs: true });
          }
          //alert("done user setting");
        })).subscribe();
    } else {
      TaUniversalHubSubject.userSettingSubject.next({ ShowSKUs: true });
    }
  }

  updateUserSetting(userSetting: TaApiUserSetting): Observable<TaAPIResponse<TaApiUserSetting>> {
    const command = "api/User/UpdateUserSetting";
    return this.http.post<TaAPIResponse<TaApiUserSetting>>(`${environment.appApiUrl}/${command}`, userSetting).pipe(tap(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        TaUniversalHubSubject.userSettingSubject.next(res.Data);
      } else {
        return TaUniversalHubSubject.userSettingSubject.next({ ShowSKUs: true });
      }
    }));
  }
  //-- USER SETTING

  recoveryPassword(recoveryEmail: string): Observable<TaAPIResponse<string>> {
    const command = "api/User/RetrievePassword";
    return this.http.post<TaAPIResponse<string>>(`${environment.appApiUrl}/${command}`, { Email: recoveryEmail });
  }

  removeSaleAssociatedOfStore(storeId: string, saleAssId: string): Observable<any> {
    const command = "api/User/RevokeAssociate";
    return this.http.post<TaAPIResponse<string>>(`${environment.appApiUrl}/${command}`, { "SaleId": saleAssId, "StoreId": storeId });
  }

  approveSaleAssociatedOfStore(storeId: string, saleAssId: string) {
    const command = "api/User/ApprovedAssociate";
    return this.http.post<TaAPIResponse<string>>(`${environment.appApiUrl}/${command}`, { "SaleId": saleAssId, "StoreId": storeId });
  }

}

export interface TaApiServiceUserDealerInfoUpdate {
  PriceMultiplier?: number,
  ChangePriceMultiplier?: boolean,
  ShowSKUs?: boolean,
  ShowPrice?: boolean,
  ShowAddress?: boolean,
  ShowWholesalePrice?: boolean
}

export interface TaApiServiceUserUserInfo {
  BillingAddress?: TaApiServiceUserAddress[],
  ShippingAddress?: TaApiServiceUserAddress[],
  Stores?: TaApiServiceUserStore[],
  PriceMultiplier?: number,
}


export interface TaApiServiceUserAddress {
  AddressLine1?: string,
  AddressLine2?: string,
  City_ID?: string,
  Postalcode?: string,
  Phone?: string,
  Fax?: string,
  IsDefault?: number,
  City?: string,
  Region?: string,
  Country?: string,
  isSelected?: boolean
}

export interface TaApiUserSetting {
  ShowSKUs?: boolean,//default is true for all users
  ShowPrice?: boolean,
  ShowAddress?: boolean,
  PriceMultiplier?: number,
  ShowWholesalePrice?: boolean
  RetailMultiplier?: number
}

export interface TaApiServiceUserStore {
  ID?: string,
  Name?: string,
  Address1?: string,
  Address2?: string,
  CityName?: string,
  RegionName?: string,
  CountryName?: string,
  PostalCode?: string,
  Phone?: string,
  Fax?: string,
  Email?: string,
  Website?: string,
  DateModified?: string,
  Associates?: {
    SalesAssociate_ID?: string,
    Firstname?: string,
    Lastname?: string,
    Email?: string,
    Username?: string,
    DealerApproved?: boolean
  }[];
  PendingAssociates?: {
    SalesAssociate_ID?: string,
    Firstname?: string,
    Lastname?: string,
    Email?: string,
    Username?: string,
    DealerApproved?: boolean
  }[],
  Dealers?: {
    UserId?: string,
    Username?: string
  }[],
}

export interface TaApiServiceUserChangePassword {
  NewPassword: string,
  ConfirmPassword: string,
  CurrentPassword: string
}

export interface TaApiServiceLoginUserModified {
  ID: string,
  AccountNumber: string,
  Email: string,
  Firstname: string,
  Lastname: string,
  Address1?: string,
  Address2?: string,
  CityId?: string,
  PostalCode?: string,
  NewPassword?: string,
  ConfirmPassword?: string,
  CurrentPassword?: string,
  UserType?: string
}

export interface TaApiServiceLoginUser {
  ID?: string,
  Username?: string,
  Password?: string,
  Lastname?: string,
  Firstname?: string,
  UserType_ID?: string,
  Email?: string,
  Address1?: string,
  PostalCode?: string,
  Address2?: string,
  UserTypeName?: string,
  MailingAddress?: string,
  CityName?: string,
  RegionName?: string,
  CountryName?: string,
  City_ID?: string,
  Region_ID?: string,
  Country_ID?: string,
  AccountNumber?: string,
  StoreInfo?: {
    ID?: string,
    Name?: string,
    Address1?: string,
    Address2?: string,
    CityName?: string,
    RegionName?: string,
    CountryName?: string,
    PostalCode?: string,
    Phone?: string,
    Fax?: string,
    Email?: string,
    Website?: string,
    DateModified?: string
  }
}


export interface TaApiServiceRegisterUserStore {
  Address1?: string,
  Address2?: string,
  CityName?: string,
  CountryName?: string,
  DateModified?: string,
  Email?: string,
  Fax?: string,
  ID?: string,
  Name?: string,
  Phone?: string,
  PostalCode?: string,
  RegionName?: string,
  Website?: string
}

export interface TaApiServiceRegisterUser {
  UserType_ID?: string,
  //Username?: string,
  Password?: string,
  confirmedPassword?: string,
  Email?: string,
  Firstname?: string,
  Lastname?: string,
  EmailVerified?: boolean,
  AccountEnabled?: boolean,
  DateCreated?: string,
  //Address1?: string,
  //Address2?: string,
  City_ID?: string,
  PostalCode?: string,
  Provider: string,//default at database is 'web'
  IsEmailSubscribed: boolean,//default at database is true
  WishListItems?: {
    ItemId: string,
    DateAdded?: string
  }[]
}

export interface TaUserCredential {
  AccountNumber?: string,
  UserId?: string,
  Email?: string,
  UserName?: string,
  FirstName?: string,
  LastName?: string,
  UserType?: string,
  CountryId?: string,
  Region?: UserRegions,
  Country?: string,
  CountryFullName?: string,
  IsShowInStock?: boolean,
  ContinentId?: string,
  ExclusivityGroupId?: string,
  RequestIpAddress?: string,
  ResponseIpAddress?: string
};

// @Injectable({ providedIn: 'root' })
// export class UserTokenResolver implements Resolve<any> {
//   constructor(private loginService: TaApiServiceLogin) { }
//   resolve(): Observable<any> | Promise<any> | any {
//     if (this.loginService.taUserCredential && this.loginService.taUserCredential.UserName) {
//       return of(this.loginService.taUserCredential);
//     } else return this.loginService.loginByToken();
//   }
// }

// @Injectable({ providedIn: 'root' })
// export class UserTokenAndSetiingResolver implements Resolve<any> {
//   constructor(private loginService: TaApiServiceLogin, y: UserTokenResolver) { }
//   resolve(): Observable<any> | Promise<any> | any {
//       return this.loginService.loginByToken();
//   }
// }


// @Injectable({ providedIn: 'root' })
// export class DealerInfoResolver implements Resolve<any> {
//   constructor(private loginService: TaApiServiceLogin) { }
//   resolve(): Observable<any> | Promise<any> | any {
//     if (this.loginService.dealerInfo) {
//       return of(this.loginService.dealerInfo);
//     } else return this.loginService.getDealerInfo();
//   }
// }
