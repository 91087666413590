import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { GhostBlog } from 'src/app/shared/GhostBlog.model';
import { GhostBlogService } from 'src/app/shared/GhostBlog.service';
@Component({
  selector: 'app-ta-web-blog-comp-carousel',
  templateUrl: './ta-web-blog-comp-carousel.component.html',
  styleUrls: ['./ta-web-blog-comp-carousel.component.scss']
})
export class TaWebBlogCompCarouselComponent implements OnInit {


  @ViewChild(SwiperDirective) swiper: SwiperDirective;
  swipeConfig: SwiperConfigInterface = {
    navigation: {
      nextEl: '._TaGlobalCssSwiper_slider_navigation_right',
      prevEl: '._TaGlobalCssSwiper_slider_navigation_left'
    },
    loop: true
  }
  domainName: string = "https://theodorealexander.com/";
  listGhostBlog001: GhostBlog[] = [];
  constructor(
    public ghostBlogService: GhostBlogService,
  ) { }

  ngOnInit(): void {
    this.getPostForCarousel();
  }

  getPostForCarousel() {
    this.ghostBlogService.getByActiveToList(true).subscribe(res => {
      this.ghostBlogService.listCarousel = res.Data as GhostBlog[];
      for (var i = 0; i < this.ghostBlogService.listCarousel.length; i++) {
        if (this.ghostBlogService.listCarousel[i].IsBanner == true) {
          this.listGhostBlog001.push(this.ghostBlogService.listCarousel[i]);
        }
      }
    });
  }
}
