import { Component, OnInit, ViewChild, Input, Inject } from '@angular/core';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-ta-media-gallery',
  templateUrl: './ta-media-gallery.component.html',
  styleUrls: ['./ta-media-gallery.component.scss']
})
export class TaMediaGalleryComponent implements OnInit {

  @ViewChild('mainSwiper', { read: SwiperDirective }) mainSwiper: SwiperDirective;
  @ViewChild('thumbSwiper', { read: SwiperDirective }) thumbSwiper: SwiperDirective;

  public static showAsDialog(dialog: MatDialog, galleryItems: any[]){
    dialog.open(TaMediaGalleryComponent, {
      panelClass: "mat-dialog-fullScreen",
      data: galleryItems
    })
  }

  constructor(@Inject(MAT_DIALOG_DATA) public items: any) { }

  ngOnInit() {
    this.swipeConfigThumb.slidesPerView = this.items.length <= 5 ? this.items.length : 5;
  }

  swipeConfig : SwiperConfigInterface = {
    navigation: {
      nextEl: '._TaGlobalCssSwiper_slider_navigation_right',
      prevEl: '._TaGlobalCssSwiper_slider_navigation_left'
    },
    //loop: true
  }

  swipeConfigThumb : SwiperConfigInterface = {
    spaceBetween: 10,
    // freeMode: true,
    // watchSlidesVisibility: true,
    // watchSlidesProgress: true,
    //slidesPerView: 5,
    //loop: true
  }

  onClickThumbItem(){
    this.mainSwiper.swiper().slideTo(this.thumbSwiper.swiper().clickedIndex);
  }
}
