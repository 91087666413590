import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppGlobal } from 'src/app/shared/AppGlobal';
import { ItemDataTransfer } from 'src/app/shared/ItemDataTransfer.model';
import { ItemService } from 'src/app/shared/Item.service';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { LifeStyle } from 'src/app/shared/LifeStyle.model';
import { LifeStyleService } from 'src/app/shared/LifeStyle.service';
import { Shape } from 'src/app/shared/Shape.model';
import { ShapeService } from 'src/app/shared/Shape.service';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { RoomAndUsage } from 'src/app/shared/RoomAndUsage.model';
import { RoomAndUsageService } from 'src/app/shared/RoomAndUsage.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { HeadService } from 'src/app/shared/Head.service';
import { UserRegions } from 'src/app/theoalex/theoalex';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { StyleService } from 'src/app/shared/Style.service';
import { Style } from 'src/app/shared/Style.model';

@Component({
  selector: 'app-shape',
  templateUrl: './shape.component.html',
  styleUrls: ['./shape.component.scss']
})
export class ShapeComponent implements OnInit {

  userId: string = "";
  region: string = "";
  queryString: string = "";
  searchString: string = "";
  collection: string = environment.Collection;
  shape: string = environment.Shape;
  type: string = environment.Type;
  lifeStyle: string = environment.LifeStyle;
  style: string = environment.Style;
  productDetail: string = environment.ProductDetail;
  search: string = environment.Search;
  imageURLSub: string = environment.DomainImage + "images/Item";
  inStockProgram: boolean = false;
  isStocked: boolean = false;
  isShowLoading: boolean = false;
  isRoomShow: boolean = false;
  isCollectionShow: boolean = false;
  isLifeStyleShow: boolean = false;
  isStyleShow: boolean = false;
  isShapeShow: boolean = false;
  isGridViewMode: boolean = true;
  isResultEmpty: boolean = false;
  isActiveTAUS: boolean = false;
  isActive: boolean = true;
  isOpenFilterAtMobile: boolean = false;
  viewModel: number = 1;
  shapeCount: number = 0;
  lifeStyleCount: number = 0;
  styleCount: number = 0;
  collectionCount: number = 0;
  extendingCount: number = 0;
  roomAndUsageCount: number = 0;
  constructor(
    public router: Router,
    public itemService: ItemService,
    public collectionService: CollectionService,
    public lifeStyleService: LifeStyleService,
    public styleService: StyleService,
    public shapeService: ShapeService,
    public itemMenuService: ItemService,
    public roomAndUsageService: RoomAndUsageService,
    public typeService: TypeService,
    public downloadService: DownloadService,
    public headService: HeadService,
  ) {        
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.getByQueryString();
        this.onShapeChange();
      }
    });
  }

  ngOnInit(): void {
  }
  getCollectionMenuItems() {
    this.collectionCount = 0;
    this.collectionService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.collectionService.list = res.Data as Collection[];
      this.collectionService.list.forEach((item, index) => {
        this.collectionCount = this.collectionCount + item.ItemCount;
      });
    });
  }
  getRoomAndUsageCollectionMenuItems() {
    this.roomAndUsageCount = 0;
    this.roomAndUsageService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.roomAndUsageService.list = res.Data as RoomAndUsage[];
      this.roomAndUsageService.list.forEach((item, index) => {
        this.roomAndUsageCount = this.roomAndUsageCount + item.ItemCount;
      });
    });
  }
  getTypeCollectionMenuItems() {
    this.typeService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.typeService.list = res.Data as Type[];
    });
  }
  getLifeStyleMenuItems() {
    this.lifeStyleCount = 0;
    this.lifeStyleService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.lifeStyleService.list = res.Data as LifeStyle[];
      this.lifeStyleService.list.forEach((item, index) => {
        this.lifeStyleCount = this.lifeStyleCount + item.ItemCount;
      });
    });
  }
  getStyleMenuItems() {
    this.styleCount = 0;
    this.styleService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.styleService.list = res.Data as Style[];
      this.styleService.list.forEach((item, index) => {
        this.styleCount = this.styleCount + item.ItemCount;
      });
    });
  }
  getShapeMenuItems() {
    this.shapeCount = 0;
    this.shapeService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.shapeService.list = res.Data as Shape[];
      this.shapeService.list.forEach((item, index) => {
        this.shapeCount = this.shapeCount + item.ItemCount;
      });
    });
  }
  getExtendingMenuItems() {
    this.extendingCount = 0;
    this.itemMenuService.getByRegionAndExtendingToItemCount(this.region, this.isActive).subscribe(res => {
      this.extendingCount = res.Data as number;
    });
  }
  getByQueryString() {  
    this.itemService.listItemDataTransfer = [];
    this.isShowLoading = true;
    this.shapeService.getByURLCode(this.queryString).subscribe(res => {
      if (res) {
        if (res.Data) {
          this.shapeService.formData = res.Data as Shape;
          if (this.shapeService.formData) {
            if (this.shapeService.formData.ID) {
              this.headService.setPageHeadByShape(this.shapeService.formData);
              TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
                if (credential) {
                  this.region = credential?.Region;
                  this.userId = credential?.UserId;
                  this.isActiveTAUS = AppGlobal.InitializationIsActiveTAUS(this.region);
                  this.getCollectionMenuItems();
                  this.getRoomAndUsageCollectionMenuItems();
                  this.getTypeCollectionMenuItems();
                  this.getLifeStyleMenuItems();
                  this.getStyleMenuItems();
                  this.getShapeMenuItems();
                  this.getExtendingMenuItems();
                  this.getByQueryString001();
                }
              });
            }
          }
        }
      }
    });   
  }
  getByQueryString001() {
    this.itemService.listItemDataTransfer = [];
    this.isShowLoading = true;
    this.itemService.asyncGetDataTransferByUser_IDAndRegionAndShape_IDAndInStockProgramAndIsStockedToList(this.userId, this.region, this.shapeService.formData.ID, this.inStockProgram, this.isStocked).subscribe(res => {
      this.itemService.listItemDataTransferData = (res.Data as ItemDataTransfer[]);
      this.itemService.listItemDataTransfer = this.itemService.listItemDataTransferData;
      this.onSort();
      this.isShowLoading = false;
    });
  }
  onSort() {
    this.isResultEmpty = false;
    if (this.viewModel == 1) {      
      this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime());
    }
    if (this.viewModel == 2) {      
      this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => Number(a.IsNew > b.IsNew) * 2 - 1);
    }
    if (this.viewModel == 3) {
      this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => a.ProductName.localeCompare(b.ProductName));
    }
    if (this.viewModel == 4) {
      this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => b.ProductName.localeCompare(a.ProductName));
    }
    if (this.itemService.listItemDataTransfer.length == 0) {
      this.isResultEmpty = true;
    }
  }
  onInStockProgramChange() {
    this.getByQueryString001();
  }
  onInStockChange() {
    this.getByQueryString001();
  }
  onSortByChange() {
    this.onSort();
  }
  onSearch() {
    let url = AppGlobal.SetSearchURLByName(this.searchString);
    this.router.navigate([url]);
  }
  onCollectionChange() {
    this.isCollectionShow = !this.isCollectionShow;
  }
  onLifeStyleChange() {
    this.isLifeStyleShow = !this.isLifeStyleShow;
  }
  onStyleChange() {
    this.isStyleShow = !this.isStyleShow;
  }
  onShapeChange() {
    this.isShapeShow = !this.isShapeShow;
  }
  onRoomChange() {
    this.isRoomShow = !this.isRoomShow;
  }
  onRoomSubChange(item: RoomAndUsage) {
    this.isRoomShow = true;
    for (let i = 0; i < this.roomAndUsageService.list.length; i++) {
      if (item.ID == this.roomAndUsageService.list[i].ID) {
        if (this.roomAndUsageService.list[i].IsMenuShow == true) {
          this.roomAndUsageService.list[i].IsMenuShow = false;
        }
        else {
          this.roomAndUsageService.list[i].IsMenuShow = true;
        }
      }
    }
  }
  onSwitchViewMode() {
    this.isGridViewMode = !this.isGridViewMode;
  }
  onCloseWishlistPopup(item: ItemDataTransfer) {
    item.IsInWishList = true;
  }
  onClickMobileFilterIcon(isNeedOpen) {
    if (isNeedOpen !== undefined) {
      this.isOpenFilterAtMobile = isNeedOpen;
    } else {
      this.isOpenFilterAtMobile = !this.isOpenFilterAtMobile;
    }
    if (this.isOpenFilterAtMobile) {
      TaUniversalHubSubject.scrollRequest.next();
    }
    this.isGridViewMode = true;
  }
}