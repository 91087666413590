import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sectional',
  templateUrl: './sectional.component.html',
  styleUrls: ['./sectional.component.scss']
})
export class SectionalComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
