import { Directive, Input, HostListener, HostBinding, AfterViewInit, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { TAUtility } from '../theoalex/theoalex';

@Directive({
  selector: '[appSocialLink]'
})
export class SocialLinkDirective implements OnInit, AfterViewInit {
  ngOnInit(): void {
    this.href = environment.appSocialLink[this.link];
  }

  @Input('appSocialLink') link: string;
  @HostBinding('href') href: string;

  ngAfterViewInit(): void {
  }

  constructor() { }

}
