import { Directive, Input, HostBinding, OnDestroy } from '@angular/core';
import { UserRegions, TAUtility } from '../theoalex/theoalex';
import { Subscription } from 'rxjs';
import { TaApiServiceLogin } from '../service/api/TaApiServiceLogin';
import { TaUniversalHubSubject } from '../service/web/universal-hub.service';
import { iTaObserverComponent } from '../theoalex/taObserverComponent';

@Directive({
  selector: '[appUserRegionAccessibility]'
})
export class UserRegionAccessibilityDirective implements OnDestroy, iTaObserverComponent{

  userHeaderInfoChangedSubscription: Subscription;

  @Input() appUserRegionAccessibility: { userRegion: UserRegions, display?: string, isDisabled?: boolean };

  @HostBinding('style.display') display: string;
  //@HostBinding('style.color') color: string = 'red';

  constructor(private loginService: TaApiServiceLogin) {
    this.display = 'none';
  }
  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }
  subscriberEvents(): void {
    this.userHeaderInfoChangedSubscription = TaUniversalHubSubject.userCredentialSubjectData.subscribe(() => {
      this.refreshUI();
    })
  }
  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userHeaderInfoChangedSubscription);
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
    this.refreshUI();
  }

  private refreshUI() {
    setTimeout( ()=>{
        //if (this.loginService.taUserCredential) {
          if (this.loginService.isReady && this.loginService.taUserCredential.Region && TAUtility.includes(this.appUserRegionAccessibility.userRegion, this.loginService.taUserCredential.Region)) {
            if (this.appUserRegionAccessibility.display) {
              this.display = this.appUserRegionAccessibility.display;
            } else {
              this.display = 'none';
            }
          } else {
            //not the target user role
            if (this.appUserRegionAccessibility.display) {
              this.display = 'none';
            } else {
              this.display = this.appUserRegionAccessibility.display;
            }
          }
        //}
      },0)

  }
}
