<form class="container" #emailForm="ngForm" (ngSubmit)="send()">
  <div class="closeBox" (click)="closeSendingEmail(true)">
    <img src="assets/close-icon.svg" />
  </div>
  <div class="modalContainer" *ngIf="!thxMsg">
    <div class="contentBox" >
      <div class="text title">Send via Email</div>
      <div class="text-small instruction">
        Enter your e-mail address.
        <br>We will send the specification of your
        furniture to it.
      </div>
      <div>
        <input
          [(ngModel)]="email"
          #emailModel="ngModel"
          (input)="printInput(emailModel)"
          type="email"
          name="email"
          class="text emailInput"
          required
          [pattern]="patternEmail"
          placeholder="Your email is required"
        />
        <ng-container *ngIf="emailModel.touched && emailModel.invalid">
          <div *ngIf="emailModel.errors?.required" class="text-small error">Field required</div>
          <div *ngIf="emailModel.errors?.pattern" class="text-small error">Not correct email</div>
        </ng-container>
      </div>
      <div>
        <button
          class="text-small formBtn send"
          type="submit"
          [disabled]="emailForm.invalid"
          (click)="send()"
        >
          SEND
        </button>
      </div>
    </div>
  </div>
  <ng-container *ngIf="thxMsg">
    <div class="contentBox title success">
      <p>Good job!</p>
      <p>Thank you for email.</p>
    </div>
  </ng-container>
</form>
