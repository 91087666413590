import {
  Component,
  ChangeDetectorRef,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
  OnDestroy,
  Input,
} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { PricingService } from 'src/app/shared/pricing.service';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-main-view-configurator',
  templateUrl: './main-view-configurator.component.html',
  styleUrls: ['./main-view-configurator.component.css'],
})
export class MainViewConfiguratorComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {

  @Input() typeOfFurniture: string = '';
  @Input() brandLogoPath = '';
  @Input() showPricing = true;
  showPrice = new BehaviorSubject<boolean>(true);

  public productId: number = 0;
  public furnitureType: string = '';
  public customerName: string = 'TheodoreAlexander';
  public optionalAttributes: string[] = [];
  public price: number | undefined;
  public showSummary: boolean = false;
  private subscription1: any;
  private subscription2: any;
  private subscription3: any;
  public nextBottomPage: boolean = false;
  public isSections: boolean = false;
  private stepByStepServiceSubscriptions: any[] = [];
  private pricingServiceSubscriptions: any[] = [];
  public tailorfitSubdomain: string = environment.TailorFitURL;
  public isHiddenPrice: boolean = false;

  constructor(
    private stepByStepService: StepByStepService,
    private cdr: ChangeDetectorRef,
    private route: ActivatedRoute,
    private pricingService: PricingService
  ) {
  }


  ngOnInit(): void {


    TaUniversalHubSubject.userCredentialSubjectData.subscribe(credential => {
      const region = localStorage.getItem("Region");
      if (credential?.UserName) {
        if (region) {
          if (region === "INTERNATIONAL") {
            this.isHiddenPrice = true;
          } else {
            this.isHiddenPrice = false;
          }
        } else {
          this.isHiddenPrice = true;
        }
      } else {
        if (region === "INTERNATIONAL") {
          this.isHiddenPrice = true;
        } else {
          this.isHiddenPrice = false;
        }
      }
    });
    setTimeout(() => { this.price = this.stepByStepService.price }, 2200)

    let idFromUrl = this.route.snapshot.paramMap.get('id');
    idFromUrl && (this.productId = parseInt(idFromUrl));
  }

  ngAfterViewInit(): void {
    this.stepByStepService.toggleStatusSummary(false)

    // this.stepByStepService.variablesGroupsReceived.subscribe(groups => {
    //   groups.filter(el => {
    //     if (el.slug === 'sections') {
    //       el.isActive ? this.isSections = true : this.isSections = false;
    //     }
    //   })
    // }
    // );
    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.variablesGroupsReceived.subscribe(groups => {
        groups.filter(el => {
          if (el.slug === 'sections') {
            el.isActive ? this.isSections = true : this.isSections = false;
          }
        })
      }
      )
    );
  }

  ngAfterViewChecked() {
    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.getStatusSummary().subscribe((status) => {
        this.showSummary = status;
      })
    );

    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.getStatusBottomNext().subscribe((status) => {
        this.nextBottomPage = status;
      })
    );
    // this.subscription1 = this.stepByStepService.getStatusSummary().subscribe((status) => {
    //   this.showSummary = status;
    // });
    // this.subscription2 = this.stepByStepService.getStatusBottomNext().subscribe((status) => {
    //   this.nextBottomPage = status;
    // })

    // this.subscription3 = this.pricingService.checkPrice().subscribe(priceFromService => {
    //   this.price = priceFromService
    // })

    this.pricingServiceSubscriptions.push(
      this.pricingService.checkPrice().subscribe(priceFromService => {
        this.price = priceFromService

        let priceBox = document.querySelector('.price') as HTMLElement;
        if (priceFromService == undefined || priceFromService == 0) {
          this.showPrice.next(false)
          priceBox && (priceBox.style.display = 'none');
        } else {
          this.showPrice.next(true)
          priceBox && (priceBox.style.display = 'flex');
        }
      })
    );

    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    // this.subscription1.unsubscribe();
    // this.subscription2.unsubscribe();
    // this.subscription3.unsubscribe();

    this.stepByStepService.toggleNext(false);
    this.stepByStepService.toggleStatusBottomNext(false);
    this.stepByStepService.toggleStatusSummary(false);

    this.stepByStepServiceSubscriptions.forEach(subscription => subscription.unsubscribe());
    this.pricingServiceSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  addFurnitureType(name: string) {
    this.furnitureType = name;
  }
}
