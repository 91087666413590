<div class="productListingFilter">
  <ngb-accordion class="productListingFilter-level-0" #accordion0>
    <!--Products-->
    <ngb-panel [id]="item0.title" *ngFor="let item0 of itemList; let idx = index;" >
      <ng-template ngbPanelTitle *ngIf="!withCount || item0.count">
        <div class="ngb_accordion_header-level-0" (click)="onItemChecked(item0)">
          <span class="ngb_accordion_header-text">{{item0.title}}<span *ngIf="withCount"> ({{item0.count}})</span></span>
          <ng-container *ngIf="item0?.items?.length">
            <span class="ngb_accordion_header_expand-collapse-indicator-plusminus"
              [hidden]="accordion0.isExpanded(item0.title)">+</span>
            <span class="ngb_accordion_header_expand-collapse-indicator-plusminus"
              [hidden]="!accordion0.isExpanded(item0.title)">-</span>
          </ng-container>

          <ng-container *ngIf="!(item0?.items?.length) && item0.isSelected">
            <i class="icon-checked"></i>
          </ng-container>
        </div>
      </ng-template>
      <ng-template ngbPanelContent>
          <!--has sub accordion: 3 levels-->
          <ngb-accordion *ngIf="isItemHasSubAccordion(item0)">
            <ng-container *ngFor="let item1 of item0.items; let itemIndex = index;" >
              <ngb-panel [id]="item0.title+item1.title" *ngIf="!withCount || item1.count">
                <ng-template ngbPanelTitle>
                  <div class="ngb_accordion_header">
                    <i class="ngb_accordion_header_expand-collapse-indicatior-arrow"></i>
                    <span>{{item1.title}}<span *ngIf="withCount"> ({{item1.count}})</span></span>
                  </div>
                </ng-template>
                <ng-template ngbPanelContent>
                  <div class="ngb-accordion_content">
                    <ul class="ngb-accordion_content-ul">
                      <ng-container *ngFor="let item2 of item1.items;">
                        <li class="ngb-accordion_content-ul-li" [ngClass]="{'checked': item2.isSelected}" *ngIf="!withCount || item2.count">
                        <div class="ngb-accordion_content-ul-li-div" [ngClass]="{'isLocked': item2.isLocked}" (click)="!item2.isLocked && onItemChecked(item2)">
                          <span>{{item2.title}}<span *ngIf="withCount"> ({{item2.count}})</span></span><i class="icon-checked"></i>
                        </div>
                      </li>
                      </ng-container>
  
                    </ul>
                  </div>
                </ng-template>
              </ngb-panel>
            </ng-container>
  
          </ngb-accordion>
          <!--no sub accordion: 2 levels -->
          <div class="ngb-accordion_content" *ngIf="!isItemHasSubAccordion(item0)">
            <ul class="ngb-accordion_content-ul">
              <ng-container *ngFor="let item1 of item0.items;">
              <li class="ngb-accordion_content-ul-li" [ngClass]="{'checked': item1.isSelected}" *ngIf="!withCount || item1.count">
                <div class="ngb-accordion_content-ul-li-div" [ngClass]="{'isLocked': item1.isLocked}" (click)="!item1.isLocked && onItemChecked(item1)">
                  <span>{{item1.title}} <span *ngIf="withCount"> ({{item1.count}})</span></span><i class="icon-checked"></i>
                </div>
              </li>
            </ng-container>
            </ul>
          </div>
        </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>
