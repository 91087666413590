import { Component, OnInit, ViewChild, ElementRef, Renderer2, Output, EventEmitter, Input, OnChanges, SimpleChanges, ViewChildren, QueryList } from '@angular/core';
import { TaApiServiceDealerStore } from 'src/app/service/api/TaApiServiceDealerStore';
import { Subscription } from 'rxjs';
import { MapMarker, MapInfoWindow, GoogleMap } from '@angular/google-maps';
import { TawDealerStore } from 'src/app/state-management/dealer-store-state/dealer-store.state';

@Component({
  selector: 'app-dealer-store-world-map',
  templateUrl: './dealer-store-world-map.component.html',
  styleUrls: ['./dealer-store-world-map.component.scss']
})
export class DealerStoreWorldMapComponent implements OnInit, OnChanges {
  @Output() onClickViewDetail?: EventEmitter<any> = new EventEmitter<any>();
  @Input() stores: TawDealerStore[];
  @Input() focusedStores: TawDealerStore[];
  @ViewChild(MapInfoWindow) infoWindow: MapInfoWindow;
  @ViewChild(GoogleMap) map: GoogleMap;
  @ViewChildren(MapMarker) mapMarkers: QueryList<MapMarker>;
  selectedStore: any;
  mapOption: google.maps.MapOptions = {
    center: { lat: 39.9119931, lng: -98.5985737 },//USA center
    zoom: 3,
    gestureHandling: 'cooperative'
  }
  storeMarkers: { marker: google.maps.Marker, store: any }[] = [];
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
    icon: '/assets/img/dotMap5px.png',
  };

  userCoords: google.maps.LatLng;
  bounds = new google.maps.LatLngBounds();

  isFocusToUserLocation = true;

  constructor(private element: ElementRef, private render: Renderer2, private serviceDealerStore: TaApiServiceDealerStore) { }

  ngOnChanges(changes: SimpleChanges): void {    
    this.infoWindow?.close();
    if (changes.stores) {
      this.buildStoreMarkers();
      if (this.isFocusToUserLocation && this.userCoords && this.stores?.length) {        
        this.isFocusToUserLocation = false;
        this.fitZoomToUserAndClosestStore();
      } else {
        this.fitZoomToFocusedStores();
      }
    }
    if (changes.focusedStores) {
      this.fitZoomToFocusedStores();
    }
  }

  ngOnInit() {
  }

  ngAfterViewInit() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.userCoords = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
        if (this.isFocusToUserLocation && this.stores?.length) {
          this.isFocusToUserLocation = false;
          this.fitZoomToUserAndClosestStore();
        }
      }, () => {
        this.fitZoomToFocusedStores();
      });
    } else {
      this.fitZoomToFocusedStores();
    }
    this.fitZoomToFocusedStores();
  }

  buildStoreMarkers() {
    if (this.stores) {
      this.storeMarkers = [];
      this.stores.forEach(store => {
          if (store.StoreCoordinate && store.StoreCoordinate.Latitude && store.StoreCoordinate.Longitude) {
            let marker = new google.maps.Marker({ position: { lat: store.StoreCoordinate.Latitude, lng: store.StoreCoordinate.Longitude } });
            this.storeMarkers.push({
              store: store,
              marker: marker
            });
          }
      });
    }
  }

  findClosestStorePosition(): google.maps.LatLng {
    let closestStorePosition: google.maps.LatLng;
    let minDistance = Number.MAX_SAFE_INTEGER;
    if (this.storeMarkers?.length) {
      this.storeMarkers.forEach(sm => {
          if (this.userCoords) {
            let distance = google.maps.geometry.spherical.computeDistanceBetween(this.userCoords, sm.marker.getPosition());
            if (minDistance > distance) {
              minDistance = distance;
              closestStorePosition = sm.marker.getPosition();
            }
          }
      });
      return closestStorePosition;
    }
  }

  openInfoWindow(marker: MapMarker, store: any) {
    this.selectedStore = store;
    this.infoWindow.open(marker);
  }

  onMarkerDetailClick(store) {
    this.onClickViewDetail.emit(store);
  }

  fitZoomToUserAndClosestStore() {
    if (this.map && this.userCoords) {
      let closestStorePosition = this.findClosestStorePosition();
      if (this.userCoords && closestStorePosition) {
        let bounds = new google.maps.LatLngBounds();
        bounds.extend(this.userCoords);
        bounds.extend(closestStorePosition);
        this.map.fitBounds(bounds);
      } else {
        this.fitZoomToFocusedStores();
      }
    }
  }

  fitZoomToFocusedStores() {
    if (this.map) {
      this.bounds = new google.maps.LatLngBounds();      
      (this.focusedStores || this.stores)?.forEach(store => {
          if (store.StoreCoordinate && store.StoreCoordinate.Latitude && store.StoreCoordinate.Longitude) {
            let marker = new google.maps.Marker({ position: { lat: store.StoreCoordinate.Latitude, lng: store.StoreCoordinate.Longitude } });            
            this.bounds.extend(marker.getPosition());
          }
      });

      if (this.focusedStores?.length == 1) {
        let marker = this.mapMarkers?.find(m => {
          let markerPosition = m.getPosition();
          return (markerPosition.lat() == this.focusedStores[0].StoreCoordinate.Latitude && markerPosition.lng() == this.focusedStores[0].StoreCoordinate.Longitude);
        });
        if (marker) {
          this.openInfoWindow(marker, this.focusedStores[0]);
        }
      }

      if (this.bounds.isEmpty()) {        
        this.map.zoom = 2;
      } else {
        this.map.fitBounds(this.bounds)
      }
    }
  }

  onMapZoomChanged() {
    if (this.map) {
      let zoomLevel = this.map.getZoom()
      if (zoomLevel >= 0) { this.markerOptions = { draggable: false, icon: '/assets/img/dotMap10px.png', } }
      if (zoomLevel >= 5) { this.markerOptions = { draggable: false, icon: '/assets/img/dotMap20px.png', } }
      if (zoomLevel >= 10) { this.markerOptions = { draggable: false, icon: '/assets/img/dotMap30px.png', } }
      if (zoomLevel >= 15) { this.markerOptions = { draggable: false, icon: '/assets/img/dotMap40px.png', } }
      if (zoomLevel >= 20) { this.markerOptions = { draggable: false, icon: '/assets/img/dotMap50px.png', } }
    }
  }

  focusToLatLong(lat: number, long: number){
    this.infoWindow?.close();
    this.map.center = new google.maps.LatLng(lat,long);
    this.map.zoom = 12;
  }
}
