import { Subscription } from 'rxjs';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TaApiServiceXML } from 'src/app/service/api/TaApiServiceXML';
import { GoogleAnalyticEventAction, GoogleAnalyticEventCategory, TAUtility } from 'src/app/theoalex/theoalex';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { MenuComponent } from '../menu.component';

@Component({
  selector: 'app-menu-designers',
  templateUrl: './menu-designers.component.html',
  styleUrls: ['./menu-designers.component.scss']
})
export class MenuDesignersComponent implements OnInit, AfterViewInit, iTaObserverComponent, OnDestroy {
  GoogleAnalyticEventAction = GoogleAnalyticEventAction;
  GoogleAnalyticEventCategory = GoogleAnalyticEventCategory;

  menuDesignerSubcriber: Subscription;
  designers = [];

  constructor(private menu: MenuComponent, private serviceMenu: TaApiServiceXML) { }

  ngOnInit() {

  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
  }

  subscriberEvents(): void {
    this.menuDesignerSubcriber = this.serviceMenu.getMenuDesigner().subscribe((res: any) => {
      if (TAUtility.isAPIRespondOk(res)) {
        this.designers = res.Data.root.Designer;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.menuDesignerSubcriber);
  }

  onRouterClick(){
    //close the menu when navigation
    this.menu.closeAllMenu();
  }

}
