import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { TaApiServiceLogin, TaApiServiceRegisterUser } from 'src/app/service/api/TaApiServiceLogin';
import { TAUtility, TawAccountProvider } from 'src/app/theoalex/theoalex';
import { TaApiServiceCommon } from 'src/app/service/api/TaApiServiceCommon';
import { TALocalStogareService } from 'src/app/service/core/taLocalStorage';
import { isPlatformBrowser } from '@angular/common';
import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-register-page',
  templateUrl: './register-page.component.html',
  styleUrls: ['./register-page.component.scss']
})
export class RegisterPageComponent implements OnInit, OnDestroy {

  registerUserObject: TaApiServiceRegisterUser = {
    Provider: TawAccountProvider.web,
    IsEmailSubscribed: true
  };

  title: string = 'Please register';
  userTypes: { ID?: string, Name?: string, isSelected?: boolean }[] = [];
  userTypeSubcriber: Subscription;
  dialogRef: NzModalRef;

  TawAccountProvider = TawAccountProvider;

  constructor(
    private serviceLogin: TaApiServiceLogin,
    private serviceCommon: TaApiServiceCommon,
    private message: NzMessageService,
    private taLocalStorage: TALocalStogareService,
    @Inject(PLATFORM_ID) private platformId) { }

  ngOnInit() {
    this.subscriberEvents();
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  onClickClose() {
    this.dialogRef.close();
  }

  onRegister() {
    if (this.registerUserObject.Email?.includes("@") && this.registerUserObject.Password && this.registerUserObject.confirmedPassword && this.registerUserObject.Firstname && this.registerUserObject.Lastname) {
      if (this.registerUserObject.Password === this.registerUserObject.confirmedPassword) {
        this.message.loading("Sign up...");
        this.title = "Sign up...";
        this.serviceLogin.register(this.registerUserObject).subscribe(res=>{this.handleRegisterResult(res);});
      }
    } else {
      this.message.error("Please correct your information");
      this.title = "Please correct your information";
    }
  }

  handleRegisterResult(res){
    this.message.remove();
    if (TAUtility.isAPIRespondOk(res)) {
      if (this.registerUserObject.Provider ==  TawAccountProvider.web) {
        this.message.success("Sign up is successful. Please check your mailbox to active the acount.");
        this.title = "Sign up is successful. Please check your mailbox to active the acount.";
      } else {
        this.message.success("Sign up is successful.");
        this.title = "Sign up is successful.";
      }

      if (isPlatformBrowser(this.platformId)) {
        this.taLocalStorage.clearAnonymousWishlist();
      }
      // close popup register
      this.dialogRef.close();
    } else {
      // Register fail
      this.message.error("Sign up is failed: " + res.Data);
      this.title = "Sign up is failed: " + res.Data;
    }
  }

  subscriberEvents(): void {
    this.userTypeSubcriber = this.serviceCommon.getUserTypeList().subscribe(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        if (TAUtility.isAPIRespondOk(res)) {
          if (res.Data.UserTypeGroups[0] && res.Data.UserTypeGroups[0].UserTypes.length) {
            res.Data.UserTypeGroups[0].UserTypes.forEach(i => { this.userTypes.push(i) });
          }
          if (res.Data.UserTypeGroups[1] && res.Data.UserTypeGroups[1].UserTypes.length) {
            res.Data.UserTypeGroups[1].UserTypes.forEach(i => { this.userTypes.push(i) });
          }
          this.userTypes.forEach(i => {
            if (i.Name == "Consumer") {
              i.isSelected = true;
              this.registerUserObject.UserType_ID = i.ID;
            }
          })
        }
      }
    });
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userTypeSubcriber);
  }

  OnChangedUserType(selectedItem) {
    this.registerUserObject.UserType_ID = selectedItem.ID;
  }

}
