<div class="photos">
  <div class="photos-main" #photoBig>
    <img class="mainPhoto" src={{images[0]}} alt="Photo-1">
    <div class="zoom text" #zoomBtn>
      <!-- <span>Zoom</span> -->
      <fa-icon [icon]="faExpandArrowsAlt"></fa-icon>
    </div>
  </div>
  <div class="photos-column">
    <div #photoSmall1><img class="smallPhoto" src={{images[1]}} alt="Photo-2"></div>
    <div #photoSmall2><img class="smallPhoto" src={{images[2]}} alt="Photo-3"></div>
    <div #photoSmall3><img class="smallPhoto" src={{images[3]}} alt="Photo-4"></div>
  </div>
</div>
<div class="fullScreen-none" #fullScreen>
  <div class="fullScreen-box">
    <img class="fullScreenPhoto" #fullScreenPhoto src="" alt="Photo-Fullscreen">
  </div>
  <div class="closeBtn" #closeBtn><img src="assets/close-icon.svg"></div>
</div>
