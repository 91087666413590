import { Directive, Input, HostBinding, Renderer2, ElementRef, AfterViewInit } from '@angular/core';
import { UserTypes, TAUtility } from '../theoalex/theoalex';
import { TaApiServiceLogin } from '../service/api/TaApiServiceLogin';
import { TaUniversalHubSubject } from '../service/web/universal-hub.service';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appUserRoleAccessibility]'
})
export class UserRoleAccessibilityDirective implements AfterViewInit {

  userHeaderInfoChangedSubscription: Subscription;

  @Input() appUserRoleAccessibility: { userRole: UserTypes, display?: string, isDisabled?: boolean, name?: string};

  @HostBinding('style.display') display: string;

  constructor(private loginService: TaApiServiceLogin) {
    this.display = 'none';
  }

  ngAfterViewInit(): void {
    this.refreshUI();

    this.userHeaderInfoChangedSubscription = TaUniversalHubSubject.userCredentialSubjectData.subscribe(() => {
      this.refreshUI();
    })
  }

  private refreshUI() {
    setTimeout(()=> {
      if (this.appUserRoleAccessibility.userRole) {
        if (this.loginService.isReady && this.loginService.taUserCredential.UserName && this.loginService.taUserCredential.UserType
          && TAUtility.includes( this.appUserRoleAccessibility.userRole, this.loginService.taUserCredential.UserType)) {
          if (this.appUserRoleAccessibility.display) {
            this.display = this.appUserRoleAccessibility.display;
          } else {
            this.display = 'none';
          }
        } else {
          //not the target user role
          if (this.appUserRoleAccessibility.display) {
            this.display = 'none';
          }
        }
      } else {
        //not desired user role => not login user
        //apply the desired display for no login user, otherwise apply the default display
        if (this.loginService.isReady && this.loginService.taUserCredential.UserName) {
          if (this.appUserRoleAccessibility.display) {
            this.display = 'none';
          }
        } else {
          this.display = this.appUserRoleAccessibility.display || this.display;
        }
      }
    },0)
  }
}
