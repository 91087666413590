<div class="summaryContainer">
  <div class="summaryPhotos">
    <app-gallery class="summaryGallery"></app-gallery>
    <div class="summaryForm" style="padding-left: 30px;">
      <p class="text-small message">Do you have additional notes?</p>
      <textarea #summaryNotes name="summaryNotes" (change)="saveNotes($event)"></textarea>
      <div class="navbar-button closeSummary" (click)="closeSummary()"><fa-icon class="arrowIcon"
          [icon]="faArrowLeft"></fa-icon> Back</div>
      <div class="row buttonContainer">
        <div class="col-sm-10 col-md-4 col-lg-4 col-xl-4">
          <button class=".text-small formBtn" (click)="sendEmail()">Send email</button>
        </div>
        <div class="col-sm-10 col-md-4 col-lg-4 col-xl-4">
          <button *ngIf="showButton === 'print'" class=".text-small formBtn"
            (click)="print()">{{printButtonText}}</button>
          <div *ngIf="showButton === 'loader'" class="loaderContainer">
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
            <span class="circle"></span>
          </div>
          <button *ngIf="showButton === 'download'" class=".text-small formBtn">
            <a [href]="urlToPdf" class="linkToPdf">Download PDF</a>
          </button>
        </div>
        <div class="col-sm-10 col-md-4 col-lg-4 col-xl-4">
          <button class=".text-small formBtn" (click)="onSave()" *ngIf="isShowSave">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="summaryInfo">
    <hr class="line">
    <div class="listBox">
      <div *ngIf="showLoader" class="loaderContainer summaryLoader">
        <span class="circle"></span>
        <span class="circle"></span>
        <span class="circle"></span>
        <span class="circle"></span>
      </div>
      <table *ngIf="!showLoader" class="summaryTable">
        <tr class="summaryRow" *ngFor="let pair of printedAttributesWithChoices | keyvalue">
          <td class="description">{{pair.key}}</td>
          <td class="description description-opacity">{{pair.value}}</td>
        </tr>
      </table>
    </div>
      <ng-container *ngIf="showPrice | async">
        <p *ngIf="!showLoader" class="text group-name finishPrice">Price: ${{price}}</p>
      </ng-container>
  </div>
</div>
<div *ngIf="showEmailModal" class="sendingEmail">
  <app-send-email (closeModal)="closeModal($event)" [data]="data" [pdfUrl]="urlToPdf"></app-send-email>
</div>
<div *ngIf="showQuestionModal" class="sendingEmail">
  <app-type-note-modal (closeModalQuestions)="closeModal($event)"></app-type-note-modal>
</div>

<div id="BackGround" [style]="cssBackGround" *ngIf="isShowLoading">
  <div id="Dim" [style]="cssDim">
    <div class="msgbox">
      <img src="{{loadingURL}}" alt="Processing. Please wait..." title="Processing. Please wait..." width="200px"
        height="200px" />
    </div>
  </div>
</div>