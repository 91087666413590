<div id="theodoreIntiaro">
    <div style="text-align:center;">
        <a title="TAilor Fit" href="dummy/3D-tailorfit.html"
            style="text-decoration: none; border-bottom: 0px solid #636363; text-align: center;">
            <img src="assets/TAilorFit.png" alt="TAilor Fit" title="TAilor Fit" style="width: 20%; margin: 3% 0 0 0;">
        </a>
    </div>
</div>
<div class="row" style="margin: 3% 0;"> 
    <div class="col-xl-3">
    </div>   
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/10825" title="Ottoman"><img
                src="https://theodorealexander.com/images/Intiaro/Ottoman.jpg" width=270 height=270 title="Ottoman"
                alt="Ottoman"></a>
        <p style="font-size: 18px;">Ottoman</p>
    </div>    
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/10825" title="Ottoman and Half"><img
                src="https://theodorealexander.com/images/Intiaro/OttomanHalf.jpg" width=270 height=270 title="Ottoman and Half"
                alt="Ottoman and Half"></a>
        <p style="font-size: 18px;">Ottoman and Half</p>
    </div>   
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/10825" title="Long Ottoman and Half"><img
                src="https://theodorealexander.com/images/Intiaro/LongOttomanHalf.jpg" width=270 height=270 title="Long Ottoman and Half"
                alt="Long Ottoman and Half"></a>
        <p style="font-size: 18px;">Long Ottoman and Half</p>
    </div>   
    <!-- <div class="col-xl-2" style="text-align: center;">
        <a href="#" title="Long Ottoman"><img
                src="https://theodorealexander.com/images/Intiaro/LongOttoman.jpg" width=270 height=270 title="Long Ottoman"
                alt="Long Ottoman"></a>
        <p style="font-size: 18px;">Long Ottoman</p>
    </div>   
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12278" title="Bumper Ottoman"><img
                src="https://theodorealexander.com/images/Intiaro/BumperOttoman.jpg" width=270 height=270 title="Bumper Ottoman"
                alt="Bumper Ottoman"></a>
        <p style="font-size: 18px;">Bumper Ottoman</p>
    </div>    -->
    <div class="col-xl-3">
    </div>
</div>