import { Component, OnInit, Inject, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-popup-furniture-care',
  templateUrl: './popup-furniture-care.component.html',
  styleUrls: ['./popup-furniture-care.component.scss']
})
export class PopupFurnitureCareComponent implements OnInit, AfterViewInit {

  constructor(private dialogRef: MatDialogRef<PopupFurnitureCareComponent>,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA) public furniture: any) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  onClickClose() {
    this.dialogRef.close();
  }

}
