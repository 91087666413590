<div class="breadcrumb_ta_blog">
  <ul data-campaign="Header">
    <li>
      <a class="home-blog" href="https://theodorealexander.com/" title="Home">
        <i class="icon-home"></i>
        <span style="padding-left:15px">Home</span>
      </a>
    </li>
    <li>&nbsp;&nbsp;/&nbsp;&nbsp;</li>
    <li>
      <a href="https://theodorealexander.com/blog" title="Blog"><b>Blog</b></a>
    </li>
  </ul>
</div>
<div style="height: 600px;">
  <div class="slider d-flex flex-column">
    <div class="slider_content d-flex flex-grow-1 overflow-hidden">
      <div *ngIf="listGhostBlog001.length" class="swiper-container position-relative w-100" [swiper]="swipeConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide slider_item d-flex" style="display: flex; justify-content: center;"
            *ngFor="let post of listGhostBlog001">
            <div class="img-fullsize-top-home-blog">
              <a style="color: #000 !important;" href="{{domainName}}blog/{{post.URLCode}}.html">
                <img class="pic-blog-top" style="object-fit: cover; object-position: center;" src="{{post.URLImage}}"
                  width="100%" height="100%"></a>
            </div>
            <div class="post-title content-main-post-top">
              <a style="color: #000 !important;" href="{{domainName}}blog/{{post.URLCode}}.html"
                title="{{ post.Title }}">
                <h1 class="title-detail-post">{{ post.Title }}</h1>
                <div class="short-description-top">{{ post.Description }}</div>
              </a>
              <div class=" info-post">
                <span class="by-post"><img style="width: 20px; height: 20px; border-radius: 10px"
                    src="https://theodorealexander.com/images/TA_logo.png"><span style="margin-left: 5px">By
                    {{post.Author}}</span></span>
                <span class="date-post-detail"><i class="icon-timer"></i>{{post.DatePostString}}</span>
                <div class="share-detail">
                  <span>Share:</span>
                  <a target="_blank" href="{{post.ShareFacebook}}" title="{{ post.ShareFacebook }}"><i
                      class="icon-facebook"></i></a>
                  <a target="_blank" href="{{post.ShareTwitter}}" title="{{ post.ShareTwitter }}"><i
                      class="icon-twitter"></i></a>
                  <a target="_blank" href="{{post.SharePinterest}}" title="{{ post.SharePinterest }}"><i
                      class="icon-pinterest2"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="_TaGlobalCssSwiper_slider_navigation">
          <div class="_TaGlobalCssSwiper_slider_navigation_left">
            <div class="_TaGlobalCssSwiper_slider_navigation_left-button"></div>
          </div>
          <div class="_TaGlobalCssSwiper_slider_navigation_right">
            <div class="_TaGlobalCssSwiper_slider_navigation_right-button"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>
<div class="bg-blog">
  <div nz-row class="post-mg-top" style="display: flex;">
    <div class="col-blog-left blog-mg-bottom">
      <div class="post-list">
        <ngx-masonry [ordered]="true">
          <div ngxMasonryItem class="post-container" *ngFor="let post of ghostBlogService.list;">
            <div class="post">
              <a title="{{post.Title}}" href="{{domainName}}blog/{{post.URLCode}}.html"><img style="width: 100%"
                  src="{{post.URLImage}}" title="{{post.Title}}" alt="{{post.Title}}" /></a>
              <div class="post-item-content">
                <a style="color: #000 !important;" href="{{domainName}}blog/{{post.URLCode}}.html"
                  title="{{post.Title}}">
                  <h1 class="blog-title-post">{{post.Title}}</h1>
                </a>
                <div class="blog-icon-social-line">
                  <div class="col-6 d-flex justify-content-start" style="padding:0;">

                    <div class="date-post">
                      <i class="icon-timer"></i>
                      <span>{{post.DatePostString}}</span>
                    </div>
                  </div>

                  <div class="col-6 d-flex justify-content-end" style="padding:0;">
                    <div class="share-post">
                      <span>Share:</span>
                      <a target="_blank" href="{{post.ShareFacebook}}"><i class="icon-facebook"></i></a>
                      <a target="_blank" href="{{post.ShareTwitter}}"><i class="icon-twitter"></i></a>
                      <a target="_blank" href="{{post.SharePinterest}}"><i class="icon-pinterest2"></i></a>
                    </div>
                  </div>
                </div>
                <div class="btn-more-post">
                  <a title="{{post.Title}}" href="{{domainName}}blog/{{post.URLCode}}.html">Read more</a>
                </div>
              </div>
            </div>
          </div>
        </ngx-masonry>
      </div>

    </div>
    <div class="col-blog-right right-sidebar-mg">
      <div class="blog-right-sidebar">
        <h3 class="right-title-post">Popular Posts</h3>
        <div class="blog-recent-post">
          <div class="sidebar-item-blog" *ngFor="let post of listGhostBlog002">
            <a style="display: flex" href="{{domainName}}blog/{{post.URLCode}}.html">
              <div class="col-left">
                <img src="{{post.URLImage}}" width="100%">
              </div>
              <div class="col-right">
                <span class="sidebar-title-blog-detail">{{post.Title}}</span>
                <div class="date-blog">
                  <i class="icon-timer"></i>
                  <span class="sidebar-item-day">{{post.DatePostString}}</span>
                </div>
              </div>
            </a>
          </div>
        </div>
        <div class="subcribe">
          <div class="subcribe-blog">
            <div class="subcribe-content">
              <div class="icon-subcribe"><i class="icon-email-blog"></i></div>
              <div class="title-subcribe">KEEP IN TOUCH</div>
              <div class="content-subcribe">Sign up to be the first to hear about our new collections, product launches,
                recent news and upcoming events! </div>
              <input class="subcribe-email-input" type="text" placeholder="Your Email (*)" #userEmail="ngModel"
                name="userEmail" placeholder="Email (*)" [(ngModel)]="subscriberEmail" email required>
              <div style="color: #d2000d;" [hidden]="!(userEmail?.invalid && (userEmail.dirty || userEmail.touched))">
                Email is required</div>
              <button nz-button nzType="primary" class="btn-subcribe" (click)="onClickSubcribe()">Subcribe</button>
            </div>
          </div>
        </div>
        <div class="product-ads" [appUserRegionAccessibility]="{userRegion: 'taus', display:'block'}">
          <a href="{{domainName}}special/in-stock.html">
            <img src="https://theodorealexander.sirv.com/website/Frontend/Live/banner/banner-instock.jpg">
          </a>
        </div>
      </div>

    </div>
  </div>
</div>