import { NgModule, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tawSkipCachedFile'
})
export class TawSkipCachedFlie implements PipeTransform {
  transform(fileUrl: string): string {
    if (fileUrl?.toLowerCase()?.includes('theodorealexander.sirv.com')) {
      let splitedTexts = fileUrl.split("/");
      let fileName = splitedTexts[splitedTexts.length-1];
      return `${fileUrl}/${fileName}_${Date.now()}.pdf`;
    } else {
      return fileUrl;
    }
  }
}

@NgModule({
  declarations: [
    TawSkipCachedFlie
  ],
  imports: [
  ],
  exports:[
    TawSkipCachedFlie
  ]
})
export class TawSkipCachedFilePipeModule { }
