import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { BannerDetail } from 'src/app/shared/BannerDetail.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class BannerDetailService {
    list: BannerDetail[] | undefined;
    list001: BannerDetail[] | undefined;
    list002: BannerDetail[] | undefined;
    list003: BannerDetail[] | undefined;
    formData!: BannerDetail;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "BannerDetail";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
        this.formData = {
        };
    }
    getByParentIDAndActiveAndRegionToList(parentID: number, active: boolean, region: string): Observable<TaAPIResponse<BannerDetail>> {        
        let url = this.aPIURL + this.controller + '/GetByParentIDAndActiveAndRegionToList';
        const params = new HttpParams()
            .set('parentID', JSON.stringify(parentID))
            .set('active', JSON.stringify(active))
            .set('region', region)        
        return this.httpClient.get<TaAPIResponse<BannerDetail>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

