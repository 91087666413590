import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
import { Config } from 'src/app/shared/types';

@Component({
  selector: 'app-wrapper-for-main-view',
  templateUrl: './wrapper-for-main-view.component.html',
  styleUrls: ['./wrapper-for-main-view.component.css']
})
export class WrapperForMainViewComponent implements OnInit {

  public typeOfFurniture = ''; // necessary variable (Important, by default empty string); it's @Input in MainViewConfiguratorComponent
  public brandLogoPath = ''; // necessary variable (Important, by default empty string); it's @Input in MainViewConfiguratorComponent
  public showPricing = true; // necessary variable (Important, by default true); it's @Input in MainViewConfiguratorComponent

  public productId: number = 0;
  public products: Config[] = [];

  private DATA_FOR_TESTING = [
    { id: '10190', categoryName: "Sofa", brandLogo: "https://theodorealexander.com/images/collection_tailor-fit_20221006111555638006517550423044.png" },
    { id: '12471', categoryName: "Grand Sofa", brandLogo: "https://theodorealexander.com/images/collection_tailor-fit_20221006111555638006517550423044.png" },
    { id: '12470', categoryName: "Long Sofa", brandLogo: "https://theodorealexander.com/images/collection_tailor-fit_20221006111555638006517550423044.png" },
    { id: '12460', categoryName: "Mid Size Sofa", brandLogo: "https://theodorealexander.com/images/collection_tailor-fit_20221006111555638006517550423044.png" },
    { id: '12429', categoryName: "Chair", brandLogo: "assets/tf_logo.png" },
    { id: '12456', categoryName: "Chair & Half", brandLogo: "assets/tf_logo.png" },
    { id: '12411', categoryName: "Loveseat", brandLogo: "assets/tf_logo.png" },
    { id: '12457', categoryName: "Long Loveseat", brandLogo: "assets/tf_logo.png" },
    { id: '10825', categoryName: "Ottoman", brandLogo: "assets/tf_logo.png" },
    { id: '12479', categoryName: "Ottoman & Half", brandLogo: "assets/tf_logo.png" },
    { id: '12278', categoryName: "Sectional", brandLogo: "assets/tf_logo.png" },
    { id: '10546', categoryName: "Aiden Chair", brandLogo: "assets/fd_logo.png", disablePrice: false },
    { id: '10271', categoryName: "Aiden Sectional", brandLogo: "assets/fd_logo.png", disablePrice: false },
    { id: '10547', categoryName: "Aiden Sofa", brandLogo: "assets/fd_logo.png", disablePrice: false },
    { id: '14196', categoryName: "Sasha Swivel Chair", disablePrice: false },
    { id: '10549', categoryName: "Aiden II Sofa", disablePrice: false },
    { id: '10577', categoryName: "Aiden Dressmaker Swivel Chair", disablePrice: false },
  ]

  constructor(private route: ActivatedRoute, private stepByStepService: StepByStepService) {
    let idFromUrl = this.route.snapshot.paramMap.get('id');
    idFromUrl && (this.productId = parseInt(idFromUrl));

    // this.DATA_FOR_TESTING.filter(element => {
    //   if (element.id == idFromUrl) {
    //     !!element.categoryName ? this.typeOfFurniture = element.categoryName : '';
    //     !!element.brandLogo ? this.brandLogoPath = element.brandLogo : '';
    //     element.disablePrice ? this.showPricing = false : '';
    //   }
    // })

    this.stepByStepService.getConfig()
      .subscribe(config => {
        this.products = config;
        const data = this.products.find(x => x.id.toString() === idFromUrl);
        console.log(data)
        if (data) {
          this.typeOfFurniture = data.furnitureType;
        }
      })
  }

  ngOnInit(): void {

  }

}
