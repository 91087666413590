import { Component, OnInit } from '@angular/core';
import { MenuComponent } from '../menu.component';

@Component({
  selector: 'app-menu-news',
  templateUrl: './menu-news.component.html',
  styleUrls: ['./menu-news.component.scss']
})
export class MenuNewsComponent implements OnInit {


  constructor(private menu: MenuComponent) { }

  onRouterClick(){
    //close the menu when navigation
    this.menu.closeAllMenu();
  }


  ngOnInit() {
  }

}
