<div class="configurator" id="cssModified" #intiaro>
  <div
    id="Intiaro-configurator"
    [attr.data-customer-id]="customerName"
    [attr.data-product-id]="productId"
    data-choices-confirmation="true"
    data-attribute-max-height="200px"
    data-allow-only-one-attribute="true"
  >
    Loading...
  </div>
  <ng-template #galleryTemplate>
    <div *ngIf="!showSummary && showBottomGallery" class="photosContainer">
      <div class="smallPhoto" (click)="changePhoto(angleForSmallPhotos[1])"><img class="photo" src={{img2}} alt="Photo-1"></div>
      <div class="smallPhoto" (click)="changePhoto(angleForSmallPhotos[2])"><img class="photo" src={{img3}} alt="Photo-2"></div>
      <div class="smallPhoto" (click)="changePhoto(angleForSmallPhotos[3])"><img class="photo" src={{img4}} alt="Photo-3"></div>
    </div>
  </ng-template>
  <ng-template #nextTemplate>
    <div *ngIf="!showSummary" #bottomNext class="navbar-button button footerNext" [ngClass]="nextBottomPage ? 'active' : 'unactive'" >
      <div class="textBox tooltip" (click)="nextBottomPage ? goToNext() : ''">
        <span>Next </span><fa-icon [icon]="faArrowRight"></fa-icon>
        <span *ngIf="!nextBottomPage" class="tooltipText text-small">Please, choose an option before going to the next step.</span>
      </div>
      <button *ngIf="pdfDocumentation" class="moreButton desktopMoreButton"><a  [href]="urlPdfDocumentation" target="_blank">More Details</a></button>
    </div>
  </ng-template>
  <ng-template #mobileMenu>
    <app-navbar class="bottomNavbar"></app-navbar>
    <div *ngIf="pdfDocumentation" class="mobileMoreButtonBox">
      <button class="moreButton mobileMoreButton"><a [href]="urlPdfDocumentation" target="_blank">More Details</a></button>
    </div>
  </ng-template>
</div>
