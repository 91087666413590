import { Component, OnInit, Input, Inject, AfterViewInit, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogConfig, MatDialog } from '@angular/material/dialog';
import { NgbModalRef, NgbModal, NgbModalConfig, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ta-alert-popup',
  templateUrl: './ta-alert-popup.component.html',
  styleUrls: ['./ta-alert-popup.component.scss']
})

export class TaAlertPopup implements OnInit, AfterViewInit, OnDestroy {
  /*
      this.modalRefMenu = this.modalService.open(HeaderMobileWithMenuComponent,{
      windowClass: "fullScreen"
    });
  */

  public static _modalRef: NgbModalRef;
  configuration: TAAlertConfiguration;

  constructor(private dialog: NgbModal) {
  }

  ngOnDestroy(): void {
  }

  ngAfterViewInit(): void {
  }

  ngOnInit() {
  }

  onCancel() {
    this.close();
    if (this.configuration && this.configuration.onCancel) {
      this.configuration.onCancel();
    }
  }

  onConfirm() {
    this.close();
    if (this.configuration && this.configuration.onConfirm) {
      this.configuration.onConfirm();
    }
  }

  private close() {
    if (TaAlertPopup._modalRef) {
      TaAlertPopup._modalRef.close();
    } else {
      this.dialog.dismissAll();
    }
  }

  //dont use this method when showing alert, use show() method
  static buildMatDialogAlertConfiguration(taAlertConfig: TAAlertConfiguration): MatDialogConfig {
    let matDialogConfig: MatDialogConfig = {
      width: '300px',
      position: { top: '30px' },
      disableClose: true,
      data: taAlertConfig
    }
    return matDialogConfig;
  }

  //use this method to show alert
  static show(taAlertConfig: TAAlertConfiguration, dialog: NgbModal, dialogConfig?: NgbModalOptions): NgbModalRef {
    let matDialogConfig: NgbModalOptions = {};
    if (dialogConfig) {
      matDialogConfig.windowClass = dialogConfig.windowClass;
    }

    if (taAlertConfig.type == TAAlertConfigurationType.info
      || taAlertConfig.type == TAAlertConfigurationType.success
      || taAlertConfig.type == TAAlertConfigurationType.confirm) {
      //matDialogConfig.disableClose = false;
    } else {
      //matDialogConfig.disableClose = true;
    }

    if (dialogConfig) {
      //matDialogConfig.disableClose = dialogConfig.disableClose;
    }
    let modalRef = dialog.open(TaAlertPopup, matDialogConfig);
    modalRef.componentInstance.configuration = taAlertConfig;
    TaAlertPopup._modalRef = modalRef;
    return modalRef;
  }
}

export enum TAAlertConfigurationType {
  info = "info", warning = "warning", error = "error", success = "success", confirm = "confirm"
}

export interface TAAlertConfiguration {
  type?: TAAlertConfigurationType,
  title?: string,
  message?: string,
  onConfirm?: Function,
  onConfirmText?: string,
  onCancel?: Function,
  onCancelText?: string
}
