import { Directive, HostListener } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import firebase from "firebase/app";

@Directive({
  selector: '[appIdentityProviderGoogle]'
})
export class IdentityProviderGoogleDirective {

  @HostListener("click") function(){
    this.auth.signInWithRedirect(new firebase.auth.GoogleAuthProvider()).then(user => {
    });
  }

  constructor(private auth: AngularFireAuth) { }

}
