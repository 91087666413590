<div class="flex-grow-1 main-content">
    <div class="productListing">
        <div class="breadcrumb_ta">
            <ul data-campaign="Header">
                <li><a href="https://theodorealexander.com/" title="Home"><i class="icon-home"></i>Home</a></li>
                <li>&nbsp;&nbsp;/&nbsp;&nbsp;</li>
                <li>
                    <a href="{{tailorfitSubdomain}}" title="TAilor Fit"><b>TAilor Fit</b></a>
                </li>
            </ul>
        </div>
        <div class="intro_1">
            <div class="header">
                <div class="row format_section1" style="background-color:#ffffff;">
                    <div class="feature__block--wide " style="background-color: rgb(244, 244, 244);"></div>
                    <div class="top_info_designer justify-content-between align-items-center">
                        <div class="col-xl-7 col-md-12 left_images">
                            <img src="{{collectionService.formData.ImageURL}}"
                                title="{{collectionService.formData.DisplayName}}"
                                alt="{{collectionService.formData.DisplayName}}">
                        </div>
                        <div class="col-xl-5 col-md-12 _texts">
                            <div class="logo_brand_introduce" *ngIf="collectionService.formData.IsLogo"
                                style="width: 60%; text-decoration: none; border-bottom: 0px solid #636363; text-align: center; margin-left: 20%;">
                                <img title='{{collectionService.formData.DisplayName}}'
                                    alt='{{collectionService.formData.DisplayName}}'
                                    src='{{collectionService.formData.URLImageName}}'>
                            </div>
                            <div class="logo_brand_introduce" *ngIf="!collectionService.formData.IsLogo">
                                {{collectionService.formData.DisplayName}}</div>
                            <div class="designer_shortDesc">
                                {{collectionService.formData.Description}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-xl-12 text-center" style="font-size: 24px; font-weight: bold; margin-top: 2%;">
                BUILD YOUR OWN
            </div>
            <div class="row">
                <div class="col-lg-1 col-xl-1" style="margin: 0 0 5% 0; text-align: center;">
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad || isShowChair ? 1 : 0.6 }">
                    <a (click)="onShow(1)" title="Chair"> <img class="img-fluid"
                            src="https://theodorealexander.com/images/item/T301_main_1.jpg" title="Chair"
                            alt="Chair"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Chair</p>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad || isShowLoveseat ? 1 : 0.6 }">
                    <a (click)="onShow(2)" title="Loveseat"><img class="img-fluid"
                            src="https://theodorealexander.com/images/item/T321_main_1.jpg" title="Loveseat"
                            alt="Loveseat"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Loveseat</p>
                    <div class="optional"></div>
                </div>
                <div class="row mobile" *ngIf="isShowChair">
                    <div class="col-xl-12 text-center text-divider">
                        Select a Chair to Customize
                    </div>
                    <div class="col-lg-4">
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[4].id)" title="Chair"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/Chair.jpg" title="Chair"
                                alt="Chair"></a>
                        <p style="font-size: 18px; font-weight: bold;">Chair <span
                                style=" font-size: 14px; font-weight: bold; display: block;">(31" - 38" W)</span></p>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[5].id)" title="Chair & Half"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/ChairHalf.jpg" title="Chair & Half"
                                alt="Chair & Half"></a>
                        <p style="font-size: 18px; font-weight: bold;">Chair & Half <span
                                style="font-size: 14px; font-weight: bold; display: block;">(43" - 50" W)</span></p>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
                <div class="row mobile" *ngIf="isShowLoveseat">
                    <div class="col-xl-12 text-center text-divider">
                        Select a Loveseat to Customize
                    </div>
                    <div class="col-lg-4">
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[6].id)" title="Loveseat"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/Loveseat.jpg" title="Loveseat"
                                alt="Loveseats"></a>
                        <p style="font-size: 18px; font-weight: bold;">Loveseat <span
                                style="font-size: 14px; font-weight: bold; display: block;">(61" - 68" W)</span></p>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[7].id)" title="Long Loveseat"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/LongLoveseat.jpg"
                                title="Long Loveseat" alt="Long Loveseat"></a>
                        <p style="font-size: 18px; font-weight: bold;">Long Loveseat <br> <span
                                style="font-size: 14px; font-weight: bold; display: block;">(67" - 74" W)</span></p>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad ||isShowSofa ? 1 : 0.6 }">
                    <a (click)="onShow(3)" title="Sofa"><img class="img-fluid"
                            src="https://theodorealexander.com/images/item/S923_main_1.jpg" title="Sofa" alt="Sofa"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Sofa</p>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad || isShowOttoman ? 1 : 0.6 }">
                    <a (click)="onShow(4)" title="Ottoman"><img class="img-fluid"
                            src="https://theodorealexander.com/images/item/T311_main_1.jpg" title="Ottoman"
                            alt="Ottoman"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Ottoman</p>
                </div>

                <div class="row mobile" *ngIf="isShowSofa">
                    <div class="col-xl-12 text-center text-divider">
                        Select a Sofa to Customize
                    </div>
                    <div class="col-lg-2 col-xl-2">
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[3].id)" title="Mid Size Sofa"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/MidSizeSofa.jpg" title="Mid Size Sofa"
                                alt="Mid Size Sofa"></a>
                        <p style="font-size: 18px; font-weight: bold;">Mid Size Sofa <br><span
                                style="font-size: 14px; font-weight: bold; display: block;">(79" - 86" W)</span></p>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[0].id)" title="Sofa"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/Sofa.jpg" title="Sofa" alt="Sofa"></a>
                        <p style="font-size: 18px; font-weight: bold;">Sofa <span
                                style="font-size: 14px; font-weight: bold; display: block;">(85" - 92" W)</span></p>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[2].id)" title="Long Sofa"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/LongSofa.jpg" title="Long Sofa"
                                alt="Long Sofa"></a>
                        <p style="font-size: 18px; font-weight: bold;">Long Sofa<span
                                style="font-size: 14px; font-weight: bold; display: block;">(91" - 98" W)</span></p>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[1].id)" title="Grand Sofa"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/GrandSofa.jpg" title="Grand Sofa"
                                alt="Grand Sofa"></a>
                        <p style="font-size: 18px; font-weight: bold;">Grand Sofa<span
                                style="font-size: 14px; font-weight: bold; display: block;">(97" - 104" W)</span></p>
                    </div>
                    <div class="col-lg-2 col-xl-2">
                    </div>
                </div>
                <div class="row mobile" *ngIf="isShowOttoman">
                    <div class="col-xl-12 text-center text-divider">
                        Select a Ottoman to Customize
                    </div>
                    <div class="col-lg-4">
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[8].id)" title="Ottoman"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/Ottoman.jpg" title="Ottoman"
                                alt="Ottoman"></a>
                        <p style="font-size: 18px; font-weight: bold;">Ottoman <span
                                style="font-size: 14px; font-weight: bold; display: block;">(22" W)</span></p>
                    </div>
                    <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                        <a (click)="goToTheodore(products[9].id)" title="Ottoman and Half"><img class="img-fluid"
                                src="https://theodorealexander.com/images/Intiaro/OttomanHalf.jpg"
                                title="Ottoman and Half" alt="Ottoman and Half"></a>
                        <p style="font-size: 18px; font-weight: bold;">Ottoman and Half <span
                                style="font-size: 14px; font-weight: bold; display: block;">(34" W)</span></p>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center" [ngStyle]="{'opacity': isPageLoad ? 1 : 0.6 }">
                    <a (click)="goToTheodore(products[10].id)" title="Sectional"><img class="img-fluid"
                            src="https://theodorealexander.com/images/item/T364_T341_main_1.jpg" title="Sectional"
                            alt="Sectional"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Sectional</p>
                </div>
                <div class="col-lg-1 col-xl-1" style="text-align: center">
                </div>
            </div>
        </div>
        <div class="row desktop" *ngIf="isShowChair">
            <div class="col-xl-12 text-center text-divider">
                Select a Chair to Customize
            </div>
            <div class="col-lg-4">
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[4].id)" title="Chair"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/Chair.jpg" title="Chair" alt="Chair"></a>
                <p style="font-size: 18px; font-weight: bold;">Chair <span
                        style="font-size: 14px; font-weight: bold; display: block;">(31" - 38" W)</span></p>
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[5].id)" title="Chair & Half"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/ChairHalf.jpg" title="Chair & Half"
                        alt="Chair & Half"></a>
                <p style="font-size: 18px; font-weight: bold;">Chair & Half <span
                        style="font-size: 14px; font-weight: bold; display: block;">(43" - 50" W)</span></p>
            </div>
            <div class="col-lg-4">
            </div>
        </div>
        <div class="row desktop" *ngIf="isShowLoveseat">
            <div class="col-xl-12 text-center text-divider">
                Select a Loveseat to Customize
            </div>
            <div class="col-lg-4">
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[6].id)" title="Loveseat"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/Loveseat.jpg" title="Loveseat"
                        alt="Loveseats"></a>
                <p style="font-size: 18px; font-weight: bold;">Loveseat <span
                        style="font-size: 14px; font-weight: bold; display: block;">(61" - 68" W)</span></p>
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[7].id)" title="Long Loveseat"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/LongLoveseat.jpg" title="Long Loveseat"
                        alt="Long Loveseat"></a>
                <p style="font-size: 18px; font-weight: bold;">Long Loveseat <span
                        style="font-size: 14px; font-weight: bold; display: block;">(67" - 74" W)</span></p>
            </div>
            <div class="col-lg-4">
            </div>
        </div>
        <div class="row desktop" *ngIf="isShowSofa">
            <div class="col-xl-12 text-center text-divider">
                Select a Sofa to Customize
            </div>
            <div class="col-lg-2 col-xl-2">
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[3].id)" title="Mid Size Sofa"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/MidSizeSofa.jpg" title="Mid Size Sofa"
                        alt="Mid Size Sofa"></a>
                <p style="font-size: 18px; font-weight: bold;">Mid Size Sofa <span
                        style="font-size: 14px; font-weight: bold; display: block;">(79" - 86" W)</span></p>
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[0].id)" title="Sofa"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/Sofa.jpg" title="Sofa" alt="Sofa"></a>
                <p style="font-size: 18px; font-weight: bold;">Sofa <span
                        style="font-size: 14px; font-weight: bold; display: block;">(85" - 92" W)</span></p>
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[2].id)" title="Long Sofa"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/LongSofa.jpg" title="Long Sofa"
                        alt="Long Sofa"></a>
                <p style="font-size: 18px; font-weight: bold;">Long Sofa <span
                        style="font-size: 14px; font-weight: bold; display: block;">(91" - 98" W)</span></p>
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[1].id)" title="Grand Sofa"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/GrandSofa.jpg" title="Grand Sofa"
                        alt="Grand Sofa"></a>
                <p style="font-size: 18px; font-weight: bold;">Grand Sofa <span
                        style="font-size: 14px; font-weight: bold; display: block;">(97" - 104" W)</span></p>
            </div>
            <div class="col-lg-2 col-xl-2">
            </div>
        </div>
        <div class="row desktop" *ngIf="isShowOttoman">
            <div class="col-xl-12 text-center text-divider">
                Select a Ottoman to Customize
            </div>
            <div class="col-lg-4">
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[8].id)" title="Ottoman"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/Ottoman.jpg" title="Ottoman"
                        alt="Ottoman"></a>
                <p style="font-size: 18px; font-weight: bold;">Ottoman <span
                        style="font-size: 14px; font-weight: bold; display: block;">(22" W)</span></p>
            </div>
            <div class="col-6 col-lg-2 col-xl-2" style="text-align: center;">
                <a (click)="goToTheodore(products[9].id)" title="Ottoman and Half"><img class="img-fluid"
                        src="https://theodorealexander.com/images/Intiaro/OttomanHalf.jpg" title="Ottoman and Half"
                        alt="Ottoman and Half"></a>
                <p style="font-size: 18px; font-weight: bold;">Ottoman and Half <span
                        style="font-size: 14px; font-weight: bold; display: block;">(34" W)</span></p>
            </div>
            <div class="col-lg-4">
            </div>
        </div>
        <!-- <hr /> -->
        <!-- <div class="row">
            <div class="col-xl-12 text-center" style="font-size: 24px; font-weight: bold; margin-top: 2%;">
                FABRIC DRAPER
            </div>

            <div class="row">
                <div class="col-lg-1 col-xl-1" style="margin: 0 0 5% 0; text-align: center;">
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad  ? 1 : 0.6 }">
                    <a (click)="goToTheodore(10546)" title="Aiden Chair"><img class="img-fluid"
                        src="https://theodorealexander.sirv.com/ProductPhotos/525/5253_main_1.jpg?w=400&h=400" title="Aiden Chair"
                        alt="Aiden Chair"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Aiden Chair</p>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad ? 1 : 0.6 }">
                    <a (click)="goToTheodore(10271)" title="Aiden Sectional"><img class="img-fluid"
                            src="https://theodorealexander.sirv.com/ProductPhotos/609/609_main_1.jpg?w=400&h=400" title="Aiden Sectional"
                            alt="Aiden Sectional"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Aiden Sectional</p>
                    <div class="optional"></div>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad  ? 1 : 0.6 }">
                    <a (click)="goToTheodore(10547)" title="Aiden Sofa"><img class="img-fluid"
                            src="https://theodorealexander.sirv.com/ProductPhotos/609/609-10_main_1.jpg?w=400&h=400" title="Aiden Sofa" alt="Aiden Sofa"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Aiden Sofa</p>
                </div>
                <div class="col-6 col-lg-2 col-xl-2 text-center"
                    [ngStyle]="{'opacity': isPageLoad ? 1 : 0.6 }">
                    <a (click)="goToTheodore(10549)" title="Aiden II Sofa"><img class="img-fluid"
                            src="https://theodorealexander.sirv.com/ProductPhotos/608/608-10_main_1.jpg?w=400&h=400" title="Aiden II Sofa"
                            alt="Aiden II Sofa"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Aiden II Sofa</p>
                </div>

                <div class="col-6 col-lg-2 col-xl-2 text-center" [ngStyle]="{'opacity': isPageLoad ? 1 : 0.6 }">
                    <a (click)="goToTheodore(14196)" title="Sasha Swivel Chair"><img class="img-fluid"
                            src="https://theodorealexander.sirv.com/ProductPhotos/528/5282_main_1.jpg?w=400&h=400" title="Sasha Swivel Chair"
                            alt="Sasha Swivel Chair"></a>
                    <p style="font-size: 18px; font-weight: bolder;">Sasha Swivel Chair</p>
                </div>

                <div class="col-lg-1 col-xl-1" style="text-align: center">
                </div>
            </div>
        </div> -->
    </div>