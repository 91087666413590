<div id="theodoreIntiaro">
    <div style="text-align:center;">
        <a title="TAilor Fit" href="dummy/3D-tailorfit.html"
            style="text-decoration: none; border-bottom: 0px solid #636363; text-align: center;">
            <img src="assets/TAilorFit.png" alt="TAilor Fit" title="TAilor Fit" style="width: 20%; margin: 3% 0 0 0;">
        </a>
    </div>
</div>
<div class="row" style="margin: 3% 0;"> 
    <div class="col-xl-4">
    </div>   
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12429" title="Chair"><img
                src="https://theodorealexander.com/images/Intiaro/Chair.jpg" width=270 height=270 title="Chair"
                alt="Chair"></a>
        <p style="font-size: 18px;">Chair</p>
    </div>
    <div class="col-xl-2" style="text-align: center;">
        <a href="/theodore-configurator/12456" title="Chair & Half"><img
                src="https://theodorealexander.com/images/Intiaro/ChairHalf.jpg" width=270 height=270 title="Chair & Half"
                alt="Chair & Half"></a>
        <p style="font-size: 18px;">Chair & Half</p>
    </div>   
    <div class="col-xl-4">
    </div>
</div>