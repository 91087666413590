import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppGlobal } from 'src/app/shared/AppGlobal';
import { ItemDataTransfer } from 'src/app/shared/ItemDataTransfer.model';
import { ItemService } from 'src/app/shared/Item.service';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { LifeStyle } from 'src/app/shared/LifeStyle.model';
import { LifeStyleService } from 'src/app/shared/LifeStyle.service';
import { Shape } from 'src/app/shared/Shape.model';
import { ShapeService } from 'src/app/shared/Shape.service';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { RoomAndUsage } from 'src/app/shared/RoomAndUsage.model';
import { RoomAndUsageService } from 'src/app/shared/RoomAndUsage.service';
import { Brand } from 'src/app/shared/Brand.model';
import { BrandService } from 'src/app/shared/Brand.service';
import { DownloadService } from 'src/app/shared/Download.service';
import { HeadService } from 'src/app/shared/Head.service';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { UserRegions } from 'src/app/theoalex/theoalex';
import { StyleService } from 'src/app/shared/Style.service';
import { Style } from 'src/app/shared/Style.model';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit {

  userId: string = "";
  region: string = "";
  queryString: string = "";
  searchString: string = "";
  collection: string = environment.Collection;
  shape: string = environment.Shape;
  type: string = environment.Type;
  lifeStyle: string = environment.LifeStyle;
  style: string = environment.Style;
  productDetail: string = environment.ProductDetail;
  search: string = environment.Search;
  imageURLSub: string = environment.DomainImage + "images/Item";
  room_IDList: string = "";
  brand_IDList: string = "";
  type_IDList: string = "";
  shape_IDList: string = "";
  style_IDList: string = "";
  lifeStyle_IDList: string = "";
  collection_IDList: string = "";
  room_IDListCount: string[] = [];
  brand_IDListCount: string[] = [];
  type_IDListCount: string[] = [];
  shape_IDListCount: string[] = [];
  style_IDListCount: string[] = [];
  lifeStyle_IDListCount: string[] = [];
  collection_IDListCount: string[] = [];
  inStockProgram: boolean = false;
  isStocked: boolean = false;
  isShowLoading: boolean = false;
  isRoomShow: boolean = false;
  isCollectionShow: boolean = false;
  isLifeStyleShow: boolean = false;
  isStyleShow: boolean = false;
  isShapeShow: boolean = false;
  isGridViewMode: boolean = true;
  isResultEmpty: boolean = false;
  isActiveTAUS: boolean = false;
  isActive: boolean = true;
  isOpenFilterAtMobile: boolean = false;
  isClearAll: boolean = false;
  extending: boolean = false;
  viewModel: number = 1;
  shapeCount: number = 0;
  lifeStyleCount: number = 0;
  styleCount: number = 0;
  collectionCount: number = 0;
  extendingCount: number = 0;
  roomAndUsageCount: number = 0;
  constructor(
    public router: Router,
    public itemService: ItemService,
    public collectionService: CollectionService,
    public lifeStyleService: LifeStyleService,
    public styleService: StyleService,
    public shapeService: ShapeService,
    public itemMenuService: ItemService,
    public roomAndUsageService: RoomAndUsageService,
    public brandService: BrandService,
    public typeService: TypeService,
    public downloadService: DownloadService,
    public headService: HeadService,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.getByQueryString();
      }
    });
  }

  ngOnInit(): void {
  }
  getCollectionMenuItems() {
    this.collectionCount = 0;
    this.isCollectionShow = false;
    this.collection_IDListCount = this.collection_IDList.split(";");
    this.collectionService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.collectionService.listData = res.Data as Collection[];
      this.collectionService.list = this.collectionService.listData;
      for (let i = 0; i < this.collectionService.listData.length; i++) {
        this.collectionCount = this.collectionCount + this.collectionService.list[i].ItemCount;
        this.collectionService.list[i].IsActive = false;
        for (let j = 0; j < this.collection_IDListCount.length; j++) {
          if (this.collectionService.list[i].URLCode == this.collection_IDListCount[j]) {
            this.collectionService.list[i].IsActive = true;
            this.isCollectionShow = true;
          }
        }
      }
    });
  }
  getCollectionMenuItemsSub() {
    this.isCollectionShow = false;
    this.collection_IDListCount = this.collection_IDList.split(";");
    for (let i = 0; i < this.collectionService.list.length; i++) {
      this.collectionService.list[i].IsActive = false;
      for (let j = 0; j < this.collection_IDListCount.length; j++) {
        if (this.collectionService.list[i].URLCode == this.collection_IDListCount[j]) {
          this.collectionService.list[i].IsActive = true;
          this.isCollectionShow = true;
        }
      }
    }
  }
  getRoomAndUsageMenuItems() {
    this.roomAndUsageCount = 0;
    this.room_IDListCount = this.room_IDList.split(";");
    this.roomAndUsageService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.roomAndUsageService.listData = res.Data as RoomAndUsage[];
      this.roomAndUsageService.list = this.roomAndUsageService.listData;
      for (let i = 0; i < this.roomAndUsageService.listData.length; i++) {
        this.roomAndUsageCount = this.roomAndUsageCount + this.roomAndUsageService.list[i].ItemCount;
        this.roomAndUsageService.list[i].IsActive = false;
        for (let j = 0; j < this.room_IDListCount.length; j++) {
          if (this.roomAndUsageService.list[i].URLCode == this.room_IDListCount[j]) {
            this.roomAndUsageService.list[i].IsActive = true;
          }
        }
      }
    });
  }
  getRoomAndUsageMenuItemsSub() {
    this.room_IDListCount = this.room_IDList.split(";");
    for (let i = 0; i < this.roomAndUsageService.list.length; i++) {
      this.roomAndUsageService.list[i].IsActive = false;
      for (let j = 0; j < this.room_IDListCount.length; j++) {
        if (this.roomAndUsageService.list[i].URLCode == this.room_IDListCount[j]) {
          this.roomAndUsageService.list[i].IsActive = true;
        }
      }
    }
  }
  getBrandMenuItems() {
    this.brand_IDListCount = this.brand_IDList.split(";");
    this.brandService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.brandService.listData = res.Data as Brand[];
      this.brandService.list = this.brandService.listData;
      for (let i = 0; i < this.brandService.listData.length; i++) {
        this.brandService.list[i].IsActive = false;
        for (let j = 0; j < this.brand_IDListCount.length; j++) {
          if (this.brandService.list[i].URLCode == this.brand_IDListCount[j]) {
            this.brandService.list[i].IsActive = true;
          }
        }
      }
    });
  }
  getBrandMenuItemsSub() {
    this.brand_IDListCount = this.brand_IDList.split(";");
    for (let i = 0; i < this.brandService.list.length; i++) {
      this.brandService.list[i].IsActive = false;
      for (let j = 0; j < this.brand_IDListCount.length; j++) {
        if (this.brandService.list[i].URLCode == this.brand_IDListCount[j]) {
          this.brandService.list[i].IsActive = true;
        }
      }
    }
  }
  getTypeMenuItems() {
    this.type_IDListCount = this.type_IDList.split(";");
    this.typeService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.typeService.listData = res.Data as Type[];
      this.typeService.list = this.typeService.listData;
      for (let i = 0; i < this.typeService.listData.length; i++) {
        this.typeService.list[i].IsActive = false;
        for (let j = 0; j < this.type_IDListCount.length; j++) {
          if (this.typeService.list[i].URLCode == this.type_IDListCount[j]) {
            this.typeService.list[i].IsActive = true;
            this.isClearAll = true;
          }
        }
      }
    });
  }
  getTypeMenuItemsSub() {
    this.type_IDListCount = this.type_IDList.split(";");
    for (let i = 0; i < this.typeService.list.length; i++) {
      this.typeService.list[i].IsActive = false;
      for (let j = 0; j < this.type_IDListCount.length; j++) {
        if (this.typeService.list[i].URLCode == this.type_IDListCount[j]) {
          this.typeService.list[i].IsActive = true;
          this.isClearAll = true;
        }
      }
    }
  }
  getLifeStyleMenuItems() {
    this.lifeStyleCount = 0;
    this.isLifeStyleShow = false;
    this.lifeStyle_IDListCount = this.lifeStyle_IDList.split(";");
    this.lifeStyleService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.lifeStyleService.listData = res.Data as LifeStyle[];
      this.lifeStyleService.list = this.lifeStyleService.listData;
      for (let i = 0; i < this.lifeStyleService.listData.length; i++) {
        this.lifeStyleCount = this.lifeStyleCount + this.lifeStyleService.list[i].ItemCount;
        this.lifeStyleService.list[i].IsActive = false;
        for (let j = 0; j < this.lifeStyle_IDListCount.length; j++) {
          if (this.lifeStyleService.list[i].URLCode == this.lifeStyle_IDListCount[j]) {
            this.lifeStyleService.list[i].IsActive = true;
            this.isLifeStyleShow = true;
          }
        }
      }
    });
  }
  getLifeStyleMenuItemsSub() {
    this.isLifeStyleShow = false;
    this.lifeStyle_IDListCount = this.lifeStyle_IDList.split(";");
    for (let i = 0; i < this.lifeStyleService.list.length; i++) {
      this.lifeStyleService.list[i].IsActive = false;
      for (let j = 0; j < this.lifeStyle_IDListCount.length; j++) {
        if (this.lifeStyleService.list[i].URLCode == this.lifeStyle_IDListCount[j]) {
          this.lifeStyleService.list[i].IsActive = true;
          this.isLifeStyleShow = true;
        }
      }
    }
  }
  getStyleMenuItems() {
    this.styleCount = 0;
    this.isStyleShow = false;
    this.style_IDListCount = this.style_IDList.split(";");
    this.styleService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.styleService.listData = res.Data as Style[];
      this.styleService.list = this.styleService.listData;
      for (let i = 0; i < this.styleService.listData.length; i++) {
        this.styleCount = this.styleCount + this.styleService.list[i].ItemCount;
        this.styleService.list[i].IsActive = false;
        for (let j = 0; j < this.style_IDListCount.length; j++) {
          if (this.styleService.list[i].URLCode == this.style_IDListCount[j]) {
            this.styleService.list[i].IsActive = true;
            this.isStyleShow = true;
          }
        }
      }
    });
  }
  getStyleMenuItemsSub() {
    this.isStyleShow = false;
    this.style_IDListCount = this.style_IDList.split(";");
    for (let i = 0; i < this.styleService.list.length; i++) {
      this.styleService.list[i].IsActive = false;
      for (let j = 0; j < this.style_IDListCount.length; j++) {
        if (this.styleService.list[i].URLCode == this.style_IDListCount[j]) {
          this.styleService.list[i].IsActive = true;
          this.isStyleShow = true;
        }
      }
    }
  }
  getShapeMenuItems() {
    this.shapeCount = 0;
    this.isShapeShow = false;
    this.shape_IDListCount = this.shape_IDList.split(";");
    this.shapeService.getByIsActiveAndRegionToList(this.isActive, this.region).subscribe(res => {
      this.shapeService.listData = res.Data as Shape[];
      this.shapeService.list = this.shapeService.listData;
      for (let i = 0; i < this.shapeService.listData.length; i++) {
        this.shapeCount = this.shapeCount + this.shapeService.list[i].ItemCount;
        this.shapeService.list[i].IsActive = false;
        for (let j = 0; j < this.shape_IDListCount.length; j++) {
          if (this.shapeService.list[i].URLCode == this.shape_IDListCount[j]) {
            this.shapeService.list[i].IsActive = true;
            this.isShapeShow = true;
          }
        }
      }
    });
  }
  getShapeMenuItemsSub() {
    this.isShapeShow = false;
    this.shape_IDListCount = this.shape_IDList.split(";");
    for (let i = 0; i < this.shapeService.list.length; i++) {
      this.shapeService.list[i].IsActive = false;
      for (let j = 0; j < this.shape_IDListCount.length; j++) {
        if (this.shapeService.list[i].URLCode == this.shape_IDListCount[j]) {
          this.shapeService.list[i].IsActive = true;
          this.isShapeShow = true;
        }
      }
    }
  }
  getExtendingMenuItems() {
    this.extendingCount = 0;
    this.itemMenuService.getByRegionAndExtendingToItemCount(this.region, this.isActive).subscribe(res => {
      this.extendingCount = res.Data as number;
    });
  }
  checkClearAll() {
    let room_IDListCheck = this.room_IDList;
    room_IDListCheck = room_IDListCheck.replace(";", "");
    room_IDListCheck = room_IDListCheck.trim();
    let brand_IDListCheck = this.brand_IDList;
    brand_IDListCheck = brand_IDListCheck.replace(";", "");
    brand_IDListCheck = brand_IDListCheck.trim();
    let type_IDListCheck = this.type_IDList;
    type_IDListCheck = type_IDListCheck.replace(";", "");
    type_IDListCheck = type_IDListCheck.trim();
    let shape_IDListCheck = this.shape_IDList;
    shape_IDListCheck = shape_IDListCheck.replace(";", "");
    shape_IDListCheck = shape_IDListCheck.trim();
    let style_IDListCheck = this.style_IDList;
    style_IDListCheck = style_IDListCheck.replace(";", "");
    style_IDListCheck = style_IDListCheck.trim();
    let lifeStyle_IDListCheck = this.lifeStyle_IDList;
    lifeStyle_IDListCheck = lifeStyle_IDListCheck.replace(";", "");
    lifeStyle_IDListCheck = lifeStyle_IDListCheck.trim();
    let collection_IDListCheck = this.collection_IDList;
    collection_IDListCheck = collection_IDListCheck.replace(";", "");
    collection_IDListCheck = collection_IDListCheck.trim();
    if ((room_IDListCheck.length == 0)
      && (brand_IDListCheck.length == 0)
      && (type_IDListCheck.length == 0)
      && (shape_IDListCheck.length == 0)
      && (style_IDListCheck.length == 0)
      && (lifeStyle_IDListCheck.length == 0)
      && (collection_IDListCheck.length == 0)) {
      this.isClearAll = false;
    }
    else {
      this.isClearAll = true;
    }
  }
  getByQueryString() {
    TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      if (credential) {
        this.headService.setPageHeadByFilters("Advanced filters");
        this.queryString = this.queryString.replace("%3B", ";");
        this.queryString = this.queryString.replace("%3B", ";");
        this.queryString = this.queryString.replace("%3B", ";");
        this.queryString = this.queryString.replace("%3B", ";");
        this.queryString = this.queryString.replace("%3B", ";");
        this.queryString = this.queryString.replace("%3B", ";");
        let queryStringList = this.queryString.split("/");
        this.room_IDList = queryStringList[2];
        this.brand_IDList = queryStringList[3];
        this.type_IDList = queryStringList[4];
        this.shape_IDList = queryStringList[5];
        this.style_IDList = queryStringList[6];
        this.lifeStyle_IDList = queryStringList[7];
        this.collection_IDList = queryStringList[8];
        this.region = credential?.Region;
        this.userId = credential?.UserId;
        this.isActiveTAUS = AppGlobal.InitializationIsActiveTAUS(this.region);
        this.getCollectionMenuItems();
        this.getRoomAndUsageMenuItems();
        this.getTypeMenuItems();
        this.getLifeStyleMenuItems();
        this.getStyleMenuItems();
        this.getShapeMenuItems();
        this.getExtendingMenuItems();
        this.getBrandMenuItems();
        this.getByQueryString001();
      }
    });
  }
  getByQueryString001() {
    this.checkClearAll();
    this.isResultEmpty = false;
    this.isShowLoading = true;
    this.itemService.listItemDataTransfer = [];
    this.itemService.asyncGetDataTransferByUser_IDAndRegionAndFiltersAndExtendingAndIsStockedToList(this.userId, this.region, this.room_IDList, this.brand_IDList, this.type_IDList, this.shape_IDList, this.style_IDList, this.lifeStyle_IDList, this.collection_IDList, this.extending, this.isStocked).subscribe(res => {
      this.itemService.listItemDataTransferData = (res.Data as ItemDataTransfer[]);
      this.itemService.listItemDataTransfer = this.itemService.listItemDataTransferData;
      this.onSort();
      this.isShowLoading = false;
    });
  }
  onSort() {
    this.isResultEmpty = false;
    if (this.itemService.listItemDataTransfer.length == 0) {
      this.isResultEmpty = true;
    }
    else {
      if (this.viewModel == 1) {
        this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => new Date(b.DateCreated).getTime() - new Date(a.DateCreated).getTime());
      }
      if (this.viewModel == 2) {
        this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => Number(a.IsNew > b.IsNew) * 2 - 1);
      }
      if (this.viewModel == 3) {
        this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => a.ProductName.localeCompare(b.ProductName));
      }
      if (this.viewModel == 4) {
        this.itemService.listItemDataTransfer = this.itemService.listItemDataTransfer.sort((a, b) => b.ProductName.localeCompare(a.ProductName));
      }
      if (this.itemService.listItemDataTransfer.length == 0) {
        this.isResultEmpty = true;
      }
    }
  }
  onInStockProgramChange() {
    this.getByQueryString001();
  }
  onInStockChange() {
    this.getByQueryString001();
  }
  onSortByChange() {
    this.onSort();
  }
  onSearch() {
    let url = AppGlobal.SetSearchURLByName(this.searchString);
    this.router.navigate([url]);
  }
  onCollectionChange() {
    this.isCollectionShow = !this.isCollectionShow;
  }
  onLifeStyleChange() {
    this.isLifeStyleShow = !this.isLifeStyleShow;
  }
  onStyleChange() {
    this.isStyleShow = !this.isStyleShow;
  }
  onShapeChange() {
    this.isShapeShow = !this.isShapeShow;
  }
  onRoomChange() {
    this.isRoomShow = !this.isRoomShow;
  }
  onRoomSubChange(item: RoomAndUsage) {
    this.isRoomShow = true;
    for (let i = 0; i < this.roomAndUsageService.list.length; i++) {
      if (item.ID == this.roomAndUsageService.list[i].ID) {
        if (this.roomAndUsageService.list[i].IsMenuShow == true) {
          this.roomAndUsageService.list[i].IsMenuShow = false;
        }
        else {
          this.roomAndUsageService.list[i].IsMenuShow = true;
        }
      }
    }
  }
  onSwitchViewMode() {
    this.isGridViewMode = !this.isGridViewMode;
  }
  onCloseWishlistPopup(item: ItemDataTransfer) {
    item.IsInWishList = true;
  }
  onClickMobileFilterIcon(isNeedOpen) {
    if (isNeedOpen !== undefined) {
      this.isOpenFilterAtMobile = isNeedOpen;
    } else {
      this.isOpenFilterAtMobile = !this.isOpenFilterAtMobile;
    }
    if (this.isOpenFilterAtMobile) {
      TaUniversalHubSubject.scrollRequest.next();
    }
    this.isGridViewMode = true;
  }
  onExtendingSelect() {
    this.extending = !this.extending;
    this.getByQueryString001();
  }
  onCollectionSelect(item: Collection) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.collection_IDList = this.collection_IDList + ";" + item.URLCode;
    }
    else {
      this.collection_IDList = this.collection_IDList.replace(item.URLCode, "");
    }
    this.getCollectionMenuItemsSub();
    this.getByQueryString001();
  }
  onTypeSelect(item: Type) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.type_IDList = this.type_IDList + ";" + item.URLCode;
    }
    else {
      this.type_IDList = this.type_IDList.replace(item.URLCode, "");
    }
    this.getTypeMenuItemsSub();
    this.getByQueryString001();
  }
  onShapeSelect(item: Shape) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.shape_IDList = this.shape_IDList + ";" + item.URLCode;
    }
    else {
      this.shape_IDList = this.shape_IDList.replace(item.URLCode, "");
    }
    this.getShapeMenuItemsSub();
    this.getByQueryString001();
  }
  onStyleSelect(item: Style) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.style_IDList = this.style_IDList + ";" + item.URLCode;
    }
    else {
      this.style_IDList = this.style_IDList.replace(item.URLCode, "");
    }
    this.getStyleMenuItemsSub();
    this.getByQueryString001();
  }
  onLifeStyleSelect(item: LifeStyle) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.lifeStyle_IDList = this.lifeStyle_IDList + ";" + item.URLCode;
    }
    else {
      this.lifeStyle_IDList = this.lifeStyle_IDList.replace(item.URLCode, "");
    }
    this.getLifeStyleMenuItemsSub();
    this.getByQueryString001();
  }
  onRoomSelect(item: RoomAndUsage) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.room_IDList = this.room_IDList + ";" + item.URLCode;
    }
    else {
      this.room_IDList = this.room_IDList.replace(item.URLCode, "");
    }
    this.getRoomAndUsageMenuItemsSub();
    this.getByQueryString001();
  }
  onBrandSelect(item: Brand) {
    item.IsActive = !item.IsActive;
    if (item.IsActive == true) {
      this.brand_IDList = this.brand_IDList + ";" + item.URLCode;
    }
    else {
      this.brand_IDList = this.brand_IDList.replace(item.URLCode, "");
    }
    this.getBrandMenuItemsSub();
    this.getByQueryString001();
  }
  onClearAll() {
    this.isClearAll = false;
    this.room_IDList = ";";
    this.brand_IDList = this.room_IDList;
    this.type_IDList = this.room_IDList;
    this.shape_IDList = this.room_IDList;
    this.style_IDList = this.room_IDList;
    this.lifeStyle_IDList = this.room_IDList;
    this.collection_IDList = this.room_IDList;
    this.getByQueryString001();
    this.getTypeMenuItemsSub();
    this.getCollectionMenuItemsSub();
    this.getLifeStyleMenuItemsSub();
    this.getShapeMenuItemsSub();
    this.getStyleMenuItemsSub();
    this.getRoomAndUsageMenuItemsSub();
    this.getBrandMenuItemsSub();
  }
}
