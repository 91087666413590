import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
import { Config } from 'src/app/shared/types';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { environment } from 'src/environments/environment';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { TaApiServiceShoppingCart } from 'src/app/service/api/TaApiServiceShoppingCart';

@Component({
  selector: 'app-tailor-fit',
  templateUrl: './tailor-fit.component.html',
  styleUrls: ['./tailor-fit.component.scss']
})
export class TAilorFitComponent implements OnInit, OnDestroy {

  public products: Config[] = [];

  isShowSofa: boolean = false;
  isShowLoveseat: boolean = false;
  isShowChair: boolean = false;
  isShowOttoman: boolean = false;
  isPageLoad: boolean = true;
  isLogin: boolean = false;
  shoppingCartCount: string = "0";
  tailorfitSubdomain: string = environment.TailorFitURL;
  constructor(
    private router: Router,
    private stepByStepService: StepByStepService,
    public collectionService: CollectionService,
    private loginService: TaApiServiceLogin,
    public shoppingCartService: TaApiServiceShoppingCart
  ) {
    this.getCollectionByID();
  }
  ngOnDestroy(): void {
  }

  ngOnInit(): void {
    this.stepByStepService.getConfig()
      .subscribe(config => {
        this.products = config;
      })
  }
  getCollectionByID() {
    var tAilorFitURLCode = 'tailor-fit';
    this.collectionService.getByURLCode(tAilorFitURLCode).subscribe(res => {
      this.collectionService.formData = res.Data as Collection;
    });
  }
  onShow(index: number) {
    this.isPageLoad = false;
    this.isShowSofa = false;
    this.isShowLoveseat = false;
    this.isShowChair = false;
    this.isShowOttoman = false;
    switch (index) {
      case 1:
        this.isShowChair = true;
        break;
      case 2:
        this.isShowLoveseat = true;
        break;
      case 3:
        this.isShowSofa = true;
        break;
      case 4:
        this.isShowOttoman = true;
        break;
    }
  }
  goToTheodore(id: number) {
    this.router.navigate(['theodore-configurator', id]);
  }
}
