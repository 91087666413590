import { Component, OnInit, EventEmitter, Input, Output, OnChanges, SimpleChanges, ViewChildren, QueryList, AfterViewInit } from '@angular/core';
import * as _ from "lodash";

@Component({
  selector: 'app-checkbox-accordion',
  templateUrl: './checkbox-accordion.component.html',
  styleUrls: ['./checkbox-accordion-ngb-accordion.component.scss', './checkbox-accordion.component.scss']
})
export class CheckboxAccordionComponent implements OnInit, OnChanges, AfterViewInit {


  @Input() itemList: any;
  @Input() isSingleChoice: boolean = false;
  @Input() withCount?: boolean;
  @Output() onItemSelectionChanged = new EventEmitter();

  constructor() { }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.processForSelectedItemsByInitial();
  }

  ngAfterViewInit(): void {
  }

  isItemHasSubAccordion(category) {
    return category && category.items && category.items.length && category.items[0] && category.items[0].items && category.items[0].items.length;
  }

  onItemChecked(item) {
    if (this.isSingleChoice) {
      if (item.parent && item.parent.items && item.parent.items.length) {
        item.parent.items.forEach(element => {
          element.isSelected = false;
        });
      } else {
        this.itemList.forEach(i => {
          i.items.forEach(element => {
            element.isSelected = false;
          });
        })
      }
      item.isSelected = true;
    } else {
      //toggle it
      item.isSelected = !item.isSelected;
    }
    this.emitItemSelectionChanged(item.isSelected, item);
  }

  private emitItemSelectionChanged(isSelected: boolean, item?: any) {
    //do not emit for parent item
    if (!item.items || !item.items.length) {
    this.onItemSelectionChanged.emit({ isSelected: isSelected, changedItem: item, parent: parent });
    }
  }

  private processForSelectedItemsByInitial() {
    let activePanelIds = []
    this.itemList.forEach(i1 => {
      if (i1.items && i1.items.length) {
        i1.items.forEach(i2 => {
          if (i2.items && i2.items.length) {
            i2.items.forEach(i3 => {
              if (i3.isSelected) {
                activePanelIds.push(i1.name);
                activePanelIds.push(i1.name+i2.name);
              }
            });
          } else {
            if (i2.isSelected) {
              activePanelIds.push(i1.name);
            }
          }
        });
      }
    });
  }
}
