import {
  Component,
  OnInit,
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy,
  HostListener,
  ViewContainerRef,
  TemplateRef,
} from '@angular/core';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
import { StepFromNewEvent } from 'src/app/shared/types';

declare let IntiaroConfigurator: any;
declare let getIntiaroController: any;

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css'],
})
export class NavbarComponent implements OnInit, AfterViewChecked, AfterViewInit, OnDestroy {

  private player: any;
  public faArrowLeft = faArrowLeft;
  public faArrowRight = faArrowRight;
  public stepByStepMenu: StepFromNewEvent[] = [];
  public statusNext: boolean = false;
  public statusCorrectConfigurationSectional: boolean = false;
  private subsription1: any;
  private subsription2: any;
  private subsription3: any;
  public activeStep: any = { name: '', details: '' }
  private productConfigData: any;
  private stepByStepServiceSubscriptions: any[] = [];

  @ViewChild('vertical') vertical!: ElementRef;
  @ViewChild('nextBtn') nextBtn!: ElementRef;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?: any) {
  }

  private _controller: any = false;
  private get controller(): any {
    if (!this._controller) {
      this._controller = getIntiaroController('Intiaro-configurator');
    }
    return this._controller;
  }


  constructor(
    private stepByStepService: StepByStepService,
    private cdr: ChangeDetectorRef,
    private viewContainerRef: ViewContainerRef,
  ) {
    this.getScreenSize();
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.subsription1 = this.stepByStepService.isCorrectConfigurationSectional.subscribe(status => {
    //   this.statusCorrectConfigurationSectional = status;
    // });

    // this.subsription2 = this.stepByStepService.variablesGroupsReceived.subscribe(groups => {
    //   this.stepByStepMenu = groups;
    //   this.stepByStepMenu.forEach((el: any) => { el.isActive === true ? this.activeStep = el : '' });
    // })

    // this.subsription3 = this.stepByStepService.getProductConfig().subscribe(config => {
    //   config.filter(el => {
    //     this.productConfigData = el;
    //   })
    // })
    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.isCorrectConfigurationSectional.subscribe(status => {
        this.statusCorrectConfigurationSectional = status;
      })
    );

    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.variablesGroupsReceived.subscribe(groups => {
        this.stepByStepMenu = groups;
        this.stepByStepMenu.forEach((el: any) => { el.isActive === true ? this.activeStep = el : '' });
      })
    );

    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.getProductConfig().subscribe(config => {
        config.filter(el => {
          this.productConfigData = el;
        })
      })
    );
  }

  ngAfterViewChecked() {
    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.getStatusNext().subscribe((status) => {
        let currentTab = this.stepByStepMenu.filter((el) => el.isActive === true);
        let currentIndex = this.stepByStepMenu.indexOf(currentTab[0]);
        if (currentIndex >= 0 && this.stepByStepMenu[currentIndex + 1]) {
          // if (this.stepByStepMenu[currentIndex].slug === 'sections') {
          //   this.statusNext = this.statusCorrectConfigurationSectional;
          // } else if (this.stepByStepMenu[currentIndex + 1].isAvailable) {
          //   this.statusNext = true;
          // } else if (this.productConfigData && this.activitateNextIfAllAttributesAreOptional(this.productConfigData, this.activeStep)) {
          //   this.statusNext = true;
          // } else {
          //   this.statusNext = status;
          // }
          if (this.stepByStepMenu[currentIndex].slug === 'sections') {
            this.statusNext = this.statusCorrectConfigurationSectional;
          } else {
            this.statusNext = true;
          }
        } else {
          currentIndex === -1 ? this.statusNext = false : '';
        }
        // this.statusNext ? this.stepByStepService.toggleStatusBottomNext(true) : this.stepByStepService.toggleStatusBottomNext(false)
        this.stepByStepService.toggleStatusBottomNext(this.statusNext)
      })
    );
    this.cdr.detectChanges();

  }

  ngOnDestroy() {
    // this.subsription1.unsubscribe();
    // this.subsription2.unsubscribe();
    // this.subsription3.unsubscribe();
    this.stepByStepServiceSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  public changeTab(index: number, slug: string) {
    if (slug === 'custom_summary') {
      this.stepByStepService.toggleStatusSummary(true);
      this.changeStyleForSummary('none');
    } else {
      if (this.stepByStepService.showSummary) {
        this.stepByStepService.toggleStatusSummary(false);
        this.changeStyleForSummary('flex');
      }
      this.controller.openTab(slug); // replace this.controller.openAttributesGroup in 2.5
    }
    this.stepByStepService.toggleNextStepActivity(index, true);

    // this.moveScrollbar('', index)
    setTimeout(() => { this.moveScrollbar('', index) }, 100)
  }

  public goToNextStep() {
    let currentIndex = this.getCurrentTabIndex();
    let nextTabSlug = this.getTabSlug(currentIndex + 1);
    this.stepByStepService.toggleNextStepAvailability(nextTabSlug, true);
    this.stepByStepService.toggleNextStepActivity(currentIndex + 1, true);
    if (currentIndex === this.stepByStepMenu.length - 2) {
      this.stepByStepService.toggleStatusSummary(true);
      this.changeStyleForSummary('none');
    } else {
      if (this.getTabSlug(currentIndex) === 'sections') this.stepByStepService.toggleClickFinishBuilding(true);
      this.controller.openTab(nextTabSlug); // in 2.5 (in 2.4: this.controller.openAttributesGroup)
      // (this.stepByStepMenu[currentIndex+1].details === 'Click to edit') ? this.stepByStepService.toggleNext(false) : '';
      this.stepByStepService.toggleNext(false)
    }

    setTimeout(() => { this.moveScrollbar('right') }, 100)
  }

  public goToBackStep() {
    let currentIndex = this.getCurrentTabIndex();
    let backTabSlug = this.getTabSlug(currentIndex - 1);
    this.stepByStepService.toggleNextStepAvailability(backTabSlug, true);
    this.stepByStepService.toggleNextStepActivity(currentIndex - 1, true);
    this.controller.openTab(backTabSlug); // replace this.controller.openAttributesGroup in 2.5

    if (this.stepByStepService.showSummary) {
      this.stepByStepService.toggleStatusSummary(false);
      this.changeStyleForSummary('flex');
    }

    setTimeout(() => { this.moveScrollbar('left') }, 100)
  }

  //move scrollbar to active step in menu will be visible
  private moveScrollbar(direction: string, index?: number): void {
    let scroll = document.querySelectorAll('.navbar-items');

    if (window.innerWidth > 800) {
      this.startScrolling(scroll[0], direction, index);
    } else {
      this.startScrolling(scroll[1], direction, index);
    }
  }

  private startScrolling(scroll: Element, direction: string, index?: number) {
    let width = scroll?.clientWidth;
    let tabs = scroll?.children;
    let moveScroll = 0;
    let i = 0;
    let activeTabIndex = 0;
    let activeTabInMenu: any;
    let lastTabInMenu: any;

    if (tabs && width) {
      Array.from(tabs).forEach((el: any) => {
        i++
        if (el.firstElementChild?.firstElementChild?.classList.contains('activeName')) {
          direction === "right" ? activeTabIndex = i : activeTabIndex = i - 1;
          i == tabs?.length ? activeTabInMenu = tabs?.item(activeTabIndex - 1) : activeTabInMenu = tabs?.item(activeTabIndex);
          lastTabInMenu = tabs?.item(tabs?.length - 1);
        };
      })
      activeTabInMenu.offsetLeft > width ? moveScroll = (activeTabInMenu.offsetLeft) : moveScroll = (activeTabInMenu.offsetLeft - 50); //wartość 50 jest uznaniowa
    }
    if (direction === "right") {
      scroll?.scrollTo({ left: moveScroll - 50, top: 0, behavior: 'smooth' })
    } else if (direction === "left") {
      scroll?.scrollTo({ left: moveScroll, top: 0, behavior: 'smooth' })
    } else {
      if (index && tabs && width) {
        let availableStepsCount = 0;
        for (let i = 0; i < tabs.length; i++) {
          tabs[i].childElementCount > 0 ? availableStepsCount += 1 : ''
        }
        if (index < availableStepsCount * 0.3) {
          scroll?.scrollTo({ left: 0, top: 0, behavior: 'smooth' });
        } else if (index >= availableStepsCount * 0.3 && index < availableStepsCount * 0.65) {
          index > activeTabIndex
            ? scroll?.scrollTo({ left: (lastTabInMenu.offsetLeft / availableStepsCount) * index - 150, top: 0, behavior: 'smooth' })
            : scroll?.scrollTo({ left: (lastTabInMenu.offsetLeft / availableStepsCount) * index - 50, top: 0, behavior: 'smooth' })
        } else {
          scroll?.scrollTo({ left: lastTabInMenu.offsetLeft, top: 0, behavior: 'smooth' });
        }
      }
    }
  }

  private getCurrentTabIndex(): number {
    let currentTab = this.stepByStepMenu.filter((el) => el.isActive === true);
    let currentIndex = this.stepByStepMenu.indexOf(currentTab[0]);
    return currentIndex;
  }

  private getTabSlug(currentIndex: number): string {
    let slug = this.stepByStepMenu[currentIndex].slug;
    return slug;
  }

  private changeStyleForSummary(newStyle: string): void {
    this.vertical.nativeElement.style.display = newStyle;
    this.nextBtn.nativeElement.style.display = newStyle;
  }

  public editStepName(name: string): string {
    let newName = name.replace('Choose Your ', '');
    return newName;
  }

  private activitateNextIfAllAttributesAreOptional(productConfigData: any, activeStep: any): boolean {
    let flag = false
    productConfigData.mapOfAttributes.filter((attr: any) => {
      if (attr.stepSlug == activeStep.slug) {
        if (attr.attributes.every((a: any) => (a.obligatory === false))) {
          this.stepByStepService.toggleNext(true)
          flag = true
        }
      }
    })
    return flag
  }
}
