import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-alexa-hampton',
  templateUrl: './new-alexa-hampton.component.html',
  styleUrls: ['./new-alexa-hampton.component.scss']
})
export class NewAlexaHamptonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
