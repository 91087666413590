import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-check-logout',
  templateUrl: './check-logout.component.html',
  styleUrls: ['./check-logout.component.scss']
})
export class CheckLogoutComponent implements OnInit {

  queryString: string = "";
  uRLReturn: string = "";
  constructor( private loginService: TaApiServiceLogin,
    public router: Router,
    ) { 
      this.router.events.forEach((event) => {
        if (event instanceof NavigationEnd) {
          this.queryString = event.url;
          this.checkLogout();          
        }
      });      
    }

  ngOnInit(): void {
   
  }
  checkLogout() {
    this.loginService.logout(true);
    if (this.queryString.includes("index")) {
      this.uRLReturn = environment.appWebUrl + "index.html";      
    }
    else {
      if (this.queryString.includes("about")) {
        this.uRLReturn = environment.appWebUrl + "about.html";
      }
      if (this.queryString.includes("contact")) {
        this.uRLReturn = environment.appWebUrl + "contact.html";
      }
      if (this.queryString.includes("site-map")) {
        this.uRLReturn = environment.appWebUrl + "site-map.html";
      }
      if (this.queryString.includes("dealer-locator")) {
        this.uRLReturn = environment.appWebUrl + "dealer-locator.html";
      }      
      if(this.uRLReturn.length==0){
        this.queryString = this.queryString.replace("/checklogout/", "");
        if (this.queryString.split("_")[1]) {
          let parameter01 = this.queryString.split("_")[0];
          let parameter02 = this.queryString.split("_")[1];
          this.uRLReturn = environment.appWebUrl + parameter01 + "/" + parameter02 + ".html";
        }
      }     
    }    
    localStorage.setItem('UserName', '');      
    localStorage.setItem('FirstName', '');   
    localStorage.setItem('Region', '');   
    window.location.href = this.uRLReturn;
  }
}
