export module TaMetadata {
  export function getProductMenu():{ text?: string, countBy?: string, id?: string, name: string, extra?:any, items?: { text: string, countBy: string, id: string, name: string, extra?:any, items?: [] }[]}[]{
    let products = [
      {
        "text": "Living Room", "countBy": "RoomIds", "id": "EE6344A6-D565-4DF1-A77E-DB67F134B210", "name": "Living Room",
        "items": [
          { "text": "Accent Chairs", "countBy": "TypeIds", "id": "F9BC6A0D-DCEC-4770-A936-F2178F4E1D7D", "name": "Accent Chairs" },
                  { "text": "Accent Tables", "countBy": "TypeIds", "id": "0853CA26-BC0C-4E95-97B0-11DDAD259D14", "name": "Accent Tables" },
                  { "text": "Center Tables", "countBy": "TypeIds", "id": "867C7E3A-F5D6-4DA3-86BB-A5C9BED72BB3", "name": "Center Tables"  },                  
                  { "text": "Cocktail Tables", "countBy": "TypeIds", "id": "51FF002B-9447-40A7-9663-BDD340CACD46", "name": "Cocktail Tables"  },
                  { "text": "Console Tables", "countBy": "TypeIds", "id": "A0C4861E-D11D-4E46-9B9C-A149DA6A5241", "name": "Console Tables"  },
                  { "text": "Game Chairs", "countBy": "TypeIds", "id": "E9F1BED1-4460-4C0C-A38C-36A146ABC8CC", "name": "Game Chairs"  },
                  { "text": "Game Tables", "countBy": "TypeIds", "id": "3A8D8463-C2C3-4326-BBDE-AE59E3035033", "name": "Game Tables"  },
                  { "text": "Made-to-Measure Upholstery", "countBy": "OptionGroupIds", "id": "A783B2ED-56EB-4FC1-B4BF-B92A76C1636B", "name": "M2M UPD Group"},
                  { "text": "Ottomans & Stools", "countBy": "TypeIds", "id": "85C9E07A-69B8-4A6E-82DB-9E3B8FC1B7F8", "name": "Ottomans & Stools"  },
                  { "text": "Sectionals", "countBy": "TypeIds", "id": "860887B4-667F-4C47-9975-95CD61DBFA4C", "name": "Sectionals"  },
                  { "text": "Side Tables", "countBy": "TypeIds", "id": "1A37737A-D388-4B8A-937F-723D57C1FE26", "name": "Side Tables"  },
                  { "text": "Sofas & Settees", "countBy": "TypeIds", "id": "40B3D638-DD97-4F67-81C3-0697C9A6D169", "name": "Sofas & Settees"  },
                  { "text": "Storage Cabinets", "countBy": "TypeIds", "id": "625F60D8-DE5B-4D28-B729-0880BE784CB7", "name": "Storage Cabinets"  },
                  { "text": "TV Cabinets", "countBy": "TypeIds", "id": "EB3CFA21-B389-40FA-87CC-E9D0070A8DC4", "name": "TV Cabinets"  }]
        },
        {
          "text": "Dining Room", "countBy": "RoomIds", "id": "02812CCF-77D7-4C48-92EC-3C1443C1A9B6", "name": "Dining Room",
          "items": [{ "text": "Bar & Counter Stools", "countBy": "TypeIds", "id": "860E766A-B580-416E-9AEC-38127BFE25B0", "name": "Bar & Counter Stools"  },
                { "text": "Bar & Pub Tables", "countBy": "TypeIds", "id": "49A794BE-99F5-4581-BA2D-34534B1D7625" , "name": "Bar & Pub Tables" },
                { "text": "Bar Cabinets", "countBy": "TypeIds", "id": "7057D4EB-8D32-4004-9229-1A2F34C29877" , "name": "Bar Cabinets" },
                { "text": "China & Curio Cabinets", "countBy": "TypeIds", "id": "C2699741-724F-45D7-8C2F-D1C37F9F90EA" , "name": "China & Curio Cabinets" },
                { "text": "Dining Chairs", "countBy": "TypeIds", "id": "3DB9E178-849F-4E5F-AFDF-2E6D5825A692" , "name": "Dining Chairs" },
                { "text": "Dining Tables", "countBy": "TypeIds", "id": "9EBEF7CF-E28B-489A-A71F-A3EE73D1280E" , "name": "Dining Tables" },
                { "text": "Sideboards & Buffets", "countBy": "TypeIds", "id": "B334C1E4-63D0-4866-877C-2705378FEB6C" , "name": "Sideboards & Buffets" }]
        },
        {
          "text": "Bedroom", "countBy": "RoomIds", "id": "DBE311AC-8E4F-4A72-BCD0-D60CE0F196D1","name": "Bedroom" ,
            "items": [
              { "text": "Benches", "countBy": "TypeIds", "id": "EEBE4A89-B362-4800-B5D1-993C4B84374D" , "name": "Benches" },
                { "text": "Beds", "countBy": "TypeIds", "id": "D1916303-3B74-467B-B317-39F3D18AA3F4" , "name": "Beds" },
                { "text": "Dressers & Chests", "countBy": "TypeIds", "id": "587B80BB-033B-4EC8-A6F5-76BFDACE621F" , "name": "Dressers & Chests" },
                { "text": "Nightstands & Bedside Tables", "countBy": "TypeIds", "id": "65915A8B-8208-450D-B00F-88BA5851B65F" , "name": "Nightstands & Bedside Tables" },
                { "text": "Vanity Chairs", "countBy": "TypeIds", "id": "AC3CBD23-2B74-4863-96AA-713F2502765F" , "name": "Vanity Chairs" },
                { "text": "Vanity Tables", "countBy": "TypeIds", "id": "8CC08B29-C34C-406E-B69D-228ED00CE193" , "name": "Vanity Tables" }]
        },
        {
          "text": "Office", "countBy": "RoomIds", "id": "E3185E73-CB9B-48C4-AFBE-2BFB757B217F", "name": "Office" ,
            "items": [{ "text": "Bookcases", "countBy": "TypeIds", "id": "F20043AC-6589-45FA-A825-D4AE79C50E0B" , "name": "Bookcases" },
                { "text": "Desk Chairs", "countBy": "TypeIds", "id": "01CC2D8A-D5C4-468D-9514-985D8BE4BC2C" , "name": "Desk Chairs" },
                { "text": "Etagères", "countBy": "TypeIds", "id": "8FF6D734-1CD5-4532-85F6-8BD7D3F03707" , "name": "Etagères" },
                { "text": "Filing", "countBy": "TypeIds", "id": "8A2A1FF6-58D9-45FE-87B4-4E83ADF1D13D" , "name": "Filing" },
                { "text": "Pedestal Desks & Bureaux", "countBy": "TypeIds", "id": "BC28EFD4-AD04-4042-92DA-BB8509E3DE5B" , "name": "Pedestal Desks & Bureaux" },
                { "text": "Writing Tables & Campaign Desks", "countBy": "TypeIds", "id": "E9868E70-58EF-4B09-ABF8-0AF14C7F9E11" , "name": "Writing Tables & Campaign Desks" }]
        },
        {
          "text": "Lighting", "countBy": "RoomIds", "id": "B7E020EC-84BF-4A2D-B250-6724EEEE3B10", "name": "Lighting" ,
            "items": [{ "text": "Ceiling Lighting", "countBy": "TypeIds", "id": "1E96B77F-3D47-4A67-B0E2-35251FB0B6E2" , "name": "Ceiling Lighting" },
                { "text": "Floor Lighting", "countBy": "TypeIds", "id": "CAEEBA01-F576-486C-8177-0427BB3A622E" , "name": "Floor Lighting" },
                { "text": "Table Lighting", "countBy": "TypeIds", "id": "7E95D203-CC65-42A1-9173-C5C0A71315AC" , "name": "Table Lighting" },
                //{ "text": "Wall Lighting", "countBy": "TypeIds", "id": "10B20C05-F790-4146-85E1-7686005121CE" , "name": "Wall Lighting" }
              ]
        },
        {
          "text": "Décor", "countBy": "RoomIds", "id": "C441230F-C2CE-4744-AAEC-40C0A942D79C", "name": "Décor" ,
            "items": [
              { "text": "Decorative Pillows", "countBy": "TypeIds", "id": "92A4FE6A-9A25-4B18-AC6F-CD17216D73B7" , "name": "Decorative Pillows" },
              { "text": "Free Standing Accessories", "countBy": "TypeIds", "id": "C117F99C-238B-4E8F-B3D4-64A9D36A3435" , "name": "Free Standing Accessories" },
                { "text": "Mirrors", "countBy": "TypeIds", "id": "77456121-455D-4E9B-9325-9A7CF952A94E" , "name": "Mirrors" },
                { "text": "Table Top Accessories", "countBy": "TypeIds", "id": "06FB509A-4025-438F-8C64-98B8E3BE4335" , "name": "Table Top Accessories" },
                { "text": "Wall Art", "countBy": "TypeIds", "id": "7750FC6D-261C-44EC-ABDA-D99AE153F28C" , "name": "Wall Art" }]
        }
      ];
      products.forEach((i:any) => {
        i.extra = {
          navigationObject : {
            countBy: i.countBy
          }
        }
        i.extra.navigationObject[i.countBy] = i.name;
        i.items.forEach(j => {
          j.extra = {
            navigationObject : {
              countBy: j.countBy
            }
          }
          j.extra.navigationObject[j.countBy] = j.name;
        })
      });
      return products;
  }
}
