
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.huyyya

export const environment = {
  DialogConfigWidth: "30%",
  production: false,
  name: "local_3.0",
  appApiUrl: "https://api3.theodorealexander.com",
  APINewURL: "https://api2.theodorealexander.com/",
  DomainImage: "https://api2.theodorealexander.com/",
  appWebUrl: "https://theodorealexander.com/",
  appBlogUrl: "https://theodorealexander.com/blog",
  TailorFitURL: "https://tailorfit.theodorealexander.com/",
  TailorFitLiveData: "https://libs.intiaro.com/integrations/theodorealexander/allProductsDataConfig_TA.json",
  TailorFitTestData: "assets/common/js/allProductsDataConfig_TA.json",
  appTitle: "Theodore Alexander",
  Room: "room",
  HomepageBanner001: 1,
  HomepageBanner002: 2,
  HomepageBanner003: 3,
  Brand: "brand",
  Designer: "designer",
  Collection: "collection",
  Shape: "shape",
  Type: "type",
  LifeStyle: "life-style",
  Style: "style",
  Search: "search",
  ProductDetail: "product-detail",
  ImageItem: "images/Item",
  NotFound: "not-found",
  TheodoreAlexanderBrandID: "D1483451-48E9-4FB4-BA11-FA8552084DFF",
  TAStudioBrandID: "87614FBC-9801-4312-A2D4-BC2F67C345AA",
  SaloneBrandID: "76CAF86D-B8A8-4416-9DA8-DB2A031F7D67",
  TailorFitID: "9901D1B1-5CD8-4764-A83C-1505B9C98156",
  TailorFitIDCollection: "41e10ecc-3aa2-4b31-1cef-08da3d4b8aa3",
  PageTitle: "Theodore Alexander is one of the finest luxury furniture brands in the world - theodorealexander.com",
  PageDescription: "Theodore Alexander is one of the finest luxury furniture brands in the world. We believe in using the most interesting materials for both function and beauty. Email: info@theodorealexander.com. Phone: (US) 336.885.5005. Fax: (US) 336.885.5260",
  PageLogo: "https://theodorealexander.com/assets/img/logo.svg",
  PageFacebook: "https://www.facebook.com/TheoAlex",
  PageTwitter: "https://twitter.com/theoalex",
  PageTwitterID: "@theoalex",
  appInsights: {
    instrumentationKey: '<your-guid>'
  },
  appSocialShare: {
    fb: "https://www.facebook.com/sharer/sharer.php?u=",
    pinteresr: "https://www.pinterest.com/pin/create/button",
    twitter: "https://twitter.com/intent/tweet"
  },
  appSocialLink: {
    facebook: 'https://www.facebook.com/TheoAlex',
    instagram: 'https://www.instagram.com/theodore_alexander_official',
    youtube: 'https://www.youtube.com/channel/UC1nO0CpfCMquPW05mTv5R4A',
    twiter: 'https://twitter.com/theoalex',
    pinterest: 'https://www.pinterest.com/theodore_alexander/_created/',
    linkedin: 'https://www.linkedin.com/company/theodore-alexander',
  },
  resource: {
    file: {
      sidebarFilter: 'https://api2.theodorealexander.com/RoomAndUsage/GetSideBarFilterViewModelByJSONFile'
    }
  },
  xmlResource: {
    menu: {
      brand: 'https://theodorealexander.sirv.com/website/frontend/live/assests/xml/Brand_Menu_V1.03.xml',
      designer: 'https://theodorealexander.sirv.com/website/Frontend/Live/assests/xml/Designer_Menu_V1.16.xml',
      custom: 'https://theodorealexander.sirv.com/website/frontend/live/assests/xml/MToM_Menu_V1.07.xml',
    },
    xml: {
      furnitureCare: 'https://theodorealexander.sirv.com/website/Frontend/Live/assests/xml/FurnitureCare.V1.06.xml',
      news: 'https://theodorealexander.sirv.com/website/Frontend/Live/assests/xml/News_V003.xml'
    },
    glossary: "https://theodorealexander.sirv.com/website/Frontend/Live/assests/xml/Glossary_V1.00.xml"
  },
  firebaseConfig: {
    apiKey: "AIzaSyDqnhaCNr38AUnW8_VEeyQTvocL0R_YpwU",
    authDomain: "auth.theodorealexander.com",
    databaseURL: "https://single-howl-267808.firebaseio.com",
    projectId: "single-howl-267808",
    storageBucket: "single-howl-267808.appspot.com",
    messagingSenderId: "86190713103",
    appId: "1:86190713103:web:bea3797906af8135f7b67a",
    measurementId: "G-TV6QXKNC14"
  }
}
