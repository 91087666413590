import { Component, OnInit, AfterViewChecked, ChangeDetectorRef, Input, ɵflushModuleScopingQueueAsMuchAsPossible } from '@angular/core';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { PricingService } from 'src/app/shared/pricing.service';
import { StepByStepService } from 'src/app/shared/step-by-step.service';

import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { IntiaroConfigurationService } from 'src/app/shared/IntiaroConfiguration.service';
import { DomSanitizer } from '@angular/platform-browser';
import { BehaviorSubject } from 'rxjs';

declare let getIntiaroPlayer: any;

@Component({
  selector: 'app-summary',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css'],
})
export class SummaryComponent implements OnInit, AfterViewChecked {

  @Input() productId: number = 0;
  @Input() showPricing = true;
  showPrice = new BehaviorSubject<boolean>(true);

  public faArrowLeft = faArrowLeft;
  public printButtonText = 'Download PDF';
  public showButton: string = 'print';
  public urlToPdf = '';
  public price: number = 0;
  private note: string = "";
  public showEmailModal = false;
  private flagNewNote = false;
  public showQuestionModal = false;
  private clickedEmail = false;
  private clickedPrint = false;
  private customerDecision = false;
  private subscription: any;
  public showLoader: boolean = true;

  private _player: any = false;
  private menuConfiguration: any;
  private productSystemVersion: any;
  public displayableProductConfiguration: any;
  public arrayFromMap: any;
  private get player(): any {
    if (!this._player) {
      this._player = getIntiaroPlayer('Intiaro-configurator');
    }
    return this._player;
  }

  public printedAttributesWithChoices: any;

  public data: any;
  isShowSave: boolean = false;
  isShowLoading: boolean = false;
  loadingURL: string = "https://theodorealexander.com/images/loading.gif";
  cssBackGround: any;
  cssDim: any;
  public isShowPrice: boolean = false;
  constructor(
    private stepByStepService: StepByStepService,
    private cdr: ChangeDetectorRef,
    private pricingService: PricingService,
    private intiaroConfigurationService: IntiaroConfigurationService,
    private sanitizer: DomSanitizer,
  ) {
    let windowHeight = Math.floor(window.innerHeight);
    let windowWidth = Math.floor(window.innerWidth);
    let top = (windowHeight - 200) / 2;
    let left = (windowWidth - 200) / 2;
    this.cssDim = 'z-index:2000; position:absolute; top:' + top + 'px;left:' + left + 'px;';
    this.cssDim = this.sanitizer.bypassSecurityTrustStyle(this.cssDim);

    this.cssBackGround = 'width:100%; height:200vh; z-index:1000; background-color: #eeeeee; opacity: 0.5; position:absolute; top:0px; left:0px;';
    this.cssBackGround = this.sanitizer.bypassSecurityTrustStyle(this.cssBackGround);

    this.showPricing ? this.showPrice.next(true) : this.showPrice.next(false);
  }

  async ngOnInit() {
    TaUniversalHubSubject.userCredentialSubjectData.subscribe(credential => {
      if (credential?.UserName) {
        this.isShowPrice = true;
      } else {
        this.isShowPrice = false;
      }
    });
    this.price = this.stepByStepService.price;
    TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      if (credential) {
        if (credential?.UserId) {
          if (credential?.UserId != TAUtility.emtyGuid()) {
            this.isShowSave = true;
          }
        }
      }
    });
  }

  async ngAfterViewInit() {
    this.player.getProductOptions(
      true,
      (options: any) => { this.printProductFinalOptions(options) },
      (err: any) => { console.log('Error: IntiaroPlayer.getProductOptions()', err) }
    )

    this.showLoader = false;
    this.player.getProductSummaryUrl(
      [40, 120, 230],
      (url: any) => {
        this.intiaroConfigurationService.formData.PDF = url;
      },
      () => {
      }
    );
  }


  ngAfterViewChecked() {
    this.subscription = this.stepByStepService.getStatusCustomerRequest().subscribe(status => {
      this.customerDecision = status;
      if (status && this.clickedEmail) this.sendEmail();
      if (status && this.clickedPrint) this.print();
    })
    // this.pricingService.checkPrice().subscribe(priceFromService => {
    //   this.price = priceFromService
    // })
    this.pricingService.checkPrice().subscribe(priceFromService => {
      this.price = priceFromService;
      (priceFromService == undefined || priceFromService == 0) ? this.showPrice.next(false) : this.showPrice.next(true);
    })
    this.cdr.detectChanges();
  }

  public saveNotes(event: any): void {
    let newNote = event.target.value;
    this.flagNewNote = true;
    if (newNote === this.note || newNote === '') {
      this.flagNewNote = false
    }
    this.note = newNote;
    this.stepByStepService.saveCommentFromSummary(this.note);
    this.stepByStepService.toggleStatusCustomerRequest(false);
  }

  public sendEmail(): void {
    if (this.flagNewNote && !this.customerDecision) {
      this.showQuestionModal = true;
      this.clickedEmail = true;
      this.clickedPrint = false;
    } else {
      if (this.player) {
        this.intiaroConfigurationService.formData.ConfigurationId = this.player.data.configurationId;
        this.intiaroConfigurationService.formData.CustomerId = this.player.data.customerId;
        this.intiaroConfigurationService.formData.ProductId = this.player.data.productId;
        this.intiaroConfigurationService.formData.AttributesString = this.player.attributesString;
        this.intiaroConfigurationService.formData.ProductInstanceId = this.player.data.productInstanceId;
        this.intiaroConfigurationService.formData.ProductName = this.player.data.menuSettings.name;
        this.intiaroConfigurationService.formData.Note = this.note;
        this.intiaroConfigurationService.formData.Price = this.price;

        this.data = this.intiaroConfigurationService.formData;
        this.showEmailModal = true;
        this.flagNewNote = false;
      }
    };
  }

  public print(): void {
    if (this.flagNewNote && !this.customerDecision) {
      this.showQuestionModal = true;
      this.clickedPrint = true;
      this.clickedEmail = false;
    } else {
      this.showButton = 'loader';
      this.player.getProductSummaryUrl(
        [40, 120, 230],
        (url: any) => {
          this.showButton = 'download';
          this.urlToPdf = url;
          console.log(url);
          window.location.href = url;
        },
        () => {
          this.showButton = 'loader';
          this.printButtonText = 'No PDF to print';
        }
      );

      this.flagNewNote = false;
      this.subscription.unsubscribe();
      this.stepByStepService.toggleStatusCustomerRequest(false)
    };
  }

  public closeModal(close: boolean): void {
    this.subscription.unsubscribe();
    if (close) {
      let statusFromService = this.customerDecision
      statusFromService && (this.stepByStepService.toggleStatusCustomerRequest(false));
      this.showEmailModal = false;
      this.showQuestionModal = false;
    }
  }

  public closeSummary(): void {
    this.stepByStepService.toggleStatusSummary(false)
  }

  public printProductFinalOptions(options: any) {
    let attributesWithChoices: { [key: string]: string } = {};
    options.elements.forEach((element: any) => {
      element.attributes.forEach((attribute: any) => {
        attributesWithChoices[attribute.name] ? '' : attributesWithChoices[attribute.name] = attribute.choice.name
      })
    })
    this.printedAttributesWithChoices = attributesWithChoices
  }

  public onSave(): void {
    if (this.player) {
      this.intiaroConfigurationService.formData.ConfigurationId = this.player.data.configurationId;
      this.intiaroConfigurationService.formData.CustomerId = this.player.data.customerId;
      this.intiaroConfigurationService.formData.ProductId = this.player.data.productId;
      this.intiaroConfigurationService.formData.SKU = this.player.data.menuSettings.name;
      this.intiaroConfigurationService.formData.ProductThumbnail = this.player.framesDictionary ? Object.values(this.player.framesDictionary)[0] as string : "";
      this.intiaroConfigurationService.formData.AttributesString = this.player.attributesString;
      this.intiaroConfigurationService.formData.ProductInstanceId = this.player.data.productInstanceId;
      this.intiaroConfigurationService.formData.FrameDictionaries = this.player.framesDictionary ? [...Object.values(this.player.framesDictionary)] as [] : [];
      this.intiaroConfigurationService.formData.FrameDictionary = this.player.framesDictionary ? Object.values(this.player.framesDictionary)[0] as string : "";
      // this.intiaroConfigurationService.formData.ClientIdentifier = this.player.data.productInstanceData.client_identifier;
      // this.intiaroConfigurationService.formData.MenuConfigurationVersion = this.player.data.productInstanceData.menu_configuration_version;
      // this.intiaroConfigurationService.formData.ProductConfigurationVersion = this.player.data.productInstanceData.product_configuration_version;
      // this.intiaroConfigurationService.formData.ProductSystemVersion = this.player.data.productInstanceData.product_system_version;
      this.intiaroConfigurationService.formData.ProductName = this.player.data.menuSettings.name;
      this.intiaroConfigurationService.formData.Note = this.note;
      this.intiaroConfigurationService.formData.Price = this.price;
      this.isShowLoading = true;
      this.player.getProductSummaryUrl(
        [40, 120, 230],
        (url: any) => {
          this.intiaroConfigurationService.formData.PDF = url;
          TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
            if (credential) {
              this.intiaroConfigurationService.formData.UserID = credential?.UserId;
              this.intiaroConfigurationService.asyncSave(this.intiaroConfigurationService.formData).subscribe(res => {
                console.log(res.Data);
                if (TAUtility.isAPIRespondOk(res)) {
                  this.isShowLoading = false;
                  alert("Save success. Code is " + this.intiaroConfigurationService.formData.ConfigurationId + "");
                }
                else {
                  this.isShowLoading = false;
                  alert("Save not success. Please try again.");
                }
              }, error => {
                this.isShowLoading = false;
                alert("Save not success. Please try again.");
              });
            }
          });

        },
        () => {

        }
      );
    }
  }

  private checkSelectedAttributes(attributesMap: Map<any, any>) {
    // el in selectedAtributes [slug of choice that is checked, slug of attribute that should be removed if there is slug of choice]
    let selectedAtributes = [
      ['ta_nailheads_none', 'ta_nailheads_finish'],
      ['ta_base_style_floating', 'ta_finish'],
      ['ta_base_style_kick_pleat_skirt', 'ta_finish'],
      ['ta_base_style_tapered_acrilic', 'ta_finish'],
      ['ta_base_style_waterfall_skirt', 'ta_finish']
    ]
    selectedAtributes.forEach((attribute: string[]) => {
      attributesMap.forEach((el: any) => {
        el == attribute[0] ? attributesMap.delete(attribute[1]) : '';
      })
    })
    return attributesMap;
  }
}
