import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaApiServiceXML } from 'src/app/service/api/TaApiServiceXML';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { GoogleAnalyticEventAction, GoogleAnalyticEventCategory, TAUtility } from 'src/app/theoalex/theoalex';
import { Router } from '@angular/router';
import { TaMetadata } from 'src/app/theoalex/TaMetadata';
import { HeaderMobileWithMenuComponent } from '../../header/header-mobile/header-mobile-with-menu/header-mobile-with-menu.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RalphLaurenInquireFormComponent } from '../../ralph-lauren-inquire-form/ralph-lauren-inquire-form.component';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { RoomAndUsage } from 'src/app/shared/RoomAndUsage.model';
import { RoomAndUsageService } from 'src/app/shared/RoomAndUsage.service';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { Designer } from 'src/app/shared/Designer.model';
import { DesignerService } from 'src/app/shared/Designer.service';
import { environment } from 'src/environments/environment';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
@Component({
  selector: 'app-menu-mobile',
  templateUrl: './menu-mobile.component.html',
  styleUrls: ['./menu-mobile.component.scss']
})
export class MenuMobileComponent implements OnInit, AfterViewInit, iTaObserverComponent, OnDestroy {
  GoogleAnalyticEventAction = GoogleAnalyticEventAction;
  GoogleAnalyticEventCategory = GoogleAnalyticEventCategory;
  selectedBrandItemIndex: number = -1;
  menuDesignerSubscriber: Subscription;
  menuBrands = [];
  menuProducts = [];
  menuDesigners = [];
  menuCustomPalette: any;

  isActive: boolean = true;
  collection: string = environment.Collection;
  type: string = environment.Type;
  brand: string = environment.Brand;
  designer: string = environment.Designer;
  domainName: string = environment.appWebUrl;
  tailorfitSubDomain: string = environment.TailorFitURL;
  isUsShow: boolean = false;
  userAuthenticCheckedEventSubscription: Subscription;
  userId: string;
  constructor(
    private parent: HeaderMobileWithMenuComponent,
    private serviceMenu: TaApiServiceXML,
    private router: Router,
    private modal: NgbModal,
    public collectionService: CollectionService,
    public roomAndUsageService: RoomAndUsageService,
    public typeService: TypeService,
    public designerService: DesignerService,
  ) {
    // this.getCollectionMenuItemsTheodoreAlexanderBrandID();
    // this.getCollectionMenuItemsTAStudioBrandID();
    // this.getCollectionMenuItemsSaloneBrandID();
    this.userAuthenticCheckedEventSubscription = TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      if (credential) {
        // let region = credential.Region ? credential.Region.toString() : localStorage.getItem("Region");
        // if (!region) {
        //   this.isUsShow = false;
        // } else if (region === 'TAUS') {
        //   this.isUsShow = true;
        // } else {
        //   this.isUsShow = false;
        // }
        this.userId = credential.UserId;
        if (this.userId === "00000000-0000-0000-0000-000000000000") {
          this.userId = 'undefined';
        }
        this.getCollectionMenuItemsTheodoreAlexanderBrandID();
        // this.getCollectionMenuItemsTAStudioBrandID();
        // this.getCollectionMenuItemsSaloneBrandID();
        // this.getDesignerMenuItems();
        this.getRoomAndUsageCollectionMenuItems();
        this.getTypeCollectionMenuItems();
        // this.getDesignerMenuItems();
        this.getTailorFit();
      }
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();

    //Product menu
    setTimeout(() => {
      this.menuProducts = TaMetadata.getProductMenu();
    }, 0);
  }

  refreshAllMenu() {
    if (this.menuDesignerSubscriber) {
      this.menuDesignerSubscriber.unsubscribe();
    }
    this.menuDesignerSubscriber = this.serviceMenu.getMenuDesigner().subscribe((designers: any) => {
      this.menuDesigners = designers;
    });
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.menuDesignerSubscriber, this.userAuthenticCheckedEventSubscription);
  }

  subscriberEvents(): void {
    // this.refreshAllMenu();
  }
  getTailorFit() {
    this.typeService.getByID(environment.TailorFitID).subscribe(res => {
      this.typeService.formData = res.Data as Type;
    });
    this.collectionService.getByID(environment.TailorFitIDCollection).subscribe(res => {
      this.collectionService.formData = res.Data as Collection;
      if (this.collectionService.formData) {
        this.collectionService.formData.URL = environment.TailorFitURL;
      }
    });
  }
  getCollectionMenuItemsTheodoreAlexanderBrandID() {
    this.collectionService.getByBrand_IDAndIsActiveToList(environment.TheodoreAlexanderBrandID, this.isActive).subscribe(res => {
      this.collectionService.listMenuTop = res.Data as Collection[];
      // if (!this.isUsShow) {
      //   let tempCollection = res.Data as Collection[];
      //   this.collectionService.listMenuTop = tempCollection.filter(x => (x.ID !== 'a87be8ac-74bd-4e6d-8d27-08dae1736987' && x.ID !== 'dbb0ae40-9523-48b9-acc2-08dbfac49b2c'));
      // } else {
      //   this.collectionService.listMenuTop = res.Data as Collection[];
      // }
      if (this.collectionService.listMenuTop) {
        if (this.collectionService.listMenuTop.length) {
          for (let i = 0; i < this.collectionService.listMenuTop.length; i++) {
            if (this.collectionService.listMenuTop[i].ID.toLowerCase() == environment.TailorFitIDCollection.toLowerCase()) {
              this.collectionService.listMenuTop[i].URL = this.userId !== 'undefined' ? environment.TailorFitURL + "?uid=" + this.userId : environment.TailorFitURL;
            }
            else {
              this.collectionService.listMenuTop[i].URL = this.domainName + "collection/" + this.collectionService.listMenuTop[i].URLCode + ".html";
            }
          }
        }
      }
    });
  }
  getCollectionMenuItemsTAStudioBrandID() {
    this.collectionService.getByBrand_IDAndIsActiveToList(environment.TAStudioBrandID, this.isActive).subscribe(res => {
      this.collectionService.listMenuTop001 = (res.Data as Collection[]).sort((a, b) => Number(a.SortCode) - Number(b.SortCode));
    });
  }
  getCollectionMenuItemsSaloneBrandID() {
    this.collectionService.getByBrand_IDAndIsActiveToList(environment.SaloneBrandID, this.isActive).subscribe(res => {
      this.collectionService.listMenuTop002 = res.Data as Collection[];
    });
  }
  getRoomAndUsageCollectionMenuItems() {
    this.roomAndUsageService.getByIsActiveToList(this.isActive).subscribe(res => {
      this.roomAndUsageService.list = res.Data as RoomAndUsage[];
    });
  }
  getTypeCollectionMenuItems() {
    this.typeService.getByIsActiveToList(this.isActive).subscribe(res => {
      this.typeService.listMenuTop = res.Data as Type[];
    });
  }
  getDesignerMenuItems() {
    this.designerService.getByIsActiveToList(this.isActive).subscribe(res => {
      this.designerService.list = res.Data as Designer[];
    });
  }

  onRouterClick() {
    //close the menu when navigation
    if (this.parent) {
      this.parent.closeHeader();
    }
  }

  onBrandItemClick(index): void {
    this.selectedBrandItemIndex = index;
  }
  openRalphLaurenForm() {
    let modalRef = this.modal.open(RalphLaurenInquireFormComponent, {
      windowClass: "ngb-modal-window-ta-full-screen",
    })
    modalRef.componentInstance.modalRef = modalRef;
  }
}
