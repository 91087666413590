<div class="block_menu_desktop_news block_menu_desktop_item">
    <div class="block_menu_desktop_item_left">
        <ul>
            <li><a appTBDClick class="news_item" id="news_open_1">News & Event</a></li>
            <li><a appTBDClick class="news_item" id="news_open_2">Media Download</a></li>
        </ul>
    </div>
    <div class="block_menu_desktop_item_right">
        <div class="news_open_1 news_open" style="display: block">
            <div class="d-flex">
                <div class="block_img">
                  <a  appTBDClick="/product-listing" (click)="onRouterClick()"><img class="Sirv" style="max-width: 100%;" data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/News/1.jpg"></a>
                    <h3><a  appTBDClick="/product-listing" (click)="onRouterClick()">Lorem ipsum dolor sit amet consectetur</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nisi enim, dapibus eu nunc
                        ut, blandit malesuada sem...</p>
                </div>
                <div class="block_img"><a  appTBDClick="/product-listing" (click)="onRouterClick()"><img style="max-width: 100%;" class="Sirv" data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/News/2.jpg"></a>
                    <h3><a  appTBDClick="/product-listing" (click)="onRouterClick()">Lorem ipsum dolor sit amet consectetur</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nisi enim, dapibus eu nunc
                        ut, blandit malesuada sem...</p>
                </div>
                <div class="block_img"><a  appTBDClick="/product-listing" (click)="onRouterClick()"><img style="max-width: 100%;" class="Sirv" data-src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/News/3.jpg"></a>
                    <h3><a  appTBDClick="/product-listing" (click)="onRouterClick()">Lorem ipsum dolor sit amet consectetur</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nisi enim, dapibus eu nunc
                        ut, blandit malesuada sem...</p>
                </div>
            </div>
        </div>
        <div class="news_open_2 news_open">
            <div class="d-flex">
                <div class="block_img"><a  appTBDClick="/product-listing" (click)="onRouterClick()"><img style="max-width: 100%;" class="Sirv" data-src="/assets/img/new1.jpg"></a>
                    <h3><a  routerLink="/product-listing" (click)="onRouterClick()">Lorem ipsum dolor sit amet consectetur2</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nisi enim, dapibus eu nunc
                        ut, blandit malesuada sem... </p>
                </div>
                <div class="block_img"><a  appTBDClick="/product-listing" (click)="onRouterClick()"><img style="max-width: 100%;" class="Sirv" data-src="/assets/img/new2.jpg"></a>
                    <h3><a  appTBDClick="/product-listing" (click)="onRouterClick()">Lorem ipsum dolor sit amet consectetur2</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nisi enim, dapibus eu nunc
                        ut, blandit malesuada sem... </p>
                </div>
                <div class="block_img"><a  appTBDClick="/product-listing" (click)="onRouterClick()"><img style="max-width: 100%;" class="Sirv" data-src="/assets/img/new3.jpg"></a>
                    <h3><a  appTBDClick="/product-listing" (click)="onRouterClick()">Lorem ipsum dolor sit amet consectetur2</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse nisi enim, dapibus eu nunc
                        ut, blandit malesuada sem... </p>
                </div>
            </div>
        </div>
    </div>
</div>
