<div [class]="configuration?.type">
  <div mat-dialog-title [innerHTML]="configuration?.title"></div>
  <div mat-dialog-content>
    <div class="content_message" [innerHTML]="configuration?.message"></div>
  </div>
  <div mat-dialog-actions>
    <button *ngIf="configuration.type == 'confirm'" class="btn_cancel" (click)="onCancel()">{{configuration.onCancelText || 'Cancel'}}</button>
    <button class="btn_confirm" (click)="onConfirm()">{{configuration.onConfirmText || 'OK'}}</button>
  </div>
</div>
