import { Directive, HostListener, Input, Output, EventEmitter, NgModule } from '@angular/core';
import { GoogleAnalyticEventAction, GoogleAnalyticEventCategory, TAUtility } from '../theoalex/theoalex';
import { MatDialog, DialogPosition } from '@angular/material/dialog';
import { TaWishlistPopupComponent } from '../comp/ta-wishlist-popup/ta-wishlist-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzModalService } from 'ng-zorro-antd/modal';

@Directive({
  selector: '[tawEventTracker]'
})
export class TawEventTrackerDirective {
  @Input() tawEventTracker: {action: GoogleAnalyticEventAction|string, category: GoogleAnalyticEventCategory|string};

  constructor() { }

  @HostListener('click') function() {
    TAUtility.sendGoogleAnalyticEvent(this.tawEventTracker.action, this.tawEventTracker.category);
  };
}

@NgModule({
  declarations: [
    TawEventTrackerDirective
  ],
  imports: [
  ],
  exports:[
    TawEventTrackerDirective
  ]
})
export class TawEventTrackerDirectiveModule { }
