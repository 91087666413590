<div ngbDropdown class="taDropDown">
  <button class="taDropDown-button" ngbDropdownToggle>
    <span taDropDown-button-name [hidden]="!placeHolderText || !isShowPlaceHolder">{{placeHolderText}}</span>
    <span class="taDropDown-button-name" *ngIf="dropDownText" innerHTML="{{dropDownText + ': '}}"></span>
    <span [ngStyle]="{'text-transform': textTransform}" class="taDropDown-button-selected-name" *ngFor="let item of items" [hidden]="!item[itemSelectValueField]" [innerHTML]="item[itemDisplayTextField]"></span>
  </button>
  <div ngbDropdownMenu class="taDropDown-items">
    <button class="taDropDown-items-item" ngbDropdownItem *ngFor="let item of items" (click)="onItemSelected(item)" [innerHTML]="item[itemDisplayTextField]"></button>
  </div>
</div>
