<div class="navbar">
  <div #navbarWrapper class="navbar-items hiddenMenu">
    <div class="item" *ngFor="let step of stepByStepMenu; index as i" id="step-{{i}}">
      <div *ngIf="step.isAvailable" [id]="'menuTab-'+i" (click)="changeTab(i, step.slug)" [ngClass]="step.isActive ? 'activeTab' : ''">
        <p class="item-name" [ngClass]="step.isActive ? 'activeName' : ''">{{editStepName(step.name)}}</p>
        <p class="item-details" [ngClass]="step.isActive ? 'activeDetails' : ''">{{step.details}}</p>
      </div>
      <div class="vertical navVertival" *ngIf="step.isAvailable && i !== (stepByStepMenu.length - 1)"></div>
    </div>
  </div>
  <div class="navbar-button">
    <div class="button active" *ngIf="stepByStepMenu[0] && !stepByStepMenu[0]?.isActive" (click)="goToBackStep()"><fa-icon class="arrowIcon" [icon]="faArrowLeft"></fa-icon> Back</div>
    <div #vertical class="vertical"></div>
    <div #nextBtn id="nextBtn" class="button" [ngClass]="statusNext ? 'active' : 'unactive'" (click)="statusNext ? goToNextStep() : ''">Next <fa-icon class="arrowIcon" [icon]="faArrowRight"></fa-icon></div>
  </div>
</div>
