<!-- <div class="block_menu_desktop_products block_menu_desktop_item d-flex justify-content-between" *ngIf="products?.length > 0">
    <ul *ngFor="let parent of products">
        <li>
            <h1><a  skipLocationChange [routerLink]="['/product-listing', parent.extra.navigationObject]" (click)="onRouterClick()">{{parent.text}}</a></h1>
        </li>
        <li *ngFor="let item of parent.items"><a  skipLocationChange [routerLink]="['/product-listing', item.extra.navigationObject]" (click)="onRouterClick()">{{item.text}}</a></li>
    </ul>
</div> -->

<div class="block_menu_desktop_products block_menu_desktop_item d-flex justify-content-between">
    <ul *ngFor="let item of roomAndUsageService.list">
        <li>
            <h1><a href="{{domainName}}{{room}}/{{item.URLCode}}.html" title="{{item.DisplayName}}">{{item.DisplayName}}</a></h1>
        </li>
        <ng-container *ngFor="let itemSub of typeService.listMenuTop">
            <li *ngIf="item.ID===itemSub.RoomAndUsage_ID">
                <a href="{{domainName}}{{type}}/{{itemSub.URLCode}}.html" title="{{itemSub.DisplayName}}">{{itemSub.DisplayName}}</a>
            </li>
        </ng-container>
    </ul>
</div>