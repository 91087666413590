import { Directive, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { TaApiServiceCommon, SubcribeEmailResp } from '../service/api/TaApiServiceCommon';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TAUtility } from '../theoalex/theoalex';

@Directive({
  selector: '[appEmailSubscriber]'
})
export class EmailSubscriberDirective implements OnDestroy {

  private subcribeEmailSubcriber: Subscription;

  constructor(private message: NzMessageService, private commonService: TaApiServiceCommon) {
  }

  ngOnDestroy(): void {
    TAUtility.unsubscribe(this.subcribeEmailSubcriber);
  }

  submitEmail(email: string) {
    this.message.loading("Subscribing...");
    this.subcribeEmailSubcriber = this.commonService.SubcribeEmail(email).subscribe((res: SubcribeEmailResp) => {
      this.message.remove();
      if (TAUtility.isAPIRespondOk(res)) {
        this.message.success("Subscribed successfully!");
      } else {
        this.message.error(`Subscribed fail! ${res.Data}`);
      }
    });
  }
}

