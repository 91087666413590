<div class="container">
  <div class="closeBox" (click)="closeQuestionModal(true)">
    <img src="assets/close-icon.svg" />
  </div>
  <div class="contentBox">
    <div class="text-small title">Is this a custom request?</div>
    <div>
      <button class="text-small formBtn btnDisplay btnYes" (click)="yesDecision()">YES</button>
    </div>
    <div>
      <button class="text-small formBtn btnDisplay btnNo" (click)="noDecision()">NO</button>
    </div>
  </div>
</div>
