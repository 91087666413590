import { Component, OnInit, ViewEncapsulation, AfterViewInit, OnDestroy } from '@angular/core';
import { TaApiServiceXML } from 'src/app/service/api/TaApiServiceXML';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { GoogleAnalyticEventAction, GoogleAnalyticEventCategory, TAUtility } from 'src/app/theoalex/theoalex';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RalphLaurenInquireFormComponent } from 'src/app/comp/ralph-lauren-inquire-form/ralph-lauren-inquire-form.component';
import { MenuComponent } from '../menu.component';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { Designer } from 'src/app/shared/Designer.model';
import { DesignerService } from 'src/app/shared/Designer.service';
import { environment } from 'src/environments/environment';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
@Component({
  selector: 'app-menu-brands',
  templateUrl: './menu-brands.component.html',
  styleUrls: ['./menu-brands.component.scss'],
  encapsulation: ViewEncapsulation.Emulated
})
export class MenuBrandsComponent implements OnInit, AfterViewInit, iTaObserverComponent, OnDestroy {

  GoogleAnalyticEventAction = GoogleAnalyticEventAction;
  GoogleAnalyticEventCategory = GoogleAnalyticEventCategory;

  selectedIndexBrand: number = 0;
  brands: any[] = [];
  iShowDesigner = false;
  menuDesignerSubcriber: Subscription;
  designers = [];

  isActive: boolean = true;
  collection: string = environment.Collection;
  brand: string = environment.Brand;
  designer: string = environment.Designer;
  domainName: string = environment.appWebUrl;
  listCollectionTheodoreAlexander001: Collection[] = [];
  listCollectionTheodoreAlexander002: Collection[] = [];
  listCollectionTheodoreAlexander003: Collection[] = [];
  listCollectionTheodoreAlexander004: Collection[] = [];
  listCollectionTAStudio001: Collection[] = [];
  listCollectionTAStudio002: Collection[] = [];
  isUsShow: boolean = false;
  userAuthenticCheckedEventSubscription: Subscription;
  userId: string;
  constructor(private serviceMenu: TaApiServiceXML,
    private modal: NgbModal,
    private menu: MenuComponent,
    public collectionService: CollectionService,
    public designerService: DesignerService,
  ) {
    setTimeout(() => {
      this.userAuthenticCheckedEventSubscription = TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
        if (credential) {
          // let region = credential.Region ? credential.Region.toString() : localStorage.getItem("Region");
          // if (!region) {
          //   this.isUsShow = false;
          // } else if (region === 'TAUS') {
          //   this.isUsShow = true;
          // } else {
          //   this.isUsShow = false;
          // }
          this.userId = credential.UserId;
          if (this.userId === "00000000-0000-0000-0000-000000000000") {
            this.userId = 'undefined';
          }
          this.getCollectionMenuItemsTheodoreAlexanderBrandID();
          // this.getCollectionMenuItemsTAStudioBrandID();
          // this.getCollectionMenuItemsSaloneBrandID();
          // this.getDesignerMenuItems();
        }
      });
    }, 0)
  }

  ngOnInit() {
    // let region = localStorage.getItem("Region");
    // if (!region) {
    //   this.isUsShow = false;
    // } else if (region === 'TAUS') {
    //   this.isUsShow = true;
    // } else {
    //   this.isUsShow = false;
    // }
    // this.getCollectionMenuItemsTheodoreAlexanderBrandID();
    // this.getCollectionMenuItemsTAStudioBrandID();
    // this.getCollectionMenuItemsSaloneBrandID();
    // this.getDesignerMenuItems();
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
  }

  subscriberEvents(): void {
    // this.refreshMenuDesigners();
  }

  refreshMenuDesigners() {
    if (this.menuDesignerSubcriber) {
      this.menuDesignerSubcriber.unsubscribe();
    }
    this.menuDesignerSubcriber = this.serviceMenu.getMenuDesigner().subscribe((designers: any) => {
      if (designers) {
        this.designers = designers;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.menuDesignerSubcriber, this.userAuthenticCheckedEventSubscription);
  }

  getCollectionMenuItemsTheodoreAlexanderBrandID() {
    // this.userId = localStorage.getItem("UserID");
    this.collectionService.getByBrand_IDAndIsActiveToList(environment.TheodoreAlexanderBrandID, this.isActive).subscribe(res => {
      if (!res) {
        return;
      }
      // if (!this.isUsShow) {
      //   let tempCollection = res.Data as Collection[];
      //   this.collectionService.listMenuTop = tempCollection.filter(x => (x.ID !== 'a87be8ac-74bd-4e6d-8d27-08dae1736987' && x.ID !== 'dbb0ae40-9523-48b9-acc2-08dbfac49b2c'));
      // } else {
      //   this.collectionService.listMenuTop = res.Data as Collection[];
      // }

      this.collectionService.listMenuTop = res.Data as Collection[];

      if (this.collectionService.listMenuTop) {
        if (this.collectionService.listMenuTop.length) {
          for (let i = 0; i < this.collectionService.listMenuTop.length; i++) {
            if (this.collectionService.listMenuTop[i].ID.toLowerCase() == environment.TailorFitIDCollection.toLowerCase()) {
              this.collectionService.listMenuTop[i].URL = this.userId !== 'undefined' ? environment.TailorFitURL + "?uid=" + this.userId : environment.TailorFitURL;
            }
            else {
              this.collectionService.listMenuTop[i].URL = this.domainName + "collection/" + this.collectionService.listMenuTop[i].URLCode + ".html";
            }
          }

          let count001 = this.collectionService.listMenuTop.length / 4;
          let count = Math.floor(count001);
          if (count < count001) {
            count = count + 1;
          }
          this.listCollectionTheodoreAlexander001 = [];
          this.listCollectionTheodoreAlexander002 = [];
          this.listCollectionTheodoreAlexander003 = [];
          this.listCollectionTheodoreAlexander004 = [];
          for (let i = 0; i < count; i++) {
            if (i >= this.collectionService.listMenuTop.length) {
              i = this.collectionService.listMenuTop.length - 1;
            }
            this.listCollectionTheodoreAlexander001.push(this.collectionService.listMenuTop[i]);
          }
          for (let i = count; i < count * 2; i++) {
            if (i >= this.collectionService.listMenuTop.length) {
              i = this.collectionService.listMenuTop.length - 1;
            }
            this.listCollectionTheodoreAlexander002.push(this.collectionService.listMenuTop[i]);
          }
          for (let i = count * 2; i < count * 3; i++) {
            if (i >= this.collectionService.listMenuTop.length) {
              i = this.collectionService.listMenuTop.length - 1;
            }
            this.listCollectionTheodoreAlexander003.push(this.collectionService.listMenuTop[i]);
          }
          for (let i = count * 3; i < this.collectionService.listMenuTop.length; i++) {
            if (i >= this.collectionService.listMenuTop.length) {
              i = this.collectionService.listMenuTop.length - 1;
            }
            this.listCollectionTheodoreAlexander004.push(this.collectionService.listMenuTop[i]);
          }
        }
      }
    });
  }
  getCollectionMenuItemsTAStudioBrandID() {
    this.collectionService.getByBrand_IDAndIsActiveToList(environment.TAStudioBrandID, this.isActive).subscribe(res => {
      if (!res) {
        return;
      }
      this.collectionService.listMenuTop001 = (res.Data as Collection[]).sort((a, b) => Number(a.SortCode) - Number(b.SortCode));
      this.collectionService.listMenuTop001.forEach((item, index) => {
        if ((index > -1) && (index < 5)) {
          this.listCollectionTAStudio001.push(item);
        }
        if ((index > 4) && (index < 10)) {
          this.listCollectionTAStudio002.push(item);
        }
      });
    });
  }
  getCollectionMenuItemsSaloneBrandID() {
    this.collectionService.getByBrand_IDAndIsActiveToList(environment.SaloneBrandID, this.isActive).subscribe(res => {
      if (!res) {
        return;
      }
      this.collectionService.listMenuTop002 = res.Data as Collection[];
    });
  }
  getDesignerMenuItems() {
    this.designerService.getByIsActiveToList(this.isActive).subscribe(res => {
      if (!res) {
        return;
      }
      this.designerService.list = res.Data as Designer[];
    });
  }
  onRouterClick() {
    //close the menu when navigation
    this.menu.closeAllMenu();
  }

  onBrandClick(index: number): void {
    this.selectedIndexBrand = index;
    this.iShowDesigner = false;
  }

  openRalphLaurenForm() {
    let modalRef = this.modal.open(RalphLaurenInquireFormComponent, {
      windowClass: "ngb-modal-window-ta-full-screen",
    })
    modalRef.componentInstance.modalRef = modalRef;
  }
}
