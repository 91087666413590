import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MarketingResource } from 'src/app/shared/MarketingResource.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class MarketingResourceService {
    list: MarketingResource[];
    formData: MarketingResource;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "MarketingResource";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.formData = {
        }
    }    
    getByParentIDAndActiveToList(parentID: number, active: boolean): Observable<TaAPIResponse<MarketingResource>> {
        let url = this.aPIURL + this.controller + '/GetByParentIDAndActiveToList';
        const params = new HttpParams()
        .set('parentID', JSON.stringify(parentID))
        .set('active', JSON.stringify(active))
        return this.httpClient.get<TaAPIResponse<MarketingResource>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
    getByActiveToList(active: boolean): Observable<TaAPIResponse<MarketingResource>> {
        let url = this.aPIURL + this.controller + '/GetByActiveToList';
        const params = new HttpParams()        
        .set('active', JSON.stringify(active))
        return this.httpClient.get<TaAPIResponse<MarketingResource>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
    getByActiveAndRegionToList(active: boolean, region: string): Observable<TaAPIResponse<MarketingResource>> {
        let url = this.aPIURL + this.controller + '/GetByActiveAndRegionToList';
        const params = new HttpParams()        
        .set('active', JSON.stringify(active))
        .set('region', region)
        return this.httpClient.get<TaAPIResponse<MarketingResource>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
}

