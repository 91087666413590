import { Component, OnInit, AfterViewInit, Inject, OnDestroy } from '@angular/core';
import { TaApiWishlistService, TaAPIWishlistData } from 'src/app/service/api/TaApiWishlist.service';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as _ from "lodash";
import { TAProductDetailViewModel } from 'src/app/service/api/TaApiServiceProduct';
import { LoginPopupDirective } from 'src/app/directive/login-popup.directive';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { AddProductWishListPopupDirectiveConfig } from 'src/app/directive/add-wishlist-popup.directive';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.scss']
})
export class WishListComponent implements OnInit {

  isLogin: boolean;
  wishlist: any[];
  selectedWishlist: any;
  newWishlistName: string;
  isAdded: boolean = false;
  userAuthenticCheckedEventSubscriber: Subscription;
  getWishlistSubscriber: Subscription;
  addProductIntoWishlist: Subscription;
  createNewWishlistSubscribe: Subscription;
  product: AddProductWishListPopupDirectiveConfig;
  modalRef: NzModalRef;

  constructor(private loginService: TaApiServiceLogin, private serviceWishlist: TaApiWishlistService, private dialog: NgbModal, private modalService: NzModalService) {
  }

  ngOnInit() {
    // this.modalRef.afterClose.subscribe(()=>{
    //   this.modalRef.close(this.isAdded);
    // });
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
    if (this.isLogin) {
      this.getWishlist();
    } else {      
      //push item into anonymous wislist
      this.addProductIntoWishlist = this.serviceWishlist.addProductToWishlist("", "0bb9c41e-a15a-4292-9856-68969a3b20ad").subscribe();     
    }
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  subscriberEvents(): void {
    this.userAuthenticCheckedEventSubscriber = TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      this.isLogin = !!credential?.UserName;
      if (credential?.UserName) {
        this.getWishlist();
      }
    });
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userAuthenticCheckedEventSubscriber, this.createNewWishlistSubscribe, this.getWishlistSubscriber);
  }

  getWishlist() {
    this.getWishlistSubscriber = this.serviceWishlist.getUserWishlist().subscribe((res: any) => {
      if (TAUtility.isAPIRespondOk(res)) {
        this.wishlist = res.Data;
        this.wishlist.forEach((list: TaAPIWishlistData) => {
          list.clientData = list.clientData || {};
          list.clientData.isAdded = !!_.find(list.ProductSKUList, sku => {
            return sku == "5006-048.C118";
          })
        })
      }
    });
  }

  onClickCreateNewWishlist() {
    this.createNewWishlistSubscribe = this.serviceWishlist.createWishlist(this.newWishlistName).subscribe((res: any) => {
      if (TAUtility.isAPIRespondOk(res)) {
        this.addProductToWishlist(res.Data);
      }
    })
  }

  onClickWishlist(selectedWishlist: TaAPIWishlistData) {
    if (!selectedWishlist.clientData.isAdded) {
      this.addProductToWishlist(selectedWishlist);
    }
  }

  private addProductToWishlist(wishlist: TaAPIWishlistData) {
    this.selectedWishlist = wishlist;
    this.isAdded = false;
    this.product.productId = "0bb9c41e-a15a-4292-9856-68969a3b20ad";
    this.addProductIntoWishlist = this.serviceWishlist.addProductToWishlist(wishlist.ID, "0bb9c41e-a15a-4292-9856-68969a3b20ad").subscribe(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        this.isAdded = true;
        this.getWishlist();
      }
    });
  }
}
