<div class="host">
  <div class="main_term">
    <div class="item1">
      <div class="result_search">
        <div>
          <div class="result_not_found" style="padding-top: 60px; padding-bottom: 60px; text-align: center;">
            <div class="note_not_result">
              <span style="font-size: 50px; color: #cbcbcb; font-family: HurmeGeometricSans1-Thin;">OOPS! Page not found</span>                          
              <br />
              <p style="font-size: 20px;">We are sorry, but the page you requested was not found</p>
              <p style="font-size: 20px;">Please visit the URL alternate <a style="color: #eea501;"
                  href="{{URLAlternate}}" title="URL alternate">here</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>