import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { GoogleAnalyticEventAction, GoogleAnalyticEventCategory, TAUtility } from 'src/app/theoalex/theoalex';
import { MenuComponent } from '../menu.component';
import { environment } from 'src/environments/environment';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-menu-3d',
  templateUrl: './menu-3d.component.html',
  styleUrls: ['./menu-3d.component.scss']
})
export class Menu3dComponent implements OnInit, AfterViewInit, iTaObserverComponent, OnDestroy {
  GoogleAnalyticEventAction = GoogleAnalyticEventAction;
  GoogleAnalyticEventCategory = GoogleAnalyticEventCategory;
  domainName: string = environment.appWebUrl;
  tailorfitSubdomain: string = environment.TailorFitURL;
  constructor(
    private menu: MenuComponent,
    public typeService: TypeService,
    public collectionService: CollectionService,
    private router: Router
  ) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
  }


  subscriberEvents(): void {

  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
  }

  onRouterClick() {
    this.menu.closeAllMenu();
  }
}
