<div class="ta_brief" style="width: 100%;">
    <div>
        <div class="ta_brief_header">
            <h1 class="ta_brief_header-h1">Virtual Showroom Tour</h1>
        </div>

        <div class="row">

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=usGaWQdqnMU" target="_blank"
                        alt="TA Fall 2023 - Section 1" title="TA Fall 2023 - Section 1"><img style="object-fit: cover;"
                            src="../../../assets/img/virtual-tour_Section_1.jpg" title="TA Fall 2023 - Section 1"
                            alt="TA Fall 2023 - Section 1"><span>TA Fall 2023 - Section
                            1</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=SdBUmAAafM2" target="_blank"
                        alt="TA Fall 2023 - Section 2" title="TA Fall 2023 - Section 2"><img style="object-fit: cover;"
                            src="../../../assets/img/virtual-tour_Section_2.jpg" title="TA Fall 2023 - Section 2"
                            alt="TA Fall 2023 - Section 2"><span>TA Fall 2023 - Section
                            2</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=CGBMDAxugmw" target="_blank"
                        alt="TA Fall 2023 - Section 3" title="TA Fall 2023 - Section 3"><img style="object-fit: cover;"
                            src="../../../assets/img/virtual-tour_Section_3.jpg" title="TA Fall 2023 - Section 3"
                            alt="TA Fall 2023 - Section 3"><span>TA Fall 2023 - Section
                            3</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=uZxc8NNmJkf" target="_blank"
                        alt="TA Fall 2023 - Section 1" title="TA Fall 2023 - Section 1"><img style="object-fit: cover;"
                            src="../../../assets/img/virtual-tour_Section_4.jpg" title="TA Fall 2023 - Section 1"
                            alt="TA Fall 2023 - Section 1"><span>TA Fall 2023 - Section
                            4</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=mcPSwzEUo3p" target="_blank"
                        alt="Essence and TA Originals" title="Essence and TA Originals"> <img style="object-fit: cover;"
                            src="../../../assets/img/TA Originals_Living room.jpg" title="Essence and TA Originals"
                            alt="Essence and TA Originals">
                        <span>Essence and TA Originals</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=5sqXR5Hy19p" target="_blank" alt="Kesden"
                        title="Kesden"><img style="object-fit: cover;" src="../../../assets/img/Kesden_living.jpg"
                            title="Kesden" alt="Kesden">
                        <span>Kesden</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=PvnZEe5md67" target="_blank" alt="Hudson"
                        title="Hudson"><img style="object-fit: cover;" src="../../../assets/img/Hudson_dining room.jpg"
                            title="Hudson" alt="Hudson"><span>Hudson</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=462irnbcnBB" target="_blank" target="_blank" alt="Lido"
                        title="Lido"><img style="object-fit: cover;"
                            src="../../../assets/img/TA54047_TAS40006_TAS41006_TA61062.C305.jpg" title="Lido"
                            alt="Lido"><span>Lido</span></a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=PGzdC5Ew5xr" target="_blank" alt="Repose"
                        title="Repose"><img style="object-fit: cover;"
                            src="../../../assets/img/experience-the-repose-collection_20230417044415638173034552510894.jpg"
                            title="Repose" alt="Repose"><span>Repose</span> </a>
                </div>
            </div>

            <div class="col-6 col-lg-2 col-xl-2 text-center zoom-hover">
                <div class="hover-zoom" style="width: 100%; ">
                    <a href="https://my.matterport.com/show/?m=VJCH7is5bgr" target="_blank" alt="Semi-Custom Barstools"
                        title="Semi-Custom Barstools"><img style="object-fit: cover;"
                            src="../../../assets/img/Bar Stools.jpg" title="Semi-Custom Barstools"
                            alt="Semi-Custom Barstools"><span>Semi-Custom Barstools</span></a>
                </div>
            </div>
        </div>
    </div>
</div>