<div class="slider d-flex flex-column">
  <button class="close" matDialogClose><i class="icon-close"></i></button>
  <div class="swipper-main">
    <div class="slider_content d-flex flex-grow-1 overflow-hidden">
      <div #mainSwiper class="swiper-container position-relative" [swiper]="swipeConfig">
        <div class="swiper-wrapper">
          <div class="swiper-slide slider_item d-flex" *ngFor="let item of items">
            <picture class="h-100 w-100">
              <source [srcset]="item.desktop + '?w=1000'" media="(min-width: 992px)">
              <source [srcset]="item.mobile + '?w=500'">
              <img class='slider_content_item-image' [src]="item.desktop + '?w=100'">
            </picture>
            <div class="bg_tranfarent">
              <div class="slider_content_item_caption position-absolute">
                <div *ngIf="item.title" [routerLink]="item.buttonLink" class="slider_content_item_caption-title">
                  {{item.title}}
                </div>
                <div *ngIf="item.subtitle" class="slider_content_item_caption-subtitle">
                  {{item.subtitle}}
                </div>
                <a *ngIf="item.buttonText" class="slider_content_item_botton_style_3" [routerLink]="item.buttonLink">{{item.buttonText}}</a>
                <div *ngIf="item.text" class="slider_content_item_caption-text">
                  {{item.text}}
                </div>
                <a *ngIf="item.buttonText" class="slider_content_item_caption-button" [routerLink]="item.buttonLink">
                  {{item.buttonText}}
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="_TaGlobalCssSwiper_slider_navigation">
          <div class="_TaGlobalCssSwiper_slider_navigation_left">
            <div class="_TaGlobalCssSwiper_slider_navigation_left-button"></div>
          </div>
          <div class="_TaGlobalCssSwiper_slider_navigation_right">
            <div class="_TaGlobalCssSwiper_slider_navigation_right-button"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="swipper-thumb">
    <div class="slider_content d-flex flex-grow-1 overflow-hidden">
      <div #thumbSwiper class="swiper-container position-relative" [swiper]="swipeConfigThumb" (swiperClick)="onClickThumbItem()">
        <div class="swiper-wrapper">
          <div class="swiper-slide slider_item d-flex" *ngFor="let item of items">
            <picture class="h-100 w-100">
              <source [srcset]="item.desktop + '?w=500'" media="(min-width: 992px)">
              <source [srcset]="item.mobile + '?w=300'">
              <img class='slider_content_item-image' [src]="item.desktop + '?w=500'">
            </picture>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
