<div (click)="onClick()">
  <div style="z-index: 999; position: sticky; top: 0; background-color: #ffffff; position: -webkit-sticky;" #header>
    <!--Desktop-->
    <div class="d-none d-xl-block">
      <app-header></app-header>
      <app-system-message></app-system-message>
      <app-logo></app-logo>
      <mat-progress-bar class="position-absolute" [hidden]='!isHttpLoading' mode="indeterminate" color="primary"
        style="z-index: 1;">
      </mat-progress-bar>
      <app-menu></app-menu>
    </div>
    <!--Mobile-->
    <div class="d-xl-none">
      <app-header-mobile></app-header-mobile>
      <app-system-message></app-system-message>
      <mat-progress-bar class="position-absolute" [hidden]='!isHttpLoading' mode="indeterminate"></mat-progress-bar>
    </div>
  </div>
  <div class="flex-grow-1 main-content">
    <app-breadcrumb></app-breadcrumb>
    <router-outlet></router-outlet>
    
  </div>
  <app-footer></app-footer>
  <app-go-to-top></app-go-to-top>
</div>
