import { environment } from '../../../environments/environment';
import { Injectable } from '@angular/core';
import { TaUniversalHubSubject } from '../web/universal-hub.service';

@Injectable({
  providedIn: 'root'
})

export class TALocalStogareService {

  set(key: TALocalStogareKeys | string, value) {
    key = `${environment.name}_${key}`;
    localStorage.setItem(key, value||"");
  }

  get(key: TALocalStogareKeys | string) {
    //if not exists, return null
    key = `${environment.name}_${key}`;
    return localStorage.getItem(key);
  }

  remove(key: TALocalStogareKeys | string) {
    key = `${environment.name}_${key}`;
    localStorage.removeItem(key);
  }

  //#region Wishlist
  addProductToAnonymousWishlist(productId: string) {
    const anonymousWishlist: string[] = this.getAnonymousWishlist();

    let isExisted = anonymousWishlist.find(i=>i==productId);
    if (!isExisted) {
      anonymousWishlist.push(productId);
      this.set(TALocalStogareKeys.TAAnonymousWishlist, anonymousWishlist.join(","));
    }
  }
  clearAnonymousWishlist() {
    this.remove(TALocalStogareKeys.TAAnonymousWishlist);
  }
  getAnonymousWishlist(): string[] {
    const productIdsStr = this.get(TALocalStogareKeys.TAAnonymousWishlist);
    if (productIdsStr) {
      return productIdsStr.split(",");
    } else {
      return [];
    }
  }
  //#endregion Wishlist

  //#region Shopping Cart
  setItemToCart(cart: string){
    this.set(TALocalStogareKeys.TALocalStogareShoppingCart, cart);
  }
  getCartItems(): string {
    return this.get(TALocalStogareKeys.TALocalStogareShoppingCart);
  }
  clearShoppingCart() {
    this.remove(TALocalStogareKeys.TALocalStogareShoppingCart);
  }
  //#endregion Shopping Cart

/*
  static anonymousWishlist = {
    addProductToAnonymousWishlist: function (productId: string) {
      const anonymousWishlist: string[] = TALocalStogare.anonymousWishlist.getAnonymousWishlist();
      anonymousWishlist.push(productId);
      TALocalStogare.set(TALocalStogareKeys.TAAnonymousWishlist, anonymousWishlist.join(","));
    },
    clearAnonymousWishlist: function () {
      TALocalStogare.remove(TALocalStogareKeys.TAAnonymousWishlist);
    },
    getAnonymousWishlist: function (): string[] {
      const productIdsStr = TALocalStogare.get(TALocalStogareKeys.TAAnonymousWishlist);
      if (productIdsStr) {
        return productIdsStr.split(",");
      } else {
        return [];
      }
    }
  }
*/
/*
  static shoppingCart = {
    setItemToCart(cart: string){
      TALocalStogare.set(TALocalStogareKeys.TALocalStogareShoppingCart, cart);
    },
    getCartItems(): string {
      return TALocalStogare.get(TALocalStogareKeys.TALocalStogareShoppingCart);
    },
    clearShoppingCart() {
      TALocalStogare.remove(TALocalStogareKeys.TALocalStogareShoppingCart);
    },
  }
  */
}

export enum TALocalStogareKeys {
  TALocalStogareUserName = 'TALocalStogareUserName',
  TALocalStogareUserPassword = 'TALocalStogareUserPassword',
  TALocalStogareUserToken = 'TALocalStogareUserToken',
  TALocalStogareUserRemember = 'TALocalStogareUserRemember',
  TALocalStogareUserLogout = 'TALocalStogareUserLogout',
  TAAnonymousWishlist = "TaAnonymousWishlistProductIds",
  TALocalStogareShoppingCart = "TALocalStogareShoppingCart",
  TALocalStogareUserPreferenceDimensionUnit = "TALocalStogareUserPreferenceDimensionUnit",//value: in/cm
  TALocalStogareIsismissedMessage = "TALocalStogareIsismissedMessage",
  TAReloadTryCount = "TAReloadTryCount"
}


@Injectable({
  providedIn: 'root'
})

export class TALocalPreferenceService {

  constructor(private taLocalStorage: TALocalStogareService) {
    TaUniversalHubSubject.userPreferenceUnitDimensionChanged.subscribe(dimension =>{
      this.setDimensionUnit(dimension);
    })
  }

  getDimensionUnit(): DimensionUnits {
    let ret = DimensionUnits.IN;
    const unitStr = this.taLocalStorage.get(DimensionUnits.key);
    if (unitStr && unitStr == DimensionUnits.CM) {
      ret = DimensionUnits.CM;
    } else {
      //set default is inch
      this.setDimensionUnit(DimensionUnits.IN);
    }
    return ret;
  }

  setDimensionUnit(value: DimensionUnits) {
    this.taLocalStorage.set(DimensionUnits.key, value);
  }

  removeDimensionUnit() {
    this.taLocalStorage.remove(DimensionUnits.key);
  }
}

export enum DimensionUnits {
  IN = 'inches',
  CM = 'centimeters',
  key = "TAUserPreferenceDimensionUnit"
}

