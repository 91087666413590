import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IntiaroConfigurationAttributes } from 'src/app/shared/IntiaroConfigurationAttributes.model';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';

@Injectable({
    providedIn: 'root'
})
export class IntiaroConfigurationAttributesService {
    list: IntiaroConfigurationAttributes[] = [];
    formData: IntiaroConfigurationAttributes = {};
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "IntiaroConfigurationAttributes";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
    }
    getByUserIDToList(userID: string): Observable<TaAPIResponse<IntiaroConfigurationAttributes>> {
        let url = this.aPIURL + this.controller + '/GetByUserIDToList';
        const params = new HttpParams()            
            .set('userID', userID)
        return this.httpClient.get<TaAPIResponse<IntiaroConfigurationAttributes>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

