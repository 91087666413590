import { Component, Input, OnInit } from '@angular/core';
import { TaGhostBlogAuthor } from 'src/app/service/api/api-ghost-blog-content.service';

@Component({
  selector: 'app-ta-web-blog-comp-author',
  templateUrl: './ta-web-blog-comp-author.component.html',
  styleUrls: ['./ta-web-blog-comp-author.component.scss']
})
export class TaWebBlogCompAuthorComponent implements OnInit {

  @Input() author: TaGhostBlogAuthor;
  constructor() { }

  ngOnInit(): void {
  }

}
