import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Router, NavigationEnd } from '@angular/router';
import { TaApiServiceLogin } from 'src/app/service/api/TaApiServiceLogin';
import { LoginPageComponent } from '../login-page/login-page.component';
import { environment } from 'src/environments/environment';
import { RegisterPageComponent } from '../register-page/register-page.component';

@Component({
  selector: 'app-check-sign-up',
  templateUrl: './check-sign-up.component.html',
  styleUrls: ['./check-sign-up.component.scss']
})
export class CheckSignUpComponent implements OnInit {

  queryString: string = "";
  uRLReturn: string = "";
  constructor(
    private loginService: TaApiServiceLogin,
    private dialog: NgbModal,
    public router: Router,
  ) {
    this.router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        this.queryString = event.url;
        this.checkSignUp();
      }
    });    
  }

  ngOnInit(): void {
  }

  checkSignUp() {
    if (this.queryString.includes("index")) {
      this.uRLReturn = environment.appWebUrl + "index.html";
    }
    else {
      if (this.queryString.includes("about")) {
        this.uRLReturn = environment.appWebUrl + "about.html";
      }
      if (this.queryString.includes("contact")) {
        this.uRLReturn = environment.appWebUrl + "contact.html";
      }
      if (this.queryString.includes("site-map")) {
        this.uRLReturn = environment.appWebUrl + "site-map.html";
      }
      if (this.queryString.includes("dealer-locator")) {
        this.uRLReturn = environment.appWebUrl + "dealer-locator.html";
      }
      if(this.uRLReturn.length==0){
        this.queryString = this.queryString.replace("/checksignup/", "");
        if (this.queryString.split("_")[1]) {
          let parameter01 = this.queryString.split("_")[0];
          let parameter02 = this.queryString.split("_")[1];
          this.uRLReturn = environment.appWebUrl + parameter01 + "/" + parameter02 + ".html";
        }
      }      
    }
    if (this.loginService.isLogin) {
    }
    else {
      if (this.loginService.isLogin == false) {
        const dialogRef = this.dialog.open(RegisterPageComponent, {
          windowClass: 'ngb-modal-window-ta-full-screen'
        });
        dialogRef.result.then(() => {
          window.location.href = this.uRLReturn;
        }, () => {
          window.location.href = this.uRLReturn;
        });
        dialogRef.componentInstance.dialogRef = dialogRef;
      }
    }
  }
}
