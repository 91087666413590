<div class="block_menu_desktop_aboutus block_menu_desktop_item d-flex justify-content-center">
    <ul>
        <li><a href="{{domainName}}about.html" title="About Us">About Us</a></li>
        <!-- <li>
            <ngb-accordion #ngbAccordion>
                <ngb-panel id="custom-panel-0">
                  <ng-template ngbPanelHeader let-opened="opened">
                    <button ngbPanelToggle class="btn txt_question">News</button>
                    <button ngbPanelToggle class="btn">
                        <span [hidden]="ngbAccordion.isExpanded('custom-panel-0')" class="icon-arrow_menu_mobile"></span>
                        <span [hidden]="!ngbAccordion.isExpanded('custom-panel-0')" class="icon-arrow_dropdown"></span>
                    </button>

                  </ng-template>
                  <ng-template ngbPanelContent>
                    <div class="button_about">
                      <ul>
                          <li>
                              <a routerLink="/news" (click)="onRouterClick()">News & Event</a>
                          </li>
                          <li>
                              <a routerLink="/media-download" (click)="onRouterClick()">Media Download</a>
                          </li>
                      </ul>
                    </div>
                  </ng-template>
                </ngb-panel>
              </ngb-accordion>
        </li> -->
        <li><a href="{{domainName}}contact.html" title="Contact Us">Contact Us</a></li>
        <li><a routerLink="/web/term-of-use" (click)="onRouterClick()">Terms of Use</a></li>
        <li><a routerLink="/web/privacy" (click)="onRouterClick()">Privacy Policy</a></li>
        <!-- <li><a appTBDClick="/web/career" (click)="onRouterClick()">Careers</a></li> -->
    </ul>
</div>
