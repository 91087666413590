import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Shape } from 'src/app/shared/Shape.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class ShapeService {
    list: Shape[];
    listData: Shape[];
    formData: Shape;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "Shape";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
        this.listData = [];
        this.formData = {           
        };
    }
    getByURLCode(URLCode: string): Observable<TaAPIResponse<Shape>> {
        let url = this.aPIURL + this.controller + '/GetByURLCode';
        const params = new HttpParams()
            .set('URLCode', URLCode)
        return this.httpClient.get<TaAPIResponse<Shape>>(url, { params }).pipe(tap((res: any) => {
        }));
    }    
    getByIsActiveAndRegionToList(isActive: boolean, region: string): Observable<TaAPIResponse<Shape>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveAndRegionToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
            .set('region', region)
        return this.httpClient.get<TaAPIResponse<Shape>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByIsActiveToList(isActive: boolean): Observable<TaAPIResponse<Shape>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
        return this.httpClient.get<TaAPIResponse<Shape>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

