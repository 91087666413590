import { Directive, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TaUniversalHubSubject } from '../service/web/universal-hub.service';
import { Params } from '@angular/router';
import { Subscription } from 'rxjs';
import { TaApiServiceLogin, TaUserCredential } from '../service/api/TaApiServiceLogin';
import { TAUtility, UserRegions, UserTypes } from './theoalex';
import { NzMessageService } from 'ng-zorro-antd/message';
import { TaApiServiceCommon, SubcribeEmailResp } from '../service/api/TaApiServiceCommon';
import { TAFormFieldComponentConfig } from '../comp/address-form/address-form.component';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';

export declare interface iTaObserverComponent {
  subscriberEvents(): void;
  unsubscriberEvents(): void;
}

export interface iTaBreadcrumb {
  label: string;
  params?: Params;
  url: string;
}

@Directive()
export abstract class TaBreadcrumbPage implements OnDestroy, OnInit {

  constructor(protected route: ActivatedRoute, protected router: Router) { }

  ngOnInit(): void {
    this.buildBreadcrumb();
  }

  ngOnDestroy(): void {
    TaUniversalHubSubject.taBreadCrumbChanged.next();
  }

  /**
   * only return value for leaf route
   * */
  abstract buildBreadcrumForLeaf(): iTaBreadcrumb;

  buildBreadcrumb() {
    this.route.data.subscribe(() => {
      let breadcrumbs: iTaBreadcrumb[] = [];
      let currentRoute = this.route;
      do {
        console.log(currentRoute.routeConfig.data);
        if (currentRoute.routeConfig.data) {
          breadcrumbs.push({
            label: currentRoute.routeConfig.data.breadcrumb,
            url: currentRoute.routeConfig.data.link,
          })
        }
        currentRoute = currentRoute.parent;
      } while (currentRoute && currentRoute.component);
      let leafBreadcrumb = this.buildBreadcrumForLeaf();
      if (leafBreadcrumb) {
        breadcrumbs.push(leafBreadcrumb);
      }
      TaUniversalHubSubject.taBreadCrumbChanged.next(breadcrumbs);
    })

    //  //replace by the above
    // this.route.data.subscribe(data => {
    //   let breadcrumbs: iTaBreadcrumb[] = [];
    //   breadcrumbs.push({
    //     label: data?.breadcrumb,
    //     url: data?.link,
    //   })
    //   TaUniversalHubSubject.taBreadCrumbChanged.next(breadcrumbs);
    // });
  }
}

@Directive()
export class TaFormHandler {

  constructor(protected formBuilder: FormBuilder){}

  initForm(config: TAFormFieldComponentConfig): FormGroup {
    let formControls = {};
    for (let key in config) {
      config[key].validators = config[key]?.validators || {};
      let validators = Object.values(config[key]?.validators)?.map((v:string) => { return Validators[v]; });
      formControls[key] = new FormControl(config[key]?.value, validators);
    }
    return this.formBuilder.group(formControls);
  }

  validateForm(fg: FormGroup): boolean {
    let isValid: boolean = true;
    for (const i in fg.controls) {
      fg.controls[i].markAsDirty();
      fg.controls[i].updateValueAndValidity();
      isValid = isValid && fg.controls[i].valid;
    }
    return isValid;
  }
}


@Directive()
export abstract class TaSupportRequiredUserCredentialComponent implements iTaObserverComponent, AfterViewInit, OnDestroy {

  private userCredential$: Subscription;
  private userCredentialChanged$: Subscription;
  private getInfoDealer$: Subscription;

  isLogin: boolean = false;
  isDealer: boolean = false;
  isDesigner: boolean = false;
  isSaleAssociate: boolean = false;
  isConsumer: boolean = true;
  isAnonymous: boolean = true;
  isRegionTAUS: boolean = true;
  isRegionINTL: boolean = false;

  credential: TaUserCredential;
  infoDealer: any;
  infoDesigner: any;
  infoSaleAssociate: any;
  infoConsumer: any;
  infoAnonymous: any;

  constructor(protected serviceLogin: TaApiServiceLogin) {
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  subscriberEvents(): void {
    this.userCredential$ = TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      this.credential = credential;
      this.isLogin = !!credential?.UserName;
      this.isAnonymous = !credential?.UserName;
      this.isDealer = credential?.UserType?.toUpperCase() == UserTypes.Dealer.toUpperCase();
      this.isDesigner = credential?.UserType?.toUpperCase() == UserTypes.Designer.toUpperCase();
      this.isSaleAssociate = credential?.UserType?.toUpperCase() == UserTypes.SalesAssociate.toUpperCase();
      this.isConsumer = credential ? credential.UserType?.toUpperCase() == UserTypes.Consumer.toUpperCase() : true;
      this.isRegionINTL = credential?.Region?.toUpperCase() == UserRegions.INTL.toUpperCase();
      this.isRegionTAUS = credential ? credential?.Region.toUpperCase() == UserRegions.US.toUpperCase() : true;
      this.refreshUserInfo();
      this.taOnUserCredentialSubjectData();
    })

    this.userCredentialChanged$ = TaUniversalHubSubject.userCredentialSubjectChanged.subscribe((credential) => {
      this.taOnUserCredentialSubjectChanged();
    })
  }

  abstract taOnUserCredentialSubjectData(): void;
  abstract taOnUserCredentialSubjectChanged(): void;

  private refreshUserInfo() {
    this.getInfoDealer();
    this.getInfoDesigner();
    this.getInfoSaleAssociate();
    this.getInfoConsumer();
    this.getInfoAnonymous();
  }

  private getInfoDealer() {
    if (this.getInfoDealer$) {
      this.getInfoDealer$.unsubscribe();
    }
    if (this.isDealer) {
      this.getInfoDealer$ = this.serviceLogin.getDealerInfo().subscribe((info) => {
        if (TAUtility.isAPIRespondOk(info)) {
          this.infoDealer = info.Data;
        } else {
          this.infoDealer = null;
        }
      });
    } else {
      this.infoDealer = null;
    }
  }

  private getInfoDesigner() {
    if (this.isDesigner) {

    } else {
      this.infoDesigner = null;
    }
  }

  private getInfoSaleAssociate() {
    if (this.isSaleAssociate) {
    } else {
      this.infoSaleAssociate = null;
    }
  }

  private getInfoConsumer() {
    if (this.isConsumer) {
    } else {
      this.infoConsumer = null;
    }
  }

  private getInfoAnonymous() {
    if (this.isAnonymous) {
    } else {
      this.infoAnonymous = null;
    }
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userCredential$, this.getInfoDealer$, this.userCredentialChanged$);
  }
}
