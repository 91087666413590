import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TaMetadata } from 'src/app/theoalex/TaMetadata';
import { MenuComponent } from '../menu.component';
import { environment } from 'src/environments/environment';
import { RoomAndUsage } from 'src/app/shared/RoomAndUsage.model';
import { RoomAndUsageService } from 'src/app/shared/RoomAndUsage.service';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
@Component({
  selector: 'app-menu-products',
  templateUrl: './menu-products.component.html',
  styleUrls: ['./menu-products.component.scss']
})
export class MenuProductsComponent implements OnInit, AfterViewInit {

  region: string = "";
  products = [];
  type: string = environment.Type;
  room: string = environment.Room;
  domainName: string = environment.appWebUrl;
  isActive: boolean = true;
  constructor(
    private menu: MenuComponent,
    public roomAndUsageService: RoomAndUsageService,
    public typeService: TypeService,
  ) {
    TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      if (credential) {        
        this.region = credential?.Region;  
        this.getRoomAndUsageCollectionMenuItems();
        this.getTypeCollectionMenuItems();      
      }
    });
    
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.products = TaMetadata.getProductMenu();
    }, 0);
  }

  onRouterClick() {
    this.menu.closeAllMenu();
  }

  getRoomAndUsageCollectionMenuItems() {
    this.roomAndUsageService.getByIsActiveToList(this.isActive).subscribe(res => {
      this.roomAndUsageService.list = res.Data as RoomAndUsage[];
    });
  }
  getTypeCollectionMenuItems() {
    this.typeService.getByIsActiveToList(this.isActive).subscribe(res => {
      this.typeService.listMenuTop = res.Data as Type[];
    });
  }
}
