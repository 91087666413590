import { Component, Input, OnInit, ViewChild, NgModule } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SwiperConfigInterface, SwiperDirective, SwiperModule } from 'ngx-swiper-wrapper';
import { GhostBlog } from 'src/app/shared/GhostBlog.model';
import { GhostBlogService } from 'src/app/shared/GhostBlog.service';
import { NzMessageService } from 'ng-zorro-antd/message';
import { EmailSubscriberDirective } from 'src/app/directive/email-subscriber.directive';
import { TaApiServiceCommon } from 'src/app/service/api/TaApiServiceCommon';

@Component({
  selector: 'app-ta-web-blog-master-page',
  templateUrl: './ta-web-blog-master-page.component.html',
  styleUrls: ['./ta-web-blog-master-page.component.scss']
})

export class TaWebBlogMasterPageComponent implements OnInit {

  @ViewChild(SwiperDirective) swiper: SwiperDirective;
  swipeConfig: SwiperConfigInterface = {
    navigation: {
      nextEl: '._TaGlobalCssSwiper_slider_navigation_right',
      prevEl: '._TaGlobalCssSwiper_slider_navigation_left'
    },
    loop: true
  }

  domainName: string = "https://theodorealexander.com/";
  listGhostBlog001: GhostBlog[] = [];
  listGhostBlog002: GhostBlog[] = [];
  subscriberEmail: string;
  constructor(
    public ghostBlogService: GhostBlogService,
    message: NzMessageService, 
    commonService: TaApiServiceCommon,
  ) { 
    
  }

  ngOnInit(): void {
    this.getToList();
  }

  getToList() {
    this.ghostBlogService.getByActiveToList(true).subscribe(res => {
      this.ghostBlogService.list = res.Data as GhostBlog[];
      for (var i = 0; i < this.ghostBlogService.list.length; i++) {
        if (this.ghostBlogService.list[i].IsBanner == true) {
          this.listGhostBlog001.push(this.ghostBlogService.list[i]);
        }
        if (this.ghostBlogService.list[i].IsPopular == true) {
          this.listGhostBlog002.push(this.ghostBlogService.list[i]);
        }
      }
    });
  }
  onClickSubcribe() {   
  }
}
