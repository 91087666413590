import { Subscription } from "rxjs";
import { environment } from 'src/environments/environment';
import { TaUniversalHubSubject } from '../service/web/universal-hub.service';
import { stringify } from 'querystring';
import { TaAPIResponse } from "../service/api/TaApiServiceCommon";

export module TAUtility {

  export function emtyGuid(): string {
    return '00000000-0000-0000-0000-000000000000';
  }

  export function navigateToTailorFit(): boolean {
    let isLogin: boolean;
    this.userAuthenticCheckedEventSubscriber = TaUniversalHubSubject.userCredentialSubjectData.subscribe((credential) => {
      isLogin = !!credential?.UserName;
      const UserId = localStorage.getItem('UserID');
      const url = environment.TailorFitURL + '?u=' + btoa(UserId);
      if (isLogin) {
        isLogin = true;
        setTimeout(() => {
          window.location.href = url, true;
          return isLogin;
        });
      } else {
        isLogin = false;
        window.location.href = environment.TailorFitURL, true;
      }
    });
    return isLogin;
  }

  export function isShowTailorfitPrice() {
    let isLogin: boolean;

    return isLogin;
  }

  export function sendGoogleAnalyticEvent(action: GoogleAnalyticEventAction | string, category?: GoogleAnalyticEventCategory | string, label?: string, value: number = 1) {
    let gtag = (window as any).gtag;
    if (gtag) {
      let data: any;
      if (category) {
        data = {
          event_category: category,
          event_label: label,
          value: value
        }
      }
      gtag("event", action, data);
    }
  }

  export function buildAPIRespondOk<O>(dataValue: O, dataField?: string) {
    let returnedRespond: TaAPIResponse<O> = {
      Success: true,
      StatusCode: 200,
    };
    returnedRespond[dataField || 'Data'] = dataValue;
    return returnedRespond;
  }

  export function unsubscribe(...subscriptions: Subscription[]) {
    subscriptions.forEach((sub, i) => {
      if (sub && sub.unsubscribe) {
        sub.unsubscribe();
        TaUniversalHubSubject.httpLoadingChangedEvent.next(false);
      }
    });
  }

  export function includesBySeparator(wholeString: string, subString: string, separator?: string, isCaseSensitive?: boolean): boolean {
    let includes: boolean = false;
    if (wholeString && subString) {
      let strs = wholeString.split(separator || ",");
      let found = strs.find(s => {
        if (isCaseSensitive) {
          return s == subString;
        } else {
          return s.toUpperCase() == subString.toUpperCase();
        }
      })
      includes = !!found;
    }
    return includes;
  }

  export function includes(wholeString?: string, subString?: string, isCaseSensitive?: boolean): boolean {
    if (!wholeString || !subString) {
      return false;
    } else {
      if (isCaseSensitive) {
        return wholeString.includes(subString);
      } else {
        return wholeString.toUpperCase().includes(subString.toUpperCase());
      }
    }
  }

  export function equalString(str1: string, str2: string, isCaseSensitive?: boolean) {
    if (!str1 || !str2) {
      return false;
    } else {
      if (isCaseSensitive) {
        return str1 == str2;
      } else {
        return str1.toUpperCase() == str2.toUpperCase();
      }
    }
  }

  export function consoleLog(obj: any) {
    if (!environment.production) {
    }
  }

  export function booleanFromString(str: string): boolean {
    return !!(str && str.toUpperCase() == "TRUE");
  }

  export function isAPIRespondOk(res) {
    return res && res.Success && res.StatusCode == 200;
  }

  export function isAPIRespondNotFound(res) {
    return (res && !res.Success && res.StatusCode == 404);
  }

  export function isHttpRespondOk(res) {
    return (res && res.ok && res.status == 200);
  }

  export function convertInchToCM(inches): number {
    if (inches >= Number.MAX_SAFE_INTEGER) {
      return inches
    } else {
      return inches * 2.54;
    }
  }

  export function isExcludedByRegion(userRegion: UserRegions, parentName: string = "", itemId: string = ""): boolean {
    const excludedTable: any[] = [
      { region: UserRegions.US, parentName: "COLLECTIONS", itemName: "TA STUDIO FRENZY", itemId: "461DA43B-0409-4EBD-8EF2-0FB911718678" },
      { region: UserRegions.US, parentName: "COLLECTIONS", itemName: "TA STUDIO HOLLI", itemId: "85CFC3ED-8876-436E-8159-B1E1025644C8" },
      { region: UserRegions.US, parentName: "COLLECTIONS", itemName: "THE OASIS COLLECTION", itemId: "EAE0B15C-D6DE-4647-800B-94EFF1E79CAB" },
      { region: UserRegions.US, parentName: "COLLECTIONS", itemName: "THE ICONIC COLLECTION", itemId: "C71176E5-F7C3-4FFF-BD82-012C0517595E" },
      { region: UserRegions.US, parentName: "COLLECTIONS", itemName: "THE GRACE COLLECTION", itemId: "6DF04F1B-3D4D-4F14-AB0A-A8661752711E" },
      { region: UserRegions.INTL, parentName: "COLLECTIONS", itemName: "TA ACCENTS", itemId: "CD343060-5214-4BDC-9395-EF25904B2844" }
    ];
    let foundExclued = excludedTable.filter(i => {
      return i.region.toUpperCase() == userRegion.toUpperCase() && i.parentName == parentName.toUpperCase() && i.itemId == itemId.toUpperCase();
    })
    return !!foundExclued.length;
  }

  export function convertCMToInch(cm): number {
    return cm * 0.39370079;
  }

  export function removeSpecialCharacters(input): string {
    input = input || "";
    input = input.replace(/`/g, '');
    input = input.replace(/~/g, '');
    input = input.replace(/!/g, '');
    input = input.replace(/@/g, '');
    input = input.replace(/#/g, '');
    input = input.replace(/\$/g, '');
    input = input.replace(/%/g, '');
    input = input.replace(/\^/g, '');
    input = input.replace(/&/g, '');
    input = input.replace(/\*/g, '');
    input = input.replace(/\(/g, '');
    input = input.replace(/\)/g, '');
    input = input.replace(/-{2,}/g, ' ');//SKU includes -
    input = input.replace(/_/g, '');
    input = input.replace(/\+/g, '');
    input = input.replace(/=/g, '');
    input = input.replace(/\[/g, '');
    input = input.replace(/\]/g, '');
    input = input.replace(/\{/g, '');
    input = input.replace(/\}/g, '');
    input = input.replace(/\\/g, '');
    input = input.replace(/\|/g, '');
    input = input.replace(/;/g, '');
    input = input.replace(/:/g, '');
    //input = input.replace(/'/g, '');
    input = input.replace(/"/g, '');
    input = input.replace(/</g, '');
    input = input.replace(/,/g, '');
    input = input.replace(/>/g, '');
    input = input.replace(/\.{2,}/g, ' ');//SKU includes .
    input = input.replace(/\//g, '');
    input = input.replace(/\?/g, '');
    input = input.replace(/ {2,}/g, ' ');
    input = input.trim();
    return input;
  }

  export function getCountByText(text: string): string {
    let countByText: string = "";
    if (text && text.toUpperCase) {
      text = text.toUpperCase();
      switch (text) {
        case 'ROOMS':
        case 'ROOM':
        case 'LIVING ROOM':
        case 'LIVING ROOMS':
        case 'LIVINGROOM':
        case 'LIVINGROOMS':
        case 'DINING ROOM':
        case 'DINING ROOMS':
        case 'DININGROOM':
        case 'DININGROOMS':
        case 'BED ROOM':
        case 'BED ROOMS':
        case 'BEDROOM':
        case 'BEDROOMS':
        case 'OFFICE':
        case 'LIGHTING':
        case 'DÉCOR':
        case 'DECOR':
          countByText = 'TypeIds';
          break;
        case 'COLLECTION':
        case 'COLLECTIONS':
          countByText = 'CollectionIds';
          break;
        case 'LIFESTYLES':
        case 'LIFESTYLE':
        case 'LIFE STYLES':
        case 'LIFE STYLE':
          countByText = 'LifeStyleIds';
          break;
        case 'STYLES':
        case 'STYLE':
          countByText = 'StyleIds';
          break;
        case 'SHAPES':
        case 'SHAPE':
          countByText = 'ShapeIds';
          break;
        case 'EXTENDING':
        case 'EXTEND':
          countByText = 'Extending';
          break;
      }
    }
    return countByText;
  }


  export function generateSocialShareFacebook(targetLink: string): string {
    let shareLink = `${environment.appSocialShare.fb}${environment.appWebUrl}`;
    if (targetLink) {
      shareLink = `${environment.appSocialShare.fb}${environment.appWebUrl}${targetLink}`;
    }
    return shareLink;
  }

  export function generateSocialShareTwitter(targetLink: string, title: string): string {
    let shareLink = `${environment.appSocialShare.twitter}?&url=${environment.appWebUrl}`;
    if (targetLink) {
      shareLink = `${environment.appSocialShare.twitter}?text=${title}&url=${environment.appWebUrl}${targetLink}`;
    }
    return shareLink;
  }

  export function generateSocialSharePinterest(targetLink: string, imageUrl: string, description: string): string {
    let shareLink = `${environment.appSocialShare.pinteresr}?url=${environment.appWebUrl}`;
    if (targetLink) {
      shareLink = `${environment.appSocialShare.pinteresr}?url=${environment.appWebUrl}${targetLink}&media=${imageUrl}&description=${description}}`;
    }
    return shareLink;
  }

  export function generateSocialShareProductFacebook(productId: string): string {
    let shareLink = '';
    if (productId) {
      shareLink = `${environment.appSocialShare.fb}${environment.appWebUrl}/product-detail/${productId}`;
    }
    return shareLink;
  }

  export function generateSocialShareProductTwitter(productId: string, productName: string): string {
    let shareLink = '';
    if (productId) {
      shareLink = `${environment.appSocialShare.twitter}?text=${productName}&url=${environment.appWebUrl}/product-detail/${productId}`;
    }
    return shareLink;
  }

  export function generateSocialShareProductPinterest(productId: string, mainImage: string, description: string): string {
    let shareLink = '';
    if (productId) {
      shareLink = `${environment.appSocialShare.pinteresr}?url=${environment.appWebUrl}/product-detail/${productId}&media=${mainImage}&description=${description}}`;
    }
    return shareLink;
  }
}

export enum UserTypes {
  Dealer = 'Dealer', SalesAssociate = 'Sales Associate', Consumer = 'Consumer', Designer = 'Designer'
}

export enum UserRegions {
  INTL = 'international', US = 'taus'
}

export enum GoogleAnalyticEventAction {
  taLoad = 'taLoad',
  taHomeBannerInstock = "taHomeBannerInstock",
  taHomeBannerClassic = "taHomeBannerClassic",
  taHomeBannerTaStudio = "taHomeBannerTaStudio",
  taHomeBannerIntroduce = "taHomeBannerIntroduce",
  taHomeBannerTavel = "taHomeBannerTavel",
  taHomeBannerElegance = "taHomeBannerElegance",
  taHomeBannerM2M = "taHomeBannerM2M",
  taHomeBannerPalette = "taHomeBannerPalette",
  taHomeBannerSubscribe = "taHomeBannerSubscribe",
  taHomeBannerBlog = "taHomeBannerBlog",
  taBlogMasterCarousel = "taBlogMasterCarousel",
  taMenuBlog = "taMenuBlog",
  taMenuCustomUpholsteryAbout = "taMenuCustomUpholsteryAbout",
  taMenuCustomUpholsteryM2M = "taMenuCustomUpholsteryM2M",
  taMenuCustomUpholsteryOurFabric = "taMenuCustomUpholsteryOurFabric",
  taMenuCustomUpholsteryResource = "taMenuCustomUpholsteryResource",
  taMenuCustomPaletteAbout = "taMenuCustomPaletteAbout",
  taMenuCustomPaletteProgram = "taMenuCustomPaletteProgram",
  taMenuCustomPaletteOurPalette = "taMenuCustomPaletteOurPalette",
  taMenuCustomPaletteResource = "taMenuCustomPaletteResource",
  taListingInstockFilterYes = "taListingInstockFilterYes",
  //Default Google Analytics Events
  add_payment_info = "",
  add_to_cart = "",
  add_to_wishlist = "",
  begin_checkout = "",
  checkout_progress = "",
  generate_lead = "",
  login = "",
  purchase = "",
  refund = "",
  remove_from_cart = "",
  search = "",
  select_content = "",
  set_checkout_option = "",
  share = "",
  sign_up = "",
  view_item = "",
  view_item_list = "",
  view_promotion = "",
  view_search_results = "",
}

export enum GoogleAnalyticEventCategory {
  taUserAction = 'taUserAction'
}

export enum TawAccountProvider {
  web = 'web',//default in database
  google = 'google',
  facebook = 'facebook',
  twitter = 'twitter',
  yahoo = 'yahoo',
  microsoft = 'microsoft',
  apple = 'apple'
}
