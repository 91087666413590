<div class="line-top-mobile">
  <div style="display: flex; justify-content: center; align-items: center;">
    <div [appUserRoleAccessibility]="{userRole: 'Dealer,Designer', display:'inline-block'}">
      <app-ta-mat-slide-toggle-module></app-ta-mat-slide-toggle-module>
    </div>
    <!-- <a class="wishlist" routerLink="/account/account-wishlist">
      <i class="icon-heart_outline"></i><span>Wish Lists</span>
    </a> -->
    <a routerLink="/account/order-shoppingcart" [appUserRoleAccessibility]="{userRole:'Dealer,Designer', display:'block'}"
     class="button-cart"><i class="icon-Cart"></i><span class="number">{{shoppingCartCount}}
      </span></a>
  </div>
  <div style="display: flex; justify-content: center; align-items: center;">
    <div [hidden]="!isUserCredentialProviderAcquiring || loginUser?.UserName" style="font-size: 14px">Checking login...</div>
    <div [hidden]="isUserCredentialProviderAcquiring" class="right-login">
      <div class="login" *ngIf="!loginUser?.UserName" appLoginPopup>
        <i class="icon-user"></i><a>Login<i>&nbsp; / &nbsp;</i></a>
      </div>
      <div class="register" *ngIf="!loginUser?.UserName" appRegisterPopup><a>Sign up</a></div>
    </div>
    <div *ngIf="loginUser?.UserName" class="welcome" routerLink="account">
      <i class="icon-user"></i> Welcome, <span class="name"><b>{{loginUser.FirstName}}</b></span>
    </div>
  </div>
</div>
<div class="header">
  <div class="d-flex justify-content-between align-items-center">
    <div class="logo"><a tabindex="-1" href="/"><img src="/assets/img/logo_footer.svg"></a></div>
    <div class="buttons">

      <div class="button-search" (click)="openSearch()">
        <i class="icon-Search"></i>
      </div>
      <div class="button-open-menu" (click)="openMenu()">
        <i class="icon-menu-3"></i>
      </div>
    </div>
  </div>
</div>
