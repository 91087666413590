import { Injectable, Inject } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';
import { Type } from 'src/app/shared/Type.model';
import { Collection } from './Collection.model';
import { LifeStyle } from './LifeStyle.model';
import { Shape } from './Shape.model';
import { Item } from './Item.model';
import { RoomAndUsage } from './RoomAndUsage.model';
import { Brand } from './Brand.model';
import { Style } from './Style.model';
@Injectable({
    providedIn: 'root'
})

export class HeadService {
    public canonicalLink: HTMLLinkElement;
    constructor(
        private titleService: Title,
        private metaService: Meta,
        @Inject(DOCUMENT) private doc,
    ) { }
    public setPageHeadByType(type: Type) {
        if (type != null) {
            let title = type.Name + " - " + environment.PageTitle;
            let description = type.Description + " - " + environment.PageDescription;
            let keywords = type.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = type.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Type + "/" + type.URLCode + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByCollection(collection: Collection) {
        if (collection != null) {
            let title = collection.Name + " - " + environment.PageTitle;
            let description = collection.Description + " - " + environment.PageDescription;
            let keywords = collection.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = collection.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Collection + "/" + collection.URLCode + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByLifeStyle(lifeStyle: LifeStyle) {
        if (lifeStyle != null) {
            let title = lifeStyle.Name + " - " + environment.PageTitle;
            let description = lifeStyle.Description + " - " + environment.PageDescription;
            let keywords = lifeStyle.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = lifeStyle.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.LifeStyle + "/" + lifeStyle.URLCode + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByStyle(style: Style) {
        if (style != null) {
            let title = style.Name + " - " + environment.PageTitle;
            let description = style.Description + " - " + environment.PageDescription;
            let keywords = style.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = style.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Style + "/" + style.URLCode + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByShape(shape: Shape) {
        if (shape != null) {
            let title = shape.Name + " - " + environment.PageTitle;
            let description = shape.Description + " - " + environment.PageDescription;
            let keywords = shape.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = shape.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Shape + "/" + shape.URLCode + ".html";

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByRoomAndUsage(roomAndUsage: RoomAndUsage) {
        if (roomAndUsage != null) {
            let title = roomAndUsage.Name + " - " + environment.PageTitle;
            let description = roomAndUsage.Description + " - " + environment.PageDescription;
            let keywords = roomAndUsage.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = roomAndUsage.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Room + "/" + roomAndUsage.URLCode + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByBrand(brand: Brand) {
        if (brand != null) {
            let title = brand.Name + " - " + environment.PageTitle;
            let description = brand.Description + " - " + environment.PageDescription;
            let keywords = brand.METAKeyword + " - " + environment.PageDescription;
            let news_keywords = brand.METAKeyword + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Brand + "/" + brand.URLCode + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
    public setPageHeadByExtending(extending: string) {
        if (extending != null) {
            let title = extending + " - " + environment.PageTitle;
            let description = extending + " - " + environment.PageDescription;
            let keywords = extending + " - " + environment.PageDescription;
            let news_keywords = extending + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + extending.toLowerCase() + ".html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }   
    public setPageHeadByFilters(filters: string) {
        if (filters != null) {
            let title = filters + " - " + environment.PageTitle;
            let description = filters + " - " + environment.PageDescription;
            let keywords = filters + " - " + environment.PageDescription;
            let news_keywords = filters + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/filters/;/;/;/;/;/;/;";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }   
    public setPageHeadByIsActive(isActive: string) {
        if (isActive != null) {
            let title = isActive + " - " + environment.PageTitle;
            let description = isActive + " - " + environment.PageDescription;
            let keywords = isActive + " - " + environment.PageDescription;
            let news_keywords = isActive + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/new-products.html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }  
    public setPageHeadByIsCFPItem(isCFPItem: string) {
        if (isCFPItem != null) {
            let title = isCFPItem + " - " + environment.PageTitle;
            let description = isCFPItem + " - " + environment.PageDescription;
            let keywords = isCFPItem + " - " + environment.PageDescription;
            let news_keywords = isCFPItem + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/custom-palette.html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }  
    public setPageHeadByIsStocked(isStocked: string) {
        if (isStocked != null) {
            let title = isStocked + " - " + environment.PageTitle;
            let description = isStocked + " - " + environment.PageDescription;
            let keywords = isStocked + " - " + environment.PageDescription;
            let news_keywords = isStocked + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/instock.html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }  
    public setPageHeadByCasualLiving(casualLiving: string) {
        if (casualLiving != null) {
            let title = casualLiving + " - " + environment.PageTitle;
            let description = casualLiving + " - " + environment.PageDescription;
            let keywords = casualLiving + " - " + environment.PageDescription;
            let news_keywords = casualLiving + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/casual-living.html";

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }  
    public setPageHeadBySearchString(searchString: string) {        

        let title = searchString + " - " + environment.PageTitle;
        let description = searchString + " - " + environment.PageDescription;
        let keywords = searchString + " - " + environment.PageDescription;
        let news_keywords = searchString + " - " + environment.PageDescription;
        let url = environment.appWebUrl + "/" + environment.Search + "/" + searchString;

        if (url) {
            this.canonicalLink = this.doc.createElement('link');
            this.canonicalLink.setAttribute('rel', 'canonical');
            this.canonicalLink.setAttribute('href', url);
            this.doc.head.appendChild(this.canonicalLink);
        }
        else if (this.canonicalLink && this.canonicalLink.parentNode) {
            this.canonicalLink.parentNode.removeChild(this.canonicalLink);
        }

        this.titleService.setTitle(title);
        this.metaService.updateTag({ name: "description", content: description });
        this.metaService.updateTag({ name: "keywords", content: keywords });
        this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
        this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
        this.metaService.updateTag({ name: "author", content: environment.appTitle });

        this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
        this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
        this.metaService.updateTag({ name: "og:type", content: "article" });
        this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
        this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
        this.metaService.updateTag({ name: "og:image", content: environment.PageLogo, itemprop: "thumbnailUrl" });
        this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
        this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
        this.metaService.updateTag({ name: "article:tag", content: keywords });

        this.metaService.updateTag({ name: "twitter:card", value: "summary" });
        this.metaService.updateTag({ name: "twitter:url", content: url });
        this.metaService.updateTag({ name: "twitter:title", content: title });
        this.metaService.updateTag({ name: "twitter:description", content: description });
        this.metaService.updateTag({ name: "twitter:image", content: environment.PageLogo });
        this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
        this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });

    }
    public setPageHeadByItem(item: Item) {
        if (item != null) {
            let title = item.ProductName + " - " + environment.PageTitle;
            let description = item.ProductName + " - " + environment.PageDescription;
            let keywords = item.ProductName + " - " + environment.PageDescription;
            let news_keywords = item.ProductName + " - " + environment.PageDescription;
            let url = environment.appWebUrl + "/" + environment.Type + "/" + item.URLCode + ".html";
            let image = environment.APINewURL + environment.ImageItem + "/" + item.Image;

            if (url) {
                this.canonicalLink = this.doc.createElement('link');
                this.canonicalLink.setAttribute('rel', 'canonical');
                this.canonicalLink.setAttribute('href', url);
                this.doc.head.appendChild(this.canonicalLink);
            }
            else if (this.canonicalLink && this.canonicalLink.parentNode) {
                this.canonicalLink.parentNode.removeChild(this.canonicalLink);
            }

            this.titleService.setTitle(title);
            this.metaService.updateTag({ name: "description", content: description });
            this.metaService.updateTag({ name: "keywords", content: keywords });
            this.metaService.updateTag({ name: "news_keywords", content: news_keywords });
            this.metaService.updateTag({ name: "copyright", content: environment.appTitle });
            this.metaService.updateTag({ name: "author", content: environment.appTitle });

            this.metaService.updateTag({ name: "og:site_name", content: environment.appWebUrl });
            this.metaService.updateTag({ name: "og:rich_attachment", content: "true" });
            this.metaService.updateTag({ name: "og:type", content: "article" });
            this.metaService.updateTag({ name: "article:publisher", content: environment.PageFacebook });
            this.metaService.updateTag({ name: "og:url", content: url, itemprop: "url" });
            this.metaService.updateTag({ name: "og:image", content: image, itemprop: "thumbnailUrl" });
            this.metaService.updateTag({ name: "og:image:url", content: image });
            this.metaService.updateTag({ name: "og:title", content: title, itemprop: "headline" });
            this.metaService.updateTag({ name: "og:description", content: description, itemprop: "description" });
            this.metaService.updateTag({ name: "article:tag", content: keywords });

            this.metaService.updateTag({ name: "twitter:card", value: "summary" });
            this.metaService.updateTag({ name: "twitter:url", content: url });
            this.metaService.updateTag({ name: "twitter:title", content: title });
            this.metaService.updateTag({ name: "twitter:description", content: description });
            this.metaService.updateTag({ name: "twitter:image", content: image });
            this.metaService.updateTag({ name: "twitter:site", content: environment.PageTwitterID });
            this.metaService.updateTag({ name: "twitter:creator", content: environment.PageTwitterID });
        }
    }
}

