import { Directive, ElementRef, AfterViewInit, OnDestroy, HostBinding } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { iTaObserverComponent } from '../theoalex/taObserverComponent';
import { TAUtility } from '../theoalex/theoalex';
import { Subscription } from 'rxjs';

@Directive({
  selector: '[appRevealPassword]'
})
export class RevealPasswordDirective implements AfterViewInit, OnDestroy, iTaObserverComponent {

  private static revealPasswordChanged = new Subject<boolean>();

  revealButton: any;
  isShown: boolean = false;
  revealPasswordChangedSubscription: Subscription;

  private showPasswordIcon =  "<i style='font-size:12px; color:#212121;cursor:pointer; text-transform:uppercase; margin-right:5px;'>Show</i>";
  private hidePasswordIcon =  "<i style='font-size:12px; color:#212121;cursor:pointer;text-transform:uppercase;margin-right:5px;'> Hide</i>";

  @HostBinding('attr.type') inputType: string = "password";

  constructor(private el: ElementRef) {
  }

  subscriberEvents(): void {
    this.revealPasswordChangedSubscription = RevealPasswordDirective.revealPasswordChanged.subscribe((isShown)=>{
      this.isShown = isShown;
      this.tryToRevealPassword();
    })
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.revealPasswordChangedSubscription);
  }

  ngAfterViewInit(): void {
    const parentNode = this.el.nativeElement.parentNode;

    parentNode.style.display = "flex";
    parentNode.style.alignItems= 'center';
    parentNode.style.position = "relative";

    this.revealButton = document.createElement('span');
    this.revealButton.style.fontSize='2em';
    this.revealButton.style.position='absolute';
    this.revealButton.style.display='flex';
    this.revealButton.style.right=0;
    this.revealButton.innerHTML=this.showPasswordIcon;

    this.revealButton.addEventListener("click", () => this.onClickRevealButton());
    parentNode.appendChild(this.revealButton);

    this.subscriberEvents();
  }

  private onClickRevealButton(){
    this.isShown = !this.isShown;
    this.tryToRevealPassword();

    //notify to reveal all password
    //RevealPasswordDirective.revealPasswordChanged.next(this.isShown);
  }

  private tryToRevealPassword(){
    if (this.isShown) {
      this.revealButton.innerHTML = this.hidePasswordIcon;
      this.inputType = "text";
    } else {
      this.revealButton.innerHTML = this.showPasswordIcon;
      this.inputType = "password";
    }
  }

  ngOnDestroy(): void {
    this.revealButton.removeEventListener('click',this.onClickRevealButton);
    this.unsubscriberEvents();
  }

}
