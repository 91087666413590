import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Config } from 'src/app/shared/types';
import { StepByStepService } from 'src/app/shared/step-by-step.service';

@Component({
  selector: 'app-start',
  templateUrl: './start.component.html',
  styleUrls: ['./start.component.css']
})
export class StartComponent implements OnInit {

  public products: Config[] = []

  constructor(
    private router: Router,
    private stepByStepService: StepByStepService,
  ) { }

  ngOnInit(): void {
  }

  goToTheodore(id: number) {
    this.router.navigate(['theodore-configurator', id]);
  }

  goToConfigurator() {
    this.router.navigate(['configurator']);
  }
}
