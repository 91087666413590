import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ta-check-box',
  templateUrl: './ta-check-box.component.html',
  styleUrls: ['./ta-check-box.component.scss']
})
export class TaCheckBoxComponent implements OnInit {

  @Input() displayText: any;
  @Input() textPosition: string = "right";//left/right
  @Input() isSelected: boolean;
  @Output() isSelectedChange = new EventEmitter<boolean>();
  @Output() isSelectionChanged = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit() {
  }

  toggle(){
    this.isSelected = !this.isSelected;
    this.isSelectedChange.emit(this.isSelected);
    this.isSelectionChanged.emit(this.isSelected);
  }

}
