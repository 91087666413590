import { CommonModule } from '@angular/common';
import { Component, Directive, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, AfterViewInit, NgModule } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TaNgZorroAntdModule } from 'src/app/ng-zorro-antd.module';
import { ModelApiCity, ModelApiCountry, ModelApiRegion, TawUserAddressViewModel } from 'src/app/service/api/model/model';
import { TaApiServiceMeta } from 'src/app/service/api/TaApiServiceMeta';
import { TaCommonModule } from 'src/app/ta-common.module';
import { TAUtility } from 'src/app/theoalex/theoalex';


@Component({
  selector: 'app-address-form',
  templateUrl: './address-form.component.html',
  styleUrls: ['./address-form.component.scss']
})

export class AddressFormComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() address: TAFormFieldComponentConfig;
  @Output() addressChange = new EventEmitter<TAFormFieldComponentConfig>();

  AddressFormComponentValidator = TAFormFieldComponentValidator;

  addressForm: FormGroup;

  constructor(private fb: FormBuilder, private apiServiceMeta: TaApiServiceMeta) {
  }

  ngOnChanges(changes: SimpleChanges): void {
  }
  ngAfterViewInit() {
  }

  ngOnInit() {
    let formControls = {};
    for (let key in this.address) {
      this.address[key].validators = this.address[key]?.validators || {};
      let validators = Object.values(this.address[key]?.validators)?.map((v:string) => { return Validators[v]; });
      formControls[key] = new FormControl(this.address[key]?.value, validators);
    }
    this.addressForm = this.fb.group(formControls);
  }

  submitAddress() {
    if (this.validateForm()) {
      this.addressChange.emit(this.address);
      return true;
    } else {
      return false;
    }
  }

  validateForm(): boolean {
    let isValid: boolean = true;
    for (const i in this.addressForm.controls) {
      this.addressForm.controls[i].markAsDirty();
      this.addressForm.controls[i].updateValueAndValidity();
      isValid = isValid && this.addressForm.controls[i].valid;
    }
    return isValid;
  }
}
//---------------------------------------------------

@Directive({})
export class taAutoCompleteBase<T>{

  @Input() value?: string;
  @Output() valueChange = new EventEmitter<string>();
  options: T[];

  constructor() {
  }

  onOptionSelected(selectedValue) {
    Promise.resolve().then(() => {
      this.value = selectedValue;
      this.valueChange.emit(this.value);
    })
  }

  setSelectedOption(selectedValue) {
    this.onOptionSelected(selectedValue);
  }

  refreshWithOptions(options: T[]) {
    Promise.resolve().then(() => {
      //this.optionSelected(null);
      this.options = options;
    })
  }
}
//----------------------------------------------

@Component({
  selector: 'country-autocomplete',
  template: `
   <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a country" [(ngModel)]="value" style="width:100%" (ngModelChange)=onOptionSelected($event) nzSize="large">
      <ng-container *ngFor="let option of options">
        <nz-option [nzLabel]="option?.Name" [nzValue]="option?.value"></nz-option>
      </ng-container>
    </nz-select>
  `
})

export class CountryAutocompleteComponent extends taAutoCompleteBase<ModelApiCountry> implements OnInit, OnChanges {
  constructor(private apiServiceMeta: TaApiServiceMeta) { super(); }

  ngOnChanges(changes: SimpleChanges): void {
  }

  ngOnInit() {
    this.apiServiceMeta.apiServiceMetaCountry.get().subscribe(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        console.log(res);
        this.refreshWithOptions(res.Data);
      }
    })
  }
}

//-------------------------------------------------------

@Component({
  selector: 'region-autocomplete',
  template: `
  <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a region" [(ngModel)]="value" style="width:100%" (ngModelChange)=onOptionSelected($event) nzSize="large">
      <ng-container *ngFor="let option of options">
        <nz-option [nzLabel]="option?.Name" [nzValue]="option?.ID"></nz-option>
      </ng-container>
  </nz-select>
  `
})

export class RegionAutocompleteComponent extends taAutoCompleteBase<ModelApiRegion> implements OnChanges {
  @Input() countryId: string;

  constructor(private apiServiceMeta: TaApiServiceMeta) { super(); }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.countryId) {
      this.refreshRegionByCountryId(changes?.countryId?.currentValue);
      //clean the city from the second changed of country (change by user, not preset)
      if (!changes?.countryId.firstChange) {
        this.setSelectedOption(null);
      }
    }
  }

  refreshRegionByCountryId(countryId?: string) {
    if (countryId) {
      this.apiServiceMeta.apiServiceMetaRegion.get(countryId).subscribe(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.refreshWithOptions(res.Data);
        }
      })
    } else {
      this.refreshWithOptions(null);
    }
  }
}

//--------------------------------------------------------------

@Component({
  selector: 'city-autocomplete',
  template: `
      <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Select a city" [(ngModel)]="value" style="width:100%" (ngModelChange)=onOptionSelected($event) nzSize="large">
      <ng-container *ngFor="let option of options">
        <nz-option [nzLabel]="option?.Name" [nzValue]="option?.ID"></nz-option>
      </ng-container>
    </nz-select>
  `
})

export class CityAutocompleteComponent extends taAutoCompleteBase<ModelApiCity> implements OnChanges {
  @Input() regionId: string;

  constructor(private apiServiceMeta: TaApiServiceMeta) { super(); }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.regionId) {
      this.refreshCitiesByRegionId(changes?.regionId?.currentValue);
      //clean the city from the second changed of region (change by user, not preset)
      if (!changes?.regionId.firstChange) {
        this.setSelectedOption(null);
      }
    }
  }

  refreshCitiesByRegionId(regionId?: string) {
    if (regionId) {
      this.apiServiceMeta.apiServiceMetaCity.get(regionId).subscribe(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.refreshWithOptions(res.Data);
        }
      })
    } else {
      this.refreshWithOptions(null);
    }
  }
}


export interface TAFormFieldComponentConfig {
  [field: string]: { value?: any; errorMessage?: string, placeHolder?: string, validators?: {[id: string] : TAFormFieldComponentValidator }};  
}

export enum TAFormFieldComponentValidator {
  required = 'required',
  email = 'email'
}


@NgModule({
  declarations: [
    AddressFormComponent,
    CountryAutocompleteComponent,
    RegionAutocompleteComponent,
    CityAutocompleteComponent
  ],
  imports: [
    TaNgZorroAntdModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
  ],
  exports: [
    AddressFormComponent,
  ]
})
export class TaWebappModuleAddressForm{ }
