import { Component, OnInit, OnChanges, AfterViewInit, AfterViewChecked, Input, ViewChild, TemplateRef, ViewContainerRef, ElementRef, ChangeDetectorRef, Directive, Output, EventEmitter } from '@angular/core';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
import { StepFromNewEvent } from 'src/app/shared/types';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { PricingService } from 'src/app/shared/pricing.service';
import { ActivatedRoute } from '@angular/router';
import { HttpClient } from "@angular/common/http";

declare let IntiaroConfigurator: any;
declare let getIntiaroPlayer: any;
declare let getIntiaroController: any;
declare let clearIntiaroInstances: any;

@Component({
  selector: 'app-intiaro-configurator',
  templateUrl: './intiaro-configurator.component.html',
  styleUrls: [
    './intiaro-configurator.component.css',
    './intiaro-configurator.modified.component.css',
  ],
})

export class IntiaroConfiguratorComponent implements OnChanges, OnInit, AfterViewInit, AfterViewChecked {

  @Input() productId: number = 0;
  @Input() customerName: string = '';
  @Input() optionalAttributes: string[] = [];
  @Input() nextBottomPage: boolean = false;
  @Input() typeOfFurniture: string = '';
  @Input() showPricing: boolean = true;
  @ViewChild('galleryTemplate') galleryTemplate!: TemplateRef<any>;
  @ViewChild('nextTemplate') nextTemplate!: TemplateRef<any>;
  @ViewChild('bottomNext') bottomNext!: ElementRef;
  @ViewChild('mobileMenu') mobileMenu!: TemplateRef<any>;
  @Output() furnitureType = new EventEmitter<string>();

  public stepByStepMenu: StepFromNewEvent[] = [];
  public faArrowRight = faArrowRight;
  private controller: any;
  private player: any;
  private paramsChanged: any;
  private configurationChanged: any;
  private changedParam: any;
  public showSummary: boolean = false;
  private btnNextTop: HTMLElement | null = null;
  public angleForSmallPhotos: number[] = [10, 90, 150, 240];
  public img1: string = '';
  public img2: string = '';
  public img3: string = '';
  public img4: string = ''
  private counter = 0;
  public showcustomRequestInput = false; //if true show textarea for custom request in last step
  public customerRequestText: string = '';
  public showBottomGallery: boolean = false;
  private clickFinish: boolean = false;
  public pdfDocumentation: boolean = false;
  public urlPdfDocumentation: string = '';
  private stepByStepServiceSubscriptions: any[] = [];
  private subscription1: any;

  constructor(
    private stepByStepService: StepByStepService,
    private viewContainerRef: ViewContainerRef,
    private cdr: ChangeDetectorRef,
    private pricingService: PricingService,
  ) { }

  ngOnChanges() {
  }

  ngOnInit() {
    // this.stepByStepService.clickFinishBuilding.subscribe(value => {
    //   this.clickFinish = value;
    //   // simulate click in FinishBuilding-button only in step "sections" for sectional
    //   if (this.clickFinish) {
    //     this.stepByStepService.toggleClickFinishBuilding(false);
    //   }
    // });

    // this.stepByStepService.fetchProductInstanceData(`${this.productId}`, this.typeOfFurniture).then(() => {
    //   this.subscription1 = this.stepByStepService.getProductConfig().subscribe(config => {
    //     config.filter(el => {
    //       this.optionalAttributes = el.optionalAttributes;
    //       this.furnitureType.emit(this.typeOfFurniture);
    //     })
    //   })
    // });
    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.clickFinishBuilding.subscribe(value => {
        this.clickFinish = value;
        // simulate click in FinishBuilding-button only in step "sections" for sectional
        if (this.clickFinish) {
          this.stepByStepService.toggleClickFinishBuilding(false);
        }
      })
    );

    this.stepByStepService.fetchProductInstanceData(`${this.productId}`, this.typeOfFurniture).then(() => {
      this.stepByStepServiceSubscriptions.push(
        this.stepByStepService.getProductConfig().subscribe(config => {
          config.filter(el => {
            this.optionalAttributes = el.optionalAttributes;
          })
        })
      );
    });
  }

  ngAfterViewInit() {
    IntiaroConfigurator.create('Intiaro-configurator').then((r: any) => {
      this.startIntiaro();

      // embed divs inside intiaro-configurator with: small pictures, bottom-next, bottom-menu-mobile
      let embeddedGallery = this.viewContainerRef.createEmbeddedView(this.galleryTemplate);
      let embeddedNext = this.viewContainerRef.createEmbeddedView(this.nextTemplate);
      let embeddedMobileMenu = this.viewContainerRef.createEmbeddedView(this.mobileMenu);
      embeddedGallery.detectChanges();
      embeddedNext.detectChanges();
      embeddedMobileMenu.detectChanges();
      for (let node of embeddedNext.rootNodes) {
        document.querySelector('.intiaroController')?.after(node);
      }
      for (let node of embeddedGallery.rootNodes) {
        document.querySelector('.intiaroController')?.after(node);
      }
      for (let node of embeddedMobileMenu.rootNodes) {
        document.querySelector('.intiaroController')?.before(node);
      }

      // this.stepByStepService.variablesGroupsReceived.subscribe(groups => {
      //   this.stepByStepMenu = groups;
      //   this.stepByStepMenu.filter((el) => {
      //     if (el.isActive) {
      //       (el.slug === 'sections' || el.slug === 'custom_summary') ? this.showBottomGallery = false : this.showBottomGallery = true;

      //       //show button for pdf-documentation
      //       this.pdfDocumentation = false;
      //       this.stepByStepService.hardDataForProduct.startData.pdfDocumetation.forEach((doc: { stepSlug: string, url: string }) => {
      //         if (doc.stepSlug === el.slug) {
      //           this.pdfDocumentation = true;
      //           this.urlPdfDocumentation = doc.url;
      //           return;
      //         }
      //       })
      //     }
      //   });
      // })
      this.stepByStepServiceSubscriptions.push(
        this.stepByStepService.variablesGroupsReceived.subscribe(groups => {
          this.stepByStepMenu = groups;
          this.stepByStepMenu.filter((el) => {
            if (el.isActive) {
              (el.slug === 'sections' || el.slug === 'custom_summary') ? this.showBottomGallery = false : this.showBottomGallery = true;

              //show button for pdf-documentation
              this.pdfDocumentation = false;
              this.stepByStepService.hardDataForProduct.startData.pdfDocumetation.forEach((doc: { stepSlug: string, url: string }) => {
                if (doc.stepSlug === el.slug) {
                  this.pdfDocumentation = true;
                  this.urlPdfDocumentation = doc.url;
                  return;
                }
              })
            }
          });
        })
      );
    })
  }

  ngAfterViewChecked(): void {
    // this.stepByStepService.getStatusSummary().subscribe((status) => {
    //   this.showSummary = status;
    // });
    this.stepByStepServiceSubscriptions.push(
      this.stepByStepService.getStatusSummary().subscribe((status) => {
        this.showSummary = status;
      })
    );

    this.cdr.detectChanges();

    this.btnNextTop = document.getElementById('nextBtn');

    // add small pictures below intiaro-player
    let image1 = document.querySelector('.slider-frame.frame_0')?.querySelector('.slider-image')?.getAttribute('src')?.slice(-9);
    if (image1 && image1 !== 'empty.png') {
      this.img1 = this.player.getFrameUrl(this.angleForSmallPhotos[0]);
      this.img2 = this.player.getFrameUrl(this.angleForSmallPhotos[1]);
      this.img3 = this.player.getFrameUrl(this.angleForSmallPhotos[2]);
      this.img4 = this.player.getFrameUrl(this.angleForSmallPhotos[3]);

      this.stepByStepService.addPhotos([this.img1, this.img2, this.img3, this.img4]);
    }

    // add note 'optional' to titles of not-obligatory attributes (NEW VERSION 03.2023)
    let sectionTitle: NodeListOf<Element> = document.querySelectorAll('.sectionTitle');
    for (let item of sectionTitle as any) {
      let textFromItem = item.innerText;
      if (textFromItem.includes('optional')) {
        continue;
      } else {
        let attributeFromItem = item.parentElement.getAttribute('id')
        if (this.optionalAttributes.includes(attributeFromItem)) {
          item.innerHTML = textFromItem + ' (optional)' + '<i class="fas fa-intiaro-angle-down sectionOpenButton"></i>';
        }
      }
    }
  }

  ngOnDestroy() {
    clearIntiaroInstances(); // in 2.5; method lets rebuild 360 for new product
    this.pricingService.clearPrice();
    this.stepByStepService.variablesGroupsReceived.next([]);

    this.stepByStepServiceSubscriptions.forEach(subscription => subscription.unsubscribe());
  }

  private startIntiaro() {
    this.player = getIntiaroPlayer('Intiaro-configurator'); //2.5
    this.controller = getIntiaroController('Intiaro-configurator');
    // console.log('this.player in Intiaro-configurator', this.player)
    // console.log('this.controller in Intiaro-configurator', this.controller)

    // this.initPricing()
    if (this.showPricing) {
      this.initPricing();
      this.listenConfigurationChanged();
    }
    this.listenForVariablesGroupsReceived();
    this.listenForChoiceConfirmation();
    this.listenConfigurationValidityChanged();
    this.listenParamsChanged();
    this.listenConfigurationChanged();
  }

  //pricing for start configuration of product in 2.5 (there isn't 'controllerInitComplete' event in 2.5)
  private initPricing(): void {
    this.player.getModularConfiguration(true, (configuration: any) => {
      this.pricingService.sendConfigurationForPricing(configuration)
    });
    let priceBox = document.querySelector('.price') as HTMLElement;
    priceBox && (priceBox.style.display = 'flex');
  }

  // listen for variables groups to be delivered
  private listenForVariablesGroupsReceived(): void {
    let tabs = getIntiaroController('Intiaro-configurator').getVisibleAttributeGroups();
    this.stepByStepService.saveVariablesGroupsReceived(this.productId, tabs);
  }

  // listen choice confirmation
  private listenForChoiceConfirmation(): void {
    this.controller.registerListener(
      'choiceConfirmed',
      (selection: { attribute: string, choice: string }) => {
        //setTimeout to register all changes in DOM
        setTimeout(() => {
          //find attributes with css-class .disabledSection
          let disabledAttributes = this.checkDOM('.disabledSection')
          //check visible in DOM attributes and compare them with attributes from map of attributes (data from backend)
          let attributesFromDOM = this.checkDOM('.section')
          let attributesData = {
            disabled: disabledAttributes,
            visibleInDOM: attributesFromDOM,
          }

          //check slug of activeStep and save user's choice to decide Next button (active or not)
          let activeStep = this.stepByStepMenu.filter((el) => el.isActive === true)[0].slug;
          this.stepByStepService.saveChoiceAndCheckNextButton(this.productId, activeStep, selection, attributesData);
        }, 0)
      }
    );
  }

  // listen correct configuration of sectional
  private listenConfigurationValidityChanged(): void {
    this.controller.registerListener(
      'canExitSectionalBuilder',
      (res: any) => {
        this.stepByStepService.saveStatusConfigrationSectional(res.canExitSectionalBuilder);
      }
    );
  }

  // listen change of params
  private listenParamsChanged(): void {
    this.player.registerListener('paramsChanged', (configuration: any) => {
      // this.paramsChanged = JSON.stringify(configuration);
      this.stepByStepService.addPhotos([this.img1, this.img2, this.img3, this.img4]);

      let activeStepNumber = this.stepByStepMenu.filter((el) => el.isActive === true)[0].stepNumber;
      if (activeStepNumber === 1) {
        this.stepByStepService.toggleNext(true);
      }
    });
  }

  // listen change of modules in sectional
  private listenConfigurationChanged(): void {
    this.player.registerListener(
      'configurationChanged',
      (configuration: any) => {
        //pricing for new configuration of product
        this.pricingService.sendConfigurationForPricing(configuration);
        // let priceBox = document.querySelector('.price') as HTMLElement;
        // priceBox && (priceBox.style.display = 'none') ? (priceBox.style.display = 'flex') : '';
      }
    );
  }

  // go to the next step after click on bottom-next
  public goToNext(): void {
    this.bottomNext.nativeElement.addEventListener('click', (ev: any) => {
      if (this.counter === 0) this.btnNextTop && this.btnNextTop.click();
      this.counter += 1;
    })
    this.counter = 0;
  }

  // change angle for view of product in intiaro-player
  public changePhoto(angle: number): void {
    this.player && this.player.setAngle(angle);
  }

  //check DOM to find elements with specific css-class
  private checkDOM(className: string): string[] {
    let elementsFromDOM: NodeListOf<Element> = document.querySelectorAll(className);
    let listWithSlugsFromDOM: string[] = [];
    Array.from(elementsFromDOM).forEach((el) => {
      let idElement = el.getAttribute('id');
      idElement && listWithSlugsFromDOM.push(idElement)
    })
    return listWithSlugsFromDOM
  }
}
