import { Pipe, PipeTransform } from '@angular/core';
import { TAProductDetailViewModel } from '../service/api/TaApiServiceProduct';

@Pipe({
  name: 'ProductPipe_IsIncludeOptionGroup'
})
export class ProductPipe_IsIncludeOptionGroup implements PipeTransform {
  transform(product: TAProductDetailViewModel, requestOptionGroupId: string): boolean {
    let isInclude: boolean = false;
    if (requestOptionGroupId && product.OptionGroups && product.OptionGroups.length) {
      product.OptionGroups.forEach(op => {
        isInclude = isInclude || (op && op.ID && op.ID.toUpperCase() == requestOptionGroupId);
      })
    }
    return isInclude;
  }
}

@Pipe({
  name: 'TaPipe_JoinString'
})
export class TaPipe_JoinString implements PipeTransform {
  transform(items: any[], propertyName: string, seperator = ", "): string {
    let strs = [];
    if (items && items.length) {
      items.forEach(i => {
        if (i && i[propertyName]) { strs.push(i[propertyName]) };
      })
    }
    return strs.join(seperator);
  }
}
