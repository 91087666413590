import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { MenuLocationsComponent } from './menu-locations/menu-locations.component';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  brands: boolean = false;
  products: boolean = false;
  designers: boolean = false;
  custom: boolean = false;
  news: boolean = false;
  locations: boolean = false;
  virtualtour: boolean = false;
  visualizer: boolean = false;
  blog: boolean = false;
  virtualShowroom: boolean = false;
  about: boolean = false;
  domainName: string = environment.appWebUrl;
  @ViewChild(MenuLocationsComponent, { static: true }) menuLocationsComponent: MenuLocationsComponent;
  @HostListener('click', ['$event'])
  clickInside($event) {
    $event.stopPropagation();
  }
  @HostListener('document:click')
  clickout() {
    this.closeAllMenu()
  }

  constructor() { }

  ngOnInit() {
  }

  triggerRequireLocation() {
    this.menuLocationsComponent.requireLocation();
  }

  timer;
  mouseEnter(name: string) {
    // this.timer = setTimeout(() => {
    //   this[name] = true;
    // }, 100);
  }

  mouseLeave(name: string) {
    // this[name] = false;
    // if (this.timer) {
    //   clearTimeout(this.timer);
    // }
  }

  openMenu(clickedMenuName: string) {
    this.closeAllMenu(clickedMenuName);
  }

  closeAllMenu(clickedMenuName?: string) {
    this.brands = clickedMenuName == 'brands' ? !this[clickedMenuName] : false;
    this.products = clickedMenuName == 'products' ? !this[clickedMenuName] : false;
    this.designers = clickedMenuName == 'designers' ? !this[clickedMenuName] : false;
    this.custom = clickedMenuName == 'custom' ? !this[clickedMenuName] : false;
    this.news = clickedMenuName == 'news' ? !this[clickedMenuName] : false;
    this.locations = clickedMenuName == 'locations' ? !this[clickedMenuName] : false;
    this.blog = clickedMenuName == 'blog' ? !this[clickedMenuName] : false;
    // this.virtualShowroom = clickedMenuName == 'virtualShowroom' ? !this[clickedMenuName] : false;
    this.visualizer = clickedMenuName == 'visualizer' ? !this[clickedMenuName] : false;
    this.virtualtour = clickedMenuName == 'virtualtour' ? !this[clickedMenuName] : false;
    this.about = clickedMenuName == 'about' ? !this[clickedMenuName] : false;
  }
}
