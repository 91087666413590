import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Instagram } from 'src/app/shared/Instagram.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class InstagramService {
    list: Instagram[] | undefined;    
    listShow: Instagram[] | undefined;    
    formData!: Instagram;
    formData001!: Instagram;
    formData002!: Instagram;
    formData003!: Instagram;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "Instagram";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];      
        this.listShow = [];      
        this.formData = {
        };
    }    
    getLastPosts(): Observable<TaAPIResponse<Instagram>> {
        let url = this.aPIURL + this.controller + '/GetLastPosts';        
        return this.httpClient.get<TaAPIResponse<Instagram>>(url).pipe(tap((res: any) => {
        }));
    }    
    getByActiveToList(active: boolean): Observable<TaAPIResponse<Instagram>> {
        let url = this.aPIURL + this.controller + '/GetByActiveToList';
        const params = new HttpParams()            
            .set('active', JSON.stringify(active))            
        return this.httpClient.get<TaAPIResponse<Instagram>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

