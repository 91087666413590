import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { StoreDataTransfer } from 'src/app/shared/StoreDataTransfer.model';
@Injectable({
    providedIn: 'root'
})
export class StoreService {
    listDataTransfer: StoreDataTransfer[];    
    aPIURL: string = environment.APINewURL;
    controller: string = "Store";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {        
    }
    getDataTransferBySearchStringToList(searchString: string) {
        let url = this.aPIURL + this.controller + '/GetDataTransferBySearchStringToList';
        const params = new HttpParams()
          .set('searchString', searchString)          
        return this.httpClient.get(url, { params }).toPromise();
      }
    
}

