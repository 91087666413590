import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TaApiServiceLogin, TaUserCredential } from 'src/app/service/api/TaApiServiceLogin';
import { MatDialog } from '@angular/material/dialog';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { TaApiServiceShoppingCart } from 'src/app/service/api/TaApiServiceShoppingCart';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit, OnDestroy, iTaObserverComponent {

  userAuthenticCheckedEventSubscriber: Subscription;
  loginUser: TaUserCredential;
  isLogged: boolean = false;
  shoppingCartChangedSubscription: Subscription;
  shoppingCartCount: string = "0";
  isUserCredentialProviderAcquiring = false;
  public domainName: string = environment.appWebUrl;

  constructor(private loginService: TaApiServiceLogin, private dialog: MatDialog, private shoppingCartService: TaApiServiceShoppingCart) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isUserCredentialProviderAcquiring = false;
      this.subscriberEvents();
    })
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  subscriberEvents(): void {
    this.userAuthenticCheckedEventSubscriber = TaUniversalHubSubject.userCredentialSubjectData.subscribe(credential => {
      if (credential?.UserName) {
        this.refreshShoppingCartCount();
      }
      this.loginUser = credential;
      localStorage.setItem('UserName', this.loginUser?.UserName);
      localStorage.setItem('UserID', this.loginUser?.UserId);
      localStorage.setItem('FirstName', this.loginUser?.FirstName);
      localStorage.setItem('UserRole', this.loginUser?.UserType);
      localStorage.setItem('Region', this.loginUser?.Region);
    });

    this.shoppingCartChangedSubscription = TaUniversalHubSubject.shoppingCartChanged.subscribe(cartItems => {
      this.refreshShoppingCartCount();
    })

    TaUniversalHubSubject.userCredentialProviderAcquiringSubject.subscribe(isAcquiring => {
      this.isUserCredentialProviderAcquiring = isAcquiring;
    })
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userAuthenticCheckedEventSubscriber, this.shoppingCartChangedSubscription);
  }

  private refreshShoppingCartCount() {
    this.shoppingCartService.getClientShoppingCart(() => {
      this.shoppingCartCount = this.shoppingCartService.shoppingCart ? this.shoppingCartService.shoppingCart.Count : "0";
      localStorage.setItem('Cart', this.shoppingCartCount);
    })
  }

  logout(e: any) {
    this.loginService.logout(true);
    return;
  }
}
