import { Component, OnInit, HostListener, Input, OnDestroy } from '@angular/core';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { TAUtility } from 'src/app/theoalex/theoalex';

@Component({
  selector: 'app-go-to-top',
  templateUrl: './go-to-top.component.html',
  styleUrls: ['./go-to-top.component.scss']
})
export class GoToTopComponent implements OnInit, iTaObserverComponent, OnDestroy, OnInit {

  scrollEventSubscriber: Subscription;
  homeSwiperSlideChangedEventSubscriber: Subscription;
  hidden: boolean = true;

  constructor() { }

  ngOnInit() {
    this.subscriberEvents();
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  subscriberEvents(): void {
    this.subscribeScrollEvent();
    this.subscriberHomeSwiperSlideChangedEvent();
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.scrollEventSubscriber, this.homeSwiperSlideChangedEventSubscriber);
  }

  private subscribeScrollEvent(){
    this.scrollEventSubscriber = TaUniversalHubSubject.outletScrollEvent.subscribe(
      scrollData => {
        if (scrollData.target.scrollingElement.scrollTop > 500) {
          this.show();
        }
        if (scrollData.target.scrollingElement.scrollTop < 100) {
          this.hide();
        }
    })
  }

  private subscriberHomeSwiperSlideChangedEvent(): void {
    this.homeSwiperSlideChangedEventSubscriber = TaUniversalHubSubject.homeSwiperSlideChangedEvent.subscribe((eventData) => {
      if (eventData) {
        if (eventData.index) {
          this.show();
        } else {
          this.hide();
        }
      } else {
        this.hide();
      }
    })
  }

  onClick(): void {
    //broast event
    //-> home page swiper show the first slide
    //-> app component scroll to the top
    TaUniversalHubSubject.scrollRequest.next();
  }

  private show(){
    this.hidden = false;
  }

  private hide(){
    this.hidden = true;
  }

  private toggle() {
    this.hidden ? this.show() : this.hide();
  }

}
