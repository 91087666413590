<!-- block_menu-->
<div class="block_menu block_desktop">
  <div class="close-mainMeenu" [hidden]="!(about || custom || products || brands)">
    <button (click)="closeAllMenu()"><i class="icon-close"></i></button>
  </div>
  <div>
    <ul class="row justify-content-between align-items-center col-12">
      <li class="block_item_first_top" (mouseenter)="mouseEnter('brands')" (mouseleave)="mouseLeave('brands')"
        [ngClass]="{'show': brands}">
        <a [ngClass]="{'menu-active-xl': brands}" class="block_item_first" (click)="openMenu('brands')">Collections<i
            class="icon-arrow_dropdown"></i></a>
        <app-menu-brands class="block_menu_item_content"></app-menu-brands>
      </li>
      <li class="block_item_first_top" (mouseenter)="mouseEnter('products')" (mouseleave)="mouseLeave('products')"
        [ngClass]="{'show': products}">
        <a [ngClass]="{'menu-active-xl': products}" class="block_item_first" (click)="openMenu('products')">PRODUCTS<i
            class="icon-arrow_dropdown"></i></a>
        <app-menu-products class="block_menu_item_content"></app-menu-products>
      </li>
      <!-- <li class="block_item_first_top" (mouseenter)="mouseEnter('custom')" (mouseleave)="mouseLeave('custom')"
        [ngClass]="{'show': custom}">
        <a [ngClass]="{'menu-active-xl': custom}" class="block_item_first" (click)="openMenu('custom')">custom <i
            class="icon-arrow_dropdown"></i></a>
        <app-menu-custom class="block_menu_item_content"></app-menu-custom>
      </li>
      <li class="block_item_first_top" [ngClass]="{'show': locations}">
        <a [ngClass]="{'menu-active-xl': locations}" class="block_item_first" href="{{domainName}}dealer-locator.html"
          (click)="closeAllMenu()">Locations</a>
      </li>
      <li class="block_item_first_top" [ngClass]="{'show': virtualShowroom}">
        <a [ngClass]="{'menu-active-xl': blog}" class="block_item_first" routerLink="/virtual-showroom"
          (click)="closeAllMenu()">Virtual Tour</a>
      </li> -->
      <li class="block_item_first_top" (mouseenter)="mouseEnter('custom')" (mouseleave)="mouseLeave('custom')"
        [ngClass]="{'show': custom}">
        <a [ngClass]="{'menu-active-xl': custom}" class="block_item_first" (click)="openMenu('custom')">DESIGN RESOURCES
          <i class="icon-arrow_dropdown"></i></a>
        <app-menu-custom class="block_menu_item_content"></app-menu-custom>
      </li>
      <li class="block_item_first_top" (mouseenter)="mouseEnter('visualizer')" (mouseleave)="mouseLeave('visualizer')"
        [ngClass]="{'show': visualizer}">
        <a [ngClass]="{'menu-active-xl': visualizer}" class="block_item_first" (click)="openMenu('visualizer')">3D
          VISUALIZER<i class="icon-arrow_dropdown"></i></a>
        <app-menu-3d class="block_menu_item_content"></app-menu-3d>
      </li>
      <li class="block_item_first_top" [ngClass]="{'show': virtualShowroom}">
        <a [ngClass]="{'menu-active-xl': blog}" class="block_item_first" routerLink="/virtual-showroom"
          (click)="closeAllMenu()">Virtual Showroom Tour</a>
      </li>
      <li class="block_item_first_top" [ngClass]="{'show': locations}">
        <a [ngClass]="{'menu-active-xl': locations}" class="block_item_first" href="{{domainName}}dealer-locator.html"
          (click)="closeAllMenu()">Locations</a>
      </li>
      <li class="block_item_first_top" (mouseenter)="mouseEnter('about')" (mouseleave)="mouseLeave('about')"
        [ngClass]="{'show': about}">
        <a [ngClass]="{'menu-active-xl': about}" class="block_item_first" (click)="openMenu('about')">ABOUT US<i
            class="icon-arrow_dropdown"></i></a>
        <app-menu-about-us class="block_menu_item_content"></app-menu-about-us>
      </li>
      <li class="block_search block_item_first_top">
        <app-search [isDesktop]="true" (click)="closeAllMenu()"></app-search>
      </li>
    </ul>
  </div>
</div>