import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import * as _ from "lodash";

@Component({
  selector: 'ta-drop-down',
  templateUrl: './ta-drop-down.component.html',
  styleUrls: ['./ta-drop-down.component.scss']
})
export class TaDropDownComponent implements OnInit, OnChanges {

  selectedItem: any;
  isShowPlaceHolder: boolean = true;
  @Input() textTransform?: string;//capitalize,lowercase,uppercase
  @Input() dropDownText?: string;
  @Input() items: any[];
  @Input() itemDisplayTextField?: string;
  @Input() itemSelectValueField?: string = "isSelected";
  @Output() itemSelectedChanged?= new EventEmitter<any>();
  @Input() placeHolderText?: string;
  @Input() isIsolated?: boolean;

  constructor() { }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes.items) {
      this.processForPresetSelectedItem();
    }
  }

  ngOnInit() {
    if (this.isIsolated) {
      this.items = _.cloneDeep(this.items);
    }
    this.processForPresetSelectedItem();
  }

  private processForPresetSelectedItem() {
    if (this.items) {
      this.items.forEach(item => {
        if (item && item[this.itemSelectValueField]) {
          this.selectedItem = item;
          this.isShowPlaceHolder = false;
          //dont emit event for default selection.
          //this.itemSelectedChanged.emit(item);
        }
      });
    }
  }

  onItemSelected(selectedItem) {
    this.isShowPlaceHolder = false;
    this.items.forEach(item => {
      item[this.itemSelectValueField] = false;
    })
    selectedItem[this.itemSelectValueField] = true;
    this.selectedItem = selectedItem;
    this.itemSelectedChanged.emit(this.selectedItem);
  }

}
