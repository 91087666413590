import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { RoomAndUsage } from 'src/app/shared/RoomAndUsage.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class RoomAndUsageService {
    list: RoomAndUsage[];
    listData: RoomAndUsage[];
    formData: RoomAndUsage;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "RoomAndUsage";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
        this.listData = [];
        this.formData = {           
        };
    }
    getByID(ID: string): Observable<TaAPIResponse<RoomAndUsage>> {
        let url = this.aPIURL + this.controller + '/GetByID';
        const params = new HttpParams()
            .set('ID', ID)
        return this.httpClient.get<TaAPIResponse<RoomAndUsage>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
    getByURLCode(URLCode: string): Observable<TaAPIResponse<RoomAndUsage>> {
        let url = this.aPIURL + this.controller + '/GetByURLCode';
        const params = new HttpParams()
            .set('URLCode', URLCode)
        return this.httpClient.get<TaAPIResponse<RoomAndUsage>>(url, { params }).pipe(tap((res: any) => {
        }));
    }        
    getByIsActiveAndRegionToList(isActive: boolean, region: string): Observable<TaAPIResponse<RoomAndUsage>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveAndRegionToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
            .set('region', region)
        return this.httpClient.get<TaAPIResponse<RoomAndUsage>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByIsActiveToList(isActive: boolean): Observable<TaAPIResponse<RoomAndUsage>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))            
        return this.httpClient.get<TaAPIResponse<RoomAndUsage>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

