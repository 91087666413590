<div class="productFilter d-flex flex-column">
    <div>
        <div #filterToolbar class="productFilter_content_toolbar sticky_filter_horizontal">
            <div class="list-product-filter">
                <h2 class="productFilter_content_toolbar_count">
                    {{listBarstool.length}} Result
                </h2>
                <div
                    class="productFilter_content_toolbar_action flex-grow-1 d-none d-xl-flex align-items-center justify-content-end">
                    <div class="productFilter_content_toolbar_action_search">
                        <input type="text" placeholder="Search..." [(ngModel)]="searchKey"
                            (keydown.esc)="searchKey = '';onConfirmSearchKey()" (keydown.enter)="onConfirmSearchKey()">
                        <i class="icon-Search" style="cursor: pointer;" (click)="onConfirmSearchKey()"></i>
                    </div>
                    <div class="productFilter_content_toolbar_action_sort">
                        <ta-drop-down textTransform="uppercase" dropDownText="{{'Sort By' | uppercase }}"
                            [items]="sorter.items" itemDisplayTextField="name"
                            (itemSelectedChanged)="onSortByChangedByDropdown($event)"
                            style="color: #636363;"></ta-drop-down>
                    </div>
                    <div class="productFilter_content_toolbar_action_sort">
                        <span class="productFilter_content_tags_clear" (click)="clearFilterTags()">
                            <a><span>Clear All</span></a>
                        </span>
                    </div>
                </div>

            </div>
            <div class="products" *ngIf="listBarstool.length > 0" [ngClass]="{'isGridMode': true}">
                <div *ngFor="let product of listBarstool;" class="product">
                    <div class="tags">
                        <span class="tag3d">3D</span>
                    </div>
                    <div class="info">
                        <div class="image">
                            <a href="{{tailorfitURL}}{{product.id}}" target="_blank" title="{{product?.product_name}}">
                                <img alt="{{product?.product_name}}" title="{{product?.product_name}}"
                                    [src]="product?.product_thumbnail + '?w=400&h=400'">
                            </a>
                        </div>
                        <div class="name">
                            <a routerLink="/draper/theodore-configurator/{{product.id}}">
                                <h4>{{product?.product_name | titlecase }}</h4>
                            </a>
                            <div class="sku">{{product?.client_identifier}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="isLoading" class="ta_circle_loader_container h-100" style="padding: 20px 0px">
        <div class="ta_circle_loader"></div>
    </div>
</div>