import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { NgbModalRef, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TaApiServiceCommon } from 'src/app/service/api/TaApiServiceCommon';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TaAlertPopup, TAAlertConfigurationType } from '../ta-alert-popup/ta-alert-popup.component';

@Component({
  selector: 'app-ralph-lauren-inquire-form',
  templateUrl: './ralph-lauren-inquire-form.component.html',
  styleUrls: ['./ralph-lauren-inquire-form.component.scss']
})
export class RalphLaurenInquireFormComponent implements OnInit {

  formData = {
    name: "",
    email: "",
    businessName: "",
    address: "",
    daytimePhone: "",
    state: "",
    country: "",
    zipCode: "",
    comment: ""
  }
  private sendRalphLaurenInquiriesSubscription: Subscription;
  modalRef: NgbModalRef;

  constructor(private dialog: NgbModal, private serviceCommon: TaApiServiceCommon) { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  subscriberEvents(): void {
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.sendRalphLaurenInquiriesSubscription);
  }


  onClickCloseButton() {    
    this.modalRef?.close();
  }

  sendForm() {
    let notifyModalRef = TaAlertPopup.show({
      title: "Sending...",
      message: "Sending your request, please wait.",
      type: TAAlertConfigurationType.info
    }, this.dialog)

    this.sendRalphLaurenInquiriesSubscription = this.serviceCommon.SendRalphLaurenInquiries(this.formData).subscribe(res => {
      notifyModalRef.close();
      if (TAUtility.isAPIRespondOk(res)) {
        notifyModalRef = TaAlertPopup.show({
          title: "Sent successfully!",
          message: "Your request has been sent successfully.",
          onConfirm: () => { this.modalRef.close(); },
          type: TAAlertConfigurationType.success
        }, this.dialog);
      } else {
        notifyModalRef = TaAlertPopup.show({
          title: "Sent fail!",
          message: "Sorry, the request failed.<br/> Please check your input and try again. <br/>"+res.Data,
          onConfirm: () => { },
          type: TAAlertConfigurationType.error
        }, this.dialog);
      }
    })
  }

  onSubmit(form) {
    if (!form.invalid) {
      this.sendForm();
    }
  }
}
