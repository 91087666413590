<div class="main-share-email align-items-center">
    <div class="share-email-product">
      <div class="title_share_email">
        <h1>Email This Product</h1>
      </div>
      <div class="frm-share-email">
        <form nz-form (ngSubmit)="sharedProduct()" [formGroup]="formGroup">
              <nz-form-item>
                <nz-form-control style="margin-bottom: 25px;" [nzErrorTip]="formConfig.fromName.errorMessage">
                  <input #firstInputFocus nz-input [placeholder]="formConfig.fromName.placeHolder" [(ngModel)]="formConfig.fromName.value" formControlName="fromName" nzSize="large">
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control style="margin-bottom: 25px;" [nzErrorTip]="formConfig.fromEmail.errorMessage">
                  <input nz-input [placeholder]="formConfig.fromEmail.placeHolder" [(ngModel)]="formConfig.fromEmail.value" formControlName="fromEmail" nzSize="large">
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control style="margin-bottom: 25px;" [nzErrorTip]="formConfig.toName.errorMessage">
                  <input nz-input [placeholder]="formConfig.toName.placeHolder" [(ngModel)]="formConfig.toName.value" formControlName="toName" nzSize="large">
                </nz-form-control>
              </nz-form-item>
              <nz-form-item>
                <nz-form-control style="margin-bottom: 25px;" [nzErrorTip]="formConfig.toEmail.errorMessage">
                  <input nz-input [placeholder]="formConfig.toEmail.placeHolder" [(ngModel)]="formConfig.toEmail.value" formControlName="toEmail" nzSize="large">
                </nz-form-control>
              </nz-form-item>

            <nz-form-item >
              <nz-form-control style="margin-bottom: 25px;" nzErrorTip="Please write something here!">
                <textarea formControlName="message" nz-input rows="2" [placeholder]="formConfig.message.placeHolder" [(ngModel)]="formConfig.message.value"></textarea>
              </nz-form-control>
            </nz-form-item>

            <nz-form-item style="margin-bottom: 25px;">
                <label nz-checkbox formControlName="isSendCopy" [(ngModel)]="formConfig.isSendCopy.value">
                  <span style="font-size: 15px;">{{formConfig.isSendCopy.placeHolder}}</span>
                </label>
            </nz-form-item>
            <div style="margin-top: 40px;">
              <button style="margin-right: 20px;" nz-button nzType="primary">Send</button>
              <button nz-button (click)="$event.preventDefault();modalRef?.close()">Cancel</button>
            </div>
        </form>
      </div>
      <!-- <div class="col-lg-12 mx-auto">
        <div class="title_share_email">
          <div class="padd">
            <h1>Email This Product</h1>
          </div>
        </div>
        <div class="content_share_email">
          <form class="share-email-form">
            <div class="row" *ngFor="let item of model.EmailTo; let i = index" >
              <div class="col-xl-6 col-md-12 col-sm-12">
                <input #firstInputFocus type="text" name="Recipient's Name" placeholder="Recipient's Name (*)" [(ngModel)]="item.PersonName">
              </div>
              <div class="col-xl-6 col-md-12 col-sm-12">
                <input type="text" name="Recipient's Email" placeholder="Recipient's Email (*)" [(ngModel)]="item.PersonEmail">
              </div>
            </div>
            <div class="row popup_row_2_email">
              <div class="col-xl-6 col-md-12 col-sm-12">
                <input type="text" name="Your Name" placeholder="Your Name (*)" [(ngModel)]="model.EmailFrom.PersonName">
              </div>
              <div class="col-xl-6 col-md-12 col-sm-12">
                <input type="text" name="Your Email" placeholder="Your Email (*)" [(ngModel)]="model.EmailFrom.PersonEmail">
              </div>
              <div class="col-md-12 col-sm-12">
                <textarea class="text_message_popup_email" style="width: 100%;height: 50px;"  rows="2" cols="50" name="Message" placeholder="Message" [(ngModel)]="model.Message"></textarea>
              </div>
              <div class="col-md-12 col-sm-12 send_yourself_popup">
                <ta-check-box displayText="Send Yourself a Copy" [(isSelected)]="model.SendACopy"></ta-check-box>
              </div>
            </div>

            <div class="row">
              <div class="col-md-12">
                <div class="d-flex justify-content-start button_action">
                  <button nz-button nzType="primary" class="btn btn-register" (click)="sharedProduct()"
                  [ngClass]="{'ta_disabled': !model.EmailFrom || !model.EmailFrom.PersonEmail || !model.EmailFrom.PersonName || !model.EmailTo[0] || !model.EmailTo[0].PersonEmail || !model.EmailTo[0].PersonName}">Send </button>
                  <button nz-button nzType="default" class="btn btn-cancel" (click)="modalRef?.close()">Cancel </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div> -->
    </div>
</div>

