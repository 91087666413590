<div>
  <div class="block_footer">
    <div class="footer-main">
      <div class="justify-content-between wrapper-footer">
        <div class="col-md-12 col-xl-4 block_item_1">
          <div class="block_logo_footer"> <a href="{{domainName}}"><img alt="Theodore Alexander"
                title="Theodore Alexander, one of the finest luxury furniture brands in the world"
                src="/assets/img/logo_footer.svg"></a></div>
          <ul class="block_social_footer" style="display: flex">
            <li><a target="_blank" appSocialLink="instagram"><i class="icon-instagram"></i></a></li>
            <li><a target="_blank" appSocialLink="facebook"><i class="icon-facebook"></i></a></li>
            <li><a target="_blank" appSocialLink="youtube"><i class="icon-youtube"></i></a></li>
            <li><a target="_blank" appSocialLink="twiter"><i class="icon-twitter"></i></a></li>
            <li><a target="_blank" appSocialLink="pinterest"><i class="icon-pinterest2"></i></a></li>
            <li><a target="_blank" appSocialLink="linkedin"><i class="icon-linkedin2"></i></a></li>
          </ul>
          <p><a href="{{domainName}}web/site-map" title="Site Map">Site Map</a></p>
        </div>
        <div class="col-md-12 col-xl-4 block_item_2">
          <ul>
            <li class="title_middle_item">NORTH AMERICA</li>
            <li>Email: <a href="mailto:info@theodorealexander.com">info@theodorealexander.com</a></li>
            <li>Phone: <a href="tel:+13368855005">336.885.5005</a></li>
            <li>Fax: 336.885.5260</li>
            <li class="title_middle_item">INTERNATIONAL</li>
            <li>Email: <a href="mailto:international@theodorealexander.com">international@theodorealexander.com</a></li>
          </ul>
        </div>
        <div class="col-md-12 col-xl-4 block_item_3">
          <div>
            <ul>
              <li><a href="{{domainName}}web/faq">FAQ / Help</a></li>
              <li
                [appUserRoleAccessibility]="{userRole: 'Dealer,Designer,Sales Associate,Consumer', display:'inline-block'}">
                <a href="{{domainName}}account/marketing-resource">Marketing Resources</a>
              </li>
              <li [appUserRegionAccessibility]="{userRegion: 'taus', display:'block'}"><a
                  href="{{domainName}}special/in-stock.html">In
                  Stock</a></li>
              <li><a href="{{domainName}}web/furniture-care">Furniture Care</a></li>

              <li><a href="{{domainName}}contact/become-an-authorized-reseller">Become an Authorized Reseller</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="copyright-footer">© {{year}} <a href="{{domainName}}">&nbsp;Theodore Alexander</a>. All rights
    reserved.</div>
</div>