import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { TAProductDetailViewModel } from './TaApiServiceProduct';

@Injectable({
  providedIn: 'root'
})

export class TaApiServiceCommon {
  constructor(private http: HttpClient) { }

  SubcribeEmail(email: string): Observable<SubcribeEmailResp> {
    const command = 'api/Common/SubcribeEmail';
    const request = {
      Email: email
    };
    return this.http.post(`${environment.appApiUrl}/${command}`, request);
  }

  SendRequestAQuoteProduct(product: TAProductDetailViewModel, inputInfo) : Observable<any>{
    let request : TAHttpModelQuoteRequest = {
      Item: product.SKU,
      ItemId: product.ID,
      Brand: product.Brand.Name,
      Collection: product.Collection.Name,
      RoomAndUsage: product.RoomAndUsage.Name,
      ProductType: product.Type.Name,
      LifeStyle: product.LifeStyle.Name,
      ProductStyle: product.Style.Name,
      Name: inputInfo.name,
      Email: inputInfo.email,
      Phone: inputInfo.daytimePhone,
      Detail: inputInfo.comment,
      City: inputInfo.city,
      State: inputInfo.state,
      Zip: inputInfo.zipCode,
      Country: inputInfo.country,
      Address: inputInfo.address,
      Designer: inputInfo.isDesigner,
      PreferredContactMedia: inputInfo.preferedContactMethod,
      IsSendCopy: inputInfo.isSendCopy,
      Types: 'Request A Quote Only',//HARD CODE
    }

    const command = 'api/Common/QuotationRequest';
    return this.http.post(`${environment.appApiUrl}/${command}`, request);
  }

  SendRalphLaurenInquiries(data): Observable<any>{
    let request = {
      "Name": data.name,
      "Email": data.email,
      "BusinessName": data.businessName,
      "Address": data.address,
      "DaytimePhone": data.daytimePhone,
      "StateProvince": data.state,
      "Country": data.country,
      "ZipPostalCode": data.zipCode,
      "Message": data.comment
    }
    const command = 'api/Common/RalphLaurenInquiries';
    return this.http.post(`${environment.appApiUrl}/${command}`, request);
  }

  sendTradeEnquiries(request: any): Observable<any> {
    const tradeEnquiries: TradeEnquiriesRequest = {
      Name: request.name,
      Phone: request.phone,
      Email: request.email,
      StoreName: request.storeName,
      EndConsumer: request.endConsumer,
      StoreWebAddress: request.storeWebAddress,
      PositionAtPrimaryStore: request.positionPrimaryStore,
      NumberOfStores: request.numberOfStore,
      Address1: request.address1,
      Address2: request.address2,
      Country: request.country,
      State: request.state,
      City: request.city,
      ZipCode: request.zipCode,
      Questions: request.questions
    };
    const command = 'public/api/SendTradeEnquiries';
    return this.http.post(`${environment.appApiUrl}/${command}`, tradeEnquiries);
  }

  getUserTypeList() : Observable<TaAPIResponse<TaAPIUserTypeList>> {
    const command = 'public/api/GetUserTypes';
    return this.http.get<TaAPIResponse<TaAPIUserTypeList>>(`${environment.appApiUrl}/${command}`);
  }

  getCountries() : Observable<any>{
    const command: string = 'api/Store/GetCountries';
    return this.http.get(`${environment.appApiUrl}/${command}`);
  }

  getRegions(countryId: string) {
    const command: string = 'api/Store/GetRegionsByCountryId';
    return this.http.get(`${environment.appApiUrl}/${command}/${countryId}`);
  }

  getCities(regionId: string) {
    const command: string = 'api/Store/GetCitiesByRegionId';
    return this.http.get(`${environment.appApiUrl}/${command}/${regionId}`);
  }
  getSubjects(): Observable<any> {
    const cmd = 'api/Common/GetSubjects';
    return this.http.get(`${environment.appApiUrl}/${cmd}`);
  }
  sendContactEmail(request: any): Observable<any> {
    console.log(request);
    const cmd = 'api/Common/ContactEmail';
    return this.http.post(`${environment.appApiUrl}/${cmd}`, request);
  }

  shareProductByEmail(request: TAAPIShareProductByEmailViewModel): Observable<any> {
    const cmd = 'api/Item/SendEmailItem';
    return this.http.post(`${environment.appApiUrl}/${cmd}`, request);
  }

  sendApplyJobVNCandidate(candidateInfo: any){
    const cmd = 'api/Common/ApplyJobVN';
    return this.http.post(`${environment.appApiUrl}/${cmd}`, candidateInfo);
  }

  ping() : Observable<any>{
    const command = "public/api/ping";
    return this.http.post(`${environment.appApiUrl}/${command}`, {});
  }
}

export interface TAAPIShareProductByEmailViewModel {
  ItemId?: string;
  FromName?: string;
  FromEmail?: string;
  ToName?: string;
  ToEmail?: string;
  IsSendCopy?: boolean;
  Message?: string;
}

export interface TAHttpModelQuoteRequest {
  Item?: string,//SKU
  ItemId?: string,
  Brand?: string,
  Collection?: string,
  RoomAndUsage?: string,
  ProductType?: string,
  LifeStyle?: string,
  ProductStyle?: string,
  Name?: string,
  Email?: string,
  Phone?: string,
  Detail?: string,
  City?: string,
  State?: string,
  Zip?: string,
  Country?: string,
  Types?: string, //Types : 'Request A Quote Only'//HAR CODE
  Address?: string,
  IsWishListItem?: boolean,
  Designer?: boolean,
  IsSendCopy?: boolean,
  PreferredContactMedia?: string,
  WishListItems?: {
    SKU?: string,
    ID?: string,
    ProductName?: string
  }
}

export interface SubcribeEmailResp {
  Success?: boolean;
  StatusCode?: number;
  Data?: any;
}

export interface TradeEnquiriesRequest {
  Name: string;
  Phone: string;
  Email: string;
  StoreName: string;
  EndConsumer: string;
  NumberOfStores: number;
  PositionAtPrimaryStore?: string;
  StoreWebAddress?: string;
  Address1: string;
  Address2?: string;
  City: string;
  State: string;
  Country: string;
  ZipCode: string;
  Questions?: string;
}

export interface TaAPIUserTypeList {
  UserTypeGroups: {
    Name: string,
    UserTypes: {
      ID: string,
      Name: string
    }[]
  }[]
}

export interface TaAPIResponse<T> {
  Success?: boolean,
  StatusCode?: number,
  Data?: T
}
