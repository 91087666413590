<div class="startConfigurator">
  <p>Click the button and start configurator</p>
  <div style="width:80%;margin:0 auto;">
    <p class="title">Sofa:</p>
    <div class="buttonsBox">
      <button (click)="goToTheodore(products[0].id)">Sofa </button>
      <button (click)="goToTheodore(products[1].id)">Grand Sofa</button>
      <button (click)="goToTheodore(products[2].id)">Long Sofa</button>
      <button (click)="goToTheodore(products[3].id)">Mid Size Sofa Sofa</button>
    </div>
    <div>
      <p class="title">Chair:</p>
      <div class="buttonsBox">
        <button (click)="goToTheodore(products[4].id)">Chair</button>
        <button (click)="goToTheodore(products[5].id)">Chair & Half</button>
      </div>
    </div>
    <div>
      <p class="title">Loveseat:</p>
      <div class="buttonsBox">
        <button (click)="goToTheodore(products[6].id)">Loveseat</button>
        <button (click)="goToTheodore(products[7].id)">Long Loveseat</button>
      </div>
    </div>
    <div>
      <p class="title">Ottoman:</p>
      <div class="buttonsBox">
        <button (click)="goToTheodore(products[8].id)">Ottoman</button>
        <button (click)="goToTheodore(products[9].id)">Ottoman & Half</button>
      </div>
    </div>
    <div>
      <p class="title">Sectional:</p>
      <div class="buttonsBox">
        <button (click)="goToTheodore(products[10].id)">Sectional</button>
      </div>
    </div>
    <!-- <button (click)="goToConfigurator()">Test configurator</button> -->
  </div>
</div>
