<div class="form-address-popup">
  <form nz-form (ngSubmit)="submitAddress()" [formGroup]="addressForm" class="address-form-wrp">
    <nz-form-item *ngIf="address?.name">
      <nz-form-label [nzSm]="7" [nzXs]="24">Name<span *ngIf="address.name.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please name the address">
        <input nz-input placeholder="My office" [(ngModel)]="address.name.value" formControlName="name" nzSize="large">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="address?.email">
      <nz-form-label [nzSm]="7" [nzXs]="24">E-mail<span *ngIf="address.email.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" [nzErrorTip]="emailErrorTpl">
        <input nz-input type="email" formControlName="email" nzSize="large">
        <ng-template #emailErrorTpl let-control>
          <ng-container *ngIf="control.hasError('email')">
            The input is not valid E-mail
          </ng-container>
          <ng-container *ngIf="control.hasError('required')">
            Please input your E-mail
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item >
     <nz-form-item *ngIf="address?.address1">
      <nz-form-label [nzSm]="7" [nzXs]="24">Address <span *ngIf="address?.address2">&nbsp;Line 1</span><span *ngIf="address.address1.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please input the address">
        <input nz-input placeholder="1600 Pennsylvania Ave" [(ngModel)]="address.address1.value" formControlName="address1" nzSize="large">
      </nz-form-control>
    </nz-form-item >
    <nz-form-item *ngIf="address?.address2">
      <nz-form-label [nzSm]="7" [nzXs]="24">Address Line 2<span *ngIf="address.address2.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please input the address">
        <input nz-input placeholder=""  [(ngModel)]="address.address2.value" formControlName="address2" nzSize="large">
      </nz-form-control>
    </nz-form-item>
    <nz-form-item *ngIf="address?.zipCode">
      <nz-form-label [nzSm]="7" [nzXs]="24">ZIP / Postal Code<span *ngIf="address.zipCode.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please input the ZIP / Postal Code">
        <input nz-input placeholder="Zip or postal code" [(ngModel)]="address.zipCode.value" formControlName="zipCode" nzSize="large">
      </nz-form-control>
    </nz-form-item >
    <nz-form-item *ngIf="address?.phone">
      <nz-form-label [nzSm]="7" [nzXs]="24">Phone Number<span *ngIf="address.phone.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please input the phone number">
        <input nz-input placeholder="Phone number" [(ngModel)]="address.phone.value" formControlName="phone" nzSize="large">
      </nz-form-control>
    </nz-form-item >
    <nz-form-item *ngIf="address?.country">
      <nz-form-label [nzSm]="7" [nzXs]="24">Country<span *ngIf="address.country.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please select the country">
        <input style="position: absolute" nz-input [ngModel]="address.country.value" formControlName="country">
        <country-autocomplete [(value)]="address.country.value"></country-autocomplete>
      </nz-form-control>
    </nz-form-item >
    <nz-form-item *ngIf="address?.region">
      <nz-form-label [nzSm]="7" [nzXs]="24">Region<span *ngIf="address.region.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please select the region">
        <input style="position: absolute" nz-input [ngModel]="address.region.value" formControlName="region">
        <region-autocomplete [(value)]="address.region.value" [countryId]="address.country.value"></region-autocomplete>
      </nz-form-control>
    </nz-form-item >
    <nz-form-item *ngIf="address?.city">
      <nz-form-label [nzSm]="7" [nzXs]="24">City<span *ngIf="address.city.validators[AddressFormComponentValidator.required]">&nbsp;(*)</span></nz-form-label>
      <nz-form-control [nzSm]="16" [nzXs]="24" nzErrorTip="Please select the city">
        <input style="position: absolute" nz-input [ngModel]="address.city.value" formControlName="city">
        <city-autocomplete [(value)]="address.city.value" [regionId]='address.region.value'></city-autocomplete>
      </nz-form-control>
    </nz-form-item>
    <!-- <nz-form-item>
      <nz-form-control [nzXs]="{ span: 24, offset: 0 }" [nzSm]="{ span: 16, offset: 8 }">
        <button nz-button nzType="primary">Save</button>
      </nz-form-control>
    </nz-form-item> -->
  </form>
  
</div>