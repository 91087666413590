import { Component, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { TaApiServiceLogin, TaApiUserSetting } from 'src/app/service/api/TaApiServiceLogin';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { TAUtility } from 'src/app/theoalex/theoalex';

export enum TaMatSlideToggleBrowserMode {
  retail = 'R', purchase = 'P'
}

@Component({
  selector: 'app-ta-mat-slide-toggle-module',
  templateUrl: './ta-mat-slide-toggle-module.component.html',
  styleUrls: ['./ta-mat-slide-toggle-module.component.scss']
})
export class TaMatSlideToggleModuleComponent implements OnInit, AfterViewInit, OnDestroy, iTaObserverComponent {

  isPurchaseMode: boolean = false;
  userSettings: TaApiUserSetting;
  userSettingChangedSubscription: Subscription;

  constructor(private loginService: TaApiServiceLogin) {
  }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isPurchaseMode = false;
      this.subscriberEvents();
    })
  }

  subscriberEvents(): void {
    this.userSettingChangedSubscription = TaUniversalHubSubject.userSettingSubject.subscribe(userSetting => {
      this.userSettings = userSetting;
      this.refreshToggleByUserSetting(userSetting);
      if (this.userSettings.ShowWholesalePrice) {
        localStorage.setItem('UserRetail', '1');
      }
      else {
        localStorage.setItem('UserRetail', '0');
      }
    })
  }

  private refreshToggleByUserSetting(userSetting: TaApiUserSetting): void {
    if (userSetting) {
      this.isPurchaseMode = userSetting.ShowWholesalePrice;
    }
  }

  onChanged(event: MatSlideToggleChange) {
    this.userSettings.ShowWholesalePrice = this.isPurchaseMode;
    this.loginService.updateUserSetting(this.userSettings).subscribe();
    if (this.userSettings.ShowWholesalePrice) {
      localStorage.setItem('UserRetail', '1');
    }
    else {
      localStorage.setItem('UserRetail', '0');
    }
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userSettingChangedSubscription);
  }

}
