Configurator component
<br><br>
<button (click)="goToStart()">Go to start</button>
<button (click)="print()">PRINT</button>
<br><br>

<!-- <div id="Intiaro-configurator"
data-customer-id="IntiaroWLShowcase"
data-product-id="8223"
> Loading...
</div> -->

<!--  data-product-id="5084" -->
<!-- data-customer-id="IntiaroWLShowcase" -->
<!-- data-customer-id="TheodoreAlexander" -->
<!-- data-product-id="10190" -->
<!-- data-customer-id="Lexington"
data-product-id="8326" -->

<div class="bigContainer">
  <div id="Intiaro-controller"
    data-customer-id="TheodoreAlexander"
    data-product-id="10190"
    data-choices-confirmation="true"
    > Loading...
  </div>
  <ng-template #galleryTemplate>
    <div class="element1">GAAALLLEEERRRIIIAAAA</div>
  </ng-template>
  <ng-template #nextTemplate>
    <div class="element2">NEXT button</div>
  </ng-template>
</div>

<button (click)="goToParams()">Go to params</button>
<button (click)="goToModules()">Go to modules</button>
<button (click)="getChangedParams()">Get Changed Params</button>
<button (click)="getChangedParamsObjects()">Get Changed Params Objects</button>
<button (click)="goToNextTab('ta_choose_your_back_style')">Go to 'Back style'</button>
<button (click)="goToNextTab('ta_decorative_details')">Go to 'Decorative'</button>
<div><strong>Params after changed:</strong><span>{{paramsChanged}}</span></div>
<div><strong>Configuration after changed:</strong><span>{{configurationChanged}}</span></div>
<div><strong>Last changed param:</strong><span>{{changedParam}}</span></div>
