import { Injectable } from '@angular/core';
import { Observable, of, BehaviorSubject } from 'rxjs';
// import { filter, map, findIndex, find, catchError, tap } from 'rxjs/operators';
import { Comment, Config, Attribute, StepFromNewEvent, UserChoise } from './types';
import { HttpClient } from "@angular/common/http";
import { map, catchError, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

declare var HardData: any;
@Injectable({
  providedIn: 'root'
})
export class StepByStepService {

  public productConfig: Config[] = [];
  public price = 0;
  public currentPhotos: string[] = [];
  public isTopNextActive: boolean = false; //flag for Next in navbarze
  public isBottomNextActive: boolean = false; // flag for bottom Next
  public showSummary: boolean = false; // flag for summary (show if true)
  public isCustomerRequest: boolean = false; //flag for modal in summary "is custom request or not"
  public isCorrectConfigurationSectional = new BehaviorSubject<boolean>(false); //flag for correct of sectional's configuration
  public clickFinishBuilding = new BehaviorSubject<boolean>(false); //if true simulate click in "FinishBuilding"-button when click in Next in first step for sectional
  public textFromSummary: string = '';
  public comments: Comment = {
    customerRequest: '',
    other: '',
  };
  public variablesGroupsReceived = new BehaviorSubject<StepFromNewEvent[]>([]);
  private userChoices: UserChoise = {};
  // urlConfig = 'https://libs.intiaro.com/integrations/theodorealexander/allProductsDataConfig_TA.json';
  urlConfig = environment.TailorFitLiveData;
  pdfDocumentation = [
    { stepSlug: 'ta_choose_your_cover', url: environment.appApiUrl + '/Download/integrations/Finish-options.pdf' },
    { stepSlug: 'ta_choose_your_seat_style', url: environment.appApiUrl + '/Download/integrations/Cushions-and-Back-Pillows.pdf' },
    { stepSlug: 'ta_choose_your_base', url: environment.appApiUrl + '/Download/integrations/Base%20options.pdf' },
  ]
  //"https://libs.intiaro.com/integrations/theodorealexander/allProductsDataConfig_TA.json",
  public productInstanceData: any = {};
  private hardDataURL: string = 'https://libs.intiaro.com/integrations/theodorealexander/1.1/scripts/intiatoIntegration.js';
  public hardDataForProduct: any;

  constructor(private httpClient: HttpClient) {
    (typeof HardData === 'undefined') ? this.createNodeWithScript() : this.createHardData();
  }

  // fetch config data for products
  public getConfig(): Observable<Config[]> {
    return this.httpClient.get<Config[]>(this.urlConfig)
      .pipe(
        map(data => this.productConfig = data),
        // tap( x => console.log('fetched configdata for products: ',x)),
        // tap(_ => console.log(`fetched product data from: ${this.urlConfig}`)),
        catchError(this.handleError<Config[]>('Fetch Config Data Error:', []))
      )
  }
  private handleError<T>(operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {
      console.error(error);
      console.log(`${operation} failed: ${error.message}`);
      return of(result as T);
    };
  }

  public getProductConfig(): Observable<Config[]> {
    const config = of(this.productConfig);
    return config;
  }

  // control status buttona NEXT in navbar
  public getStatusNext(): Observable<boolean> {
    const status = of(this.isTopNextActive);
    return status;
  }

  public toggleNext(value: boolean): void {
    this.isTopNextActive = value;
  }

  // show / hide summary
  public getStatusSummary(): Observable<boolean> {
    const status = of(this.showSummary);
    return status;
  }

  public toggleStatusSummary(value: boolean): void {
    this.showSummary = value;
  }

  // gallery in summary
  public getAllPhotos(): Observable<string[]> {
    const photos = of(this.currentPhotos);
    return photos;
  }

  public addPhotos(arrPhotos: string[]): void {
    this.currentPhotos = arrPhotos;
  }

  // show/hide modal "is custom request or not" in summary
  public getStatusCustomerRequest(): Observable<boolean> {
    const status = of(this.isCustomerRequest);
    return status;
  }

  public toggleStatusCustomerRequest(value: boolean): void {
    this.isCustomerRequest = value;
  }

  // collect info about comments in summary
  public saveCommentFromSummary(content: string) {
    this.textFromSummary = content;
  }
  public saveStatusComment(status: string) {
    if (this.textFromSummary !== '') {
      status === 'customerRequest' ? this.comments.customerRequest = this.textFromSummary : this.comments.other = this.textFromSummary;
      status === 'customerRequest' ? this.comments.other = '' : this.comments.customerRequest = '';
    }
  }

  //bottom next
  public getStatusBottomNext(): Observable<boolean> {
    const status = of(this.isBottomNextActive);
    return status;
  }

  public toggleStatusBottomNext(value: boolean): void {
    this.isBottomNextActive = value;
  }

  //save received attributes groups (steps in menu)
  public saveVariablesGroupsReceived(productId: number, data: StepFromNewEvent[]): void {
    this.variablesGroupsReceived.next(this.mapReceivedData(productId, data));
  }
  public getVariablesGroupsReceived() {
    return this.variablesGroupsReceived.value;
  }

  //save customer choices with using new event listenForChoiceConfirmation()
  public saveChoiceAndCheckNextButton(productId: number, activeStep: string, selection: { attribute: string, choice: string }, attributesData: { disabled: string[], visibleInDOM: string[] }): void {
    let product = this.productConfig.findIndex(el => el.id === productId);
    let currentStep = this.productConfig[product].mapOfAttributes.findIndex(el => el.stepSlug === activeStep);
    let currentAttribute = this.productConfig[product].mapOfAttributes[currentStep].attributes.findIndex(el => el.attributeSlug == selection.attribute);
    let allAttributesInStep = this.productConfig[product].mapOfAttributes[currentStep].attributes;
    allAttributesInStep[currentAttribute].choice = selection.choice;

    //check disabled elements and change status disable:true
    allAttributesInStep.forEach((el: any) => {
      //check disabled elements and change status disable:true
      attributesData.disabled.includes(el.attributeSlug) ? el.disabled = true : el.disabled = false;
      //compare attributes from DOM with attributes from allAttributesInStep (disabled status true for attribute that is not in the DOM and that is in allAttributesInStep)
      attributesData.visibleInDOM.includes(el.attributeSlug) ? '' : el.disabled = true;
    })

    //check if each attribute with 'obligatory === true' has 'choice !== ""' and activate Next if all obligatory attributes are checked
    //save customer choice in navbar (above name of step)
    this.saveChoice(productId, activeStep, selection)
    // this.checkObligatoryAttributes(allAttributesInStep); //don't check if next-button is permanent active
  }

  public checkObligatoryAttributes(arrayWithAttributes: Attribute[]): void {
    //logic for Next-button in external file
    // this.useExternalScriptToCheckObligatoryAttributes(arrayWithAttributes) ? this.toggleNext(true) : '';
    this.useExternalScriptToCheckObligatoryAttributes(arrayWithAttributes) ? this.toggleNext(true) : this.toggleNext(false);
  }

  public saveChoice(productId: number, activeStep: string, infoAboutChoice: { attribute: string, choice: string }): void {
    //use data from api
    let finalName = ''
    this.productInstanceData.choices.find((choice: any) => {
      choice.slug === infoAboutChoice.choice ? finalName = choice.name : '';
    })

    const changedAttribute = this.variablesGroupsReceived.getValue().map(el => {
      if (el.slug === activeStep) {
        let product = this.productConfig.find(el => el.id == productId)
        let numberAttributesInStep = 1;
        product?.mapOfAttributes.find(attr => {
          attr.stepSlug == el.slug ? numberAttributesInStep = attr.attributes.length : ''
        })
        if (numberAttributesInStep > 1) {
          return { ...el, details: 'Multiple Choices' }; //if in the step there are 2 and more attributes
        }
        else if (activeStep === 'sections') {
          return { ...el, details: 'Multiple Choices' };
        }
        else {
          return { ...el, details: finalName };
        }
      }
      return el;
    });
    this.variablesGroupsReceived.next(changedAttribute);
  }

  //change activity and availability of step in navbar
  public toggleNextStepAvailability(name: string, value: boolean) {
    const changedAttribute = this.variablesGroupsReceived.getValue().map(el => {
      if (el.slug === name) {
        return { ...el, isAvailable: value }
      }
      return el;
    });
    this.variablesGroupsReceived.next(changedAttribute);
  }
  public toggleNextStepActivity(index: number, value: boolean) {
    const changedAttribute = this.variablesGroupsReceived.getValue().map((el, i) => {
      if (i === index) {
        return { ...el, isActive: value };
      }
      return { ...el, isActive: !value };
    });
    this.variablesGroupsReceived.next(changedAttribute);
  }

  //map of data from intiaro-controller
  private mapReceivedData(productId: number, data: StepFromNewEvent[]): StepFromNewEvent[] {
    const sortedDataByPriority: StepFromNewEvent[] = data.sort((a, b) => {
      return a.index - b.index; //for 2.5
    });
    let visibleGroupAttributes: StepFromNewEvent[] = [];
    sortedDataByPriority.filter(el => {
      el.hidden ? '' : visibleGroupAttributes.push(el); // TA/2.4
    });
    let isSectional = visibleGroupAttributes.some(el => el.slug === 'sections');
    visibleGroupAttributes.map((el, index) => {
      el.id = index;
      el.stepNumber = index + 1;
      index == 0 ? el.isActive = true : el.isActive = false; //for 2.5
      index == 0 ? el.isAvailable = true : el.isAvailable = false; //for 2.5
      if (isSectional) {
        (index === 0) ? el.isAvailable = true : el.isAvailable = false; // for sectional
        index === 0 ? el.details = 'Click to edit' : el.details = 'Your choice'; // for sectional
        index === 0 ? el.isActive = true : el.isActive = false;
      } else {
        el.details = 'Click to edit';
      };
      this.productConfig.filter(item => {
        if (item.id === productId) {
          item.stepWithPermanentActiveNext.includes(el.slug) ? el.details = 'Multiple  Choices' : el.details = 'Click to edit';
        }
      });
    });
    let summary: StepFromNewEvent = {
      description: '',
      index: 999,
      name: 'Summary',
      priority: 999,
      slug: 'custom_summary',
      id: 999,
      stepNumber: 999,
      isActive: false,
      isAvailable: false,
      details: '',
    }
    visibleGroupAttributes.push(summary);
    return visibleGroupAttributes;
  }

  //flag for next-button in first step of sectional (avtive/inactive)
  public saveStatusConfigrationSectional(value: boolean): void {
    this.isCorrectConfigurationSectional.next(value);
  }

  //control api for simulating of click in "FinishBuilding"-button (only first step of sectional)
  public toggleClickFinishBuilding(value: boolean): void {
    this.clickFinishBuilding.next(value);
  }

  //fetch product instance data from api
  public async fetchProductInstanceData(productId: string, typeOfFurniture: string) {
    let customerName = 'TheodoreAlexander'
    let instance = productId;

    //fetch data from intiaro-backend for product with 'productId'
    let urlProdInstance = `https://public-api.intiaro.com/piminstanceapi/${customerName}/product_instance/${instance}/?internal=false&environment=prod&api_version=1.2.3`
    this.productInstanceData = await (await fetch(urlProdInstance)).json();

    // let productsCategories = this.hardDataForProduct.hardDataProductsConfig
    let fullProductInformation: any = {}
    fullProductInformation.id = this.productInstanceData.id;
    fullProductInformation.customer = customerName

    //fetch hard data from JSON for product with 'productId'
    let productDataFromJSON = this.hardDataForProduct.getProductInfo(fullProductInformation.id);
    (typeof productDataFromJSON.furnitureType == 'string' && productDataFromJSON.furnitureType.length > 0)
      ? fullProductInformation.furnitureType = productDataFromJSON.furnitureType
      : fullProductInformation.furnitureType = typeOfFurniture;

    fullProductInformation.stepWithPermanentActiveNext = productDataFromJSON.stepWithPermanentActiveNext;
    fullProductInformation.optionalAttributes = productDataFromJSON.optionalAttributes;
    fullProductInformation.mapOfAttributes = [];

    this.productInstanceData.variable_groups.forEach((el: any) => {
      if (el.slug != 'ta_technical_group') {
        let attributes: any = []
        this.productInstanceData.attributes.forEach((attr: any) => {
          if (attr.variable_group == el.slug) {
            if (fullProductInformation.optionalAttributes.includes(attr.slug)) {
              attributes.push({ attributeSlug: attr.slug, obligatory: false, choice: "", disabled: false })
            } else {
              attributes.push({ attributeSlug: attr.slug, obligatory: true, choice: "", disabled: false })
            }
          }
        })
        if (el.slug === 'sections') {
          attributes.push({ attributeSlug: 'sections', obligatory: true, choice: "", disabled: false })
        }
        fullProductInformation.mapOfAttributes.push({ stepSlug: el.slug, attributes: attributes });
      }
    })
    this.productConfig = [fullProductInformation]
    // console.log('====this.productConfig', this.productConfig)
    return this.productConfig;
  }

  ////for external function to check obligatory attributes and
  //checking whether <script> exists in <head> in index.html in constructor of this service
  public useExternalScriptToCheckObligatoryAttributes(attributes: Attribute[]): boolean | void {
    if (this.hardDataForProduct.checkChoicesForObligatoryAttributes(attributes)) {
      return true;
    } else {
      return false;
    }
  }

  private createNodeWithScript() {
    let node: HTMLScriptElement = document.createElement('script');
    node.src = this.hardDataURL;
    node.type = 'text/javascript';
    node.async = true;
    node.onload = () => {
      this.createHardData();
    }
    document.getElementsByTagName('head')[0].appendChild(node);
    return true
  }

  private createHardData() {
    this.hardDataForProduct = new HardData();
  }
}
