import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { delay, map, tap } from 'rxjs/operators';
import { TawDealerStore } from 'src/app/state-management/dealer-store-state/dealer-store.state';
import { TaAPIResponse } from './TaApiServiceCommon';

@Injectable({
  providedIn: 'root'
})
export class TaApiServiceDealerStore {

  constructor(private http: HttpClient) {
  }

  private dealerStoreSubject = new ReplaySubject<any>(1);
  private dealerStoresObservable: Observable<any> = this.dealerStoreSubject.asObservable();
  private cachedDealerStoresData;

  public getDealerStores(): Observable<any> {
    if (this.cachedDealerStoresData) {
      return this.dealerStoresObservable;
    } else {
      return this.http.get(`${environment.appApiUrl}/api/Store/GetLocations`)
        .pipe(map((res: any) => {
          this.cachedDealerStoresData = res.Data;
          this.dealerStoreSubject.next(this.cachedDealerStoresData);
          return this.cachedDealerStoresData;
        }))
    }
  }

  public getStoreById(id: string): Observable<TaAPIResponse<TawDealerStore>> {
    return this.http.get<TaAPIResponse<TawDealerStore>>(`${environment.appApiUrl}/api/Store/GetStoreById?storeId=${id}`);
  }

  private USCitiesSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  public getUSCities(): Observable<any> {    
    if (this.USCitiesSubject.value) {      
      return this.USCitiesSubject;
    } else {
      return this.http.get<TaAPIResponse<any>>(`https://theodorealexander.sirv.com/website/Frontend/Live/assests/json/USCities_v100.json`).pipe(map((res) => {                
        this.USCitiesSubject.next(res.Data);
        return this.USCitiesSubject;
      }));
    }
  }
}
