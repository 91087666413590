<div class="overlay-login" style="display: block">
  <div class="overlay-navigation">
           <a class="close" (click)="onClickClose()"><i class="icon-close"></i></a>
      </div>
  <div class="row">
    <div class="main_login align-items-center">
      <div class="col-xl-6 col-md-12">
      <div class="funiture_popup_left">
        <img [src]="furniture?.image">
      </div>
      </div>
      <div class="col-xl-6 col-md-12" >
        <div class="funiture_popup_right">
          <div class="title_popup_funiture_care">Furniture Care</div>
          <div class="title_content_funiture_care"><h1>{{furniture?.ContentTitle}}</h1></div>
          <div class="content_funiture_care" [innerHTML]="furniture?.ContentBody">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
