<div class="overlay-login" style="display: block">
  <!-- <div class="overlay-navigation">
    <a class="close" (click)="onClickClose()"><i class="icon-close"></i></a>
  </div> -->

  <div class="row">
    <div class="main_login align-items-center">
      <div class="col-lg-12 mx-auto">
        <div class="title_login">
          <div class="padd">
            <h2>{{ title }}</h2>
            <span>Once you register with Theodore Alexander you can create Wish Lists that let you save and share your favorite pieces.</span>
          </div>
        </div>
        <div class="content_login">
          <form class="login-form">
            <div class="row">
              <div class="col-xl-6 col-md-12 col-sm-12 re-pad">
                <input type="text" #userFirstName="ngModel" name="userFirstName" placeholder="First name (*)"
                  [(ngModel)]="registerUserObject.Firstname" required [ngClass]="{ invalid: userFirstName.invalid && (userFirstName.dirty || userFirstName.touched)}" />
                <div style="color: #d2000d" [hidden]="!( userFirstName?.invalid && (userFirstName.dirty || userFirstName.touched) )">
                  Firstname is required
                </div>
              </div>
              <div class="col-xl-6 col-md-12 col-sm-12 frm_1 re-pad">
                <input type="text" #userLasName="ngModel" name="userLasName" placeholder="Last name (*)"
                  [(ngModel)]="registerUserObject.Lastname" required [ngClass]="{ invalid: userLasName.invalid && (userLasName.dirty || userLasName.touched)}" />
                <div style="color: #d2000d" [hidden]="!( userLasName?.invalid && (userLasName.dirty || userLasName.touched))">
                  Lastname is required
                </div>
              </div>
            </div>
            <div class="row">
              <!-- <div class="col-xl-6 col-md-12 col-sm-12" style="margin-bottom: 40px">
                <input type="text" #userName="ngModel" name="userName" placeholder="Username (*)"
                  [(ngModel)]="registerUserObject.Username" required [readonly]="
                    registerUserObject.Provider != TawAccountProvider.web
                  " [disabled]="
                    registerUserObject.Provider != TawAccountProvider.web
                  " [ngClass]="{
                    invalid:
                      userName.invalid && (userName.dirty || userName.touched)
                  }" />
                <div style="color: #d2000d" [hidden]="
                    !(userName?.invalid && (userName.dirty || userName.touched))
                  ">
                  Username is required
                </div>
              </div> -->

              <div class="col-xl-6 col-md-12 col-sm-12 re-pad">
                <input type="text" #userEmail="ngModel" name="userEmail" placeholder="Email (*)"
                  [(ngModel)]="registerUserObject.Email" email required [readonly]="registerUserObject.Provider != TawAccountProvider.web" [disabled]="registerUserObject.Provider != TawAccountProvider.web" [ngClass]="{
                    invalid: userEmail.invalid && (userEmail.dirty || userEmail.touched)}" />
                <div style="color: #d2000d" [hidden]="!( userEmail?.invalid && (userEmail.dirty || userEmail.touched))">
                  Email is required
                </div>
              </div>
              <div class="col-xl-6 col-md-12 col-sm-12 frm_1 re-pad">
                <div class="com_select_user">
                  <ta-drop-down style="display: flex; justify-content: flex-end;" textTransform="uppercase" dropDownText="{{ 'Select one user profile (*)' }}"
                    [items]="userTypes" itemDisplayTextField="Name" (itemSelectedChanged)="OnChangedUserType($event)" style="color: #636363">
                  </ta-drop-down>
                </div>
              </div>

            </div>

            <div class="row">
              <div class="col-xl-6 col-md-12 col-sm-12 re-pad" style="display: block !important">
                <div>
                  <input type="password" #userPassword="ngModel" name="userPassword" placeholder="Password (*)"
                    [(ngModel)]="registerUserObject.Password" required appRevealPassword [hidden]="
                      registerUserObject.Provider != TawAccountProvider.web
                    " [ngClass]="{ invalid: userPassword.invalid && (userPassword.dirty || userPassword.touched)}" />
                </div>
                <div style="color: #d2000d" [hidden]="!( userPassword?.invalid && (userPassword.dirty || userPassword.touched)) ">
                  Password is required
                </div>
              </div>
              <div class="col-xl-6 col-md-12 col-sm-12 frm_1" style="margin-bottom: 15px">
                <div>
                  <input type="password" #userConfirmPassword="ngModel" name="userConfirmPassword" [hidden]="
                      registerUserObject.Provider != TawAccountProvider.web
                    " placeholder="Re-enter Password (*)" [(ngModel)]="registerUserObject.confirmedPassword" required
                    appRevealPassword [ngClass]="{ invalid: userConfirmPassword.invalid && (userConfirmPassword.dirty || userConfirmPassword.touched)}" />
                </div>
                <div style="color: #d2000d" [hidden]="!( userConfirmPassword?.invalid && (userConfirmPassword.dirty || userConfirmPassword.touched))">
                  Password confirmation is required
                </div>
                <div style="color: #d2000d" [hidden]="!(userConfirmPassword?.value != userPassword?.value)">
                  Password do not match.
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 chk-term-policy">
                <ta-check-box displayText="I want to be the first to know about new products and upcoming events via email" [(isSelected)]="registerUserObject.IsEmailSubscribed"></ta-check-box>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="d-flex mg-btn">
                  <button nz-button nzType="primary" class="btn btn-register" [ngClass]="{ ta_disabled:
                        userFirstName?.invalid ||
                        userLasName?.invalid ||
                        userEmail?.invalid ||
                        userPassword?.invalid ||
                        userConfirmPassword?.invalid ||
                        userConfirmPassword.value != userPassword.value
                    }" (click)="onRegister()">
                    Sign up
                  </button>
                  <ng-container *ngIf="registerUserObject.Provider == 'web'">
                    <span class="lable_0">Or</span>
                    <button nz-button class="btn btn-google col" appIdentityProviderGoogle>
                      <i class="icon-google_plus"></i> Sign up with Google
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
