import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { IntiaroConfigurationService } from 'src/app/shared/IntiaroConfiguration.service';

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  public email: string = '';
  public check: boolean = false;
  public patternEmail = '^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z0-9-.]{2,6}$'
  public thxMsg:boolean = false;

  @Output() closeModal = new EventEmitter<boolean>();
  @Input() data: any;
  @Input() pdfUrl: any;
  constructor(private intiaroConfigurationService: IntiaroConfigurationService,) { }

  ngOnInit(): void {
  }

  public closeSendingEmail(close: boolean): void {
    this.closeModal.emit(close);
  }

  public send(): void {
    this.thxMsg = true;
    this.intiaroConfigurationService.intiaroSendEmailToCustomer(this.email, this.data).then(success => {
      this.thxMsg = false;
      this.closeSendingEmail(true);
    }, error => {
      console.log(error);
    });
  }

  public printInput(sth: any) {
    // console.log('ng model w input:', sth)
  }
}
