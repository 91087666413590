import { Pipe, PipeTransform } from '@angular/core';
import { TAUtility } from '../theoalex/theoalex';

@Pipe({
  name: 'includeString'
})
export class IncludeStringPipe implements PipeTransform {

  transform(value: string, substring: string, isCaseSensitive?: boolean): any {
    if (value && substring) {
      if (!isCaseSensitive) {
        value = value.toUpperCase();
        substring = substring.toUpperCase();
      }
      let ret = TAUtility.includes(value, substring);
      return ret;
    } else {
      return false;
    }
  }
}

@Pipe({
  name: 'TAPipeStringStartswith'
})
export class TAPipeStringStartswith implements PipeTransform {

  transform(value: string, substring: string, isCaseSensitive?: boolean): any {
    if (value && substring) {
      if (!isCaseSensitive) {
        value = value.toUpperCase();
        substring = substring.toUpperCase();
      }
      let ret = value.startsWith(substring);
      return ret;
    }
    return false;
  }
}
