import {Pipe, PipeTransform} from '@angular/core';
@Pipe({name: 'GetFirstName'})
export class GetFirstNamePipe implements PipeTransform {
    transform(name: string, ...args: any[]):string {
        let firstName = '';
        if (name) {
            firstName = name.split(' ')[0];
        }
        return firstName;
    }
}