import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { IntiaroConfiguration } from 'src/app/shared/IntiaroConfiguration.model';
import { IntiaroConfigurationImage } from 'src/app/shared/IntiaroConfigurationImage.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { TAUtility } from 'src/app/theoalex/theoalex';

@Injectable({
    providedIn: 'root'
})
export class IntiaroConfigurationService {
    list: IntiaroConfiguration[] | undefined;
    listData: IntiaroConfiguration[] | undefined;
    formData: IntiaroConfiguration = {};    
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "IntiaroConfiguration";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
    }   
    getByParentIDToList(parentID: number): Observable<TaAPIResponse<IntiaroConfiguration>> {
        let url = this.aPIURL + this.controller + '/GetByParentIDToList';
        const params = new HttpParams()            
            .set('parentID', JSON.stringify(parentID))
        return this.httpClient.get<TaAPIResponse<IntiaroConfiguration>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncSaveIntiaroConfigurationAndListIntiaroConfigurationImage(model: IntiaroConfiguration, listIntiaroConfigurationImage: IntiaroConfigurationImage[]): Observable<TaAPIResponse<IntiaroConfigurationImage>> {
        let url = this.aPIURL + this.controller + '/AsyncSaveIntiaroConfigurationAndListIntiaroConfigurationImage';
        const formUpload: FormData = new FormData();
        formUpload.append('IntiaroConfiguration', JSON.stringify(model));
        formUpload.append('listIntiaroConfigurationImage', JSON.stringify(listIntiaroConfigurationImage));
        return this.httpClient.post<TaAPIResponse<IntiaroConfigurationImage>>(url, formUpload).pipe(tap(res => {
            if (TAUtility.isAPIRespondOk(res)) {
            }
        }));
    }
    asyncSave(model: IntiaroConfiguration): Observable<TaAPIResponse<IntiaroConfiguration>> {
        let url = this.aPIURL + this.controller + '/AsyncSave';
        const formUpload: FormData = new FormData();
        formUpload.append('IntiaroConfiguration', JSON.stringify(model));        
        return this.httpClient.post<TaAPIResponse<IntiaroConfiguration>>(url, formUpload).pipe(tap(res => {
            if (TAUtility.isAPIRespondOk(res)) {
            }
        }));
    }
    intiaroSendEmailToCustomer(email: string, data: any) {
        let url = this.aPIURL + this.controller + '/IntiaroSendEmailToCustomer';
        const formUpload: FormData = new FormData();
        formUpload.append('IntiaroConfiguration', JSON.stringify(data));   
        formUpload.append('mailAddress', JSON.stringify(email));                 
        return this.httpClient.post(url, formUpload).toPromise();
    }
}

