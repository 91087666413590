import { ActionReducerMap } from '@ngrx/store';
import {TawAppState} from 'src/app/state-management/app-state/app.state'
import {TawAppStateReducer} from 'src/app/state-management/app-state/app.reducer'

export interface AppRootState {
  appState: TawAppState,
}

export const appReducers: ActionReducerMap<AppRootState> = {
  appState: TawAppStateReducer.reducer
}
