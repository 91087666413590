<div class="overlay-design block-designer-popup">
  <div class="overlay-navigation">
    <div class="designer_detail_popup-closeButton" (click)="onClickCloseButton()">
      <i class="icon-close"></i>
    </div>
  </div>

  <div class="row mx-auto">
    <div class="main_login align-items-center">
      <div class="col-xl-6 col-md-12">
      <div class="designer_popup_left">
        <img [src]="designer.Image">
      </div>
      </div>
      <div class="col-xl-6 col-md-12" >
          <div class="design-right">
            <div class="funiture_popup_right">
              <div class="title_popup_funiture_care">Design Partners</div>
              <div class="title_content_funiture_care"><h1>{{designer.Name}}</h1></div>
              <div class="sub_content_popup">
                <div class='designer_detail_popup-highlight'>{{designer.HighLight}}</div>
                <div class='content_funiture_care' [innerHTML]="designer.Info"></div>
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
</div>
