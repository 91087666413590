import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sirvProductMainImage'
})
export class SirvPipe implements PipeTransform {
  transform(sku: string, size?: number, profile?: string): any {
    if (size) {
      return `https://theodorealexander.sirv.com/ProductPhotos/${sku.substring(0,3)}/${sku}_main_1.jpg?w=${size}&profile=${profile||'new_PlaceHolderImageNotFound'}`;
    } else {
      return `https://theodorealexander.sirv.com/ProductPhotos/${sku.substring(0,3)}/${sku}_main_1.jpg?profile=${profile||'new_PlaceHolderImageNotFound'}`;
    }
  }
}
