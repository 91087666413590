import { Directive, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { RegisterPageComponent } from '../page/register-page/register-page.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { TaApiServiceRegisterUser } from '../service/api/TaApiServiceLogin';
import { TawAccountProvider } from '../theoalex/theoalex';

@Directive({
  selector: '[appRegisterPopup]'
})
export class RegisterPopupDirective {

  constructor(private dialog: NzModalService) { }

  @HostListener("click") function(){
    let dialogRef = RegisterPopupDirective.show(this.dialog);
    dialogRef.componentInstance.dialogRef = dialogRef;
  }

  public static show(modalService: NzModalService, registerObj?: TaApiServiceRegisterUser, title?: string) : NzModalRef{
    let registerModal = modalService.create({
      nzClassName: 'ngb-modal-window-ta-full-screen',
      nzFooter: null,
      nzContent: RegisterPageComponent
    });
    registerModal.componentInstance.registerUserObject = registerObj || {Provider: TawAccountProvider.web, IsEmailSubscribed: true};
    registerModal.componentInstance.dialogRef = registerModal;
    title && (registerModal.componentInstance.title = title);

    return registerModal;
  }
}
