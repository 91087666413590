import { Component, OnInit, Input, Output, AfterViewInit, ViewChild, ElementRef, OnChanges, HostListener } from '@angular/core';
import { EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HeaderMobileWithMenuComponent } from '../header/header-mobile/header-mobile-with-menu/header-mobile-with-menu.component';
import { AppGlobal } from 'src/app/shared/AppGlobal';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, AfterViewInit, OnChanges {

  shownValue: boolean;
  textSearch: string;

  @ViewChild("searchInput") searchInput: ElementRef;
  @Output() onSearchSubmitted = new EventEmitter<any>();
  @Output() onSearchCollapsed = new EventEmitter<any>();
  @Input() isExpanded: boolean;
  @Input() isDesktop: boolean;

  //to close search when user click out side, only for desktop
  @HostListener('click', ['$event'])
  clickInside($event) {
    if (this.isDesktop) {
      $event.stopPropagation();
    }
  }
  @HostListener('document:click')
  clickout() {
    if (this.isDesktop) {
      this.isExpanded = false;
    }
  }

  constructor(private router: Router) { }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void {
    if (changes && changes.isExpanded && changes.isExpanded.currentValue) {
      this.setSearchInputFocus();
    }
  }

  ngOnInit() {
  }

  toggle(): void {
    this.isExpanded = !this.isExpanded;
    this.setSearchInputFocus();
  }

  onClickIconSearch() {
    if (this.isExpanded) {
      this.goSearch();
    } else {
      this.isExpanded = true;
      this.setSearchInputFocus();
    }
  }

  onPressKeyEnter() {
    this.goSearch();
    this.textSearch = "";
  }

  goSearch() {
    // let url = AppGlobal.SetSearchURLByName(this.textSearch);
    // this.router.navigate([url]);
    this.router.navigate(["/product-listing/", { SearchKey: this.textSearch ? this.textSearch.trim() : "" }], { skipLocationChange: true });
    this.onSearchSubmitted.emit();
    this.closeSearchBar();
  }

  onPressKeyESC() {
    if (this.textSearch) {
      this.textSearch = "";
    } else {
      this.closeSearchBar();
    }
  }

  ngAfterViewInit(): void {
    this.setSearchInputFocus();
  }

  closeSearchBar() {
    this.isExpanded = false;
    this.onSearchCollapsed.emit();
  }

  setSearchInputFocus() {
    if (this.isExpanded) {
      setTimeout(() => {
        this.searchInput.nativeElement.focus();
      }, 500)
    }
  }
}
