import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { configurationForPricing } from './types'

@Injectable({
  providedIn: 'root'
})
export class PricingService {

  public price = 0;

  public apiPricingPOST: string = 'https://theodorealexander.pim.intiaro.com/api-pricing/price/'

  constructor(private http: HttpClient) { }

  public sendConfigurationForPricing(configuration: any): any {
    let dataForPricingJSON = this.prepareConfigurationForPricing(configuration)
    this.sendToApiForPricing(dataForPricingJSON)
  }

  private prepareConfigurationForPricing(configuration: any): string {

    let dataForPricing: configurationForPricing  = {
      contexts: ['simplified'],
      region: 'en_US',
      currency: 'USD',
      configuration: {
        elements: []
      }
    }

    for(const el in configuration.elements) {
      let elementFromConfiguration = {
        name: el,
        id: configuration.elements[el].element_id,
        sku: configuration.elements[el].element_sku,
        variables: this.makeArrayFromVariables(configuration.elements[el].variables)
      }
      dataForPricing.configuration.elements.push(elementFromConfiguration)
    }

    return JSON.stringify(dataForPricing)
  }

  private makeArrayFromVariables(variables: any): {attribute: string, choice: string}[] {
    let array = [];
    for(const variable in variables) {
      array.push({attribute: variable, choice: variables[variable]})
    }
    return array
  }

  private sendToApiForPricing(content: string) {
    let newHeaders = new HttpHeaders({
      "Content-Type": "application/json",
      "Accept": "application/json"
    });

    this.http.post<any>(this.apiPricingPOST, content
        , {headers: newHeaders}).subscribe(data => {
          this.price = data.list_price.value;
        })
  }

  public checkPrice(): Observable<number> {
    const priceFromService = of(this.price);
    return priceFromService;
  }

  public clearPrice() {
    this.price = 0;
  }
}
