import { Router } from '@angular/router';
import { Component, OnInit, AfterViewChecked, AfterViewInit, ViewChild, TemplateRef, ViewContainerRef } from '@angular/core';

// declare var IntiaroConfigurator: any;
// declare var IntiaroPlayer: any;
declare let IntiaroConfigurator: any;
declare let getIntiaroController: any;

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.css']
})
export class ConfiguratorComponent implements OnInit, AfterViewChecked, AfterViewInit {

  @ViewChild('galleryTemplate') galleryTemplate!: TemplateRef<any>;
  @ViewChild('nextTemplate') nextTemplate!: TemplateRef<any>;

  controller: any;
  player: any;
  paramsChanged: any;
  configurationChanged: any;
  changedParam: any;

  constructor(
    private router: Router,
    private viewContainerRef: ViewContainerRef
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    // this.player  = new IntiaroConfigurator('Intiaro-controller');
    // this.controller = getIntiaroController('Intiaro-controller');
    // console.log('controller', this.controller);
    // console.log('player', this.player);
    this.listenParamsChanged();
    this.listenConfigurationChanged();
    this.listenForVariablesGroupsReceived();
    this.listenForChoiceConfirmation();

    // const playerInDOM = document.querySelector('.intiaro360player')
    // const el = document.createElement("div");
    // el.classList.add("element1");
    // el.style.background = `hsl(${Math.random()*360}, 90%, 70%)`;
    // el.style.color = "#333";
    // el.innerText = `TEST TEST TEST`;
    // // const newDiv1 = '<div style="background-color:red">TEST TEST TES</div>'
    // playerInDOM && playerInDOM.after(el);

    // const controllerInDOM = document.querySelector('.intiaroController')
    // const el2 = document.createElement("div");
    // el2.classList.add("element2");
    // // el2.style.background = `hsl(${Math.random()*360}, 90%, 70%)`;
    // // el2.style.color = "#777";
    // el2.innerText = `LOREM LOREM LOREM`;
    // controllerInDOM && controllerInDOM.after(el2);

    let embeddedGallery = this.viewContainerRef.createEmbeddedView( this.galleryTemplate )
    let embeddedNext = this.viewContainerRef.createEmbeddedView( this.nextTemplate )

    embeddedGallery.detectChanges();
    embeddedNext.detectChanges();
    for ( let node of embeddedGallery.rootNodes ) {
			document.querySelector('.intiaroController')?.after( node );
		}
    for ( let node of embeddedNext.rootNodes ) {
			document.querySelector('.intiaroController')?.after( node );
		}

  }

  ngAfterViewChecked() {
  }

  goToStart() {
    this.router.navigate(['']);
  }

  // pokazuje aktualne parametry produktu
  goToParams() {
    console.log('params', this.controller.getParams());
    //console.log('url', this.player.getFrameUrl(90));
    this.controller.setAngle(220)
  }

  // pokazuje aktualne moduły sectionali + ich parametry (gdy nie sectional, pokazuje po prostu jeden obiekt z parametrami)
  goToModules() {
    console.log('modules', this.controller.getModularConfiguration(
      true,
      (success: any) => {console.log(success)},
      (err: any) => {console.log('nie ma modułów',err)})
    );
  }

  // pokazuje tylko zmienione parametry
  getChangedParams() {
    console.log('getChangedParams', this.controller.getChangedParams());
  }

  // pokazuje komplet info o zmienionych parametrach (można wyciągnąć 'użytkową' nazwę tych parametrów)
  getChangedParamsObjects() {
    console.log('getChangedParamsObjects', this.controller.getChangedParamsObjects());
  }

  // nasłuchiwanie na zmiany parametrów
  listenParamsChanged() {
    console.log('start listenParamsChanged')
    this.controller.registerListener(
      'paramsChanged',
      (configuration : any) => {
        this.paramsChanged = JSON.stringify(configuration);
        console.log('listener słuchający params:', configuration);
      }
    )
  }

  // nasłuchiwanie na zmiany modułów w secionalach
  listenConfigurationChanged() {
    console.log('start listenConfigurationChanged')
    this.controller.registerListener(
      'configurationChanged',
      (configuration : any) => {
        this.configurationChanged = JSON.stringify(configuration);
        let params = this.controller.getChangedParamsObjects();
        this.displayChangedParams(params);
        console.log('listener słuchający konfiguracji:', configuration);
      }
    )
  }

  // listen for variables groups to be delivered
  // mockVariableGroups_0
  private listenForVariablesGroupsReceived(): void {
    this.controller.registerListener(
      'variableGroupsReceived',
      (tabsDefinition: any) => {
        console.log("Tabs received", tabsDefinition);
      }
    );
  }

  // listen choice confirmation
  private listenForChoiceConfirmation(): void {
    this.controller.registerListener(
      'choiceConfirmed',
      (selection: any) => {
        console.log("choice confirmed", selection);
      }
    );
  }

  displayChangedParams(params: any) {
    params.forEach((object: any) => {
      this.changedParam = `${object.attribute.name}: ${object.choice.name}`;
      console.log('zmieniony parametr:', this.changedParam)
    });
  }

  print() {
    this.player.getProductSummaryWithDimensionsUrl([40, 120, 230], 'i', (url: any) => {
      window.location.href = url;
      console.log('Printing:', url);
    }, () => console.log('No pdf to print'));
  }

  goToNextTab(slug: string) {
    this.controller.openAttributesGroup(slug)
    console.log('otwarcie', slug)
  }
}
