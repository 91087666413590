import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { BreakpointState } from '@angular/cdk/layout';
import { TaUniversalHubSubject, screenBreakpointEventSize } from 'src/app/service/web/universal-hub.service';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TaApiServiceLoginUser, TaApiServiceLogin, TaUserCredential } from 'src/app/service/api/TaApiServiceLogin';
import { RegisterPageComponent } from 'src/app/page/register-page/register-page.component';
import { LoginPageComponent } from 'src/app/page/login-page/login-page.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { TaApiServiceShoppingCart, ClientShoppingCartItem } from 'src/app/service/api/TaApiServiceShoppingCart';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-mobile-with-menu',
  templateUrl: './header-mobile-with-menu.component.html',
  styleUrls: ['./header-mobile-with-menu.component.scss']
})
export class HeaderMobileWithMenuComponent implements OnInit, AfterViewInit, OnDestroy, iTaObserverComponent {

  private screenSizeBreakpointSubscriber: Subscription;

  loginUser: TaUserCredential;
  shoppingCartCount = 0;
  modalRef: NgbModalRef;
  isShowMenu: boolean = false;
  isShowSearch: boolean = false;

  constructor(private loginService: TaApiServiceLogin,
    private dialog: MatDialog,
    private shoppingCartService: TaApiServiceShoppingCart) {
  }

  ngOnInit() {
    this.loginUser = this.loginService.taUserCredential;
    if (this.loginService.isLogin) {
      this.shoppingCartService.getClientShoppingCart(() => {
        setTimeout(()=>{this.shoppingCartCount = this.shoppingCartService.shoppingCart ? parseInt(this.shoppingCartService.shoppingCart.Count) : 0;},0);
      })
    }
  }

  ngAfterViewInit(): void {
    this.subscriberEvents();
  }

  subscriberEvents(): void {
    this.screenSizeBreakpointSubscriber = TaUniversalHubSubject.screenBreakpointEvent.subscribe(size => {
      if (size == screenBreakpointEventSize.mobile) {
        // this.isShowMenu = false;
        // this.isShowSearchBox = false;
      }
      if (size == screenBreakpointEventSize.tablet) {

      }
      if (size == screenBreakpointEventSize.desktop) {
        this.modalRef.close();
      }
    })
  }

  toggleMenu(){
    this.isShowSearch = false;
    this.isShowMenu = !this.isShowMenu;
    if (!this.isShowMenu) {
      this.closeHeader();
    }
  }

  toggleSearch(){
    this.isShowMenu = false;
    this.isShowSearch = !this.isShowSearch;
    if (!this.isShowSearch) {
      this.closeHeader();
    }
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents()
  }

  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.screenSizeBreakpointSubscriber);
  }

  closeHeader(){
    this.modalRef.close();
  }
}
