import { Injectable, Optional } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http'
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';
import { TaAPIResponse } from './TaApiServiceCommon';
import { map, tap } from 'rxjs/operators';
import { TAUtility } from 'src/app/theoalex/theoalex';
import * as _ from "lodash";
import { TaUniversalHubSubject } from '../web/universal-hub.service';
import { TaApiUphFabric } from './TaApiServiceUpholstery';
import { ItemService } from 'src/app/shared/Item.service';

@Injectable({
  providedIn: 'root'
})
export class TaApiServiceProduct {

  constructor(@Optional() private http: HttpClient,
    private itemService: ItemService) {
    TaUniversalHubSubject.userCredentialSubjectChanged.subscribe(() => {
      this.clearProductFromCache();
      this.clearProductFilterFromCache();
      this._productDataPrinting = null;
    });
  }

  private _productDataPrinting: {
    product: any,
  };
  get productDataPrinting(): any {
    return this._productDataPrinting;
  }
  set productDataPrinting(value: any) {
    this._productDataPrinting = value;
  }

  private productFilterCached: any[] = [];
  private productFilterCachedSize: number = 1;
  pushProductFilterToCache(request: TAHttpModelGetItemsRequest, response: TAHttpModelGetItemsRespond, filteredData: any, scrollPosition: number) {
    if (TAUtility.isAPIRespondOk(response)) {
      if (this.productFilterCached.length >= this.productFilterCachedSize) {
        this.productFilterCached.shift();
      }
      //WARNING: clone deep does not work
      let req = _.cloneDeep(request);
      let res = _.cloneDeep(response);
      let data = _.cloneDeep(filteredData);
      res.Data.Items = filteredData;
      this.productFilterCached.push({
        request: req,
        response: res,
        data: data,
        scrollPosition: scrollPosition
      });
    }
  }
  getProductFilterFromCache(request: TAHttpModelGetItemsRequest): any {
    return _.findLast(this.productFilterCached, item => {
      let isEqual = item.request.timestamp == request.timestamp;
      return isEqual;
    })
  }
  clearProductFilterFromCache(): any {
    this.productFilterCached = []
  }


  //+++PRODUCT CACHING
  private productCache: any = {
    data: [],
    size: 5
  }
  private pushProductToCache(product: TAProductDetailViewModel) {
    if (this.productCache.data.length >= this.productCache.size) {
      this.productCache.data.shift();
    }
    this.productCache.data.push(product);
  }
  private getProductFromCache(findField: string, findValue: string): TAProductDetailViewModel {
    return _.findLast(this.productCache.data, product => {
      return product[findField].toUpperCase() == findValue.toUpperCase();
    })
  }
  clearProductFromCache() {
    this.productCache.data = [];
  }
  //---PRODUCT CACHING

  getItemById(productId: string, ignoreCached?: boolean): Observable<TaAPIResponse<TAProductDetailViewModel>> {
    let cachedProduct = this.getProductFromCache("ID", productId);
    if (!ignoreCached && cachedProduct) {
      return new Observable<any>((observer: Observer<any>) => {
        setTimeout(() => {
          observer.next(TAUtility.buildAPIRespondOk(cachedProduct));
        }, 0)
      });
    } else {
      return this.http.get<TaAPIResponse<TAProductDetailViewModel>>(`${environment.appApiUrl}/api/item/GetItemById/${productId}`).pipe(tap((res: any) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.pushProductToCache(res.Data);
        }
      }));
    }
  }
  getItemBySku(sku: string, ignoreCached?: boolean): Observable<TaAPIResponse<TAProductDetailViewModel>> {
    let cachedProduct = this.getProductFromCache("SKU", sku);
    if (!ignoreCached && cachedProduct) {
      return new Observable<any>((observer: Observer<any>) => {
        setTimeout(() => {
          observer.next(TAUtility.buildAPIRespondOk(cachedProduct));
        }, 0)
      });
    } else {
      return this.http.get(`${environment.appApiUrl}/api/item/GetItemBySku?sku=${sku}`).pipe(tap((res: any) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.pushProductToCache(res.Data);
        }
      }));
    }
  }
  getItemByURLCode(URLCode: string, ignoreCached?: boolean): Observable<TaAPIResponse<TAProductDetailViewModel>> {
    let cachedProduct = this.getProductFromCache("URLCode", URLCode);
    if (!ignoreCached && cachedProduct) {
      return new Observable<any>((observer: Observer<any>) => {
        setTimeout(() => {
          observer.next(TAUtility.buildAPIRespondOk(cachedProduct));
        }, 0)
      });
    } else {
      return this.http.get(`${environment.appApiUrl}/api/item/GetItemByURLCode?URLCode=${URLCode}`).pipe(tap((res: any) => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.pushProductToCache(res.Data);
        }
      }));
    }
  }

  getItems(request: TAHttpModelGetItemsRequest): Observable<TAHttpModelGetItemsRespond> {
    //check and build dimension range for filtering
    if (request.dimensionRangeDepthMin || request.dimensionRangeDepthMax
      || request.dimensionRangeHeightMin || request.dimensionRangeHeightMax
      || request.dimensionRangeWidthMin || request.dimensionRangeWidthMax) {
      request.DimensionRange = {
        DepthMin: Math.floor(request.dimensionRangeDepthMin || 0),
        DepthMax: Math.ceil(request.dimensionRangeDepthMax || 0),
        HeightMin: Math.floor(request.dimensionRangeHeightMin || 0),
        HeightMax: Math.ceil(request.dimensionRangeHeightMax || 0),
        WidthMin: Math.floor(request.dimensionRangeWidthMin || 0),
        WidthMax: Math.ceil(request.dimensionRangeWidthMax || 0),
        IsInch: !request.dimensionRangeIsCentimetUnit,
      }
    }
    const command = 'api/Item/GetItemsV2';
    // cleanup data
    for (const property in request) {
      if (typeof request[property] === 'undefined') {
        delete request[property];
      }
    }
    request.IPAddress = this.itemService.ipAddress;

    return this.http.post(`${environment.appApiUrl}/${command}`, request).pipe(tap(() => {
      delete request.DimensionRange; //do not need to keep it at client, if keep it, it will be shown on URL
    })) as Observable<TAHttpModelGetItemsRespond>;
  }

  getItemsCount(request: TAHttpModelGetCountItemForSideBarFilter): Observable<any> {
    const command = 'api/Item/CountItemForSideBarFilter';
    // cleanup data
    for (const property in request) {
      if (typeof request[property] === 'undefined') {
        delete request[property];
      }
    }
    return this.http.post(`${environment.appApiUrl}/${command}`, request).pipe() as Observable<TAHttpModelGetItemsRespond>;
  }

  getDimensionRange(request: TAHttpModelGetDimensionRangeRequest): Observable<TaAPIResponse<TAHttpModelGetDimensionRangeRespondData>> {
    const command = 'api/Item/GetDimensionRange';
    return this.http.post<TaAPIResponse<TAHttpModelGetDimensionRangeRespondData>>(`${environment.appApiUrl}/${command}`, request).pipe(
      map((res: TaAPIResponse<TAHttpModelGetDimensionRangeRespondData>) => {
        if (TAUtility.isAPIRespondOk(res)) {
          res.Data.CM.DepthMax = Math.ceil(res.Data.CM.DepthMax);
          res.Data.CM.DepthMin = Math.floor(res.Data.CM.DepthMin);
          res.Data.CM.WidthMax = Math.ceil(res.Data.CM.WidthMax);
          res.Data.CM.WidthMin = Math.floor(res.Data.CM.WidthMin);
          res.Data.CM.HeightMax = Math.ceil(res.Data.CM.HeightMax);
          res.Data.CM.HeightMin = Math.floor(res.Data.CM.HeightMin);
          res.Data.Inch.DepthMax = Math.ceil(res.Data.Inch.DepthMax);
          res.Data.Inch.DepthMin = Math.floor(res.Data.Inch.DepthMin);
          res.Data.Inch.WidthMax = Math.ceil(res.Data.Inch.WidthMax);
          res.Data.Inch.WidthMin = Math.floor(res.Data.Inch.WidthMin);
          res.Data.Inch.HeightMax = Math.ceil(res.Data.Inch.HeightMax);
          res.Data.Inch.HeightMin = Math.floor(res.Data.Inch.HeightMin);
        }
        return res;
      })
    );
  }
  getIntiaroData(): Observable<TAIntiaroData[]> {
    const url = 'https://public-api.intiaro.com/app/TheodoreAlexander/product_configurations/availability/?api_version=2.0';
    return this.http.get<TAIntiaroData[]>(url);
  }
}

export interface TAProductDetailViewModel {
  ID?: string,
  SKU?: string,
  ProductName?: string
  ParentCode?: string;
  DefaultCode?: string;
  Story?: string;
  VariationDescription?: string;
  ExtendedDescription?: string;
  AdditionalFeatures?: string;
  IsNew?: boolean;
  QuantityMultiplier?: number;
  UPHFinish?: boolean;
  CBM?: number;
  Depth?: number;
  Width?: number;
  Height?: number;
  Dimensions?: string;
  DimensionsCM?: string;
  ChairArmHeight?: number;
  ChairArmHeightInch?: string;
  ChairSeatHeight?: number;
  ChairSeatHeightInch?: string;
  ChairInsideSeatDepth?: number;
  ChairInsideSeatDepthInch?: string;
  ChairInsideSeatWidth?: number;
  ChairInsideSeatWidthInch?: string;
  TableClearance?: number;
  TableClearanceInch?: string;
  TableClosedDepth?: number;
  TableClosedDepthInch?: string;
  TableClosedWidth?: number;
  TableClosedWidthInch?: string;
  TableClosedHeight?: number;
  TableClosedHeightInch?: string;
  TableLeavesWidth?: number;
  TableLeavesWidthInch?: string;
  TableLeavesCount?: number;
  TablesSeatsCountClosed?: number;
  TablesSeatsCountOpen?: number;
  Designer?: ObjectIdName;
  Designer_ID?: string;
  Designer_Name?: string;
  LifeStyle?: ObjectIdName;
  LifeStyle_ID?: string;
  LifeStyle_Name?: string;
  Style?: ObjectIdName;
  Style_ID?: string;
  Style_Name?: string;
  Type?: ObjectIdName;
  Type_ID?: string;
  Type_Name?: string;
  RoomAndUsage?: ObjectIdName;
  RoomAndUsage_ID?: string;
  RoomAndUsage_Name?: string;
  Brand?: ObjectIdName;
  Brand_ID?: string;
  Brand_Name?: string;
  Collection?: ObjectIdName;
  Collection_ID?: string;
  Collection_Name?: string;
  OptionGroups?: ObjectIdName[];
  OptionGroup_ID?: string;
  OptionGroup_Name?: string;
  OptionGroup_GroupName?: string;
  OptionGroup2_ID?: string;
  OptionGroup2_Name?: string;
  OptionGroup2_GroupName?: string;
  OptionGroup3_ID?: string;
  OptionGroup3_Name?: string;
  OptionGroup3_GroupName?: string;
  Materials?: ObjectIdName[];
  Shapes?: ObjectIdName[];
  FurnitureCares?: any[];
  ListOption?: any[];
  ListBedSizeOption?: any[];
  ListAvailableSizesOption?: any[];
  ListSpecialOption?: any[];
  RetailPriceList?: any;
  WholesalePriceList?: any;
  RelatedItems?: any[];
  CatalogsDownload?: any[];
  SchematicDiagram?: any[];
  Downloads?: any[];
  InStockProgram?: boolean;
  Availability?: string;
  IsAvailable?: boolean;
  IsCFPItem?: boolean;
  IsAXHCFPItem?: boolean;
  IsInWishList?: boolean;
  Fabrics?: TaApiUphFabric[];
  //client side property
  images?: any[];
  sketchImage?: string;
  seatingPlanImage?: string;
  seatingPlanPdf?: string;
  additionalFeatures?: (any)[][];
  socialShareProductLinks?: any;
  listOption?: any;
  listBedSizeOption?: any;
  listAvailableSizesOption?: any;
  listSpecialOption?: any;
  dimensionUnits?: any;
  Tags?: { [key: string]: boolean }
  URL?: string,
  URLCode?: string,
  CMSideAndFrontRailApronClearance?: number;
  CMSlatToTopOfSideRailClearance?: number;
  CMSlatToTopOfFootRailClearance?: number;
  INCHSideAndFrontRailApronClearance?: number;
  INCHSlatToTopOfSideRailClearance?: number;
  INCHSlatToTopOfFootRailClearance?: number;
  INCHSideAndFrontRailApronClearanceString?: string;
  INCHSlatToTopOfSideRailClearanceString?: string;
  INCHSlatToTopOfFootRailClearanceString?: string;
}

export interface ObjectIdName {
  ID?: string;
  Name?: string;
  SecondaryName?: string;
}

export interface TAHttpModelGetDimensionRangeRespondData {
  CM?: {
    DepthMax?: number,
    DepthMin?: number,
    HeightMax?: number,
    HeightMin?: number,
    WidthMax?: number,
    WidthMin?: number
  },
  Inch?: {
    DepthMax?: number,
    DepthMin?: number,
    HeightMax?: number,
    HeightMin?: number,
    WidthMax?: number,
    WidthMin?: number
  }
}

export interface TAHttpModelGetDimensionRangeRequest extends ItemRequest { }

export interface ItemRequest {
  BrandIds?: string,
  //Brands?: string,
  CollectionIds?: string,
  //Collections?: string,
  RoomIds?: string,
  //Rooms?: string,
  TypeIds?: string,
  //Types?: string,
  LifeStyleIds?: string,
  //LifeStyles?: string,
  StyleIds?: string,
  //Styles?: string,
  DesignerIds?: string,
  //Designers?: string,
  ShapeIds?: string,
  //Shapes?: string,
  OptionGroupIds?: string,
  //OptionGroups?: string,
  ColourAndFinishIds?: string,
  //ColourAndFinishes?: string,

  PageSize?: number,
  PageNum?: number,
  OrderBy?: string,
  Ascending?: boolean,
  SearchKey?: string,
  NewOnly?: boolean,
  DefaultItemOnly?: boolean,
  IsStockProgram?: boolean,
  IsStocked?: boolean,
  IsCustomPalette?: boolean,
  Extending?: boolean,
  IsBestSeller?: boolean,

  //do not use at client side, use dimensionRange... instead of
  DimensionRange?: {
    WidthMin?: number,
    WidthMax?: number,
    HeightMin?: number,
    HeightMax?: number,
    DepthMin?: number,
    DepthMax?: number
    IsInch?: boolean
  };
}

export interface TAHttpModelGetItemsRequest extends ItemRequest {
  //use to set dimension at client side
  dimensionRangeWidthMin?: number,
  dimensionRangeWidthMax?: number,
  dimensionRangeHeightMin?: number,
  dimensionRangeHeightMax?: number,
  dimensionRangeDepthMin?: number,
  dimensionRangeDepthMax?: number,
  dimensionRangeIsCentimetUnit?: boolean,
  requestCount?: number,
  timestamp?: number,
  countBy?: string,//if countBy, no lockedBy
  lockedBy?: string,//e.g. "CollectionIds,BrandIds,StyleIds" without space, if there is lockedBy, no countBy
  introduceId?: number,
  isAdvancedMode?: boolean//is advance mode filter
  IPAddress?: string,
}

export interface TAHttpModelGetItemsRespond {
  Success?: boolean,
  StatusCode?: number,
  Data?: {
    Items?: TAProductDetailViewModel[],
    TotalCount?: number,
    TotalPage?: number,
    CurrentPage?: number,
    PreviousPage?: boolean,
    PageSize?: number,
    NextPage?: boolean
  }
}

export interface TAHttpModelGetItemsRespondItem {
  ID?: string,
  SKU?: string,
  ProductName?: string,
  VariationDescription?: string,
  ExtendedDescription?: string,
  AdditionalFeatures?: string,
  ParentCode?: string,
  DefaultCode?: string,
  CategoryId?: string,
  CategoryName?: string,
  CollectionName?: string,
  BrandId?: string,
  BrandName?: string,
  Themes?: any[],
  MaterialPriName?: string,
  MaterialSecName?: string,
  MaterialTerName?: string,
  Keywords?: string,
  OptionGroupName?: string,
  OptionGroups?: string,
  OptionGroup_ID?: string,
  OptionGroup2_ID?: string,
  OptionGroup3_ID?: string,
  Types?: string,
  RoomAndUsage_Name?: string,
  Collection_Name?: string,
  Theme_Name?: string,
  ColourAndFinishes?: string,
  Styles?: string,
  MinimumDelay?: number,
  HasOtherSizes?: boolean,
  Depth?: number,
  Width?: number,
  Height?: number,
  TableClosedDepth?: number,
  TableClosedWidth?: number,
  TableClosedHeight?: number,
  ChairSeatHeight?: number,
  ChairArmHeight?: number,
  ChairInsideSeatDepth?: number,
  ChairInsideSeatWidth?: number,
  TableClearance?: number,
  TableLeavesCount?: number,
  TableLeavesWidth?: number,
  TableSeatsCountClosed?: number,
  TableSeatsCountOpen?: number,
  IsNew?: boolean,
  Colour?: string,
  Shape?: string,
  RoomAndUsageName?: string,
  Geography?: string,
  Century?: string,
  Theme?: string,
  ProcessAndTechnique?: string,
  Feature2D?: string,
  Feature3D?: string,
  Price?: number,
  Story?: string,
  IsActive?: boolean,
  IsActiveTAUS?: boolean,
  IsActiveINTL?: boolean,
  IsAuthorized?: boolean,
  Availability?: any,
  QuantityMultiplier?: 1,
  CollectionId?: string,
  MSRPrice?: number,
  CFPPrice?: number,
  IsAXHGroup?: boolean,//OptionGroup: 6D3916AD-04F4-4D9B-97E9-5A1FCDE2DCA2
  IsAXHGroupIntl?: boolean,
  IsAXHGroupTAUS?: boolean,
  IsCustomFinish?: boolean,
  Options?: any,
  InStockProgram?: boolean
  _image?: {
    mainImageUrls?: string[],
    moreImageUrls?: string[]
  }
}

export interface TAHttpModelGetCountItemForSideBarFilter {
  CountBy: string,
  IgnoreCountby?: ItemRequest,
  IncludeCountby?: ItemRequest
}

export interface TAIntiaroData {
  id: string,
  client_identifier: string,
  product_thumbnail: string,
  product_name: string,
  product_category: string
}