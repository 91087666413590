import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { TaApiServiceLogin } from './TaApiServiceLogin';
import { Observer, Observable } from 'rxjs';
import { TaAPIResponse } from './TaApiServiceCommon';
import { tap } from 'rxjs/operators';
import { TAUtility } from 'src/app/theoalex/theoalex';
import * as _ from "lodash";
import { TAProductDetailViewModel } from './TaApiServiceProduct';
import { TALocalStogareService } from 'src/app/service/core/taLocalStorage';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class TaApiWishlistService {

  constructor(private http: HttpClient, private serviceLogin: TaApiServiceLogin, private taLocalStorage: TALocalStogareService, @Inject(PLATFORM_ID) private platformId) { }

  getUserWishlist(): Observable<TaAPIResponse<TaAPIWishlistData[]>> {
    if (this.serviceLogin.isLogin) {
      //if user login, it is ok to clear the anonymous wishlist
      if (isPlatformBrowser(this.platformId)) {
        this.taLocalStorage.clearAnonymousWishlist();
      }
      //then return the wishlist of the user from server
      const command = "api/WishList/GetWishListOfUser";
      return this.http.get<TaAPIResponse<TaAPIWishlistData[]>>(`${environment.appApiUrl}/${command}`).pipe(tap(res => {
        if (TAUtility.isAPIRespondOk(res)) {
          this.appendClientDataObject(res.Data);
        }
      }));
    } else {
      //if user not not login, return the default wishlist name "LookBook"
      return new Observable<TaAPIResponse<TaAPIWishlistData[]>>((observer: Observer<TaAPIResponse<any>>) => {
        //simulate the respond from api
        setTimeout(() => {
          if (isPlatformBrowser(this.platformId)) {
          observer.next({
            Success: true,
            StatusCode: 200,
            Data: [
              {
                Name: "Wishlist",//HARD CODE
                NumberOfItems: this.taLocalStorage.getAnonymousWishlist().length,
                clientData: {}
              }]
          })
        }
        }, 0);
      });
    }
  }

  deleteWishlist(wishlistId: string): Observable<TaAPIResponse<TaAPIWishlistData[]>> {
    const command = "api/WishList/DeleteWishList";
    return this.http.get<TaAPIResponse<TaAPIWishlistData[]>>(`${environment.appApiUrl}/${command}/?wishListId=${wishlistId}`).pipe(tap(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        this.appendClientDataObject(res.Data);
      }
    }));
  }

  addProductToWishlist(wishlistId: string, productId: string) {
    if (this.serviceLogin.isLogin) {
      const command = "api/WishList/AddItemToWishList";
      return this.http.post(`${environment.appApiUrl}/${command}`, { WishListId: wishlistId, ItemId: productId });
    } else {
      if (isPlatformBrowser(this.platformId)){
      //if user not login, add product to anonymous wishlist
      this.taLocalStorage.addProductToAnonymousWishlist(productId);
      }
      //return the default wishlist name "LookBook"
      return new Observable<any>((observer: Observer<any>) => {
        //simulate the respond from api
        observer.next({
          Success: true,
          StatusCode: 200
        })
      });
    }
  }

  createWishlist(wishlistName: string) {
    const command = "api/WishList/AddWishList";
    return this.http.post<TaAPIResponse<TaAPIWishlistData>>(`${environment.appApiUrl}/${command}`, { WishListName: wishlistName }).pipe(tap(res => {
      if (TAUtility.isAPIRespondOk(res)) {
        res.Data.clientData = {};
      }
    }));
  }

  editWishlist(wishlistId: string, newWishlistName: string) {
    const command = "api/WishList/EditWishList";
    return this.http.post<any>(`${environment.appApiUrl}/${command}`, { WishListName: newWishlistName, ID: wishlistId });
  }

  private appendClientDataObject(wishlists: TaAPIWishlistData[], clientData?: any) {
    wishlists.forEach(w => {
      w.clientData = _.cloneDeep(clientData) || {}
    })
  }

  getWishlistItems(wishlistId: string): Observable<TaAPIResponse<TaApiWishlistItemModel[]>> {
    const command = "api/WishList/GetWishListItems";
    return this.http.post<TaAPIResponse<TaApiWishlistItemModel[]>>(`${environment.appApiUrl}/${command}`, { WishListId: wishlistId }).pipe(tap(res => {
      res.Data.map(item => {
        item.clientData = {
          quantity: item.QuantityMultiplier,
          dateCreated: Date.parse(item.WishList_DateCreated),
          lastModified: Date.parse(item.WishList_LastModified),
          dateAdded: Date.parse(item.DateAdded),
        }
      })
    }));
  }

  removeItemInWishList(wislistId: string, itemId: string) {
    const command = "api/WishList/RemoveItemInWishList";
    return this.http.post<TaAPIResponse<TaApiWishlistItemModel[]>>(`${environment.appApiUrl}/${command}`, { WishListId: wislistId, ItemId: itemId }).pipe(tap(res => {
      res.Data.map(item => {
        item.clientData = {
          quantity: 1,
          dateCreated: Date.parse(item.WishList_DateCreated),
          lastModified: Date.parse(item.WishList_LastModified),
          dateAdded: Date.parse(item.DateAdded),
        }
      })
    }));
  }

  editNoteOfWishlistItem(item: TaApiWishlistItemModel) {
    const request = _.cloneDeep(item);
    for (const property in request) {
      if (property.toUpperCase() != "NOTE" && property.toUpperCase() != "ID" && property.toUpperCase() != "WISHLIST_ID") {
        delete request[property];
      }
    }
    const command = "api/WishList/EditNoteOfWishlistItem";
    return this.http.post<TaAPIResponse<boolean>>(`${environment.appApiUrl}/${command}`, request);
  }

  getSharedWishlistItems(sharedWishlisId){
    const command = "api/WishList/GetSharedWishlistItems";
    return this.http.get<TaAPIResponse<TaApiWishlistItemModel[]>>(`${environment.appApiUrl}/${command}?sharedWishlistId=${sharedWishlisId}`).pipe(tap(res => {
      res.Data.map(item => {
        item.clientData = {
          quantity: item.QuantityMultiplier,
          dateCreated: Date.parse(item.WishList_DateCreated),
          lastModified: Date.parse(item.WishList_LastModified),
          dateAdded: Date.parse(item.DateAdded),
        }
      })
    }));
  }

  shareTheWishlist(request: TaApiShareWishlistRequest) {
    const command = "api/WishList/ShareWishlist";
    return this.http.post<TaAPIResponse<boolean>>(`${environment.appApiUrl}/${command}`, request);
  }
}

export interface TaApiShareWishlistRequest {
  FromName?: string,
  FromEmail?: string,
  ToList?: {
    Name?: string,
    Email: string
  }[],
  WishlistId?: string,
  Message?: string,
  IncludeNotes?: boolean,
  IsSelfCopy?: boolean,
}

export interface TaApiWishlistServiceAnonymousWishlist {
  wishlistName: string;
  productIds: string[];
}


export interface TaAPIWishlistData {
  ID?: string,
  Name?: string,
  NumberOfItems?: number
  DateCreated?: string,
  LastModified?: string,
  ProductSKUList?: string[],
  clientData?: any
}

export interface TaApiWishlistItemModel extends TAProductDetailViewModel {
  WishList_ID?: string,
  WishList_Name?: string,
  Note?: string,
  clientData?: any
  WishList_DateCreated?: string,
  WishList_LastModified?: string,
  DateAdded?: string,
}
