<div class="overlay-login" style="display: block">
  <div class="overlay-navigation">
    <button class="close" (click)="onClickClose()"><i class="icon-close"></i></button>
  </div>
  <div class="row">
    <div class="main_login align-items-center">
      <div class="col-lg-12" style="padding: 0;"  [hidden]="isRecoveryPasswordMode">
        <div class="title_login">
          <h1>{{messageString}}</h1>
        </div>
        <div class="content_login">
          <div class="login-form">
            <div>
              <input (keydown.enter)="!(username.invalid || password.invalid) && onClickLogin()" nz-input type="text" #username="ngModel" name="email" placeholder="Email or Username" [(ngModel)]="model.username" required></div>
            <div style="padding-top: 25px;">
              <input (keydown.enter)="!(username.invalid || password.invalid) && onClickLogin()" nz-input type="password" #password="ngModel" name="Password" placeholder="Password" [(ngModel)]="model.password" required appRevealPassword></div>
            <div style="padding-top: 25px;" class="remember lable_check remember d-flex justify-content-between">
              <mat-checkbox color="primary" [(ngModel)]="model.isRemember" class="remember_format">Remember</mat-checkbox>
              <span class="txt_forgot_pass"><a (click)="onClickForgotPassword()">Forgot Username / Password?</a></span>
            </div>
            <div class="btn-mobile justify-content-start">
              <button nz-button nzType="primary" class="btn btn-login" (click)="!(username.invalid || password.invalid) && onClickLogin()" [ngClass]="{'ta_disabled': username.invalid || password.invalid}">Login</button>
              <span class="lable_0">Or</span>
              <button class="btn btn-google col" appIdentityProviderGoogle><i class="icon-google_plus"></i> Log in with Google</button>
              <!-- <button style="opacity: 0.3;" class="btn btn-facebook mr-2 col" (appTBDClick)="onClickLoginFacebook()"><i class="icon-facebook"></i> Facebook </button> -->
            </div>
          </div>
        </div>

        <div class="register">
          <div class="registry_content justify-content-between">
            <span class="txt_content">Make sure to login or register (if you are new) so you can save your wish lists, otherwise they will disappear when you leave the site. Once you register, you can also email your wish lists, add notes and create multiple wish lists.</span>
            <div><button nz-button appRegisterPopup nz-button>Register</button></div>
          </div>
        </div>
      </div>

      <div class="col-lg-12" style="padding: 0;" [hidden]="!isRecoveryPasswordMode">
        <div class="title_recover_pw">
          <h1>Recover Your Username & Password</h1>
        </div>
        <div class="content_recover_privacy">
          <div class="form-recover-pw">
            <div><input nz-input type="text" #userFirstName="ngModel" name="recoveryEmail" placeholder="Your registered email (*)" [(ngModel)]="recoveryPasswordEmail" email required></div>
            <div class="d-flex justify-content-between">
              <button nz-button nzType="primary" class="btn btn-recover-password" [ngClass]="{'ta_disabled': userFirstName.invalid}" (click)="!userFirstName.invalid && onClickSendRecoveryPassword()">Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
