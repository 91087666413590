import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MainViewConfiguratorComponent } from './main-view-configurator/main-view-configurator.component';

@Component({
  selector: 'app-intiaro',
  templateUrl: './intiaro.component.html',
  styleUrls: ['./intiaro.component.scss']
})
export class IntiaroComponent implements OnInit {

  constructor(
    private dialog: NgbModal,
  ) {
    this.initialization();
  }

  ngOnInit(): void {
  }
  initialization() {
    const dialogRef = this.dialog.open(MainViewConfiguratorComponent, {
      windowClass: 'ngb-modal-window-ta-full-screen'
    });
    dialogRef.result.then(() => {
    }, () => {
    });
    dialogRef.componentInstance.dialogRef = dialogRef;
  }
}
