<div class="block_menu_desktop_brands">
  <!-- <div class="block_menu_desktop_item_left">
    <div style="border-bottom: 1px solid #c0c0c0; padding: 2px 0 18px 0;">
      <h3>Brands</h3>
    </div>
    <ul>
      <li>
        <a class="block_menu_desktop_item_left_item" (click)="onBrandClick(0)"
          [ngClass]="{'block_menu_desktop_item_left_item-selected': 0==selectedIndexBrand}">
          <img src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/TA.png">
        </a>
      </li>

      <li>
        <a class="block_menu_desktop_item_left_item" (click)="onBrandClick(4)"
          [ngClass]="{'block_menu_desktop_item_left_item-selected': 4==selectedIndexBrand}">
          <img src="https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/logo-laurent.jpg">
        </a>
      </li>
    </ul>
  </div> -->

  <div class="block_menu_desktop_item_right">
    <div class="block_menu_desktop_item_right_item"
      [ngClass]="{'block_menu_desktop_item_right_item-selected': 0==selectedIndexBrand}">
      <!-- <h3 class="" style="display: flex">
        <div class="collection" class="design_item" [ngClass]="{'active': !iShowDesigner}"
          (click)="iShowDesigner = false;">Collections</div> -->
      <!-- <div class="design_partner" style="margin-left: 100px;" class="design_item"
          [ngClass]="{'active': iShowDesigner}" *ngIf="selectedIndexBrand == 0" (click)="iShowDesigner = true;">Design
          Partners</div> -->
      <!-- </h3> -->

      <div class="justify-content-between" [hidden]="iShowDesigner">
        <ul>
          <ng-container *ngFor="let item of listCollectionTheodoreAlexander001">
            <li id="{{item.ID}}">
              <a href="{{item.URL}}" class="item_left_branch" title="{{item.DisplayName}}">{{item.DisplayName}}</a>
            </li>
          </ng-container>
        </ul>
        <ul>
          <li *ngFor="let item of listCollectionTheodoreAlexander002">
            <a href="{{item.URL}}" class="item_left_branch" title="{{item.DisplayName}}">{{item.DisplayName}}</a>
          </li>
        </ul>
        <ul>
          <li *ngFor="let item of listCollectionTheodoreAlexander003">
            <a href="{{item.URL}}" class="item_left_branch" title="{{item.DisplayName}}">{{item.DisplayName}}</a>
          </li>
        </ul>
        <ul>
          <li *ngFor="let item of listCollectionTheodoreAlexander004">
            <a href="{{item.URL}}" class="item_left_branch" title="{{item.DisplayName}}">{{item.DisplayName}}</a>
          </li>
        </ul>
      </div>
      <!-- <div *ngIf="selectedIndexBrand == 0 && iShowDesigner" class="justify-content-between">
        <ul *ngFor="let item of this.designerService.list">
          <li class='icon_designer_optimize'>
            <div class='image' style="text-align: center;">
              <a href='{{domainName}}{{designer}}/{{item.URLCode}}.html' title='{{item.DisplayName}}'>
                <img src='{{item.URLImageIcon}}' title='{{item.DisplayName}}' alt='{{item.DisplayName}}' />
              </a>
            </div>
            <div class='title_menu_designer'>
              <a href='{{domainName}}{{designer}}/{{item.URLCode}}.html'
                title='{{item.DisplayName}}'>{{item.DisplayName}}</a>
            </div>
          </li>
        </ul>
      </div> -->
    </div>


    <!-- <div class="block_menu_desktop_item_right_item"
      [ngClass]="{'block_menu_desktop_item_right_item-selected': 1==selectedIndexBrand}">
      <h3 class="">
        <div class="collection" class="design_item" [ngClass]="{'active': !iShowDesigner}"
          (click)="iShowDesigner = false;">Collections</div>
      </h3>
      <div class="d-flex">
        <div style="display: flex;">
          <ul style="width: 250px;">
            <li *ngFor="let item of collectionService.listMenuTop002">
              <a href="{{domainName}}{{collection}}/{{item.URLCode}}.html" class="item_left_branch"
                title="{{item.DisplayName}}">{{item.DisplayName}}</a>
            </li>
          </ul>
          <a href="{{domainName}}{{brand}}/salone.html" nz-button class="btn_brand_see_salone" title="See All">See
            All</a>
        </div>

        <div [appUserRegionAccessibility]="{userRegion: 'international', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_saloni_TAU.jpg') no-repeat; background-position: center center; width: 100%; height: 400px; margin-top: 15px;">
        </div>
        <div [appUserRegionAccessibility]="{userRegion: 'taus', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_saloni_TAU.jpg') no-repeat; background-position: center center; width: 100%; height: 400px; margin-top: 15px;">
        </div>
      </div>
    </div> -->

    <!-- <div class="block_menu_desktop_item_right_item"
      [ngClass]="{'block_menu_desktop_item_right_item-selected': 2==selectedIndexBrand}">
      <h3 class="">
        <div class="collection" class="design_item" [ngClass]="{'active': !iShowDesigner}"
          (click)="iShowDesigner = false;">Collections</div>
      </h3>
      <div class="d-flex item_studio">
        <div style="display: flex;">
          <ul style="min-width: 150px;">
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-no-1.html'
                title='TA Studio No. 1'>TA Studio No. 1</a></li>
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-no-2.html'
                title='TA Studio No. 2'>TA Studio No. 2</a></li>
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-no-3.html'
                title='TA Studio No. 3'>TA Studio No. 3</a></li>
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-no-4.html'
                title='TA Studio No. 4'>TA Studio No. 4</a></li>
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-raia.html'
                title='TA Studio Raia'>TA Studio Raia</a></li>
          </ul>
          <ul style="min-width: 220px;">
            <li [appUserRegionAccessibility]="{userRegion: 'taus'}"><a class="item_left_branch"
                href='{{domainName}}{{collection}}/ta-studio-frenzy.html' title='TA Studio Frenzy'>TA Studio Frenzy</a>
            </li>
            <li [appUserRegionAccessibility]="{userRegion: 'taus'}"><a class="item_left_branch"
                href='{{domainName}}{{collection}}/ta-studio-holli.html' title='TA Studio Holli'>TA Studio Holli</a>
            </li>
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-accents.html'
                title='TA Studio Accents'>TA Studio Accents</a></li>
            <li><a class="item_left_branch" href='{{domainName}}{{collection}}/ta-studio-upholstery.html'
                title='TA Studio Upholstery'>TA Studio Upholstery</a></li>
          </ul>
          <a nz-button class="btn_brand_see" href="{{domainName}}{{brand}}/ta-studio.html" title="See All">See All</a>
        </div>
        <div [appUserRegionAccessibility]="{userRegion: 'international', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_taStudio_TAU.jpg') no-repeat; background-position: center center; width: 100%; height: 400px; margin-top: 15px;">
        </div>
        <div [appUserRegionAccessibility]="{userRegion: 'taus', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_taStudio_TAU.jpg') no-repeat; background-position: center center; width: 100%; height: 400px; margin-top: 15px;">
        </div>
      </div>
    </div> -->



    <!-- <div class="block_menu_desktop_item_right_item"
      [ngClass]="{'block_menu_desktop_item_right_item-selected': 3==selectedIndexBrand}">
      <h3 class="">
        <div class="collection" class="design_item" [ngClass]="{'active': !iShowDesigner}"
          (click)="iShowDesigner = false;">Collections</div>
      </h3>

      <div class=" justify-content-between" [hidden]="iShowDesigner" style="position: relative;">
        <div [appUserRegionAccessibility]="{userRegion: 'international', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_accent.jpg') no-repeat; background-position: left center; width: 100%; height: 515px;margin-top: 5px;">
        </div>
        <div [appUserRegionAccessibility]="{userRegion: 'taus', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_accent.jpg') no-repeat; background-position: left center; width: 100%; height: 406px;margin-top: 5px;">
        </div>
        <div>
          <a nz-button class="block_menu_desktop_item_right_item-button menu_button_branch"
            href="{{domainName}}{{brand}}/ta-accents.html" title="See All">See All</a>
        </div>
      </div>
    </div> -->

    <!-- <div class="block_menu_desktop_item_right_item"
      [ngClass]="{'block_menu_desktop_item_right_item-selected': 4==selectedIndexBrand}">
      <h3 class="">
        <div class="collection" class="design_item" [ngClass]="{'active': !iShowDesigner}"
          (click)="iShowDesigner = false;">Collections</div>
      </h3>

      <div class=" justify-content-between" [hidden]="iShowDesigner" style="position: relative;">
        <div [appUserRegionAccessibility]="{userRegion: 'international', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_laurant_TAU.jpg') no-repeat; background-position: center center; width: 100%; height: 406px; margin-top: 15px;">
          <div class="btn-laurent">
            <div class="btn_requiries">
              <a class="menu_button_branch_laurent" (click)="openRalphLaurenForm()">Design Trade Inquiries</a>
            </div>
            <div class="btn-see-all">
              <button nz-button class="menu_button_branch_laurent">
                <a href="https://www.ralphlauren.com/home-furniture?webcat=home-furniture" target="_blank">See All
                  Collections</a>
              </button>
            </div>
          </div>
        </div>
        <div [appUserRegionAccessibility]="{userRegion: 'taus', display:'block'}"
          style="background: url('https://theodorealexander.sirv.com/website/Frontend/Live/Menu/Brand/brand_menu_laurant_TAU.jpg') no-repeat; background-position: center center; width: 100%; height: 406px; margin-top: 15px;">
          <div class="btn-laurent">
            <div class="btn_requiries">
              <button nz-button class="menu_button_branch_laurent" (click)="openRalphLaurenForm()">Design Trade
                Inquiries</button>
            </div>
            <div class="btn-see-all">
              <button nz-button class="menu_button_branch_laurent">
                <a href="https://www.ralphlauren.com/home-furniture?webcat=home-furniture" target="_blank">See All
                  Collections</a>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</div>