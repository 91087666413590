import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { ConfiguratorComponent } from './intiaro/configurator/configurator.component';
import { MainViewConfiguratorComponent } from './intiaro/main-view-configurator/main-view-configurator.component';
import { TAilorFitComponent } from './intiaro/tailor-fit/tailor-fit.component';
import { WrapperForMainViewComponent } from './intiaro/wrapper-for-main-view/wrapper-for-main-view.component';
import { VirtualShowroomTourComponent } from './page/virtual-showroom-tour/virtual-showroom-tour.component';
import { DrapperComponent } from './page/drapper/drapper.component';
import { BarstoolComponent } from './page/barstool/barstool.component';

const routes: Routes = [
  { path: '', component: TAilorFitComponent, },
  {
    path: 'theodore-configurator/:id', component: WrapperForMainViewComponent,
  },
  { path: 'configurator', component: ConfiguratorComponent, pathMatch: 'full' },
  {
    path: 'virtual-showroom', component: VirtualShowroomTourComponent,
  },
  {
    path: 'draper/theodore-configurator/:id', component: WrapperForMainViewComponent,
  },
  {
    path: 'draper', component: DrapperComponent,
  },
  {
    path: 'barstool', component: BarstoolComponent,
  },
  { path: '', loadChildren: () => import('./ta-modules/ta-webapp-module.module').then(m => m.TaWebappModuleModule) },
  { path: '**', redirectTo: 'index.html' }
];

// @NgModule({
//   imports: [RouterModule.forRoot(routes, {
//     preloadingStrategy: PreloadAllModules,
//     initialNavigation: 'enabled'
//   })],
//   exports: [RouterModule]
// })
@NgModule({
  imports: [RouterModule.forRoot(routes, {
    preloadingStrategy: PreloadAllModules,
    initialNavigation: 'enabled',
    relativeLinkResolution: 'legacy'
  })],
  providers: [
    {
      provide: 'externalUrlRedirectResolver',
      useValue: (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
        window.location.href = (route.data as any).externalUrl;
      }
    }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }

