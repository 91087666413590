import { Directive, HostListener, Input, Output, EventEmitter } from '@angular/core';
import { TAUtility } from '../theoalex/theoalex';
import { MatDialog, DialogPosition } from '@angular/material/dialog';
import { TaWishlistPopupComponent } from '../comp/ta-wishlist-popup/ta-wishlist-popup.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzModalService } from 'ng-zorro-antd/modal';

@Directive({
  selector: '[appAddProductWishListPopup]'
})
export class AddProductWishListPopupDirective {
  constructor(private dialog: NgbModal, private modalService: NzModalService) { }

  @Input('appAddProductWishListPopup') product: AddProductWishListPopupDirectiveConfig;
  @Output('appAddProductWishListPopupOnClose') onClose = new EventEmitter();

  @HostListener('click',) function() {

    let wishlistModal = this.modalService.create({
      nzClassName: 'ngb-modal-window-ta-full-screen-at-mobile',
      nzFooter: null,
      nzContent: TaWishlistPopupComponent
    });
    wishlistModal.componentInstance.modalRef = wishlistModal;
    wishlistModal.componentInstance.product = this.product;
    wishlistModal.afterClose.subscribe(
      () => {
        if (wishlistModal.componentInstance.isAdded) {
          this.onClose.emit();
        } else {
          wishlistModal?.close();
        }
      }
    );
  };
}

export interface AddProductWishListPopupDirectiveConfig {
  productId?: string,
  productName?: string;
  productSku?: string
}
