import { Component, OnInit, AfterViewInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { Subscription } from 'rxjs';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-logo',
  templateUrl: './logo.component.html',
  styleUrls: ['./logo.component.scss']
})
export class LogoComponent implements OnInit, AfterViewInit, OnDestroy, iTaObserverComponent {

  domainName: string = environment.appWebUrl;
  subscriberEvents(): void {
  }

  unsubscriberEvents(): void {
  }

  onClickLogo() {
    if (this.router.url == "/") {
      TaUniversalHubSubject.scrollRequest.next();
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  ngAfterViewInit(): void {

  }

  constructor(private router: Router) {
  }

  ngOnInit() {
    this.subscriberEvents();
  }
}
