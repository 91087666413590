import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Title, Meta } from '@angular/platform-browser';
import { HttpClient, HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppGlobal {

  public static SetURLItem(ID: string, SKU: string, productName: string) {
    let URL = "";
    productName = productName.split(' ').join('-');
    productName = productName.split('(').join('');
    productName = productName.split(')').join('');
    productName = productName.split('‘').join('');
    productName = productName.split('’').join('');
    productName = productName.split(',').join('');
    productName = productName.split('/').join('');
    productName = productName.split('\\').join('');
    SKU = SKU.split('.').join('-');
    SKU = SKU.split('(').join('');
    SKU = SKU.split(')').join('');
    URL = ID + '_' + productName + '-' + SKU + '.html';
    return URL;
  };
  public static SetName(Name: string) {
    let result = Name;
    result = result.split(' ').join('-');
    result = result.split('(').join('');
    result = result.split(')').join('');
    result = result.split('‘').join('');
    result = result.split('’').join('');
    result = result.split(',').join('');
    result = result.split('/').join('');
    result = result.split('\\').join('');
    return result;
  };
  public static SetSearchURLByName(Name: string) {
    let result = Name.split(' ').join('_');
    result = "/" + environment.Search + "/" + result;
    return result;
  };
  public static InitializationIsActiveTAUS(region: string) {
    let result: boolean = false;
    if (region) {
      if (region.toUpperCase() == "TAUS") {
        result = true;
      }
    }
    return result;
  };
}

