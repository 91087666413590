import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';

import { faTimes, faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { StepByStepService } from 'src/app/shared/step-by-step.service';
// import { } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit, AfterViewInit {

  faTimes = faTimes;
  faExpandArrowsAlt = faExpandArrowsAlt;
  images: string[] = [];

  @ViewChild('photoSmall1') photoSmall1!: ElementRef;
  @ViewChild('photoSmall2') photoSmall2!: ElementRef;
  @ViewChild('photoSmall3') photoSmall3!: ElementRef;
  @ViewChild('photoBig') photoBig!: ElementRef;
  @ViewChild('zoomBtn') zoomBtn!: ElementRef;
  @ViewChild('fullScreen') fullScreen!: ElementRef;
  @ViewChild('fullScreenPhoto') fullScreenPhoto!: ElementRef;
  @ViewChild('closeBtn') closeBtn!: ElementRef;

  constructor(private stepByStepService: StepByStepService) { }

  ngOnInit(): void {
    this.stepByStepService.getAllPhotos()
      .subscribe(photos => this.images = photos);
  }

  ngAfterViewInit(): void {
    this.addListenerImage(this.photoSmall1);
    this.addListenerImage(this.photoSmall2);
    this.addListenerImage(this.photoSmall3);
    this.addListenerBtn();
  }

  addListenerImage(el: ElementRef): void {
    el.nativeElement.addEventListener('click', () => {
      let clickedImageSrc = el.nativeElement.querySelector('img')?.src;
      clickedImageSrc && this.toogleImage(clickedImageSrc, el);
    });
  }

  addListenerBtn() {
    this.zoomBtn.nativeElement.addEventListener('click', () => {
      this.showFullScreenImage();
    });
  }

  toogleImage(smallImageSrc: string, bigImageId: ElementRef): void {
    let bigImage = this.photoBig.nativeElement.querySelector('img');
    let smallImage = bigImageId.nativeElement.querySelector('img');

    if(smallImage && bigImage) {
      smallImage.src = bigImage.src;
      bigImage.src = smallImageSrc;
    }
  };

  showFullScreenImage(): void {
    let expandedImage = this.photoBig.nativeElement.querySelector('img');
    let imageBox = this.fullScreen.nativeElement;
    let photo = this.fullScreenPhoto.nativeElement;

    if(imageBox) {
      imageBox.classList.remove('fullScreen-none');
      imageBox.classList.add('fullScreen');
      this.closeFullScreenImage(imageBox);
    };
    photo && expandedImage && (photo.setAttribute('src', expandedImage.src));
  }

  closeFullScreenImage(el: HTMLElement): void {
    this.closeBtn.nativeElement.addEventListener('click', () => {
      if(el) {
        el.classList.remove('fullScreen');
        el.classList.add('fullScreen-none');
      };
    });
  }

}
