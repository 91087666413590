import { Component, OnInit, Optional } from '@angular/core';
import { TaConstantsService } from 'src/app/service/web/ta-constants.service';

@Component({
  selector: 'app-drapper',
  templateUrl: './drapper.component.html',
  styleUrls: ['./drapper.component.scss']
})
export class DrapperComponent implements OnInit {

  public list = [];


  constructor(@Optional() public taConstants: TaConstantsService) {

    if (this.taConstants.DatabaseData.DATA_FOR_TESTING) {
      for (let index = 0; index < this.taConstants.DatabaseData.DATA_FOR_TESTING.length; index++) {
        const element = this.taConstants.DatabaseData.DATA_FOR_TESTING[index];
        element.urlImage = `https://theodorealexander.sirv.com/ProductPhotos/${element.sku.substring(0, 3)}/${element.sku}_main_1.jpg`;

      }

      this.list = [...this.taConstants.DatabaseData.DATA_FOR_TESTING];
      console.log(this.list);
    }
  }

  ngOnInit() {
  }

}
