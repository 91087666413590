import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-designer-detail-popup',
  templateUrl: './designer-detail-popup.component.html',
  styleUrls: ['./designer-detail-popup.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DesignerDetailPopupComponent implements OnInit {

  designer:any;
  dialogRef: NgbModalRef;
  constructor() { }

  ngOnInit() {
  }
  onClickCloseButton(){
    this.dialogRef.close();
  }
}
