<div class="wrap_wishlist_popup" *ngIf="isLogin === true">
    <div>
      <div class="wishlist_popup_button_close">
        <button tabindex="-1"><i class="icon-close"></i></button>
      </div>
      <div>
        <div class="title_name_wishlist">Create New Wish Lists</div>
        <div class="head_popup_wishlist">
          <div class="field_add_new_wishlist_popup">
            <div class="wishlist_popup_input_name">
              <input class="input_txt_placeholder" tabindex="-1" type="text" [(ngModel)]="newWishlistName" placeholder="Name your Wish Lists (*)" />
            </div>
            <div>
              <button class="btn_add_wishlist_popup" [disabled]="!newWishlistName" [ngClass]="{'ta_disabled':!newWishlistName}"
                (click)="onClickCreateNewWishlist()">
                Add New
              </button>
            </div>
          </div>
          <div style="margin-top: -5px; margin-bottom: 15px;">
            <div style="font-size:14px; text-align: left; margin-top: 3px;" [hidden]="!isAdded">
              Added <b>{{product?.productName}}</b> to <b>{{selectedWishlist?.Name}}</b>
            </div>
            <div style="font-size:14px; text-align: left; margin-top: 3px;" [hidden]="isAdded">
              &nbsp;
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="display: flex; flex-flow: column; overflow: hidden;">
      <div class="sub_title_name_wishlist">Save to:</div>
      <div class="list_wishlist_popup">
        <ul>
          <li *ngFor="let w of wishlist" style="display: flex;" (click)="onClickWishlist(w)">           
            <div class="main_name_wishlist_popup" >{{w.Name}}</div>
            <div class="selected_wishlist_popup">
              <input type="radio" checked="checked" name="radio">
              <span class="checkmark" [ngClass]="{'checkmark-added': w?.clientData?.isAdded}"></span></div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  