<div class="line-top-mobile">
    <div style="display: flex; justify-content: center; align-items: center;">
      <div [appUserRoleAccessibility]="{userRole: 'Dealer,Designer', display:'inline-block'}">
        <app-ta-mat-slide-toggle-module></app-ta-mat-slide-toggle-module>
      </div>
      <!-- <a tabindex="-1" class="wishlist" routerLink="/account/account-wishlist" (click)="closeHeader()">
        <i class="icon-heart_outline"></i><span>Wish Lists</span>
      </a> -->
      <a routerLink="/account/order-shoppingcart" [appUserRoleAccessibility]="{userRole:'Dealer,Designer', display:'block'}"
        style="display: none;" class="button-cart"><i class="icon-Cart"></i><span
          class="number">{{shoppingCartCount}}</span></a>
    </div>

    <div style="display: flex; justify-content: center; align-items: center;">
      <div class="right-login">
        <div class="login" *ngIf="!loginUser?.UserName" appLoginPopup (click)="closeHeader()">
          <i class="icon-user"></i><a>Login<i>&nbsp; / &nbsp;</i></a></div>
        <div class="register" *ngIf="!loginUser?.UserName" appRegisterPopup (click)="closeHeader()"><a>Sign up</a></div>
      </div>
      <div *ngIf="loginUser?.UserName" class="welcome" routerLink="account" (click)="closeHeader()">
        <i class="icon-user"></i> Welcome, <span class="name"><b>{{loginUser.FirstName}}</b></span>
      </div>
    </div>

</div>
<!-- block_header mobile-->
<div class="header_mobile d-flex flex-column block_mobile_full_height">

  <div class="header">
    <div class="d-flex justify-content-between align-items-center">
      <div class="logo"><a tabindex="-1" routerLink="/" (click)="closeHeader()"><img alt="Theodore Alexander" title="Theodore Alexander, one of the finest luxury furniture brands in the world" src="/assets/img/logo_footer.svg"></a></div>
      <div class="buttons">
        <!-- <a routerLink="/account/order-shoppingcart" (click)="closeHeader()"
          [appUserRoleAccessibility]="{userRole:'Dealer', display:'block'}" style="display: none;"
          class="button-cart"><i class="icon-Cart"></i><span class="number">{{shoppingCartCount}}</span></a> -->
        <div class="button-search" (click)="toggleSearch()">
          <i class="icon-Search" [hidden]="isShowSearch"></i>
          <i class="icon-close" [hidden]="!isShowSearch"></i>
        </div>
        <div class="button-close-menu" (click)="toggleMenu()" [hidden]="!isShowMenu">
          <i class="icon-close"></i>
        </div>
        <div class="button-open-menu" (click)="toggleMenu()" [hidden]="isShowMenu">
          <i class="icon-menu-3"></i>
        </div>
      </div>
    </div>
  </div>

  <div class="menu" [hidden]="!isShowMenu">
    <app-menu-mobile></app-menu-mobile>
  </div>
  <div class="search" *ngIf="isShowSearch">
    <app-search [isExpanded]="true" (onSearchCollapsed)="closeHeader()" (searchSubmitted)="closeHeader()"></app-search>
  </div>


  <!-- <div class="footer">
    <div class="left">
      <a class="wishlist" routerLink="/account/account-wishlist" (click)="closeHeader()">
        <i class="icon-heart_outline"></i><span>Wish Lists</span>
      </a>
    </div>
    <div class="right">
      <div class="login" *ngIf="!loginUser" appLoginPopup (click)="closeHeader()">
        <i class="icon-user"></i><a>Login<i>/</i></a></div>
      <div class="register" *ngIf="!loginUser" appRegisterPopup (click)="closeHeader()"><a>Sign up</a></div>
      <div *ngIf="loginUser" class="welcome" routerLink="account" (click)="closeHeader()">
        <i class="icon-user"></i> Welcome, <span class="name"><b>{{loginUser.Firstname}}</b></span>
      </div>
    </div>
  </div> -->

</div>
