<div class="close-button" (click)="onClickCloseButton()"><i class="icon-close"></i></div>
<div class="pad-popup-mobile">
  <div class="header">
    <div class="row">
      <div class="col-12">
        <h2>DESIGN TRADE INQUIRIES</h2>
      </div>
    </div>
  </div>

  <form class="row ta_form" #f="ngForm" (ngSubmit)="onSubmit(f)">
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Name (*)" name="name" required [(ngModel)]="formData.name" #name="ngModel">
      <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert-requires-popup">
        <div *ngIf="name.errors.required">
          Name is required.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Email (*)" name="email" [(ngModel)]="formData.email" #email="ngModel" email required>
      <div *ngIf="email.invalid && (email.dirty || email.touched)" class="alert-requires-popup">
        <div *ngIf="email.errors.required">
          Email is required.
        </div>
        <div *ngIf="email.errors.email">
          Email is invalid.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Business Name (*)" name="businessName" required [(ngModel)]="formData.businessName" #businessName="ngModel">
      <div *ngIf="businessName.invalid && (businessName.dirty || businessName.touched)" class="alert-requires-popup">
        <div *ngIf="businessName.errors.required">
          Business Name is required.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Address (*)" name="address" required [(ngModel)]="formData.address" #address="ngModel">
      <div *ngIf="address.invalid && (address.dirty || address.touched)" class="alert-requires-popup">
        <div *ngIf="address.errors.required">
          Address is required.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Daytime Phone (*)" name="daytimePhone" required [(ngModel)]="formData.daytimePhone" #daytimePhone="ngModel">
      <div *ngIf="daytimePhone.invalid && (daytimePhone.dirty || daytimePhone.touched)" class="alert-requires-popup">
        <div *ngIf="daytimePhone.errors.required">
          Daytime Phone is required.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="State / Province (*)" name="state" required [(ngModel)]="formData.state" #state="ngModel">
      <div *ngIf="state.invalid && (state.dirty || state.touched)" class="alert-requires-popup">
        <div *ngIf="state.errors.required">
          State / Province is required.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Country (*)" name="country" required [(ngModel)]="formData.country" #country="ngModel">
      <div *ngIf="country.invalid && (country.dirty || country.touched)" class="alert-requires-popup">
        <div *ngIf="country.errors.required">
          Country is required.
        </div>
      </div>
    </div>
    <div class="col-xl-6 col-md-12 line-item-form">
      <input type="text" placeholder="Zip / Postal Code (*)" name="zipCode" required [(ngModel)]="formData.zipCode" #zipCode="ngModel">
      <div *ngIf="zipCode.invalid && (zipCode.dirty || zipCode.touched)" class="alert-requires-popup">
        <div *ngIf="zipCode.errors.required">
          Zip / Postal Code is required.
        </div>
      </div>
    </div>
    <div class="col-md-12 col-md-12 line-item-form">
      <textarea class="commment_box_talk_rep" placeholder="Questions or Comments" name="comment" #comment="ngModel" [(ngModel)]="formData.comment"></textarea>
    </div>

    <div class="col-12 line-item-form">
      <input [ngClass]="{'ta_disabled': f.invalid}" class="ta_button positive button-mobile" type="submit" value="Submit">
      <input (click)="$event.preventDefault();onClickCloseButton()" class="ta_button negative button-mobile btn-format-desktop"  value="Cancel" type="button">
    </div>
  </form>
</div>
