import { Directive, HostListener } from '@angular/core';
import { LoginPageComponent } from '../page/login-page/login-page.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';

@Directive({
  selector: '[appLoginPopup]'
})
export class LoginPopupDirective {

  constructor(private modalService: NzModalService) { }

  @HostListener("click") function(){
    LoginPopupDirective.show(this.modalService);
  }

  public static show(modalService: NzModalService, isRecoveryPasswordMode?: boolean) : NzModalRef<LoginPageComponent>{
    let loginModal = modalService.create({
      nzClassName: 'ngb-modal-window-ta-full-screen',
      nzFooter: null,
      nzContent: LoginPageComponent
    });

    setTimeout(()=>{
      loginModal.componentInstance.dialogRef = loginModal;
      loginModal.componentInstance.isRecoveryPasswordMode = isRecoveryPasswordMode
    },0)
    return loginModal;
  }
}
