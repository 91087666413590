import { Component, OnInit } from '@angular/core';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { iTaBreadcrumb } from 'src/app/theoalex/taObserverComponent';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  breadcrumbs: iTaBreadcrumb[];

  constructor() { }

  ngOnInit() {
    TaUniversalHubSubject.taBreadCrumbChanged.subscribe(breadCrumbs => {
      this.breadcrumbs = breadCrumbs;
    })
  }
}
