import { createReducer, on } from "@ngrx/store";
import { stat } from "fs";
import { TawAppStateAction } from "./app.action";
import { initAppState, TawAppState } from "./app.state";

export class TawAppStateReducer {
  public static reducer = createReducer(initAppState,
    on(TawAppStateAction.update, (state) => {
      //console.log(TawAppStateAction.update.type);
      let newState : TawAppState = {
        ...state,
        message: `Hello ${Date.now().toString()}`
      };
      //console.log(newState);
      return newState;
    }))
}
