
import { Component, OnInit, AfterViewInit, OnDestroy, Directive } from '@angular/core';
import { TaUniversalHubSubject } from 'src/app/service/web/universal-hub.service';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { Subscription } from 'rxjs';
import { TAUtility } from 'src/app/theoalex/theoalex';
import { TaApiServiceLogin, TaUserCredential } from 'src/app/service/api/TaApiServiceLogin';
import { MatDialog } from '@angular/material/dialog';
import { TaApiServiceShoppingCart } from 'src/app/service/api/TaApiServiceShoppingCart';
import { HeaderMobileWithMenuComponent } from './header-mobile-with-menu/header-mobile-with-menu.component';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-header-mobile',
  templateUrl: './header-mobile.component.html',
  styleUrls: ['./header-mobile.component.scss']
})

export class HeaderMobileComponent {
  private userAuthenticCheckedEventSubscriber: Subscription;
  private modalRefSearch: NgbModalRef;
  private modalRefMenu: NgbModalRef;

  shoppingCartChangedSubscription: Subscription;
  loginUser: TaUserCredential;
  shoppingCartCount = 0;

  isUserCredentialProviderAcquiring = false;

  ngOnInit() {
  }

  constructor(protected modalService: NgbModal, protected shoppingCartService: TaApiServiceShoppingCart) {
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.isUserCredentialProviderAcquiring = false;
      this.subscriberEvents();
    })
  }

  subscriberEvents(): void {
    this.userAuthenticCheckedEventSubscriber = TaUniversalHubSubject.userCredentialSubjectData.subscribe(credential => {
      if (credential?.UserName) {
        this.refreshShoppingCartCount();
      }
      this.loginUser = credential;
      localStorage.setItem('UserName', this.loginUser?.UserName);
      localStorage.setItem('UserID', this.loginUser?.UserId);
      localStorage.setItem('FirstName', this.loginUser?.FirstName);
      localStorage.setItem('UserRole', this.loginUser?.UserType);
      localStorage.setItem('Region', this.loginUser?.Region);
    });

    this.shoppingCartChangedSubscription = TaUniversalHubSubject.shoppingCartChanged.subscribe(() => {
      this.refreshShoppingCartCount();
    })

    TaUniversalHubSubject.userCredentialProviderAcquiringSubject.subscribe(isAcquiring => {
      this.isUserCredentialProviderAcquiring = isAcquiring;
    })
  }

  ngOnDestroy(): void {
    this.unsubscriberEvents()
  }
  unsubscriberEvents(): void {
    TAUtility.unsubscribe(this.userAuthenticCheckedEventSubscriber, this.shoppingCartChangedSubscription);
  }

  openMenu(): void {
    if (this.modalRefMenu) {
      this.modalRefMenu.close();
    }
    this.modalRefMenu = this.modalService.open(HeaderMobileWithMenuComponent, {
      windowClass: "ngb-modal-window-ta-full-screen"
    });
    this.modalRefMenu.componentInstance.modalRef = this.modalRefMenu;
    this.modalRefMenu.componentInstance.isShowSearch = false;
    this.modalRefMenu.componentInstance.isShowMenu = true;
  }

  openSearch() {
    if (this.modalRefSearch) {
      this.modalRefSearch.close();
    }
    this.modalRefSearch = this.modalService.open(HeaderMobileWithMenuComponent, {
      windowClass: "ngb-modal-window-ta-full-screen",
      keyboard: false,
    });
    this.modalRefSearch.componentInstance.modalRef = this.modalRefSearch;
    this.modalRefSearch.componentInstance.isShowSearch = true;
    this.modalRefSearch.componentInstance.isShowMenu = false;
  }

  private refreshShoppingCartCount() {
    this.shoppingCartService.getClientShoppingCart(() => {
      setTimeout(() => {
        this.shoppingCartCount = this.shoppingCartService.shoppingCart ? parseInt(this.shoppingCartService.shoppingCart.Count) : 0;
        localStorage.setItem('Cart', this.shoppingCartCount.toString());
      })
    })
  }
}
