import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { GoogleAnalyticEventAction, GoogleAnalyticEventCategory } from 'src/app/theoalex/theoalex';
import { MenuComponent } from '../menu.component';
import { environment } from 'src/environments/environment';
import { Type } from 'src/app/shared/Type.model';
import { TypeService } from 'src/app/shared/Type.service';
import { Collection } from 'src/app/shared/Collection.model';
import { CollectionService } from 'src/app/shared/Collection.service';
@Component({
  selector: 'app-menu-custom',
  templateUrl: './menu-custom.component.html',
  styleUrls: ['./menu-custom.component.scss']
})
export class MenuCustomComponent implements OnInit, AfterViewInit, iTaObserverComponent, OnDestroy {
  GoogleAnalyticEventAction = GoogleAnalyticEventAction;
  GoogleAnalyticEventCategory = GoogleAnalyticEventCategory;
  domainName: string = environment.appWebUrl;
  tailorfitSubdomain: string = environment.TailorFitURL;

  constructor(
    private menu: MenuComponent,
    public typeService: TypeService,
    public collectionService: CollectionService,
  ) {
    // this.getTailorFit();
  }

  ngOnInit() {
  }
  getTailorFit() {
    this.typeService.getByID(environment.TailorFitID).subscribe(res => {
      this.typeService.formData = res.Data as Type;
    });
    this.collectionService.getByID(environment.TailorFitIDCollection).subscribe(res => {
      this.collectionService.formData001 = res.Data as Collection;
      if (this.collectionService.formData001) {
        this.collectionService.formData001.URL = environment.TailorFitURL;
      }
    });
  }
  ngAfterViewInit(): void {
    this.subscriberEvents();
  }

  subscriberEvents(): void {
  }


  ngOnDestroy(): void {
    this.unsubscriberEvents();
  }

  unsubscriberEvents(): void {
  }

  onRouterClick() {
    this.menu.closeAllMenu();
  }

}
