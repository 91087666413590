import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { DealerStoreWorldMapComponent } from 'src/app/comp/dealer-store-world-map/dealer-store-world-map.component';

@Component({
  selector: 'app-menu-locations',
  templateUrl: './menu-locations.component.html',
  styleUrls: ['./menu-locations.component.scss']
})
export class MenuLocationsComponent implements OnInit, AfterViewInit {

  @ViewChild(DealerStoreWorldMapComponent, {static: true}) dealerStoreWorldMapComponent: DealerStoreWorldMapComponent;

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit(): void {
  }

  onRouterClick() {
    //close the menu when navigation
  }

  requireLocation(){
    //this.dealerStoreWorldMapComponent.requireLocation();
  }

}
