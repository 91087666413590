//NG
import { NgModule } from '@angular/core';

//CORE
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
//import { ScrollDispatchModule } from '@angular/cdk/scrolling';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

//MODULE
import { TaNgMaterialModule } from './app-ng-material.module'
import { TaInterceptor } from './service/api/TaInterceptor';

//COMPONENT
import { TaCheckBoxComponent } from './comp/ta-check-box/ta-check-box.component';
import { TaDropDownComponent } from './comp/ta-drop-down/ta-drop-down.component';
import { BreadcrumbComponent } from './comp/breadcrumb/breadcrumb.component';
import { CheckboxAccordionComponent } from './comp/checkbox-accordion/checkbox-accordion.component';
import { CustomPalettePageComponent } from './page/custom-palette-page/custom-palette-page.component';
import { LoginPageComponent } from './page/login-page/login-page.component';
import { RegisterPageComponent } from './page/register-page/register-page.component';
import { TaAlertPopup } from './comp/ta-alert-popup/ta-alert-popup.component';
import { HeaderComponent } from './comp/header/header-desktop/header.component';
import { FooterComponent } from './comp/footer/footer.component';
import { MenuComponent } from './comp/menu/menu-desktop/menu.component';
import { LogoComponent } from './comp/logo/desktop/logo.component';
import { HeaderMobileComponent } from './comp/header/header-mobile/header-mobile.component';
import { MenuMobileComponent } from './comp/menu/menu-mobile/menu-mobile.component';
import { MenuBrandsComponent } from './comp/menu/menu-desktop/menu-brands/menu-brands.component';
import { MenuProductsComponent } from './comp/menu/menu-desktop/menu-products/menu-products.component';
import { MenuDesignersComponent } from './comp/menu/menu-desktop/menu-designers/menu-designers.component';
import { MenuCustomComponent } from './comp/menu/menu-desktop/menu-custom/menu-custom.component';
import { Menu3dComponent } from './comp/menu/menu-desktop/menu-3d/menu-3d.component';
import { MenuNewsComponent } from './comp/menu/menu-desktop/menu-news/menu-news.component';
import { MenuAboutUsComponent } from './comp/menu/menu-desktop/menu-about-us/menu-about-us.component';
import { SearchComponent } from './comp/search/search.component';
import { GoToTopComponent } from './comp/goToTop/go-to-top.component';
import { DesignerDetailPopupComponent } from './comp/designer-detail-popup/designer-detail-popup.component';
import { TaWishlistPopupComponent } from './comp/ta-wishlist-popup/ta-wishlist-popup.component';
import { AccountPageComponent } from './pagePrivate/account-page/account-page.component';
import { TaMediaGalleryComponent } from './comp/ta-media-gallery/ta-media-gallery.component';
import { ShareProductEmailComponent } from './comp/share-product-email/share-product-email.component';
import { HeaderMobileWithMenuComponent } from './comp/header/header-mobile/header-mobile-with-menu/header-mobile-with-menu.component';
import { RalphLaurenInquireFormComponent } from './comp/ralph-lauren-inquire-form/ralph-lauren-inquire-form.component';
import { SystemMessageComponent } from './comp/system-message/system-message.component';
import { PopupFurnitureCareComponent } from './page/furniture-care-page/popup-furniture-care/popup-furniture-care.component';
import { TaMatSlideToggleModuleComponent } from './comp/ta-mat-slide-toggle-module/ta-mat-slide-toggle-module.component';

//DIRECTIVE
import { AddProductWishListPopupDirective } from './directive/add-wishlist-popup.directive';
import { UserRoleAccessibilityDirective } from './directive/user-role-accessibility.directive';
import { UserRegionAccessibilityDirective } from './directive/user-region-accessibility-directive';
import { TBDClickDirective } from './directive/tbd-click.directive';
import { SocialLinkDirective } from './directive/social-link.directive';
import { LoginPopupDirective } from './directive/login-popup.directive';
import { RegisterPopupDirective } from './directive/register-popup.directive';
import { RevealPasswordDirective } from './directive/reveal-password.directive';
import { HrefExternalDirective } from './directive/external-url-link-transform.directive';
import { EmailSubscriberDirective } from './directive/email-subscriber.directive';
import { IdentityProviderGoogleDirective } from 'src/app/directive/identity-provider-google.directive'

//PIPE
import { IncludeStringPipe, TAPipeStringStartswith } from './pipe/include-string.pipe';
import { SirvPipe } from './pipe/sirv.pipe';
import { GetFirstNamePipe } from './pipe/get-first-name-pipe';
import { Trim } from './pipe/trim';
import { ProductPipe_IsIncludeOptionGroup, TaPipe_JoinString } from './pipe/ta-product.pipe';
import { TaNgZorroAntdModule } from './ng-zorro-antd.module';
import { TaWebBlogCompCarouselComponent } from './blog/comp/ta-web-blog-comp-carousel/ta-web-blog-comp-carousel.component';
import { TaWebBlogCompAuthorComponent } from './blog/comp/ta-web-blog-comp-author/ta-web-blog-comp-author.component';
import { TawSkipCachedFilePipeModule } from 'src/app/pipe/skipCachedFlie';
import { TawEventTrackerDirectiveModule } from 'src/app/directive/event-tracker-directive';

//3RD
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SwiperModule } from 'ngx-swiper-wrapper';//https://github.com/zefoy/ngx-swiper-wrapper
import { TextMaskModule } from 'angular2-text-mask';


@NgModule({
  declarations: [
    //COMPONENT
    TaCheckBoxComponent,
    TaDropDownComponent,
    BreadcrumbComponent,
    CheckboxAccordionComponent,
    CustomPalettePageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    TaAlertPopup,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LogoComponent,
    HeaderMobileComponent,
    MenuMobileComponent,
    MenuBrandsComponent,
    MenuProductsComponent,
    MenuDesignersComponent,
    MenuCustomComponent,
    Menu3dComponent,
    MenuNewsComponent,
    MenuAboutUsComponent,
    SearchComponent,
    GoToTopComponent,
    DesignerDetailPopupComponent,
    TaWishlistPopupComponent,
    AccountPageComponent,
    TaMediaGalleryComponent,
    ShareProductEmailComponent,
    HeaderMobileWithMenuComponent,
    RalphLaurenInquireFormComponent,
    SystemMessageComponent,
    PopupFurnitureCareComponent,
    TaMatSlideToggleModuleComponent,
    TaWebBlogCompCarouselComponent,
    TaWebBlogCompAuthorComponent,

    //DIRECTIVE
    AddProductWishListPopupDirective,
    UserRoleAccessibilityDirective,
    UserRegionAccessibilityDirective,
    TBDClickDirective,
    SocialLinkDirective,
    LoginPopupDirective,
    RegisterPopupDirective,
    RevealPasswordDirective,
    HrefExternalDirective,
    EmailSubscriberDirective,
    IdentityProviderGoogleDirective,

    //PIPE
    IncludeStringPipe,
    TAPipeStringStartswith,
    SirvPipe,
    GetFirstNamePipe,
    Trim,
    //TransformFabricName,
    ProductPipe_IsIncludeOptionGroup,
    TaPipe_JoinString,
  ],
  imports: [
    //CORE
    RouterModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    //ScrollDispatchModule,
    HttpClientModule,
    //3RD
    NgbModule,
    SwiperModule,
    TextMaskModule,
    //MODULE
    TaNgMaterialModule,
    TaNgZorroAntdModule,
    //PIPE
    TawSkipCachedFilePipeModule,
    TawEventTrackerDirectiveModule
  ],
  exports: [
    //CORE
    RouterModule,
    CommonModule,
    FormsModule,
    //ScrollDispatchModule,
    //3RD
    NgbModule,
    SwiperModule,
    TextMaskModule,
    //MODULE
    TaNgMaterialModule,
    TaNgZorroAntdModule,
    //COMPONENT
    TaCheckBoxComponent,
    TaDropDownComponent,
    BreadcrumbComponent,
    CheckboxAccordionComponent,
    CustomPalettePageComponent,
    LoginPageComponent,
    RegisterPageComponent,
    TaAlertPopup,
    HeaderComponent,
    FooterComponent,
    MenuComponent,
    LogoComponent,
    HeaderMobileComponent,
    MenuMobileComponent,
    MenuBrandsComponent,
    MenuProductsComponent,
    MenuDesignersComponent,
    MenuCustomComponent,
    Menu3dComponent,
    MenuNewsComponent,
    MenuAboutUsComponent,
    SearchComponent,
    GoToTopComponent,
    DesignerDetailPopupComponent,
    TaWishlistPopupComponent,
    AccountPageComponent,
    TaMediaGalleryComponent,
    ShareProductEmailComponent,
    HeaderMobileWithMenuComponent,
    RalphLaurenInquireFormComponent,
    SystemMessageComponent,
    TaMatSlideToggleModuleComponent,
    TaWebBlogCompCarouselComponent,
    TaWebBlogCompAuthorComponent,

    //DIRECTIVE
    AddProductWishListPopupDirective,
    UserRoleAccessibilityDirective,
    UserRegionAccessibilityDirective,
    TBDClickDirective,
    SocialLinkDirective,
    LoginPopupDirective,
    RegisterPopupDirective,
    RevealPasswordDirective,
    HrefExternalDirective,
    IdentityProviderGoogleDirective,
    //PIPE
    IncludeStringPipe,
    TAPipeStringStartswith,
    SirvPipe,
    GetFirstNamePipe,
    Trim,
    TawSkipCachedFilePipeModule,
    TawEventTrackerDirectiveModule,
    //TransformFabricName,
    ProductPipe_IsIncludeOptionGroup,
    TaPipe_JoinString,
  ],
  entryComponents: [
    HeaderMobileWithMenuComponent,
    RegisterPageComponent,
    LoginPageComponent,
    TaAlertPopup,
    DesignerDetailPopupComponent,
    TaWishlistPopupComponent,
    TaMediaGalleryComponent,
    RalphLaurenInquireFormComponent,
    PopupFurnitureCareComponent
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TaInterceptor,
      multi: true
    },
    //{ provide: "windowObject", useValue: window}
  ],
})
export class TaCommonModule { }
