import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StepByStepService } from 'src/app/shared/step-by-step.service';


@Component({
  selector: 'app-type-note-modal',
  templateUrl: './type-note-modal.component.html',
  styleUrls: ['./type-note-modal.component.css']
})
export class TypeNoteModalComponent implements OnInit {

  @Output() closeModalQuestions = new EventEmitter<boolean>();

  constructor(private stepByStepService: StepByStepService) { }

  ngOnInit(): void {
  }

  public closeQuestionModal(close: boolean): void {
    this.closeModalQuestions.emit(close);
  }

  public yesDecision() {
    this.stepByStepService.toggleStatusCustomerRequest(true);
    this.stepByStepService.saveStatusComment('customerRequest');
    this.closeModalQuestions.emit(true);
  }

  public noDecision() {
    this.stepByStepService.toggleStatusCustomerRequest(true);
    this.stepByStepService.saveStatusComment('other');
    this.closeModalQuestions.emit(true);
  }

}
