<div class="block_menu_desktop_locations block_menu_desktop_item d-flex justify-content-between align-items-center">
  <a routerLink="/dealer-locator" (click)="onRouterClick()">
    <div class="block_menu_desktop_item_left">
      <h1>SHOWROOMS / STORES</h1>
      <h4>Search</h4>
    </div>
  </a>
  <div class="block_menu_desktop_item_right">
    <!-- <app-dealer-store-world-map></app-dealer-store-world-map> -->
  </div>
</div>
