import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { TaApiWishlistService } from 'src/app/service/api/TaApiWishlist.service';
import { iTaObserverComponent } from 'src/app/theoalex/taObserverComponent';
import { TAUtility } from 'src/app/theoalex/theoalex';

@Component({
  selector: 'app-account-page',
  templateUrl: './account-page.component.html',
  styleUrls: ['./account-page.component.scss']
})
export class AccountPageComponent implements OnInit {


  constructor( ){ }

  ngOnInit() {
  }
}
