import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Collection } from 'src/app/shared/Collection.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class CollectionService {
    list: Collection[] | undefined;
    listData: Collection[] | undefined;
    listMenuTop: Collection[] | undefined;
    listMenuTop001: Collection[] | undefined;
    listMenuTop002: Collection[] | undefined;    
    formData!: Collection;
    formData001!: Collection;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "Collection";
    queryString: string = "";
    constructor(private httpClient: HttpClient) {
        this.initializationFormData();
    }
    initializationFormData() {
        this.list = [];
        this.listData = [];
        this.listMenuTop = [];
        this.listMenuTop001 = [];
        this.listMenuTop002 = [];        
        this.formData = {           
        };
    }
    getByID(ID: string): Observable<TaAPIResponse<Collection>> {
        let url = this.aPIURL + this.controller + '/GetByID';
        const params = new HttpParams()
            .set('ID', ID)
        return this.httpClient.get<TaAPIResponse<Collection>>(url, { params }).pipe(tap((res: any) => {
        }));
    }   
    getByURLCode(URLCode: string): Observable<TaAPIResponse<Collection>> {
        let url = this.aPIURL + this.controller + '/GetByURLCode';
        const params = new HttpParams()
            .set('URLCode', URLCode)
        return this.httpClient.get<TaAPIResponse<Collection>>(url, { params }).pipe(tap((res: any) => {
        }));
    }    
    getByBrand_IDAndIsActiveToList(brand_ID: string, isActive: boolean): Observable<TaAPIResponse<Collection>> {
        let url = this.aPIURL + this.controller + '/GetByBrand_IDAndIsActiveToList';
        const params = new HttpParams()
            .set('brand_ID', brand_ID)
            .set('isActive', JSON.stringify(isActive))
        return this.httpClient.get<TaAPIResponse<Collection>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByIsActiveAndRegionToList(isActive: boolean, region: string): Observable<TaAPIResponse<Collection>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveAndRegionToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
            .set('region', region)
        return this.httpClient.get<TaAPIResponse<Collection>>(url, { params }).pipe(tap((res: any) => {
        }));
    }    
    getByIsActiveAndIsActiveTAUSCovertMenuToList(isActive: boolean, isActiveTAUS: boolean): Observable<TaAPIResponse<Collection>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveAndIsActiveTAUSCovertMenuToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
            .set('isActiveTAUS', JSON.stringify(isActiveTAUS))
        return this.httpClient.get<TaAPIResponse<Collection>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByIsActiveToList(isActive: boolean): Observable<TaAPIResponse<Collection>> {
        let url = this.aPIURL + this.controller + '/GetByIsActiveToList';
        const params = new HttpParams()
            .set('isActive', JSON.stringify(isActive))
        return this.httpClient.get<TaAPIResponse<Collection>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

