import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TAHttpModelGetItemsRequest, TAHttpModelGetItemsRespond } from '../service/api/TaApiServiceProduct';
import { Item } from 'src/app/shared/Item.model';
import { ItemDataTransfer } from 'src/app/shared/ItemDataTransfer.model';
import { TaAPIResponse } from 'src/app/service/api/TaApiServiceCommon';
import { Observable, Observer } from 'rxjs';
import { map, tap } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class ItemService {
    list: Item[] = [];
    listRelated: Item[] = [];
    listOptionGroup: Item[] = [];
    listItemDataTransfer: ItemDataTransfer[] = [];
    listItemDataTransferData: ItemDataTransfer[] = [];
    formData: Item;
    aPIURL: string = environment.appApiUrl + "/api/";
    controller: string = "Item";
    ipAddress: string = "";
    shapeCount: number = 0;
    lifeStyleCount: number = 0;
    collectionCount: number = 0;
    extendingCount: number = 0;
    roomAndUsageCount: number = 0;
    constructor(private httpClient: HttpClient) {
    }
    checkURL(URL: string) {
        return this.httpClient.get(URL).toPromise();
    }
    getByURLCode(URLCode: string): Observable<TaAPIResponse<Item>> {
        let url = this.aPIURL + this.controller + '/GetByURLCode';
        const params = new HttpParams()
            .set('URLCode', URLCode)
        return this.httpClient.get<TaAPIResponse<Item>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getBySKU(SKU: string): Observable<TaAPIResponse<Item>> {
        let url = this.aPIURL + this.controller + '/GetBySKU';
        const params = new HttpParams()
            .set('SKU', SKU)
        return this.httpClient.get<TaAPIResponse<Item>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByID(ID: string): Observable<TaAPIResponse<Item>> {
        let url = this.aPIURL + this.controller + '/GetByID';
        const params = new HttpParams()
            .set('ID', ID)
        return this.httpClient.get<TaAPIResponse<Item>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByIDListToList(IDList: string): Observable<TaAPIResponse<Item>> {
        let url = this.aPIURL + this.controller + '/GetByIDListToList';
        const params = new HttpParams()
            .set('IDList', IDList)
        return this.httpClient.get<TaAPIResponse<Item>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    getByRegionAndExtendingToItemCount(region: string, extending: boolean) {
        let url = this.aPIURL + this.controller + '/GetByRegionAndExtendingToItemCount';
        const params = new HttpParams()
            .set('region', region)
            .set('extending', JSON.stringify(extending))
        return this.httpClient.get<TaAPIResponse<number>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndSearchStringAndInStockProgramAndIsStockedToList(user_ID: string, region: string, searchString: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndSearchStringAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('searchString', searchString)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndExtendingAndInStockProgramAndIsStockedToList(user_ID: string, region: string, extending: boolean, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndExtendingAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('extending', JSON.stringify(extending))
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndType_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, type_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndType_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('type_ID', type_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndShape_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, shape_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndShape_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('shape_ID', shape_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndRoomAndUsage_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, roomAndUsage_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndRoomAndUsage_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('roomAndUsage_ID', roomAndUsage_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndLifeStyle_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, lifeStyle_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndLifeStyle_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('lifeStyle_ID', lifeStyle_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndStyle_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, style_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndStyle_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('style_ID', style_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndCollection_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, collection_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndCollection_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('collection_ID', collection_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndBrand_IDAndInStockProgramAndIsStockedToList(user_ID: string, region: string, brand_ID: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndBrand_IDAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('brand_ID', brand_ID)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndInStockProgramAndIsStockedToList(user_ID: string, region: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndIsActiveTAUSAndCasualLivingAndInStockProgramAndIsStockedToList(user_ID: string, region: string, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndIsActiveTAUSAndCasualLivingAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndIsCFPItemAndInStockProgramAndIsStockedToList(user_ID: string, region: string, isCFPItem: boolean, inStockProgram: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndIsCFPItemAndInStockProgramAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('isCFPItem', JSON.stringify(isCFPItem))
            .set('inStockProgram', JSON.stringify(inStockProgram))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
    asyncGetDataTransferByUser_IDAndRegionAndFiltersAndExtendingAndIsStockedToList(user_ID: string, region: string, room_IDList: string, brand_IDList: string, type_IDList: string, shape_IDList: string, style_IDList: string, lifeStyle_IDList: string, collection_IDList: string, extending: boolean, isStocked: boolean): Observable<TaAPIResponse<ItemDataTransfer>> {
        let url = this.aPIURL + this.controller + '/AsyncGetDataTransferByUser_IDAndRegionAndFiltersAndExtendingAndIsStockedToList';
        const params = new HttpParams()
            .set('user_ID', user_ID)
            .set('region', region)
            .set('room_IDList', room_IDList)
            .set('brand_IDList', brand_IDList)
            .set('type_IDList', type_IDList)
            .set('shape_IDList', shape_IDList)
            .set('style_IDList', style_IDList)
            .set('lifeStyle_IDList', lifeStyle_IDList)
            .set('collection_IDList', collection_IDList)
            .set('extending', JSON.stringify(extending))
            .set('isStocked', JSON.stringify(isStocked))
        return this.httpClient.get<TaAPIResponse<ItemDataTransfer>>(url, { params }).pipe(tap((res: any) => {
        }));
    }
}

