<div id="theodoreIntiaro">
  <div class="contentBox">
    <div class="undertitle">
      <div class="titleBox">
        <a title="TAilor Fit" routerLink="/">
          <img src="https://theodorealexander.com/images/collection_tailor-fit_20221006111555638006517550423044.png"
            alt="TAilor Fit" title="TAilor Fit">
        </a>
        <ng-container *ngIf="typeOfFurniture">
          <div class="categoryName" style="width: 350%; margin-left: -25%">{{typeOfFurniture}}</div>
        </ng-container>
      </div>
      <ng-container *ngIf="showPricing">
        <div *ngIf="showPrice | async" class="price">Price: ${{price}}</div>
      </ng-container>
    </div>
    <hr class="line navBorder topMenu">
    <app-navbar class="topNavbar"></app-navbar>
    <hr class="line navBorder">
    <div class="configuratorOrSummary">
      <app-intiaro-configurator [nextBottomPage]="nextBottomPage" [productId]="productId" [customerName]="customerName"
        [typeOfFurniture]="typeOfFurniture" [showPricing]="showPricing">
      </app-intiaro-configurator>
      <app-summary *ngIf="showSummary" class="summary" [productId]="productId"
        [showPricing]="showPricing"></app-summary>
    </div>
  </div>
</div>